import React, { useEffect, useState } from "react";

//css
import "../theme/homepage.css";
import "../theme/sourcing.css";

//components
import BarChart from "../components/BarChart";
import RadialCharts from "../components/RadialCharts";

//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { filterOfficeNames } from "../utils/Auxiliar/OfficeAux";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import {
  getPreviousSeason,
  filterDataByYear,
  sortRawData,
  constructSalesDataBarChart,
  constructSalesDataRadialChar,
  sortExcelWeekleyRawData,
} from "../utils/Auxiliar/SourcingSalesAux";

//req
import { getCompanyNameById } from "../utils/Requests/CompanyRequest";
import {
  getOffices,
  getOfficeIdByName,
  getOfficeNameById,
} from "../utils/Requests/OfficeReqs";
import { getCurrentSalesByCompanyOfficeWeek } from "../utils/Requests/SalesReq";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";

//interfaces
import { IBarChartData } from "../interfaces/IBarCharData";
import { IRadialChartData } from "../interfaces/IRadialCharData";
import { ISales } from "../interfaces/ISales";

//middleware
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { CSVLink } from "react-csv";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

//constants
import weeksSort from "../utils/statics/week.json";

export interface IChartData {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
}

export interface IExcelWeeklySalesData {
  season: string;
  week: number;
  producer: string;
  office?: string;
  variety: string;
  product: string;
  calibre: number;
  tons: any;
}

interface IState {
  location: {
    state: {
      searchWeek: number;
      searchOffice: string;
      searchProduct: string;
    };
  };
}

const SourcingWeeklySales = ({ location: { state } }: IState) => {
  const { role, officeId, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  let { pathname } = useLocation();
  let id = pathname.split("/")[2];

  const [thisYearRawData, setThisYearRawData] = useState<ISales[]>([]);
  const [lastYearRawData, setLastYearRawData] = useState<ISales[]>([]);

  const [thisYearBarData, setThisYearBarData] = useState<IBarChartData[]>([]);
  const [lastYearBarData, setLastYearBarData] = useState<IBarChartData[]>([]);

  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);
  const [pickedCompany, setPickedCompany] = useState("");

  const [offices, setOfficess] = useState<string[]>([]);
  const [pickedOffice, setPickedOffice] = useState("");

  const [pickedVariety, setPickedVariety] = useState("");

  const [pickedWeek, setPickedWeek] = useState(0);

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [excelWeeklySalesData, setExcelWeeklySalesData] = useState<
    IExcelWeeklySalesData[]
  >([]);

  useEffect(() => {
    if (token) {
      setShow(true);
      setBasicData();
      getPurchaseData();
    }
  }, [pathname]);

  const setBasicData = async () => {
    let { name: companyName } = await getCompanyNameById(id, token);
    setPickedCompany(companyName);
    if (state.searchOffice) {
      setPickedOffice(state.searchOffice);
    } else if (officeId) {
      let { country } = await getOfficeNameById("" + officeId, token);
      setPickedOffice(country);
    }
    setPickedWeek(state.searchWeek);

    let offices = await getOffices(token);
    let sortedOffices = filterOfficeNames(offices);
    setOfficess(sortedOffices);
  };

  const getPurchaseData = async (
    pickedWeek?: number | null,
    pickedOffice?: string
  ) => {
    setIsDataLoading(true);
    handleThisYearData([]);
    handleLastYearData([]);
    let office = pickedOffice ? pickedOffice : state.searchOffice;
    let week = pickedWeek ? pickedWeek : state.searchWeek;

    if (office && office !== "n/a") {
      let { id: officeId } = await getOfficeIdByName(office, token);
      office = officeId;
    } else if (office === "n/a") {
      office = "n/a";
    }
    // else {
    //   office = "" + officeId!;
    // }
    let rawData = await getCurrentSalesByCompanyOfficeWeek(
      +id,
      office,
      week,
      token
    );

    //Export to Excel
    let excelData = sortExcelWeekleyRawData(rawData);
    setExcelWeeklySalesData(excelData);

    if (rawData.length) {
      let sortedData = sortRawData(rawData);
      const season = checkSeasonShort();
      const previousSeason = getPreviousSeason(season);
      const { id: currentSeasonId } = await getSeasonByName(season, token);
      const { id: lastSeasonId } = await getSeasonByName(previousSeason, token);

      let thisYearData = filterDataByYear(sortedData, currentSeasonId);
      let lastYearData = filterDataByYear(sortedData, lastSeasonId);

      handleThisYearData(thisYearData);
      handleLastYearData(lastYearData);
      setIsDataLoading(false);
    } else {
      setThisYearRawData([]);
      setThisYearBarData([]);
      setRadialData([]);
      setLastYearBarData([]);
      setIsDataLoading(false);
    }
  };

  const handleThisYearData = (data: ISales[]) => {
    setThisYearRawData(data);
    let barData = constructSalesDataBarChart(data);
    setThisYearBarData(barData);

    let radialData = constructSalesDataRadialChar(data);
    setRadialData(radialData);
    setPickedVariety(data[0]?.variety!);
  };

  const handleLastYearData = (data: ISales[]) => {
    setLastYearRawData(data);
    let barData = constructSalesDataBarChart(data);
    setLastYearBarData(barData);
  };

  const handlePickedVariety = (variety: string) => {
    setPickedVariety(variety);
    let newRadialData = constructSalesDataRadialChar(thisYearRawData, variety);
    setRadialData(newRadialData);
  };

  const colorCondition = (variety: string) => {
    if (variety === pickedVariety) return true;
    else {
      return false;
    }
  };

  const filterByOffice = (e: any) => {
    let office = e.target.value;
    setPickedOffice(office);
    getPurchaseData(pickedWeek, office);
  };

  const filterByWeek = (e: any) => {
    let week = e.target.value;
    setPickedWeek(week);
    getPurchaseData(week, pickedOffice);
  };

  const weekleySalesHeaders = [
    { label: "Campaña", key: "season" },
    { label: "Semana", key: "week" },
    { label: "Productor", key: "producer" },
    { label: "Oficina", key: "office" },
    { label: "Variedad", key: "variety" },
    { label: "Producto", key: "product" },
    { label: "Calibre", key: "calibre" },
    { label: "Toneladas", key: "tons" },
  ];

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span className="top-panel-text">Compras Semanales</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div style={{ marginTop: "2%", width: "4%", marginLeft: "77%" }}>
        <CSVLink
          data={excelWeeklySalesData}
          headers={weekleySalesHeaders}
          filename={`compras${pickedCompany}${pickedOffice}Semana${pickedWeek}.csv`}
        >
          <button className="btn btn-md text-light export-button">
            Exportar a excel
          </button>
        </CSVLink>
      </div>
      <div
        style={{
          width: "89%",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container">
          <div className="row mb-3 mt-3 d-flex justify-content-between">
            <h4>
              {pickedCompany}
              {pickedOffice && pickedOffice !== "n/a" && " | " + pickedOffice}
              {pickedWeek && ` | Semana ${pickedWeek}`}
            </h4>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
        }}
      >
        <div className="container pl-5">
          <div className="row mb-3 mt-3 pt-2">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <div
              className="circle ml-5 mr-1"
              style={{ backgroundColor: `#cad401` }}
            />
            <span className="pt-1">Año Actual</span>
            <div
              className="circle ml-5"
              style={{ backgroundColor: `darkGrey` }}
            />
            <span className="pt-1 mr-5">Año Anterior</span>
            {role === "sourcing" && (
              <select
                className="form-control form-control-sm ml-5"
                style={{ width: "6.5rem" }}
                data-spy="scroll"
                value={pickedOffice}
                onChange={filterByOffice}
              >
                <option value={"n/a"}>Oficina</option>
                {offices.map((office: string, index: number) => (
                  <option key={index} value={office}>
                    {office}
                  </option>
                ))}
              </select>
            )}
            <select
              className="form-control form-control-sm ml-5"
              style={{ width: "6.5rem" }}
              data-spy="scroll"
              value={pickedWeek}
              onChange={filterByWeek}
            >
              {weeksSort.map(({ week }: any, index: number) => (
                <option key={index} value={week}>
                  {week}
                </option>
              ))}
            </select>
            {Boolean(thisYearBarData.length || lastYearBarData.length) && (
              <BarChart
                data={thisYearBarData}
                data2={lastYearBarData}
                height={[0, 100]}
                width={1050}
                type={"Tn"}
              />
            )}
          </div>
          {isDataLoading && (
            <div
              style={{ marginTop: "-2%" }}
              className="row justify-content-center pb-3"
            >
              <span
                className="spinner-border spinner-border-lg mb-5"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
          {!Boolean(thisYearBarData.length || lastYearBarData.length) &&
          !isDataLoading ? (
            <div className="row justify-content-center pb-3 mt-5">
              <h2 style={{ fontWeight: "bold", marginBottom: "10%" }}>
                No hay datos para esta semana
              </h2>
            </div>
          ) : (
            <div className="row justify-content-center pb-3">
              <span style={{ fontWeight: "bold" }}>Variedades</span>
            </div>
          )}
        </div>
      </div>

      <div
        className="whiteContainer"
        style={{
          minHeight: "180px",
          width: "89%",
        }}
      >
        <div className="container pl-3">
          <h5 className="d-flex justify-content-center">Año Actual</h5>
          <div className="row mb-3 mt-3">
            <div className="col-sm-7 col-12">
              <table className="table text-center smallTable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">PRODUCTOR</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {thisYearBarData.map(({ x: variety, y: tons }, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: colorCondition(variety)
                          ? "#cad401"
                          : "",
                      }}
                      onClick={() => handlePickedVariety(variety)}
                    >
                      <th scope="row">{pickedCompany}</th>
                      <td>{variety}</td>
                      <td>{treatNumber(tons)}</td>
                      <td
                        className="boldFontLink"
                        style={{
                          color: pickedVariety === variety ? "white" : "",
                        }}
                      >
                        {">"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isDataLoading && (
                <div className="row justify-content-center pb-3 mt-4">
                  <span
                    className="spinner-border spinner-border-lg mb-5"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>

            <div className="col-sm-2 col-md-2 col-12">
              <h4>Tn/Calibre</h4>
              {isDataLoading && (
                <div className="row justify-content-center pb-3 mt-5">
                  <span
                    className="spinner-border spinner-border-lg mb-5"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              )}
              <RadialCharts data={radialData} />
            </div>
            <div className="col-sm-2 col-md-2 col-12 text-right">
              <h6 className="mb-4 mr-4 ">
                {pickedVariety ? pickedVariety : thisYearBarData[0]?.x}
              </h6>

              {radialData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  {label !== "Cualquiera" && <p> Calibre {label}: </p>}
                  {label === "Cualquiera" && <p> Sin calibrar: </p>}
                  <span>{angle.toFixed(2)}Tn</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcingWeeklySales;
