import React from "react";
import "../theme/qualityinfo.css";
import "leaflet/dist/leaflet.css";

interface PlantationListItemProps {
  wr: any;
}

const TitleWarehouseReportInfo: React.FC<PlantationListItemProps> = ({
  wr,
}) => {
  return (
    <div className="title-plant-container" style={{ marginLeft: "3.4%" }}>
      <div style={{ fontSize: 18, marginRight: 20, marginLeft: 20 }}>
        {wr?.variety.name}
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 20,
          color: "#cad401",
        }}
      >
        |
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        {wr?.variety.product.name}
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 50,
          fontWeight: 400,
        }}
      >
        Fecha creación parte almacén:{" "}
        {new Date(wr?.createdAt).toLocaleDateString()}{" "}
        {new Date(wr?.createdAt).toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </div>
    </div>
  );
};

export default TitleWarehouseReportInfo;
