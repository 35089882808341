import { IAgribioAvailability } from "../../interfaces/IAgribioAvailability";

export const constructTotalTonsFromVarCal = (data: []) => {
  let totalTons = 0;
  data.map(({ volume }) => {
    totalTons += volume;
  });
  return totalTons;
};

export const sortByWeekAndCompany = (
  companyAvailability: IAgribioAvailability[],
  pickedCompany: string,
  week: number
) => {
  let availabilityByCompany = companyAvailability.filter(({ company }) => {
    return company === pickedCompany;
  });
  let availabilityByWeekAndCompany = availabilityByCompany.filter(
    ({ week: anyWeek }) => {
      return anyWeek === week;
    }
  );
  return availabilityByWeekAndCompany;
};
