import IVariety from "../../interfaces/IVariety";
import IVarietyProduct from "../../interfaces/IVarietyProduct";
import IPlantationVariety from "../../interfaces/IPlantationVariety";
import IPlantation from "../../interfaces/IPlantation";

export const getVarietiesByProduct = (
  pv: IVarietyProduct[],
  e: number,
  sv: any
) => {
  let p: IVariety[] = [];
  pv.forEach((v: IVarietyProduct) => {
    if (v.productId?.toString() === e.toString()) {
      p.push({
        id: v.varietyId || -1,
        name: v.variety,
        plantationVarietyId: v.id,
      });
    }
  });
  //console.log(p);
  sv(p);
  return p;
};

export const getVarietyByProductVarieties = (
  pv: IVarietyProduct[],
  productVarietyId: any
) => {
  let varietyId;
  pv.forEach((v: IVarietyProduct) => {
    if (v.id) {
      if (v.id.toString() === productVarietyId.toString()) {
        varietyId = v.varietyId;
      }
    }
  });
  return varietyId;
};

export const clearPlantationVarieties = (
  pv: IPlantationVariety[],
  plt: IPlantation[]
) => {
  let clean: IPlantationVariety[] = [];
  let aux: IPlantationVariety[] = [];
  plt.forEach((v: IPlantation) => {
    if (v.id) {
      aux = pv.filter((c) => c.plantationId.toString() === v.id.toString());
    }
    clean = clean.concat(aux);
  });

  const resultado = Array.from(new Set(clean.map((s) => s.varietyId)));
  let auxResultado: any[] = [];

  // Eliminamos variedades repetidas

  resultado.forEach((v: number) => {
    let ax = clean.find((s) => s.varietyId === v);
    auxResultado.push({
      varietyId: ax?.varietyId,
      varietyName: ax?.variety.name,
      productName: ax?.variety.product.name,
    });
  });
  return auxResultado;
};

export const selectOwnPltVarieties = (
  pv: IPlantationVariety[],
  plt: IPlantation[]
) => {
  let clean: IPlantationVariety[] = [];
  let aux: IPlantationVariety[] = [];
  plt.forEach((v: IPlantation) => {
    if (v.id) {
      aux = pv.filter((c) => c.plantationId.toString() === v.id.toString());
    }
    clean = clean.concat(aux);
  });
  return clean;
};

export const varietySelected = (
  variety: string,
  pv: any[],
  plt: any[],
  sps: any
) => {
  if (variety.toString() === "Cualquier variedad") {
    sps(plt);
    return plt;
  } else {
    let clean: any[] = [];
    let aux: IPlantationVariety[] = [];
    aux = pv.filter((c) => c.variety.name.toString() === variety.toString());
    if (aux[0].variety.product.name) {
    }
    aux.forEach((v: IPlantationVariety) => {
      let ax = plt.find((s) => s.id === v.plantationId);
      if (ax) {
        clean = clean.concat(ax);
      }
    });

    sps(clean);
    return clean;
  }
};

export const varietySelected2 = (
  variety: string,
  pv: any[],
  plt: any[],
  sps: any
) => {
  if (variety.toString() === "Cualquier variedad") {
    sps(plt);
    return plt;
  } else {
    let clean: any[] = [];
    let aux: IPlantationVariety[] = [];
    aux = pv.filter((c) => c.variety.name.toString() === variety.toString());

    aux.forEach((v: IPlantationVariety) => {
      let ax = plt.find((s) => s.id === v.plantationId);
      if (ax) {
        clean = clean.concat(ax);
      }
    });

    sps(clean);
    return clean;
  }
};

export const getIconUrl = (prods: any[], product: string) => {
  let route: string;
  switch (product.toUpperCase()) {
    case "NARANJA DE MESA":
      route = "/icon/map-varieties/naranja-marker.png";
      break;
    case "LIMÓN":
      route = "/icon/map-varieties/limon-marker.png";
      break;
    case "CLEMENTINA":
      route = "/icon/map-varieties/clementina-marker.png";
      break;
    case "LIMA":
      route = "/icon/map-varieties/lima-marker.png";
      break;
    case "MANDARINA":
      route = "/icon/map-varieties/mandarina-marker.png";
      break;
    case "NARANJA DE ZUMO":
      route = "/icon/map-varieties/naranja-marker.png";
      break;
    case "POMELO":
      route = "/icon/map-varieties/pomelo-marker.png";
      break;
    case "CUALQUIER VARIEDAD":
      route = "/icon/map-varieties/normal-marker.png";
      break;
    default:
      route = "/icon/map-varieties/normal-marker.png";
  }
  return route;
};

export const checkVarietyByName = (name: string) => {
  let route: string;

  switch (name.toUpperCase()) {
    case "CLEMENRUBI":
      route = "/images/varieties/clementina-clemenrubi.png";
      break;
    case "MARISOL":
      route = "/images/varieties/clementina-clemenrubi.png";
      break;
    case "CLEMENRUBI HOJA":
      route = "/images/varieties/clementina-clemenrubi.png";
      break;
    case "ORONULES":
      route = "/images/varieties/clementina-oronules.png";
      break;
    case "ORONULES HOJA":
      route = "/images/varieties/clementina-oronules.png";
      break;
    case "TAHITI":
      route = "/images/varieties/lima-tahiti.png";
      break;
    case "BEARSS":
      route = "/images/varieties/lima-tahiti.png";
      break;
    case "EUREKA":
      route = "/images/varieties/limon-eureka.png";
      break;
    case "PRIMOFIORI":
      route = "/images/varieties/limon-primofiori.png";
      break;
    case "VERDELLI":
      route = "/images/varieties/limon-verdelli.png";
      break;
    case "VERNA":
      route = "/images/varieties/limon-verna.png";
      break;
    case "CLEMENULES":
      route = "/images/varieties/mandarina-clemenules.png";
      break;
    case "LORETINA":
      route = "/images/varieties/mandarina-clemenules.png";
      break;
    case "CLEMENULES HOJA":
      route = "/images/varieties/mandarina-clemenules.png";
      break;
    case "CLEMENVILLA":
      route = "/images/varieties/mandarina-clemenvilla.png";
      break;
    case "CLEMENVILLA HOJA":
      route = "/images/varieties/mandarina-clemenvilla.png";
      break;
    case "HERNANDINA":
      route = "/images/varieties/mandarina-clemenvilla.png";
      break;
    case "SAFOR":
      route = "/images/varieties/mandarina-clemenvilla.png";
      break;
    case "MURCOTT":
      route = "/images/varieties/mandarina-murcott.png";
      break;
    case "MURCOTT HOJA":
      route = "/images/varieties/mandarina-murcott.png";
      break;
    case "NADORCOTT":
      route = "/images/varieties/mandarina-nadorcott.png";
      break;
    case "NADORCOTT HOJA":
      route = "/images/varieties/mandarina-nadorcott.png";
      break;
    case "OROGROSS":
      route = "/images/varieties/mandarina-nadorcott.png";
      break;
    case "ORRI":
      route = "/images/varieties/mandarina-orri.png";
      break;
    case "ORRI HOJA":
      route = "/images/varieties/mandarina-orri.png";
      break;
    case "ORTANIQUE":
      route = "/images/varieties/mandarina-ortanique.png";
      break;
    case "ORTANIQUE HOJA":
      route = "/images/varieties/mandarina-ortanique.png";
      break;
    case "PRIMOSOLE":
      route = "/images/varieties/mandarina-primosole.png";
      break;
    case "PRIMOSOLE HOJA":
      route = "/images/varieties/mandarina-primosole.png";
      break;
    case "TANGO":
      route = "/images/varieties/mandarina-tango.png";
      break;
    case "TANGO HOJA":
      route = "/images/varieties/mandarina-tango.png";
      break;
    case "BARBERINA":
      route = "/images/varieties/naranja-mesa-barberina.png";
      break;
    case "LANE LATE":
      route = "/images/varieties/naranja-mesa-lanelate.png";
      break;
    case "NAVEL":
      route = "/images/varieties/naranja-mesa-navelpowell.png";
      break;
    case "NAVEL LATE":
      route = "/images/varieties/naranja-mesa-navellate.png";
      break;
    case "NAVELINA":
      route = "/images/varieties/naranja-mesa-navelina.png";
      break;
    case "NAVEL POWELL":
      route = "/images/varieties/naranja-mesa-navelpowell.png";
      break;

    case "CHISLETT":
      route = "/images/varieties/naranja-mesa-navelpowell.png";
      break;
    case "NEWHALL":
      route = "/images/varieties/naranja-mesa-navelpowell.png";
      break;
    case "SALUSTIANA":
      route = "/images/varieties/naranja-zumo-salustiana.png";
      break;
    case "CASTELLANA":
      route = "/images/varieties/naranja-zumo-salustiana.png";
      break;
    case "VALENCIA LATE":
      route = "/images/varieties/naranja-zumo-valencialate.png";
      break;
    case "STAR RUBY":
      route = "/images/varieties/pomelo.png";
      break;
    case "MARSH":
      route = "/images/varieties/pomelo.png";
      break;
    default:
      route = "/images/varieties/agribio.png";
  }

  return route;
};

export const searchByVariety = (
  variety: string,
  product: string,
  setPlantationsSearch: any,
  setPlantationsTable: any,
  plantations: any,
  products: any,
  varieties: any,
  setVarietiesShow: any,
  setProductsShow: any
) => {
  if (
    // todos los productos / todas las variedades
    product === "Todos los productos" &&
    variety === "Cualquier variedad"
  ) {
    setVarietiesShow(varieties);
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 10));
    setProductsShow(products);
  } else if (
    // todos los productos / variedad concreta
    product === "Todos los productos" &&
    variety !== "Cualquier variedad"
  ) {
    let ax: any[] = plantations.filter(
      (s: any) => s.variety.name.toString() === variety
    );
    setPlantationsSearch(ax);
    setPlantationsTable(ax.slice(0, 10));
    let prod: any[] = [];
    prod.push(
      products.find(
        (s: any) => s.productName.toString() === ax[0].variety.product.name
      )
    );

    setProductsShow(prod);
  } else if (
    // producto concreto / todas las variedades
    product !== "Todos los productos" &&
    variety === "Cualquier variedad"
  ) {
    setVarietiesShow(varieties);
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 10));
    setProductsShow(products);
  } else if (
    // producto concreto / variedad concreta
    product !== "Todos los productos" &&
    variety !== "Cualquier variedad"
  ) {
    let ax: any[] = plantations.filter(
      (s: any) => s.variety.name.toString() === variety
    );
    setPlantationsSearch(ax);
    setPlantationsTable(ax.slice(0, 10));
    let prod: any[] = [];
    prod.push(
      products.find(
        (s: any) => s.productName.toString() === ax[0].variety.product.name
      )
    );

    setProductsShow(prod);
  }
};

export const collectVarieties = (pt: any[]) => {
  let aux: any = [];

  let resultado: any = Array.from(new Set(pt.map((s) => s.variety.id))).map(
    (id) => {
      return {
        id: id,
        varietyName: pt.find((s) => s.variety.id === id).variety.name,
        productName: pt.find((s) => s.variety.id === id).variety.product.name,
      };
    }
  );

  return aux.concat(resultado);
};

export const sortVarietiesByCompany = (unsortedVarieties: any) => {
  let varieties: any[] = [];
  unsortedVarieties.map(({ plantationVarieties }: any) => {
    plantationVarieties.map(({ variety }: any) => {
      if (!varieties.find((v: any) => variety.name === v.name)) {
        varieties.push({ name: variety.name, id: variety.id });
      }
    });
  });

  return varieties;
};

export const sortVarietiesByCompanyWithId = (unsortedVarieties: any) => {
  let varieties: any[] = [];
  unsortedVarieties.map(({ plantationVarieties }: any) => {
    plantationVarieties.map(({ variety }: any) => {
      let repetido: boolean = false;
      varieties.forEach((v: any) => {
        if (variety.name === v.name) {
          repetido = true;
        }
      });
      if (!repetido) {
        varieties.push({ name: variety.name, id: variety.id });
      }
    });
  });
  return varieties;
};
