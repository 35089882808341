import React, { useEffect } from "react";
import "react-vis/dist/style.css";

import {
  XYPlot,
  XAxis, // Shows the values on x axis
  YAxis, // Shows the values on y axis
  VerticalBarSeries,
  HorizontalGridLines,
} from "react-vis";

const BarChartPurchase = ({ data }) => {
  useEffect(() => {
    let max = 0;
    data.forEach((element) => {
      if (element.y > max) {
        max = element.y;
      }
    });
  }, []);

  return (
    <div style={{ marginTop: 10 }}>
      <XYPlot
        style={{ marginTop: 20 }}
        xType="ordinal"
        width={800}
        height={300}
      >
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <VerticalBarSeries color={"#cad401"} data={data} barWidth={0.2} />
      </XYPlot>
    </div>
  );
};
export default BarChartPurchase;
