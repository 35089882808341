import React, { useState } from "react";
import "react-vis/dist/style.css";

import {
  XYPlot,
  XAxis, // Shows the values on x axis
  YAxis, // Shows the values on y axis
  VerticalBarSeries,
  LabelSeries,
  HorizontalGridLines,
} from "react-vis";

import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

const BarChart = ({ data, data2, height, width, type }) => {
  let secondBar = data2 ?? true;

  let yAxisHeight = height;

  const [points, setPoints] = useState([]);
  const [points2, setPoints2] = useState([]);

  const handleOnMouseLeave = () => {
    setPoints([]);
    setPoints2([]);
  };
  return (
    <XYPlot
      xType="ordinal"
      width={width}
      height={300}
      yDomain={yAxisHeight}
      onMouseLeave={handleOnMouseLeave}
    >
      {/* <VerticalGridLines /> */}
      <HorizontalGridLines />
      <XAxis />
      <YAxis />
      <VerticalBarSeries
        color={"#cad401"}
        data={data}
        barWidth={0.3}
        onNearestX={(v) => setPoints([v])}
      />
      {secondBar && (
        <VerticalBarSeries
          color={"darkGrey"}
          data={data2}
          barWidth={0.3}
          onNearestX={(v) => setPoints2([v])}
        />
      )}

      <LabelSeries
        // style={{ display: points ? "none" : "" }}
        data={points.map((obj) => {
          return { ...obj, label: treatNumber(obj.y) + type };
        })}
        labelAnchorX="end"
        labelAnchorY="text-after-edge"
      />
      <LabelSeries
        // style={{ display: points2 ? "none" : "" }}
        data={points2.map((obj) => {
          return { ...obj, label: treatNumber(obj.y) + type };
        })}
        labelAnchorX="start"
        labelAnchorY="text-after-edge"
      />
    </XYPlot>
  );
};
export default BarChart;
