import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { RouteComponentProps } from "react-router-dom";
import ICutOrder from "../interfaces/ICutOrder";
import {
  getSingleCutOrderById,
  getSingleCutOrderCalibreById,
} from "../utils/Requests/CutOrderRequest";
import { checkVarietyByName } from "../utils/Auxiliar/VarietyAuxiliar";
import ICutCalibre from "../interfaces/ICutCalibre";
import { IRadialChartData } from "../interfaces/IRadialCharData";
import { constructReportDataRadialChar } from "../utils/Auxiliar/CutOrderAuxiliar";
import RadialCharts from "../components/RadialCharts";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "@material-ui/core";
import {
  getCutOrderIdByHarvestReportId,
  getSingleHarvestReportById,
  getHarvestCalibreByCutOrderId,
} from "../utils/Requests/HarvestReportRequest";
import IHarvestReport from "../interfaces/IHarvestReport";
import {
  conditionRoute,
  equivalentCondition,
  treatNumber,
} from "../utils/Auxiliar/EquivalentAuxiliar";
import { getHarvestReportImagesById } from "../utils/Requests/AttachmentsRequest";
import { baseURL } from "../utils/constants";
import INotification from "../interfaces/INotification";
import { deleteNotificationById } from "../utils/Requests/NotificationRequest";
import { useSelector, useDispatch } from "react-redux";
import { RemoveNotificationAction } from "../redux/actions";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

interface IEditedCutOrder {
  pickedCalibre: string;
  tons: number;
}

interface IEditedHarvestReport {
  pickedCalibre: string;
  tons: number;
}

interface HarvestReportViewProps extends RouteComponentProps<{ id: string }> {}

const HarvestReportView: React.FC<HarvestReportViewProps> = ({ match }) => {
  const [showFin, setShowFin] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");

  const [showImages, setShowImages] = useState<boolean>(false);

  const [cutOrder, setCutOrder] = useState<ICutOrder>();

  const [cutCalibres, setCutCalibres] = useState<ICutCalibre[]>([]);
  const [editedCutOrder, setEditedCutOrder] = useState<IEditedCutOrder[]>([]);
  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);

  const [ruta, setRuta] = useState<string>("");
  const [arrive, setArrive] = useState<any>();

  const [harvestReport, setHarvestReport] = useState<IHarvestReport>();
  const [harvestReportToEdit, setHarvestReportToEdit] = useState<
    IHarvestReport
  >();

  const [radialHarvestData, setRadialHarvestData] = useState<
    IRadialChartData[]
  >([]);
  const [editedHarvestReport, setEditedHarvestReport] = useState<
    IEditedHarvestReport[]
  >([]);

  const [arriveReport, setArriveReport] = useState<any>();

  const [images, setImages] = useState<any[]>([]);
  const dispatch = useDispatch();
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    let local: INotification | undefined = notifications?.find(
      (n: INotification) =>
        n.docId === parseInt(match.params.id) && n.type === 3
    );

    if (local) {
      dispatch(RemoveNotificationAction(local?.id!));
      // eliminarlo de la base de datos
      deleteNotificationById(local?.id!, token)
        .then((not: any) => {})
        .catch((error: string) => {
          setAlertText(error.toString());
          setShowAlert(true);
        });
    }

    getCutOrderIdByHarvestReportId(parseInt(match.params.id), token)
      .then((response) => {
        getSingleCutOrderById(response.cutOrderId, token)
          .then((cutOrder) => {
            setCutOrder(cutOrder!);
            editCutOrderData(cutOrder!);
            setArrive(cutOrder?.arrive);
            setRuta(
              checkVarietyByName(cutOrder?.plantationVarieties?.variety?.name)
            );

            getHarvestCalibreByCutOrderId(response?.cutOrderId, token)
              .then((harvestCalibreToEdit) => {
                setHarvestReportToEdit(harvestCalibreToEdit);
                editHarvestReportData(harvestCalibreToEdit);
              })
              .catch((error: string) => {
                setAlertText(error.toString());
                setShowAlert(true);
              });

            getHarvestReportImagesById(parseInt(match.params.id), token)
              .then((response) => {
                setImages(response);
              })
              .catch((error: string) => {
                setAlertText(error.toString());
                setShowAlert(true);
              });

            getSingleCutOrderCalibreById(response.cutOrderId, token)
              .then((cutCalibres) => {
                setCutCalibres(cutCalibres);
              })
              .catch((error: string) => {
                setAlertText(error.toString());
                setShowAlert(true);
              });
          })
          .catch((error: string) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      })
      .catch((error: string) => {
        setAlertText(error.toString());
        setShowAlert(true);
      });

    getSingleHarvestReportById(parseInt(match.params.id), token)
      .then((harvestReport) => {
        setHarvestReport(harvestReport);
        setArriveReport(harvestReport.createdAt);
      })
      .catch((error: string) => {
        setAlertText(error.toString());
        setShowAlert(true);
      });
  }, [notifications, token]);

  const editCutOrderData = (cutOrder: any) => {
    let editedCutOrder: IEditedCutOrder[] = [];
    cutOrder?.cutCalibres?.map((c: any) => {
      editedCutOrder.push({
        pickedCalibre: c.calibre.code,
        tons: c.volume / 1000,
      });
    });

    setEditedCutOrder(editedCutOrder);
    let radialData = constructReportDataRadialChar(editedCutOrder);
    setRadialData(radialData);
  };

  const editHarvestReportData = (harvestReportToEdit: any) => {
    let editedHarvestReport: IEditedHarvestReport[] = [];
    harvestReportToEdit?.harvestCalibres?.map((c: any) => {
      editedHarvestReport.push({
        pickedCalibre: c.calibre.code,
        tons: c.volume / 1000,
      });
    });

    setEditedHarvestReport(editedHarvestReport);
    let radialHarvestData = constructReportDataRadialChar(editedHarvestReport);
    setRadialHarvestData(radialHarvestData);
  };

  const showHarvestReportImages = () => {
    setShowImages(true);
  };

  let options = { weekday: "short", month: "short", day: "numeric" };
  let volume = (
    cutCalibres.reduce((a: any, c: any) => a + c.volume, 0) / 1000
  ).toFixed(2);

  let productStatus = conditionRoute(harvestReport?.condition?.toString()!);

  return (
    <div className="div-principal">
      <div className="top-panel2">
        <div>Parte de recogida</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>

      <Modal
        show={showFin}
        onHide={() => {
          setShowFin(false);
        }}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Alerta</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>¿Deseas eliminar la orden de corte?</Modal.Body>

        <Modal.Footer className="footer-modal">
          <Button
            // onClick={deleteSingleCutOrder}
            className="send-btnCO"
            type="button"
            style={{
              color: "white",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
            }}
          >
            Aceptar
          </Button>
          <Button
            onClick={() => setShowFin(false)}
            className="send-button"
            type="button"
            style={{
              color: "white",
              width: "70px",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
              backgroundColor: " #707070 ",
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="container ml-3"
        style={{ display: "flex" }}
      >
        <div className="row main-containersHR">
          <div className="col-6">
            <div className="div-codeHR">Código AGR-{harvestReport?.id}</div>
            <img
              className="list-iconC"
              src={ruta}
              style={{ paddingTop: "40%" }}
            ></img>
          </div>
          <div className="col-6">
            <div className="div-weekHR" style={{ marginTop: "5%" }}>
              Semana {setWeekNumber(harvestReport?.createdAt)}
            </div>
            <div
              className="list-detail"
              style={{ paddingTop: "40%", fontSize: "16px" }}
            >
              {cutOrder?.plantationVarieties?.variety?.name}
            </div>
            <span
              className="list-title"
              style={{ color: "#cad401", fontWeight: "bold", fontSize: "16px" }}
            >
              {cutOrder?.plantationVarieties.plantation.name}
            </span>
            <div className="li" style={{ fontSize: "16px" }}>
              {cutOrder?.plantationVarieties.plantation.location.name} ,{" "}
              {cutOrder?.plantationVarieties.plantation.location.province.name}
            </div>
          </div>
        </div>

        <div className=" main-containersHR2">
          <div className="row">
            <div
              className="col-6"
              style={{ textAlign: "center", paddingTop: "2%" }}
            >
              <h5>Solicitado en orden de corte</h5>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "center", paddingTop: "2%" }}
            >
              <h5>Datos de la recogida</h5>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "2%" }}>
            <div className="col-6" style={{ textAlign: "center" }}>
              <span style={{ fontWeight: "bold" }}>Volumen:</span>{" "}
              {treatNumber(parseFloat(volume))}
              {""} Tn
            </div>
            <div className="col-6" style={{ textAlign: "center" }}>
              <span style={{ fontWeight: "bold" }}>Volumen Estimado:</span>{" "}
              {treatNumber(harvestReport?.volumeEstimated! / 1000)}
              {""} Tn
            </div>
          </div>

          <div className="row" style={{ display: "flex", paddingTop: "4%" }}>
            <div className="col-2">
              <div style={{ paddingLeft: "1%", fontSize: "14px" }}>
                <RadialCharts data={radialData} />
              </div>
            </div>
            <div className="col-4" style={{ fontSize: "14px" }}>
              <h6 style={{ marginLeft: "50px" }}>Tn/calibre</h6>
              {radialData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  {label !== "Cualquiera" && <p> Calibre {label} : </p>}
                  {label === "Cualquiera" && <p> Sin calibrar : </p>}
                  <span>{treatNumber(angle)}Tn</span>
                </div>
              ))}
            </div>
            <div className="col-2">
              <div style={{ paddingLeft: "1%", fontSize: "14px" }}>
                <RadialCharts data={radialHarvestData} />
              </div>
            </div>
            <div className="col-4" style={{ fontSize: "14px" }}>
              <h6 style={{ marginLeft: "50px" }}>Tn/calibre</h6>
              {radialHarvestData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  {label !== "Cualquiera" && <p> Calibre {label} : </p>}
                  {label === "Cualquiera" && <p> Sin calibrar : </p>}
                  <span>{treatNumber(angle)}Tn</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showImages}
        onHide={() => {
          setShowImages(false);
        }}
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Carousel>
            {images.map(({ id, url }: any) => (
              <Carousel.Item key={id}>
                <div
                  className="image-carousel"
                  style={{
                    backgroundImage: `url(${baseURL}${url})`,
                  }}
                ></div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      <div
        className="container ml-3"
        style={{ display: "flex"}}
      >
        <div className="main-containersHR3">
          <div
            className="row"
            style={{ textAlign: "center", paddingTop: "5%" }}
          >
            <div className="col-6">
              <h5>Llegada a almacén</h5>
              <div className="row" style={{ textAlign: "center" }}>
                <div
                  className="col-6"
                  style={{ borderRight: "2px solid #cad401", marginTop: "4%" }}
                >
                  Requerida
                  <div>
                    <h5>
                      {new Date(arrive).toLocaleTimeString(navigator.language, {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      horas
                    </h5>
                    <span>
                      {new Date(arrive).toLocaleDateString("es-ES", options)}
                    </span>
                  </div>
                </div>
                <div className="col-6" style={{ marginTop: "4%" }}>
                  Real
                  <div>
                    <h5>
                      {new Date(arriveReport).toLocaleTimeString(
                        navigator.language,
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}{" "}
                      horas
                    </h5>
                    <span>
                      {new Date(arriveReport).toLocaleDateString(
                        "es-ES",
                        options
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5>Estado del producto</h5>
              <div style={{ paddingTop: "7%" }}>
                {<img className="product-status" src={productStatus}></img>}
              </div>
              <div style={{ marginTop: "5%" }}>
                <span>{equivalentCondition(harvestReport?.condition!)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className=" main-containersHR4">
          <div
            className="row"
            style={{ textAlign: "center", paddingTop: "5%" }}
          >
            <div className="col-6" style={{ textAlign: "center" }}>
              <h5>Comentario Jefe de Campo</h5>
              {harvestReport?.summary?.length ? (
                <div>{harvestReport?.summary}</div>
              ) : (
                <div>No hay comentario</div>
              )}
            </div>
            <div className="col-6" style={{ textAlign: "center" }}>
              <h5>Archivos Multimedia</h5>
              <img
                className="icon-multimedia mt-3"
                src="/icon/fotos.png"
                onClick={() => {
                  showHarvestReportImages();
                }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarvestReportView;
