import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import ModalPopup from "../components/ModalPopup";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import {
  getWarehouseReportsById,
  deleteWarehouseReportById,
  deleteWarehouseCalibreById,
  postHarvestReportAssociated,
} from "../utils/Requests/WarehouseRequest";
import TitleWarehouseReportInfo from "../components/TitleWarehouseReportInfo";
import RadialChartWarehouse from "../components/RadialChartWarehouse";
import { Link } from "react-router-dom";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
interface PlantationViewProps extends RouteComponentProps<{ id: string }> {}

const WarehouseReportView: React.FC<PlantationViewProps> = ({ match }) => {
  const [report, setReport] = useState<any>();
  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState("Error");
  const [mostrar, setMostrar] = useState<boolean>(true);
  const { token, companyId } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token && companyId) {
      getWarehouseReportsById(match.params.id, token).then((rep: any) => {
        if (rep.companyId === companyId) {
          setReport(rep);
        } else {
          setMostrar(false);
        }
      });
    }
  }, [match.params.id, token, companyId]);

  const deleteWarehouseReport = async () => {
    deleteWarehouseReportById(report.id, token)
      .then((response: any) => {
        report.warehouseCalibres.forEach((wc: any) => {
          deleteWarehouseCalibreById(wc.id, token)
            .then((res: any) => {})
            .catch((error: any) => {
              setModalText(error.toString());
              setShow(true);
            });
        });

        report.harvestReports.forEach((hr: any) => {
          postHarvestReportAssociated(null, hr.id, token)
            .then((res: any) => {})
            .catch((error: any) => {
              setModalText(error.toString());
              setShow(true);
            });
        });
        setTimeout(() => openModalConfirm(), 600);
      })
      .catch((error: any) => {
        setModalText(error.toString());
        setShow(true);
      });
  };

  const openModalConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Parte de almacén eliminado correctamente.",
      showCancelButton: false,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
      customClass: {
        // cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/partes-almacen");
      }
    });
  };
  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Quieres eliminar un parte de almacén?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        deleteWarehouseReport();
      }
    });
  };
  return (
    <div className="div-principal3">
      <div className="top-panel">
        <div className="top-panel-text">Parte de almacén</div>

        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {mostrar ? (
        <>
          {" "}
          <div className="button-flex-warehouse">
            <TitleWarehouseReportInfo wr={report} />
            <div
              className="button-flex-warehouse"
              style={{ textAlign: "center", marginTop: 30, marginLeft: 20 }}
            >
              <button
                className="edit-report-button"
                onClick={() =>
                  history.push("/editar-parte-almacen/" + report.id)
                }
              >
                <div className="button-flex-warehouse">
                  <div>
                    <svg
                      width="15px"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="pencil-alt"
                      className="svg-inline--fa fa-pencil-alt fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                      ></path>
                    </svg>
                  </div>
                  <div style={{ marginLeft: 10 }}>Editar</div>
                </div>
              </button>
              <button
                className="delete-report-button"
                onClick={openModalDelete}
              >
                <div className="button-flex-warehouse">
                  <div>
                    <svg
                      style={{ marginBottom: 2 }}
                      width="12"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="trash-alt"
                      className="svg-inline--fa fa-trash-alt fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="middle-panel" style={{ marginTop: 14 }}>
            <RadialChartWarehouse report={report} />

            <div className="graf-panel-purchase" style={{ width: "60%" }}>
              <div style={{ fontSize: 20, color: "#515258", fontWeight: 500 }}>
                Partes de recogida asociados
              </div>
              <div className="graf2-panel-purchase">
                <table
                  className="table"
                  style={{
                    textAlign: "center",
                    background: "white",
                    fontSize: 15,
                    marginLeft: 30,
                    marginRight: 30,
                    marginTop: 10,
                  }}
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CÓDIGO</th>
                      <th scope="col">FINCA</th>
                      <th scope="col">PARTE DE RECOGIDA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {report ? (
                      <>
                        {report.harvestReports.length > 0 ? (
                          <>
                            {report.harvestReports.map((wr: any) => (
                              <tr key={wr.id}>
                                <td>AGR-{wr.id}</td>
                                <td>
                                  {
                                    wr.cutOrder.plantationVarieties.plantation
                                      .name
                                  }
                                </td>
                                <td>
                                  <Link to={`/parte-recogida/${wr.id}`}>
                                    <b style={{ color: "#cad401" }}>Ver</b>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ModalPopup show={show} setShow={setShow} modalText={modalText} />
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Parte de almacén privado
        </div>
      )}
    </div>
  );
};

export default WarehouseReportView;
