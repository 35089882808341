import React, { useState, useEffect } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import {
  getAllCompanies,
  editCompany,
  createCompany,
} from "../../utils/Requests/CompanyRequest";
import ModalPopup from "../../components/ModalPopup";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import Modal from "react-bootstrap/Modal";
import { normalizer } from "../../utils/Auxiliar/EquivalentAuxiliar";

//interfaces
import { ICompany } from "../../interfaces/ICompany";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";

interface ICompanyShort {
  id: number;
  name: string;
}

const AdminCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companiesToShow, setCompaniesToShow] = useState<ICompanyShort[]>([]);

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [numberShow, setNumberShow] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");

  //edit modal
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editCompanyId, setEditCompanyId] = useState(0);
  const [editCompanyName, setEditCompanyName] = useState("");
  const [editCompanyType, setEditCompanyType] = useState<number>(0);
  const [errorText, setErrorText] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      loadCompanies();
    }
  }, [token]);

  const loadCompanies = () => {
    getAllCompanies(token).then((companies) => {
      setCompanies(companies);
      setCompaniesToShow(companies);
    });
  };

  const searchHanlder = ({ target: { value } }: any) => {
    setSearchText(value);
    let filteredCompanies = companies.filter(({ name }) => {
      return normalizer(name.toLowerCase()).includes(normalizer(value));
    });
    setCompaniesToShow(filteredCompanies);
  };

  const openEditModal = (id: number, name: string, type: number) => {
    setEditCompanyId(id);
    setEditCompanyName(name);
    setEditCompanyType(type);
    setShowEditModal(true);
  };

  const confirmCompany = async () => {
    if (!editCompanyName) {
      setErrorText("Debes añadir un nombre de productor");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    let postInfo = {
      name: editCompanyName,
      type: editCompanyType,
      createdAt: new Date(),
    };
    createCompany(postInfo, token).then((response) => {
      if (response) {
        setConfirmationText("Productor creado correctamente");
        setTimeout(() => setConfirmationText(""), 800);
        setTimeout(() => restartModal(), 800);
        loadCompanies();
      }
    });
  };

  const confirmEditCompany = async () => {
    if (!editCompanyName) {
      setErrorText("Debes añadir un nombre de productor");
      setTimeout(() => setErrorText(""), 1500);
      return;
    }
    let patchInfo = {
      name: editCompanyName,
      type: editCompanyType,
      updatedAt: new Date(),
    };
    editCompany(patchInfo, editCompanyId, token).then((response) => {
      if (response) {
        setConfirmationText("Productor actualizado correctamente");
        loadCompanies();
        setTimeout(() => setConfirmationText(""), 800);
        setTimeout(() => restartModal(), 800);
      }
    });
  };

  const restartModal = () => {
    setEditCompanyId(0);
    setEditCompanyName("");
    setEditCompanyType(0);
    setShowEditModal(false);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">
          Productores {`(${companies.length})`}
        </div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      <div className="select-divider-search d-flex justify-content-start mt-4">
        <Form.Control
          className="search-select ml-3"
          size="sm"
          type="text"
          placeholder="Buscar productores"
          value={searchText}
          onChange={searchHanlder}
          style={{ width: 170, height: 31, marginLeft: 1 }}
        />
        <button
          style={{ width: "11rem" }}
          className="button-search mr-4"
          onClick={() => setShowEditModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> <b>Crear Productor</b>
        </button>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-6">
            <table
              className="table ml-3"
              style={{ textAlign: "center", background: "white" }}
            >
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">PRODUCTOR</th>
                  <th scope="col">CON PARTES DE ALMACÉN</th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {companiesToShow
                  .slice(0, numberShow)
                  .map(({ id, name, type }: any) => (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>{Boolean(type) ? "Si" : "No"}</td>
                      <td>
                        <button
                          className="btn btn-sm text-light mr-3"
                          style={{ backgroundColor: "#cad401" }}
                          onClick={() => openEditModal(id, name, type)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {companiesToShow.length > numberShow && (
              <div className="more-button-align">
                <button
                  className="more-button"
                  onClick={() => setNumberShow(numberShow + 5)}
                >
                  Ver más
                </button>
              </div>
            )}
          </div>
          <div className="col-6" />
        </div>
      </div>

      <Modal show={Boolean(showEditModal)} onHide={restartModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!editCompanyId ? "Crear" : "Editar"} Productor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Nombre del Productor</label>
              <input
                type="text"
                placeholder="Nombre del productor"
                className="form-control"
                value={editCompanyName}
                onChange={({ target: { value } }) => setEditCompanyName(value)}
              />
            </div>
            <div className="form-group">
              <label>Tipo</label>
              <select
                className="form-control"
                value={editCompanyType}
                onChange={({ target: { value } }) => setEditCompanyType(+value)}
              >
                <option value="1">Con Partes de Almacén</option>
                <option value="0">Sin Partes de Almacén</option>
              </select>
            </div>
            <p className="text-danger text-center">{errorText}</p>
            <p className="text-center">{confirmationText}</p>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn text-white"
            style={{ backgroundColor: "#cad401" }}
            onClick={editCompanyId ? confirmEditCompany : confirmCompany}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminCompanies;
