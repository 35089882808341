import React, { useState, useEffect } from "react";
import "../../theme/plantationslist.css";
import "../../theme/admin.css";

//middleware
import produce from "immer";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

//reqs
import {
  getAllCompanies,
  getCompanyNameById,
  getCompanyIdByName,
} from "../../utils/Requests/CompanyRequest";
import {
  getOffices,
  getOfficeNameById,
  getOfficeIdByName,
} from "../../utils/Requests/OfficeReqs";
import {
  getUser,
  editUser,
  saveProvinceUserRelation,
  getProvinceUserRelations,
  deleteProvinceUserRelations,
  saveNewUser,
} from "../../utils/Requests/UserRequests";
import {
  getProvinces,
  getProvinceIdByName,
  getProvinceNameById,
} from "../../utils/Requests/ProvincesReq";

//aux
import { filterOfficeNames } from "../../utils/Auxiliar/OfficeAux";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { sortProvinces } from "../../utils/Auxiliar/ProvinceAuxiliar";
import history from "../../utils/Auxiliar/HistoryAuxiliar";
import { validateEmail } from "../../utils/Auxiliar/UsersAux";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";

//interfaces
import { ICompany } from "../../interfaces/ICompany";

const EditUserAdmin: React.FC = () => {
  let { pathname } = useLocation();
  let userId = +pathname.split("/")[2];

  //user
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [surname1, setSurname1] = useState("");
  const [surname2, setSurname2] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [office, setOffice] = useState("");

  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [offices, setOfficess] = useState<string[]>([]);
  const [provinces, setProvinces] = useState<string[]>([]);
  const [pickedProvinces, setPickedProvinces] = useState<string[]>([]);
  const [provinceValue, setProvinceValue] = useState<string>(provinces[0]);
  const [inputProvinceValue, setInputProvinceValue] = useState("");

  const [errorText, setErrorText] = useState("");

  //modal password
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      loadCompanies();
      loadOffices();
      loadProvinces();
      Boolean(userId) && loadUser();
    }
  }, [token]);
  const loadUser = async () => {
    const {
      name,
      surname1,
      surname2,
      role,
      email,
      username,
      companyId,
      officeId,
    } = await getUser(userId, token);
    let companyName = "";
    let officeName = "";
    if (companyId) {
      let { name } = await getCompanyNameById(companyId, token);
      companyName = name;
    }
    if (officeId) {
      let { country } = await getOfficeNameById(officeId, token);
      officeName = country;
    }

    setName(name);
    setSurname1(surname1);
    setSurname2(surname2);
    setRole(role);
    setEmail(email);
    setUsername(username);
    setCompany(companyName);
    setOffice(officeName);

    role === "fieldManager" && loadUserProvinces();
  };

  const loadCompanies = () => {
    getAllCompanies(token).then((companies) => {
      setCompanies(companies);
    });
  };

  const loadOffices = async () => {
    let offices = await getOffices(token);
    let sortedOffices = filterOfficeNames(offices);
    setOfficess(sortedOffices);
  };

  const loadProvinces = async () => {
    let provinces = await getProvinces(token);
    let sortedProvinces = sortProvinces(provinces);
    setProvinces(sortedProvinces);
  };

  const loadUserProvinces = async () => {
    let userProvinces = await getProvinceUserRelations(userId, token);
    let loadingProvinces: string[] = [];
    Promise.all(
      userProvinces.map(async ({ provinceId }: any) => {
        let { name } = await getProvinceNameById(provinceId, token);
        loadingProvinces.push(name);
      })
    ).then(() => {
      setPickedProvinces(loadingProvinces);
    });
  };

  const onTagsChange = (event: any, value: any) => {
    setProvinceValue(value);
    setProvinces(provinces.filter((province) => province !== value));

    value !== null &&
      setPickedProvinces((state) => {
        const newProvince = produce(state, (drafState) => {
          drafState.push(value);
        });
        return newProvince;
      });
  };

  const removePickedProvince = (value: string) => {
    setProvinces((state) => {
      const newProvinces = produce(state, (drafState) => {
        drafState.splice(0, 0, value);
      });
      return newProvinces;
    });
    setPickedProvinces(
      pickedProvinces.filter((province) => province !== value)
    );
  };

  const openCreateUserModal = () => {
    if (!username || !name || !surname1 || !email || !role) {
      setErrorText("Por favor rellena todos los campos");
      setTimeout(() => setErrorText(""), 3000);
      return;
    } else if (!validateEmail(email)) {
      setErrorText("Formato de email inválido");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    if (role === "fieldManager" && !pickedProvinces.length) {
      setErrorText("Debes asociar al menos una provincia al Jefe de Campo");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    Swal.fire({
      icon: "question",
      text: "¿Quieres crear un nuevo usuario?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(async ({ value }) => {
      if (value) {
        let companyId = 0;
        let officeId = 0;
        let companyCondition =
          role === "producer" ||
          role === "fieldManager" ||
          role === "quality" ||
          role === "qualityAssistant";

        companyCondition ? (companyId = 1) : (companyId = 0);

        if (company && companyCondition) {
          let { id } = await getCompanyIdByName(company, token);
          companyId = id;
        }

        role === "sales" ? (officeId = 1) : (officeId = 0);
        if (office && role === "sales") {
          let { id } = await getOfficeIdByName(office, token);
          officeId = id;
        }
        let newUser = {
          name,
          surname1,
          surname2,
          role,
          updatedAt: new Date(),
          username,
          email,
          companyId,
          officeId,
          password,
        };
        saveNewUser(newUser, token)
          .then(({ id: newUserId }) => {
            if (role === "fieldManager") {
              pickedProvinces.map(async (province) => {
                let { id: provinceId } = await getProvinceIdByName(
                  province,
                  token
                );
                saveProvinceUserRelation(newUserId, provinceId, token);
              });
            }
            Swal.fire({
              icon: "success",
              text: "Usuario creado correctamente",
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
            history.push("/admin-usuarios");
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              text: `Se ha producido un error, intente nuevamente.
                (Asegúrese que el *nombre de usuario y el *email no estén repetidos).`,
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
          });
      }
    });
  };

  const openEditUserModal = () => {
    if (!username || !name || !surname1 || !email || !role) {
      setErrorText("Por favor rellena todos los campos");
      setTimeout(() => setErrorText(""), 3000);
      return;
    } else if (!validateEmail(email)) {
      setErrorText("Formato de email inválido");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    if (role === "fieldManager" && !pickedProvinces.length) {
      setErrorText("Debes asociar al menos una provincia al Jefe de Campo");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    Swal.fire({
      icon: "question",
      text: "¿Quieres guardar los cambios para este usuario?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(async ({ value }) => {
      if (value) {
        let companyId = 0;
        let officeId = 0;

        let companyCondition =
          role === "producer" ||
          role === "fieldManager" ||
          role === "quality" ||
          role === "qualityAssistant";
        companyCondition ? (companyId = 1) : (companyId = 0);

        if (company && companyCondition) {
          let { id } = await getCompanyIdByName(company, token);
          companyId = id;
        }

        role === "sales" ? (officeId = 1) : (officeId = 0);
        if (office && role === "sales") {
          let { id } = await getOfficeIdByName(office, token);
          officeId = id;
        }
        let userPatch = {
          name,
          surname1,
          surname2,
          role,
          updatedAt: new Date(),
          username,
          email,
          companyId,
          officeId,
        };
        if (role === "fieldManager") {
          let userProvincesId = await getProvinceUserRelations(userId, token);
          userProvincesId.map(({ id }: any) => {
            deleteProvinceUserRelations(id, token);
          });
          pickedProvinces.map(async (province) => {
            let { id: provinceId } = await getProvinceIdByName(province, token);
            saveProvinceUserRelation(userId, provinceId, token);
          });
        }
        editUser(userPatch, userId, token).then((response) => {
          if (response) {
            Swal.fire({
              icon: "success",
              text: "Usuario guardado correctamente",
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
            history.push("/admin-usuarios");
          } else {
            Swal.fire({
              icon: "error",
              text: "Se ha producido un error, intente guardando nuevamente",
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
          }
        });
      }
    });
  };

  const openNewPasswordModal = () => {
    if (password !== repeatPassword) {
      return;
    }
    Swal.fire({
      icon: "question",
      text: "¿Quieres guardar los cambios para este usuario?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        //TODO - See how to change password in loopback
        // saveNewPassword();
      }
    });
  };
  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">
          {!Boolean(userId) ? "Nuevo" : "Editar"} Usuario - {username}
        </div>
        <button className="semana-div3">
          Semana actual
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </button>
      </div>
      <div className="container mt-4 ml-4">
        <div className="row pt-4" style={{ backgroundColor: "white" }}>
          <div className="col-6">
            <form>
              <div className="form-group">
                <label>*Nombre de Usuario</label>
                <input
                  type="text"
                  className="form-control"
                  value={username}
                  onChange={({ target: { value } }) => setUsername(value)}
                />
              </div>
              <div className="form-group">
                <label>*Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
              <div className="form-group">
                <label>*Rol</label>
                <select
                  className="form-control"
                  value={role}
                  onChange={({ target: { value } }) => setRole(value)}
                >
                  <option value="">Elegir Rol</option>
                  <option value="producer">Productor</option>
                  <option value="quality">Calidad</option>
                  <option value="qualityAssistant">Asistente de Calidad</option>
                  <option value="sourcing">Sourcing</option>
                  <option value="sales">Comercial</option>
                  <option value="fieldManager">Jefe de Campo</option>
                  <option value="marketing">Marketing</option>
                  <option value="agribio">Agribio</option>
                </select>
              </div>
              {(role === "producer" ||
                role === "fieldManager" ||
                role === "quality" ||
                role === "qualityAssistant") && (
                <div className="form-group">
                  <label>Productor</label>
                  <select
                    className="form-control"
                    value={company}
                    onChange={({ target: { value } }) => setCompany(value)}
                  >
                    {companies.map(({ id, name }) => (
                      <option key={id}>{name}</option>
                    ))}
                  </select>
                </div>
              )}
              {role === "sales" && (
                <div className="form-group">
                  <label>Oficina</label>
                  <select
                    className="form-control"
                    value={office}
                    onChange={({ target: { value } }) => setOffice(value)}
                  >
                    {offices.map((name, index) => (
                      <option key={index}>{name}</option>
                    ))}
                  </select>
                </div>
              )}
              {role === "fieldManager" && (
                <div className="form-group">
                  <label>Asociar de 1 a 3 Provincias al Jefe de Campo</label>
                  <Autocomplete
                    value={provinceValue ?? null}
                    onChange={onTagsChange}
                    inputValue={inputProvinceValue}
                    onInputChange={(event, newInputValue) => {
                      setInputProvinceValue(newInputValue);
                    }}
                    disabled={pickedProvinces.length >= 3}
                    id="provinces"
                    size="small"
                    options={provinces}
                    style={{ borderColor: "green" }}
                    renderInput={(params) => (
                      <TextField
                        style={{ borderColor: "green" }}
                        {...params}
                        label="Provincias"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                  {pickedProvinces.map((province, index) => (
                    <span
                      key={index}
                      style={{ cursor: "pointer" }}
                      className="badge badge-warning mr-1 mt-3  "
                      onClick={() => removePickedProvince(province)}
                    >
                      {province} X
                    </span>
                  ))}
                </div>
              )}
              {!Boolean(userId) && (
                <>
                  <div className="form-group">
                    <label>*Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={({ target: { value } }) => setPassword(value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>*Repite Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      value={repeatPassword}
                      onChange={({ target: { value } }) =>
                        setRepeatPassword(value)
                      }
                    />
                  </div>
                  {password !== repeatPassword && (
                    <span className="text-danger">
                      Contraseñas no coinciden
                    </span>
                  )}
                </>
              )}
              <span className="text-danger">{errorText}</span>
            </form>

            {Boolean(userId) ? (
              <button
                className="btn btn-md text-light mt-3 mb-3 finishButton"
                disabled={!username || !name || !surname1 || !email || !role}
                onClick={openEditUserModal}
              >
                Guardar Edición
              </button>
            ) : (
              <button
                className="btn btn-md text-light mt-3 mb-3 finishButton"
                disabled={
                  !username ||
                  !name ||
                  !surname1 ||
                  !email ||
                  !role ||
                  !password ||
                  !repeatPassword
                }
                onClick={openCreateUserModal}
              >
                Guardar
              </button>
            )}
            {/* {Boolean(userId) && (
              <button
                type="button"
                className="btn btn-warning ml-2 text-white"
                style={{ borderRadius: "20px", fontWeight: "bold" }}
                onClick={() => setShowPasswordModal(true)}
              >
                Cambiar Contraseña
              </button>
            )} */}
          </div>
          <div className="col-6">
            <form>
              <div className="form-group">
                <label>*Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>
              <div className="form-group">
                <label>*Primer Apellido</label>
                <input
                  type="text"
                  className="form-control"
                  value={surname1}
                  onChange={({ target: { value } }) => setSurname1(value)}
                />
              </div>
              <div className="form-group">
                <label>Segundo Apellido</label>
                <input
                  type="text"
                  className="form-control"
                  value={surname2}
                  onChange={({ target: { value } }) => setSurname2(value)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editar contraseña para usuario <u>{username}</u>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>*Nueva Contreseña</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
              />
            </div>
            <div className="form-group">
              <label>*Repite Contraseña</label>
              <input
                type="password"
                className="form-control"
                value={repeatPassword}
                onChange={({ target: { value } }) => setRepeatPassword(value)}
              />
            </div>
            {password !== repeatPassword && (
              <span className="text-danger">Contraseñas no coinciden</span>
            )}
          </form>

          <button
            className="btn btn-md text-light mt-3 mb-3 "
            style={{
              backgroundColor: "#cad401",
              borderRadius: "18px",
              fontWeight: "bold",
            }}
            disabled={password !== repeatPassword}
            onClick={openNewPasswordModal}
          >
            Guardar Nueva Contraseña
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditUserAdmin;
