import { IProduct } from "../../pages/Admin/AdminProducts";

export const collectProducts = (pt: any[]) => {
  let aux: any = [];
  aux.push({
    id: -1,
    productName: "Todos los productos",
  });
  let resultado: any = Array.from(
    new Set(pt.map((s) => s.variety.product.id))
  ).map((id) => {
    return {
      id: id,
      productName: pt.find((s) => s.variety.product.id === id).variety.product
        .name,
    };
  });
  return aux.concat(resultado);
};

export const searchByProduct = (
  product: string,
  setPlantationsSearch: any,
  setPlantationsTable: any,
  plantations: any,
  setVariety: any,
  varieties: any,
  setVarietiesShow: any
) => {
  if (
    // todos los productos
    product === "Todos los productos"
  ) {
    setVarietiesShow(varieties);
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 10));
    setVariety("Cualquier variedad");
  } else if (
    // producto concreto
    product !== "Todos los productos"
  ) {
    let ax: any[] = plantations.filter(
      (s: any) => s.variety.product.name.toString() === product
    );
    setPlantationsSearch(ax);
    setPlantationsTable(ax.slice(0, 10));
    let varis: any[] = varieties.filter(
      (s: any) => s.productName.toString() === product
    );

    setVarietiesShow(varis);
  }
};

export const filterProductNames = (productsArray: any[]) => {
  let products: string[] = [];
  productsArray.map(({ name }) => {
    products.push(name);
  });
  return products;
};

export const buildAdminProducts = (rawProducts: any) => {
  let products: IProduct[] = [];
  rawProducts.map(({ categoryId: category, id, name }: any) => {
    if (products.filter(({ categoryId }) => categoryId === category).length) {
      let index = products.findIndex(
        ({ categoryId }) => categoryId === category
      );
      products[index].product.push({ id, name });
    } else {
      products.push({
        categoryId: category,
        product: [{ id, name }],
      });
    }
  });
  return products;
};

