import React, { useEffect, useState } from "react";

//static
import more from "../icons/more.png";
import weeks from "../utils/statics/week.json";

//css
import "../theme/homepage.css";
import "../theme/sourcing.css";

//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { getCalibreCodes } from "../utils/Auxiliar/CalibreAuxiliar";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { filterCompanyNames } from "../utils/Auxiliar/CompanyAux";
import { filterOfficeNames } from "../utils/Auxiliar/OfficeAux";

//req
import {
  getVarietyIdByName,
  getCalibresByVarietyId,
  getVarietiesByCompany,
  getVarietyCalibreId,
} from "../utils/Requests/VarietyRequests";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import {
  getCompanyIdByName,
  getAllCompanies,
} from "../utils/Requests/CompanyRequest";
import { getOffices, getOfficeIdByName } from "../utils/Requests/OfficeReqs";
import {
  savePurchaseForecast,
  saveToPurchaseVarietyCalibre,
  getPurchasesForecastDetailsById,
  editPurchaseForecast,
  resetPurchaseVarietyCalibre,
  deleteForecastPurchaseFromDB,
  deletePurchaseForecastDetailsById,
  checkIfRepeatedForecast,
} from "../utils/Requests/PurchaseForecastReq";
import { getCalibreIdByCode } from "../utils/Requests/CalibreReq";

//middleware
import produce from "immer";
import { useLocation, Prompt } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
import ModalPopup from "../components/ModalPopup";

export interface IAddedForecast {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
}

const ManagePurchasesForecast: React.FC = () => {
  //checking if location is create (id=0) or edit (id!=0)
  let { pathname } = useLocation();
  let forecastId = +pathname.split("/")[2];

  const [isAddingOpen, setIsAddingOpen] = useState(false);

  const [companies, setCompanies] = useState<string[]>([]);
  const [pickedCompany, setPickedCompany] = useState("");

  const [countries, setCountries] = useState<string[]>([]);
  const [pickedCountry, setPickedCountry] = useState("");
  const [noCountryError, setNoCountryError] = useState(false);

  const [varieties, setVarieties] = useState<any[]>([]);
  const [pickedVariety, setPickedVariety] = useState("");

  const [calibres, setCalibres] = useState<string[]>([]);
  const [pickedCalibre, setPickedCalibre] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [tons, setTons] = useState<number>(0);

  let [totalTons, setTotalTons] = useState(0);

  const [addedForecast, setAddedForecast] = useState<IAddedForecast[]>([]);

  const [saving, setSaving] = useState(false);
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      getDefaultData();
      setAddedForecast([]);
      if (Boolean(forecastId)) {
        getDataToEdit();
      }
    }
  }, [forecastId, token]);

  const getDefaultData = () => {
    getAllCompanies(token).then((response) => {
      let companies = filterCompanyNames(response);

      setCompanies(companies);
    });
    getOffices(token).then((response) => {
      let countries = filterOfficeNames(response);
      setCountries(countries);
    });
    setTotalTons(0);
  };

  const getDataToEdit = async () => {
    let forecastsToEdit = await getPurchasesForecastDetailsById(
      forecastId,
      token
    );
    let tons = 0;
    forecastsToEdit.map(({ volumen }: any) => {
      tons += volumen;
    });
    setTotalTons(tons / 1000);

    let editedForecasts: IAddedForecast[] = [];
    forecastsToEdit.map(({ varietyCalibre, volumen }: any) => {
      editedForecasts.push({
        pickedVariety: varietyCalibre.variety.name,
        pickedCalibre: varietyCalibre.calibre.code,
        tons: volumen / 1000,
      });
    });
    setPickedCompany(forecastsToEdit[0].forecastPurchase.company.name);
    setPickedCountry(forecastsToEdit[0].forecastPurchase.office.country);
    setAddedForecast(editedForecasts);
    let varieties = await getVarietiesByCompany(
      forecastsToEdit[0].forecastPurchase.company.name,
      token
    );

    setVarieties(varieties);
  };

  const pickCompany = (event: any) => {
    setPickedCompany(event.target.value);
    getVarietiesByCompany(event.target.value, token).then((response) => {
      setVarieties(response);
    });
  };

  const pickVariety = (event: any) => {
    setPickedVariety(event.target.value);
    if (event.target.value !== "Selecciona Variedad") {
      getVarietyIdByName(event.target.value, token).then(({ id }) => {
        getCalibresByVarietyId(id, token).then((response) => {
          let calibres = getCalibreCodes(response);
          setCalibres(calibres);
        });
      });
    } else {
      setCalibres([]);
      setPickedCalibre("");
      setTons(0);
    }
  };

  const pickCalibre = (event: any) => {
    setPickedCalibre(event.target.value);
    if (event.target.value === "Añadir Calibre") {
      setPickedCalibre("");
      setTons(0);
    }
  };

  const addForecast = async () => {
    const isForecastRepeated = await checkIfRepeated();
    if (isForecastRepeated) {
      return;
    }
    if (tons) {
      if (tons.toString() === "0" || parseFloat(tons.toString()) === 0) {
        setAlertText("No se pueden poner valores de 0 para las toneladas");
        setShowAlert(true);
      } else {
        if (!pickedCountry) {
          setNoCountryError(true);
          setTimeout(() => setNoCountryError(false), 3000);
          return;
        }
        setAddedForecast([
          ...addedForecast,
          {
            pickedVariety,
            pickedCalibre,
            tons,
          },
        ]);
        let total =
          parseFloat(totalTons.toString()) + parseFloat(tons.toString());
        setTotalTons(total);
        setPickedCalibre("");
        setTons(0);
      }
    }
  };

  const checkIfRepeated = async () => {
    if (!addedForecast.length) {
      let { id: companyId } = await getCompanyIdByName(pickedCompany, token);
      let { id: officeId } = await getOfficeIdByName(pickedCountry, token);
      const repeatedForecast = await checkIfRepeatedForecast(
        companyId,
        officeId,
        token
      );
      let aux = false;
      if (repeatedForecast.length) {
        Swal.fire({
          icon: "warning",
          text: `Ya has generado una previsión para ${pickedCompany} / ${pickedCountry} `,
          showCancelButton: false,
          confirmButtonText: "Aceptar",
          cancelButtonText: "No",
          customClass: {
            cancelButton: "cancelButton",
            confirmButton: "finishButton",
          },
        });
        setPickedCompany("");
        setPickedCountry("");
        setTotalTons(0);
        setAddedForecast([]);
        setPickedVariety("");
        setPickedCalibre("");
        setTons(0);
        aux = true;
      }
      return aux;
    }
  };

  const deleteForecast = (index: number) => {
    let total =
      parseFloat(totalTons.toString()) -
      parseFloat(addedForecast[index].tons.toString());
    setTotalTons(total);

    setAddedForecast((state) => {
      const newAddedForecast = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newAddedForecast;
    });
  };

  const openModal = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres generar una previsión de compra?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendPurchaseForecast();
      }
    });
  };

  const sendPurchaseForecast = async () => {
    setSaving(true);
    //create forecast report
    let repetido: string = "";
    addedForecast.forEach((af: any) => {
      let aux = addedForecast.filter(
        (a: any) =>
          a.pickedVariety === af.pickedVariety &&
          a.pickedCalibre === af.pickedCalibre
      );
      if (aux.length > 1) {
        repetido = af.pickedVariety;
      }
    });
    if (repetido === "") {
      const seasonName = checkSeasonShort();
      let { id: seasonId } = await getSeasonByName(seasonName, token);
      let { id: companyId } = await getCompanyIdByName(pickedCompany, token);
      let { id: officeId } = await getOfficeIdByName(pickedCountry, token);
      let currentWeek = +setWeekNumber(new Date());
      let weekSort = weeks.find(({ week }: any) => week === currentWeek);

      let purchaseForecastReport: any = {
        createdAt: new Date(),
        updatedAt: new Date(),
        seasonId,
        companyId,
        officeId,
        totalTons: (totalTons * 1000).toFixed(2),
        isSent: 0,
        week: setWeekNumber(new Date()),
        weekSort: weekSort?.sort,
      };

      //edit forecast report
      let forecastPurchaseId = forecastId;
      if (Boolean(forecastId)) {
        purchaseForecastReport = {
          updatedAt: new Date(),
          seasonId,
          companyId,
          officeId,
          totalTons: (totalTons * 1000).toFixed(2),
          isSent: 0,
        };
        editPurchaseForecast(purchaseForecastReport, forecastId, token);
        resetPurchaseVarietyCalibre(forecastId, token);
      } else {
        let { id } = await savePurchaseForecast(purchaseForecastReport, token);
        forecastPurchaseId = id;
      }

      //save individual varietyCalibre in middle table
      Promise.all(
        addedForecast.map(async ({ pickedVariety, pickedCalibre, tons }) => {
          let { id: varietyId } = await getVarietyIdByName(
            pickedVariety,
            token
          );
          let { id: calibreId } = await getCalibreIdByCode(
            pickedCalibre,
            token
          );
          let { id: varietyCalibreId } = await getVarietyCalibreId(
            varietyId,
            calibreId,
            token
          );
          let purchaseVarietyCalibreReport = {
            varietyCalibreId,
            forecastPurchaseId,
            volumen: (tons * 1000).toFixed(2),
          };
          let response = await saveToPurchaseVarietyCalibre(
            purchaseVarietyCalibreReport,
            token
          );
          return response;
        })
      ).then(() => {
        Swal.fire({
          icon: "success",
          text: "Previsión generada correctamente",
          customClass: {
            cancelButton: "cancelButton",
            confirmButton: "finishButton",
          },
        });
        history.push("/previsiones-compras");
      });
    } else {
      openModalRepetido(repetido);
    }
  };

  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Quieres borrar esta previsión de compra?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        deleteForecastFromDB();
      }
    });
  };

  const openModalRepetido = (variedad: string) => {
    Swal.fire({
      icon: "warning",
      text:
        "La variedad " +
        variedad +
        " tiene calibres repetidos, por favor corríjalo.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(() => {});
  };

  const deleteForecastFromDB = async () => {
    let response = await getPurchasesForecastDetailsById(forecastId, token);
    Promise.all(
      response.map(async ({ id }: any) => {
        await deletePurchaseForecastDetailsById(id, token);
      })
    ).then((response: any) => {
      deleteForecastPurchaseFromDB(forecastId, token).then(({ count }) => {
        if (count) {
          Swal.fire({
            icon: "success",
            text: "Previsión borrada correctamente",
            customClass: {
              cancelButton: "cancelButton",
              confirmButton: "finishButton",
            },
          });
          history.push("/previsiones-compras");
        } else {
          Swal.fire({
            icon: "error",
            text: "Se ha producido un error, intente borrarla nuevamente",
            customClass: {
              cancelButton: "cancelButton",
              confirmButton: "finishButton",
            },
          });
        }
      });
    });
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setAlertText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShowAlert(true);
      setTons(0);
    } else {
      setTons(event.target.value);
    }
  };

  return (
    <div className="div-principal2">
      <ModalPopup
        show={showAlert}
        setShow={setShowAlert}
        modalText={alertText}
      />
      <Prompt
        when={!!pickedVariety && !saving}
        message="Tienes cambios sin guardar, ¿Seguro quieres salir?"
      />
      <div className="top-panel">
        <span className="top-panel-text">Crear previsión de compras</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>

      <div
        style={{
          width: "85%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "4%",
          paddingBottom: "2%",
        }}
      >
        <div className="container p-4">
          <div className="row mb-3">
            <div className="col-5">
              <select
                className="form-control form-control-sm"
                style={{ width: "15rem" }}
                data-spy="scroll"
                value={pickedCompany}
                onChange={pickCompany}
                disabled={Boolean(addedForecast.length)}
              >
                {!pickedCompany && <option>Productor Asignado</option>}
                {companies.map((company: any, index: number) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <select
                className="form-control form-control-sm ml-3"
                style={{
                  width: "12rem",
                  border: noCountryError ? "solid red 2px" : "",
                }}
                data-spy="scroll"
                value={pickedCountry}
                disabled={Boolean(addedForecast.length)}
                onChange={(event) => setPickedCountry(event.target.value)}
              >
                {!pickedCountry && <option>País</option>}
                {countries.map((country: any, index: number) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="text"
                disabled
                value={
                  totalTons
                    ? treatNumber(totalTons) + " Tn"
                    : "Volumen Total Tn"
                }
              />
            </div>
          </div>
          <div className="row" style={{ paddingTop: "2vh" }}>
            <div className="col-12">
              <table className="table text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {addedForecast.map(
                    ({ pickedVariety, pickedCalibre, tons }, index) => (
                      <tr key={index}>
                        <th scope="row">{pickedVariety}</th>
                        <td>{pickedCalibre}</td>
                        <td>{treatNumber(parseFloat(tons.toString()))}</td>
                        <td
                          className="boldFontLink"
                          onClick={() => deleteForecast(index)}
                        >
                          X
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container addVariety">
          <div
            className="row mt-2"
            onClick={() => setIsAddingOpen(!isAddingOpen)}
          >
            <img
              className="ml-2 mt-1"
              src={more}
              style={{ width: "1.2rem", height: "1.2rem" }}
              alt={""}
            />
            <span className="ml-2">Añadir variedad</span>
            {noCountryError && (
              <span className="ml-2 text-danger">
                (Por favor añada un país)
              </span>
            )}
          </div>
          {isAddingOpen && pickedCountry && (
            <div className="row mt-3 pb-2">
              <select
                className="form-control form-control-sm ml-2"
                style={{ width: "12rem" }}
                data-spy="scroll"
                disabled={!varieties.length}
                value={pickedVariety}
                onChange={pickVariety}
              >
                <option>Selecciona Variedad</option>
                {varieties.map((variety) => (
                  <option key={variety.id} value={variety.name}>
                    {variety.name}
                  </option>
                ))}
              </select>
              <select
                className="form-control form-control-sm ml-2"
                style={{ width: "10rem" }}
                value={pickedCalibre}
                disabled={!calibres.length}
                onChange={pickCalibre}
              >
                <option>Añadir Calibre</option>
                {calibres.map((calibreCode: string, index: number) => (
                  <option key={index} value={calibreCode}>
                    {calibreCode}
                  </option>
                ))}
              </select>

              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="number"
                placeholder="Toneladas"
                disabled={!pickedCalibre}
                value={tons === 0 ? "" : tons}
                onChange={tonHandler}
                // onChange={handleKg}
              />
              <button
                className="btn btn-sm text-light ml-3 addButton"
                disabled={!pickedCalibre || !tons}
                onClick={addForecast}
              >
                Añadir
              </button>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "85%", marginLeft: "5%" }}>
        <div className="container">
          <div className="row mt-3 d-flex flex-row-reverse">
            {Boolean(forecastId) && (
              <button
                style={{ marginTop: 2, marginLeft: 10 }}
                className="delete-report-button"
                onClick={openModalDelete}
              >
                <div className="button-flex-warehouse">
                  <div>
                    <svg
                      style={{ marginBottom: 2 }}
                      width="12"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="trash-alt"
                      className="svg-inline--fa fa-trash-alt fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </button>
            )}
            <button
              className="btn btn-md text-light mr-2 finishButton"
              style={{ height: "35px" }}
              disabled={!addedForecast.length}
              onClick={openModal}
            >
              {!Boolean(forecastId) ? "Finalizar" : "Guardar"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePurchasesForecast;
