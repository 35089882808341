import IHarvestReport from "../../interfaces/IHarvestReport";
import { setWeekNumber, pickUniqueWeek } from "./WeekAuxiliar";

export const createHarvestReportList = (
  hvt: IHarvestReport[],
  companyId: number
) => {
  let newHarvestReport: any[] = [];
  hvt.forEach((v: any) => {
    if (
      v.cutOrder.companyId === companyId &&
      (v.cutOrder.state === 4 || v.cutOrder.state === 3)
    ) {
      newHarvestReport.push({
        id: v.id,
        variety: v.cutOrder.plantationVarieties.variety.name,
        plantation: v.cutOrder.plantationVarieties.plantation.name,
        week: v.createdAt,
        volume: v.volumeEstimated,
        state: v.cutOrder.state,
      });
    }
  });
  return newHarvestReport;
};

export const pickHarvestReportWeeks = (hvt: IHarvestReport[]) => {
  let companyId: any = parseInt(sessionStorage.getItem("companyId")!);

  let newWeeksHarvestReport: any[] = [];
  hvt.forEach((v: any) => {
    if (v.cutOrder.companyId === companyId && v.cutOrder.state === 4) {
      newWeeksHarvestReport.push({
        createdAt: v.createdAt,
      });
    }
  });

  return selectHarvestReportWeeks(newWeeksHarvestReport);
};

const selectHarvestReportWeeks = (newWeeksHarvestReport: any) => {
  let weeks: string[] = [];
  newWeeksHarvestReport.map((w: any) => weeks.push(setWeekNumber(w.createdAt)));

  return pickUniqueWeek(weeks);
};

export const searchHarvestReport = (
  plantation: string,
  variety: any,
  week: any,
  setHarvestReporSearch: any,
  harvestReports: any
) => {
  if (
    // todas las fincas / todos las variedades / todas las semanas
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    setHarvestReporSearch(harvestReports);
  } else if (
    // finca concreta / todos las variedades / todas las semanas
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => c.plantation.toString() === plantation
    );

    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / todas las semanas
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => c.variety.toString() === variety
    );
    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / todas las variedades / semana concreta
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => setWeekNumber(c.week).toString() === week
    );
    setHarvestReporSearch(aux);
  } else if (
    // finca concreta / variedad concreta / todas las semanas
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.plantation.toString() === plantation &&
        c.variety.toString() === variety
    );

    setHarvestReporSearch(aux);
  } else if (
    // finca concreta / todas las variedades / semana concreta
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.plantation.toString() === plantation &&
        setWeekNumber(c.week).toString() === week
    );

    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / semana concreta
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    // provincia concreta / cualquier variedad / clima concreto
    let aux = harvestReports.filter(
      (c: any) =>
        c.variety.toString() === variety &&
        setWeekNumber(c.week).toString() === week
    );

    setHarvestReporSearch(aux);
  } else if (
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.plantation.toString() === plantation &&
        c.variety.toString() === variety &&
        setWeekNumber(c.week).toString() === week
    );

    setHarvestReporSearch(aux);
  }
};
export const searchHarvestReport2 = (
  plantation: string,
  variety: any,
  week: any,
  setHarvestReporSearch: any,
  harvestReports: any
) => {
  if (
    // todas las fincas / todos las variedades / todas las semanas
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    setHarvestReporSearch(harvestReports);
  } else if (
    // finca concreta / todos las variedades / todas las semanas
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => c.report.plantation.toString() === plantation
    );

    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / todas las semanas
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => c.report.variety.toString() === variety
    );
    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / todas las variedades / semana concreta
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) => setWeekNumber(c.report.week).toString() === week
    );
    setHarvestReporSearch(aux);
  } else if (
    // finca concreta / variedad concreta / todas las semanas
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.report.plantation.toString() === plantation &&
        c.report.variety.toString() === variety
    );

    setHarvestReporSearch(aux);
  } else if (
    // finca concreta / todas las variedades / semana concreta
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.report.plantation.toString() === plantation &&
        setWeekNumber(c.report.week).toString() === week
    );

    setHarvestReporSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / semana concreta
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    // provincia concreta / cualquier variedad / clima concreto
    let aux = harvestReports.filter(
      (c: any) =>
        c.report.variety.toString() === variety &&
        setWeekNumber(c.report.week).toString() === week
    );

    setHarvestReporSearch(aux);
  } else if (
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = harvestReports.filter(
      (c: any) =>
        c.report.plantation.toString() === plantation &&
        c.report.variety.toString() === variety &&
        setWeekNumber(c.report.week).toString() === week
    );

    setHarvestReporSearch(aux);
  }
};

export const filterHarvestReportByVariety = (data: any[], variety: string) => {
  let aux: any[] = [];
  data.forEach((hr: any) => {
    if (hr.cutOrder.plantationVarieties.variety.name === variety) {
      aux.push(hr);
    }
  });
  return aux;
};

export const calculateHarvestQuantity = (
  purchases: any,
  setWeek: any,
  setMonth: any,
  setTotal: any
) => {
  let semAnterior = parseInt(setWeekNumber(new Date())) - 1;
  let dias = new Date(
    new Date().getUTCFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate(); // me devuelve los dias que tiene el mes en curso
  let inicioWeek = setWeekNumber(
    new Date(new Date().getMonth() + 1 + "/1/" + new Date().getUTCFullYear())
  );

  let finalWeek = setWeekNumber(
    new Date(
      new Date().getMonth() + 1 + "/" + dias + "/" + new Date().getUTCFullYear()
    )
  );

  let total: number = 0;
  let semana: number = 0;
  let mes: number = 0;
  purchases.forEach((p: any) => {
    if (p.realPurchaseVarCals) {
      p.realPurchaseVarCals.forEach((e: any) => {
        if (p.week === semAnterior) {
          semana = semana + e.volumen;
        }
        if (p.week >= inicioWeek && p.week <= finalWeek) {
          mes = mes + e.volumen;
        }
        total = total + e.volumen;
      });
    }
  });
  setTotal(total);
  setWeek(semana);
  setMonth(mes);
};
