import IError from "../../interfaces/IError";

export const locateError = (code: number) => {
  let error: IError = {
    code: -1,
    name: "",
    description: "",
  };

  switch (code) {
    case 400:
      error.code = 400;
      error.name = "BAD REQUEST";
      error.description = "Invalid request sintaxis";
      break;
    case 401:
      error.code = 401;
      error.name = "UNATHORIZED";
      error.description = "Not permissions to access";
      break;
    case 403:
      error.code = 403;
      error.name = "FORBIDDEN";
      error.description = "Not permissions to access";
      break;
    case 404:
      error.code = 404;
      error.name = "NOT FOUND";
      error.description = "Resource not found";
      break;
    case 408:
      error.code = 408;
      error.name = "REQUEST TIMEOUT";
      error.description = "Timeout exceeded to access the resource";
      break;
    case 500:
      error.code = 500;
      error.name = "INTERNAL SERVER ERROR";
      error.description = "The server encounter a problem";
      break;
    case 501:
      error.code = 501;
      error.name = "NOT IMPLEMENTED";
      error.description = "The not implemented yet the function required";
      break;
    case 502:
      error.code = 502;
      error.name = "BAD GATEWAY";
      error.description = "The server obtain a bad response";
      break;
    case 503:
      error.code = 503;
      error.name = "SERVICE UNAVAILABLE";
      error.description = "The server is not ready for manage the request";
      break;
    case 504:
      error.code = 504;
      error.name = "GATEWAY TIMEOUT";
      error.description = "Not received the response before timeout";
      break;
    default:
      error.code = 0;
      error.name = "INTERNAL APP ERROR";
      error.description = "Internar error of application";
  }

  return error;
};
