import React from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import HarvestPopupRow from "./HarvestPopupRow";
import "../theme/warehouse.css";

interface PlantationListItemProps {
  show: boolean;
  setShow: any;
  reports: any;
  checked: any;
  setChecked: any;
  estimado: number;
  setEstimado: any;
  deleted: any;
  setDeleted: any;
}

const ModalHarvestEditPopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  reports,
  checked,
  setChecked,
  estimado,
  setEstimado,
  deleted,
  setDeleted,
}) => {
  const handleCheck = (event: any) => {
    let check = event.target.checked;
    let id = event.target.id;
    let newChecked: any = [...checked];
    let a: any = [];
    let report = reports.find((r: any) => parseInt(r.id) === parseInt(id));
    let total = 0;
    if (report.harvestCalibres.length > 0) {
      report.harvestCalibres.forEach((c: any) => {
        total = total + c.volume;
      });
    }
    if (event.target.checked) {
      // si lo selecciono
      setEstimado(estimado + total);
      a = [{ id, check, report, total }, ...checked];
    } else {
      // si los quito
      setEstimado(estimado - total);
      a = newChecked.filter((tool: any) => event.target.id != tool.id);
    }
    setChecked(a);
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18 }}>
          Partes de recogida pendientes de almacén
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="button-flex-warehouse"
          style={{ fontSize: 14, marginBottom: 3 }}
        >
          Selecciona uno o más partes de recogida a los que quieres vincular un
          parte de almacén
        </div>
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
            fontSize: 13,
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">CÓDIGO</th>
              <th scope="col">SEMANA</th>
              <th scope="col">VARIEDAD</th>
              <th scope="col">FINCA</th>
              <th scope="col">VOLUMEN (Tn)</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {reports?.map((report: any) => (
              <HarvestPopupRow
                key={report.id}
                report={report}
                handleCheck={handleCheck}
                checked={checked}
              />
            ))}

            {deleted?.map((report: any) => (
              <HarvestPopupRow
                key={report.id}
                report={report}
                handleCheck={handleCheck}
                checked={checked}
              />
            ))}
          </tbody>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHarvestEditPopup;
