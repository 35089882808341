export const createPriorAnalysisImagesArray = (prior: any[]) => {
  let newPriorAnalysisImages: any[] = [];
  prior.map((p: any) => {
    newPriorAnalysisImages.push({
      id: p.id,
      url: p.url,
      type: p.type,
      variety: p.priorAnalysis?.plantationVariety?.variety.name,
      plantation: p.priorAnalysis?.plantationVariety?.plantation.name,
      producer: p.priorAnalysis?.plantationVariety?.plantation.company.name,
      seasonId: p.priorAnalysis?.seasonId,
      companyId: p.priorAnalysis?.plantationVariety?.plantation.companyId,
    });
  });

  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);

  return newPriorAnalysisImages;
};

export const createHarvestReportImagesArray = (harvest: any[]) => {
  let newHarvestReportImages: any[] = [];
  harvest.map((h: any) => {
    newHarvestReportImages.push({
      id: h.id,
      url: h.url,
      type: h.type,
      variety: h.harvestReport?.cutOrder?.plantationVarieties?.variety.name,
      plantation: h.harvestReport?.cutOrder?.plantationVarieties?.plantation.name,
      producer: h.harvestReport?.cutOrder?.company.name,
      seasonId: h.harvestReport?.seasonId,
      companyId: h.harvestReport?.cutOrder?.companyId,
    });
  });
  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);
  return newHarvestReportImages;
};

export const createQualityReportImagesArray = (quality: any[]) => {
  let newQualityImages: any[] = [];

  quality.map((p: any) => {
    console.log(p)
    newQualityImages.push({
      id: p.id,
      url: p.url,
      type: p.type,
      variety: p.qualityReport?.plantationVariety?.variety.name,
      plantation: p.qualityReport?.plantationVariety?.plantation.name,
      producer: p.qualityReport?.plantationVariety?.plantation.company.name,
      seasonId: p.qualityReport?.seasonId,
      companyId: p.qualityReport?.plantationVariety?.plantation.companyId,
    });
    console.log(p)
  });
  console.log(quality)
  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);
  console.log(newQualityImages)
  return newQualityImages;
};

export const selectImagesByCompany = (images: any[], companyId: number) => {
  // console.log(seasonId)
  // console.log(images);
  let imagesByCompanyAndSeason: any[] = [];
  images.forEach((i: any) => {
    if (i.companyId === companyId!) {
      imagesByCompanyAndSeason.push({
        id: i.id,
        url: i.url,
        type: i.type,
        variety: i.variety,
        plantation: i.plantation,
        producer: i.producer,
        companyId: i.companyId,
      });
    }
  });
  // console.log(imagesByCompanyAndSeason)
  return imagesByCompanyAndSeason;
};

export const createPriorAnalysisProducerImagesArray = (
  prior: any[],
  companyId: number
) => {
  console.log(companyId)
  let newPriorAnalysisImages: any[] = [];
  prior.map((p: any) => {
    if (p.priorAnalysis?.plantationVariety?.plantation?.companyId === companyId) {
      newPriorAnalysisImages.push({
        id: p.id,
        url: p.url,
        type: p.type,
        variety: p.priorAnalysis?.plantationVariety?.variety.name,
        plantation: p.priorAnalysis?.plantationVariety?.plantation.name,
        producer: p.priorAnalysis?.plantationVariety?.plantation.company.name,
        seasonId: p.priorAnalysis?.seasonId,
        companyId: p.priorAnalysis?.plantationVariety?.plantation.companyId,
      });
    }
  });
  console.log(newPriorAnalysisImages)
  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);
  return newPriorAnalysisImages;
};

export const createHarvestReportProducerImagesArray = (
  harvest: any[],
  companyId: number
) => {
  let newHarvestReportImages: any[] = [];
  harvest.map((h: any) => {
    if (h.harvestReport?.cutOrder?.companyId === companyId) {
      newHarvestReportImages.push({
        id: h?.id,
        url: h?.url,
        type: h?.type,
        variety: h.harvestReport?.cutOrder?.plantationVarieties?.variety?.name,
        plantation:
          h.harvestReport?.cutOrder?.plantationVarieties?.plantation?.name,
        producer: h.harvestReport?.cutOrder?.company?.name,
        seasonId: h.harvestReport?.seasonId,
        companyId: h.harvestReport?.cutOrder?.companyId,
      });
    }
  });
  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);
  return newHarvestReportImages;
};

export const createQualityReportProducerImagesArray = (
  quality: any[],
  companyId: number
) => {
  
  let newQualityImages: any[] = [];
  quality.map((p: any) => {
    
    if (p.qualityReport?.plantationVariety?.plantation?.companyId === companyId) {
      newQualityImages.push({
        id: p?.id,
        url: p?.url,
        type: p?.type,
        variety: p?.qualityReport?.plantationVariety?.variety?.name,
        plantation: p.qualityReport?.plantationVariety?.plantation?.name,
        producer: p.qualityReport?.plantationVariety?.plantation?.company?.name,
        seasonId: p.qualityReport?.seasonId,
        companyId: p.qualityReport?.plantationVariety?.plantation?.companyId,
      });
    }
  });
  // return selectImagesByCompanyAndSeason(newPriorAnalysisImages);
  console.log(newQualityImages);
  
  return newQualityImages;
};

export const searchImages = (
  plantation: string,
  variety: any,
  setImagesToShow: any,
  totalImages: any
) => {
  if (
    // todas las fincas / todas las variedades
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad"
  ) {
    setImagesToShow(totalImages);
  } else if (
    // finca concreta / todas las variedades
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad"
  ) {
    let aux = totalImages.filter(
      (c: any) => c.plantation?.toString() === plantation
    );

    setImagesToShow(aux);
  } else if (
    // todas las fincas / variedad concreta
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad"
  ) {
    let aux = totalImages?.filter((c: any) => c.variety?.toString() === variety);
    setImagesToShow(aux);
  } else if (
    // finca concreta / variedad concreta
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad"
  ) {
    let aux = totalImages.filter(
      (c: any) =>
        c.plantation?.toString() === plantation &&
        c.variety?.toString() === variety
    );

    setImagesToShow(aux);
  }
};

export const searchSourcingImages = (
  company: string,
  plantation: string,
  variety: any,
  setImagesToShow: any,
  totalImages: any
) => {
  console.log(totalImages)
  console.log(company)
  if (
    // todas las fincas / todos las variedades / todos los productores
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    company === "Todos los productores"
  ) {
    setImagesToShow(totalImages);
  } else if (
    // finca concreta / todos las variedades / todos los productores
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    company === "Todos los productores"
  ) {
    let aux = totalImages.filter(
      (c: any) => c.plantation?.toString() === plantation
    );

    setImagesToShow(aux);
  } else if (
    // todas las fincas / variedad concreta / todos los productores
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    company === "Todos los productores"
  ) {
    let aux = totalImages.filter((c: any) => c.variety?.toString() === variety);
    setImagesToShow(aux);
  } else if (
    // todas las fincas / todas las variedades / productor concreto
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    company !== "Todos los productores"
  ) {
    let aux = totalImages.filter((c: any) => c.producer?.toString()! === company);
    setImagesToShow(aux);
  } else if (
    // finca concreta / variedad concreta / todos los productores
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    company === "Todos los productores"
  ) {
    let aux = totalImages.filter(
      (c: any) =>
        c.plantation?.toString() === plantation &&
        c.variety?.toString() === variety
    );

    setImagesToShow(aux);
  } else if (
    // finca concreta / todas las variedades / productor concreto
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    company !== "Todos los productores"
  ) {
    let aux = totalImages.filter(
      (c: any) =>
        c.plantation?.toString() === plantation &&
        c.producer?.toString() === company
    );

    setImagesToShow(aux);
  } else if (
    // todas las fincas / variedad concreta / productor concreto
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    company !== "Todos los productores"
  ) {
    // provincia concreta / cualquier variedad / productor concreto
    let aux = totalImages.filter(
      (c: any) =>
        c.variety?.toString() === variety && c.producer?.toString() === company
    );

    setImagesToShow(aux);
  } else if (
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    company !== "Todos los productores"
  ) {
    let aux = totalImages.filter(
      (c: any) =>
        c.plantation?.toString() === plantation &&
        c.variety?.toString() === variety &&
        c.producer?.toString() === company
    );

    setImagesToShow(aux);
  }
};
