import Axios from "axios";
import { baseURL } from "../constants";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { createAvailabilityToExport } from "../Auxiliar/ForecastAuxiliar";

export const saveAgribioForecast = (coReport: any, token: string) => {
  return Axios.post(`${baseURL}Forecast`, coReport, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const saveToForecastVarietyCalibre = (report: any, token: string) => {
  return Axios.post(`${baseURL}ForecastVarCalibres`, report, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAgribioForecastId = (companyId: any, token: string) => {
  let filter = {
    fields: ["id", "visible"],

    where: { companyId: companyId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}Forecast?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getFullAgribioForecast = (seasonId: string, token: string) => {
  let filter = {

    where: { seasonId: seasonId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}Forecast?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAgribioForecastVarieties = (id: any, token: string) => {
  let filter = {
    include: [
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name", "id"],
                  },
                },
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },{
        relation: "forecast",
        scope: {
          fields: ["week", "id"],
        }
      }
    ],
    where: { forecastId: id },
    order: "id DESC",
  };
  return Axios.get(
    `${baseURL}ForecastVarCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postAgribioVisibility = (visible: any, id: any, token: string) => {
  return Axios.patch(
    `${baseURL}Forecast/${id}`,
    { visible },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getValCalAvailabilityById = (
  forecastId: number,
  token: string
) => {
  let filter = {
    where: {
      forecastId: forecastId,
    },
  };
  return Axios.get(
    `${baseURL}ForecastVarCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editAgribioForecast = (edit: any, id: number, token: string) => {
  return Axios.patch(`${baseURL}Forecast/${id}`, edit, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
export const getAvailabilityAgribioById = (id: number, token: string) => {
  return Axios.get(`${baseURL}Forecast/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const resetForecastVarietyCalibre = (id: number, token: string) => {
  return Axios.delete(`${baseURL}Forecast/${id}/forecastVarCalibres`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getLastWeekAgribioForecastId = (companyId: any, token: string) => {
  let filter = {
    fields: ["id", "week"],

    where: { companyId: companyId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}Forecast?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAvailabilityInfoToExport = (
  forecastId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "forecast",
        scope: {
          include: [
            {
              relation: "company",
              scope: {
                fields: ["name", "id"],
              },
            },
          ],
        },
      },
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },
    ],
    where: { forecastId: forecastId },
  };
  return Axios.get(
    `${baseURL}ForecastVarCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(createAvailabilityToExport(data));
      }
    })
    .catch((err) => Promise.reject(err));
};
