import { TAction } from "../types";
import produce from "immer";
import INotification from "../../interfaces/INotification";

const initialState: INotification[] = [];

export default (state = initialState, action: TAction): INotification[] =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_NOTIFICATIONS":
        draftState = action.payload;
        return draftState;
      case "REMOVE_NOTIFICATION":
        return draftState.filter((n: INotification) => n.id !== action.payload);
      default:
        return state;
    }
  });
