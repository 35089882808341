import React, { useEffect, useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationListItemProps {
  report: any;
  handleCheck: any;
  checked: any;
}

const HarvestPopupRow: React.FC<PlantationListItemProps> = ({
  report,
  handleCheck,
  checked,
}) => {
  const [volumen, setVolumen] = useState<number>(0);
  const [check, setCheck] = useState<boolean>(false);
  useEffect(() => {
    let total = 0;
    let aux = checked.find((c: any) => parseInt(c.id) === parseInt(report.id));
    if (aux) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    if (report.harvestCalibres.length > 0) {
      report.harvestCalibres.forEach((c: any) => {
        total = total + c.volume;
      });
      setVolumen(total);
    }
  }, [report, checked]);

  return (
    <tr key={report.id}>
      <td>AGR-{report.id}</td>
      <td>{setWeekNumber(new Date(report.arrive))}</td>
      <td>{report.cutOrder.plantationVarieties.variety.name}</td>
      <td>{report.cutOrder.plantationVarieties.plantation.name}</td>
      <td>{treatNumber(volumen / 1000)}</td>
      <td>
        {" "}
        <div className="form-group form-check">
          <input
            checked={check}
            type="checkbox"
            className="form-check-input"
            id={report.id}
            onClick={handleCheck}
          />
        </div>
      </td>
    </tr>
  );
};

export default HarvestPopupRow;
