import React from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";

interface PlantationListItemProps {
  show: any;
  setShow: any;
  modalText: string;
}

const ModalPopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  modalText,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Alerta</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalText}</Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopup;
