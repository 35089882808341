import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const getAllCertifications = (token: string) => {
  let filter = {
    fields: ["name", "id"],
  };

  return Axios.get(
    `${baseURL}Certifications?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
