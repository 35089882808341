import { colorGama } from "../constants";

export const constructForecastData = (data: any) => {
  let dataByVariety: any[] = [];

  data.map(({ variety, product, calibre, tons, week }: any) => {
    //if the variety already exists
    if (dataByVariety.filter(({ v }) => v === variety).length) {
      //only add the tons, calibre, product to the variety
      let index = dataByVariety.findIndex(({ v }) => v === variety);
      dataByVariety[index].t += tons;
      dataByVariety[index].c += calibre;
    } else {
      dataByVariety.push({ t: tons, v: variety, p: product, c: calibre, w: week });
    }
  });

  return dataByVariety;
};

export const extractForecastCalibres = (
  forecast: any,
  pickedVariety: string
) => {
  let calibresByVariety: any[] = [];
  forecast.forEach((f: any) => {
    if (f.varietyCalibre.variety.name === pickedVariety) {
      calibresByVariety.push({
        calibre: f.varietyCalibre.calibre.code,
        volume: f.volume,
      });
    }
  });

  return addColorGama(calibresByVariety);
};

// add colorGama

const addColorGama = (calibresByVariety: any) => {
  calibresByVariety.map(({ calibre, volume }: any, index: any) => {
    calibresByVariety[index].color = colorGama[index];
  });
  // console.log(calibresByVariety)
  return calibresByVariety;
};

export const constructAgribioPurchaseForecastData = (data: any) => {
  let dataByVariety: any[] = [];

  data.map(({ pickedVariety, pickedCalibre, tons }: any) => {
    //if the variety already exists
    if (dataByVariety.filter(({ v }) => v === pickedVariety).length) {
      //only add the tons, calibre, product to the variety
      let index = dataByVariety.findIndex(({ v }) => v === pickedVariety);
      dataByVariety[index].t += tons;
      dataByVariety[index].c += pickedCalibre;
    } else {
      dataByVariety.push({ t: tons, v: pickedVariety, c: pickedCalibre });
    }
  });

  return dataByVariety;
};

export const createAvailabilityToExport = (rawData: any[]) => {
  let newForecastToExport: any[] = [];
  if(rawData){
  rawData.forEach((v: any) => {
    newForecastToExport.push({
        week: v.forecast.week,
        producer: v.forecast.company.name,
        variety: v.varietyCalibre.variety.name,
        calibre: v.varietyCalibre.calibre.code,
        tons: v.volume/1000,
        
      });
  });
}
  return replacingAvailabilityDotByComma(newForecastToExport);
};

export const replacingAvailabilityDotByComma = (data: any[]) => {
  let finalData: any[] = [];;

  data.map(
    ({
      week,
      producer,
      variety,
      calibre,
      tons,
    }) => { 
      finalData.push({
        week,
        producer,
        variety,
        calibre,
        tons: tons.toLocaleString(),
      });
    })
 return finalData
}

