import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";
import {
  createHarvestReportList,
  pickHarvestReportWeeks,
  filterHarvestReportByVariety,
} from "../Auxiliar/HarvestReportAuxiliar";

export const getHarvestReportsByCompanyId = (
  companyId: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    // Company finished CutOrders filter

    include: [
      {
        relation: "cutOrder",
        scope: {
          include: [
            {
              relation: "plantationVarieties",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                      include: {
                        relation: "product",
                      },
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId", "locationId"],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    where: { seasonId: seasonId },
    order: "arrive DESC",
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(
          createHarvestReportList(response.data, companyId)
        );
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportForPlantationView = (
  pvId: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    // Company finished CutOrders filter

    include: [
      {
        relation: "cutOrder",
        scope: {
          where: { and: [{ plantationVarietyId: pvId }, { active: 1 }] },
          include: [
            {
              relation: "plantationVarieties",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                      include: {
                        relation: "product",
                      },
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId", "locationId"],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    where: { and: [{ seasonId: seasonId }] },
    order: "createdAt DESC",
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux: any[] = [];

        response.data.forEach((pv: any) => {
          if (pv.cutOrder) {
            aux.push(pv);
          }
        });

        return Promise.resolve(
          aux.sort(function (a, b) {
            if (a.arrive > b.arrive) {
              return 1;
            }
            if (a.arrive < b.arrive) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
        );
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportsForProductorHome = (
  init: Date,
  seasonId: number,
  token: string
) => {
  let filter = {
    where: { seasonId: seasonId, start: { gt: init } },
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportWeekByCompanyId = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "cutOrder",
        scope: {
          fields: ["state", "companyId"],
        },
      },
    ],

    where: { and: [{ companyId: id }, { seasonId: seasonId }] },
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(pickHarvestReportWeeks(response.data));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportIdCutOrderId = (id: number, token: string) => {
  let filter = {
    fields: ["id"],

    where: { cutOrderId: id },
    order: "id DESC",
  };
  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCutOrderIdByHarvestReportId = (id: number, token: string) => {
  let filter = {
    fields: ["cutOrderId"],

    where: { id: id },
    order: "id DESC",
  };
  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestCalibreByCutOrder = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "harvestReport",
        scope: {
          fields: ["state", "companyId"],
        },
      },
    ],

    where: { and: [{ companyId: id }, { seasonId: seasonId }] },
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(pickHarvestReportWeeks(response.data));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSingleHarvestReportById = (id: number, token: string) => {
  return Axios.get(`${baseURL}HarvestReports/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestCalibreByCutOrderId = (id: number, token: string) => {
  let filter = {
    include: [
      {
        relation: "harvestCalibres",
        scope: {
          include: {
            relation: "calibre",
            scope: {
              fields: ["id", "code"],
            },
          },
        },
      },
    ],
    where: { cutOrderId: id },
  };
  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportsForWarehouse = (
  id: number,
  seasonId: number,
  variety: string,
  token: string
) => {
  let filter = {
    // Company finished CutOrders filter

    include: [
      {
        relation: "cutOrder",
        scope: {
          include: [
            {
              relation: "plantationVarieties",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                      include: {
                        relation: "product",
                      },
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId", "locationId"],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      { relation: "harvestCalibres" },
    ],
    where: {
      and: [{ companyId: id, seasonId: seasonId, warehouseReportId: null }],
    },
    order: "createdAt DESC",
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(
          filterHarvestReportByVariety(response.data, variety)
        );
      }
    })
    .catch((err) => Promise.reject(err));
};
