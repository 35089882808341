import React, { useEffect, useState } from "react";

//css
import "../theme/homepage.css";
import "../theme/sourcing.css";

//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";

//reqs
import {
  getPurchasesForecast,
  editPurchaseForecast,
} from "../utils/Requests/PurchaseForecastReq";

import { getCompanyIdByName } from "../utils/Requests/CompanyRequest";

//middleware
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getUsersByUniqueRolAndCompany } from "../utils/Requests/UserRequests";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { postUserNotification } from "../utils/Requests/NotificationRequest";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface IPurchaseForecast {
  id: number;
  week: number;
  weekSort: number;
  company: string;
  office: string;
  totalTons: number;
}
const PurchasesForecast: React.FC = () => {
  // let { pathname } = useLocation();
  let history = useHistory();

  const [forecasts, setForecasts] = useState<IPurchaseForecast[]>([]);

  const [rawHistoricForecasts, setRawHistoricForecasts] = useState<
    IPurchaseForecast[]
  >([]);
  const [historicForecastsToShow, setHistoricForecastsToShow] = useState<
    IPurchaseForecast[]
  >([]);
  const [historicIndex, setHistoricIndex] = useState(5);

  const [isForecastSent, setIsForecastSent] = useState(false);

  const [week, setWeek] = useState(0);

  const { token, role } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      fetchdata();
    }
  }, [token]);

  const fetchdata = async () => {
    //season info

    const season = checkSeasonShort();

    const { id: currentSeasonId } = await getSeasonByName(season, token!);
    let data: [] = await getPurchasesForecast(currentSeasonId, token);

    let unsentForecasts: IPurchaseForecast[] = [];
    let sentForecasts: IPurchaseForecast[] = [];
    data.map(
      ({ id, company, office, isSent, totalTons, week, weekSort }: any) => {
        if (!isSent) {
          unsentForecasts.push({
            id,
            week,
            weekSort,
            company: company.name,
            office: office.country,
            totalTons,
          });
        } else {
          sentForecasts.push({
            id,
            week,
            weekSort,
            company: company.name,
            office: office.country,
            totalTons,
          });
        }
      }
    );
    setForecasts(unsentForecasts);
    setRawHistoricForecasts(sentForecasts);
    setHistoricForecastsToShow(sentForecasts);
    //setting if weekly forecast has been sent
    sentForecasts.map(({ week }) => {
      if (week === +setWeekNumber(new Date())) {
        setIsForecastSent(true);
      }
    });
  };

  const openModal = () => {
    Swal.fire({
      icon: "question",
      title: "¿Quieres enviar todos los datos a los productores?",
      text: "¡Una vez envíes la previsión no podrás cambiarla!",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendPurchaseForecast();
      }
    });
  };

  const sendPurchaseForecast = () => {
    Promise.all(
      forecasts.map(async ({ id, company }) => {
        getCompanyIdByName(company, token)
          .then((comp: any) => {
            getUsersByUniqueRolAndCompany(comp.id, "productor", token)
              .then((users: any) => {
                users.forEach((user: any) => {
                  let not = {
                    type: 4,
                    docId: id,
                    personId: user.id,
                  };
                  postUserNotification(not, token)
                    .then((resp: any) => {})
                    .catch((error) => {
                      console.log(error);
                    });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
        let response = await editPurchaseForecast({ isSent: 1 }, id, token);
        return response;
      })
    ).then(() => {
      fetchdata();
      Swal.fire({
        icon: "success",
        title: "¡Gracias!",
        text: "Datos enviados correctamente",
        customClass: {
          cancelButton: "cancelButton",
          confirmButton: "finishButton",
        },
      });
    });
  };

  const sortWeek = ({ target: { value } }: any) => {
    value === "" && setHistoricForecastsToShow(rawHistoricForecasts);
    setWeek(value);
    let sortedForecasts = rawHistoricForecasts.filter(({ week }) => {
      return week.toString().includes(`${value}`);
    });
    setHistoricForecastsToShow(sortedForecasts);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Previsión de Compras</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <div style={{ width: "85%", marginLeft: "9%" }}>
        <div className="container">
          <div className="row mb-3 mt-2 d-flex flex-row-reverse">
            {isForecastSent ? (
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Previsión ya enviada para esta semana"
              >
                {role === "sourcing" ? (
                  <button
                    className="btn btn-md text-light mt-3 finishButton"
                    disabled
                  >
                    Crear previsión
                  </button>
                ) : (
                  <></>
                )}
              </span>
            ) : (
              <>
                {role === "sourcing" ? (
                  <button
                    className="btn btn-md text-light mt-3 finishButton"
                    disabled={isForecastSent}
                    onClick={() =>
                      history.push("/gestionar-previsiones-compras/0")
                    }
                  >
                    Crear previsión
                  </button>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container whiteContainer">
        <div className="row">
          <div className="col-4">
            {!isForecastSent && !!forecasts.length && role === "sourcing" && (
              <div className="d-flex flex-column">
                <button
                  className="btn btn-md text-light mr-4 mb-2 finishButton"
                  disabled={!forecasts.length}
                  onClick={openModal}
                >
                  Enviar
                </button>
                <span style={{ fontSize: "0.7rem" }}>
                  (Solo puedes enviar una previsión a la semana)
                </span>
              </div>
            )}
          </div>
          <div className="col-8">
            <h5 className="ml-5">Previsiones a enviar</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <table className="table text-center">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">SEMANA</th>
                  <th scope="col">PRODUCTOR</th>
                  <th scope="col">OFICINA</th>
                  <th scope="col">VOLUMEN (Tn)</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {forecasts?.map(({ id, week, company, office, totalTons }) => (
                  <tr key={id}>
                    <td>{week}</td>
                    <td>{company}</td>
                    <td>{office}</td>
                    <td>{treatNumber(totalTons / 1000)}</td>
                    <td>
                      <Link
                        to={`/resumen-previsiones-compras/${id}`}
                        className="boldFontLink"
                      >
                        {role === "sourcing" ? "Ver/Editar" : "Ver"}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!forecasts.length && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay previsiones para enviar-</h5>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container whiteContainer">
        <div className="row">
          <div className="col-4">
            <input
              className="form-control form-control-sm mb-2 filterWeekInput"
              type="number"
              placeholder="Semana"
              value={week === 0 ? "" : week!}
              onChange={(e) => sortWeek(e)}
            />
          </div>
          <div className="col-8">
            <h5 className="ml-5">Previsiones enviadas</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <table className="table text-center">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">SEMANA</th>
                  <th scope="col">PRODUCTOR</th>
                  <th scope="col">OFICINA</th>
                  <th scope="col">VOLUMEN (Tn)</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {historicForecastsToShow
                  .slice(0, historicIndex)
                  ?.map(({ id, week, company, office, totalTons }) => (
                    <tr key={id}>
                      <td>{week}</td>
                      <td>{company}</td>
                      <td>{office}</td>
                      <td>{treatNumber(totalTons / 1000)}</td>
                      <td>
                        <Link
                          to={`/resumen-previsiones-compras/${id}`}
                          className="boldFontLink"
                        >
                          Ver
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center mb-3">
              {!historicForecastsToShow.length && (
                <h5>-No hay previsiones para ver-</h5>
              )}
            </div>
            {historicForecastsToShow.length &&
            historicForecastsToShow.length > historicIndex ? (
              <div className="d-flex justify-content-center mb-3 ">
                <span
                  className="boldFontLink"
                  onClick={() => setHistoricIndex(historicIndex + 5)}
                >
                  Ver más
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasesForecast;
