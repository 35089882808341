import React from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import CalibreTablePopupRow from "./CalibreTablePopupRow";

interface PlantationListItemProps {
  show: boolean;
  setShow: any;
  calibres: any;
}

const ModalCalibrePopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  calibres,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Calibres</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">CALIBRES</th>
              <th scope="col">TONELADAS</th>
            </tr>
          </thead>
          <tbody>
            {calibres?.map((cal: any) => (
              <CalibreTablePopupRow key={cal.calibres.code} calibre={cal} />
            ))}
          </tbody>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCalibrePopup;
