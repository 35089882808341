import IPlantation from "../../interfaces/IPlantation";

export const cleanEmptyProvinces = (pt: IPlantation[]) => {
  let a: any[] = [];
  pt.forEach((v: IPlantation) => {
    // Solo añado las que existen, es decir, tienen provincia asociada
    if (v.location) {
      a.push(v);
    }
  });
  return a;
};

export const collectProvinces = (pt: any[]) => {
  const resultado = Array.from(
    new Set(pt.map((s) => s.location.province.id))
  ).map((id) => {
    return {
      id: id,
      name: pt.find((s) => s.location.province.id === id).location.province
        .name,
    };
  });

  return resultado;
};

export const collectProvincesByPlantationVarierty = (pt: any[]) => {
  const resultado = Array.from(
    new Set(pt.map((s) => s.plantation.location.province.id))
  ).map((id) => {
    return {
      id: id,
      name: pt.find((s) => s.plantation.location.province.id === id).plantation
        .location.province.name,
    };
  });

  return resultado;
};

export const sortProvinces = (provinces: []) => {
  return provinces.map(({ name }) => name);
};
