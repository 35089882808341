import React, { useEffect, useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";

interface PlantationListItemProps {
  meteo: any;
}

const MeteoInfo: React.FC<PlantationListItemProps> = ({ meteo }) => {
  const [dias, setDias] = useState([]);

  function diaSemana(dia: number, mes: number, anio: number) {
    var dias = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    var dt = new Date(mes + " " + dia + ", " + anio + " 12:00:00");
    return dias[dt.getUTCDay()];
  }

  useEffect(() => {
    let arr: any = [];
    let now = new Date();
    let proxFechas = [
      new Date(now.setDate(now.getDate() + 0)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
      new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    ];

    proxFechas.forEach((p: any) => {
      let aux = meteo.find((m: any) => m.dayDate === p);
      if (aux) {
        arr.push(aux);
      }
    });

    setDias(arr);
  }, [meteo]);

  return (
    <>
      {dias.length > 0 ? (
        <>
          <div className="subtop-panel">
            <div>Meteorología (Próximos 7 días)</div>

            <div className="dia-container3">
              {meteo.length > 0 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[0].day, meteo[0].month, meteo[0].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[0].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[0].min)}º/{Math.round(meteo[0].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[0].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 1 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[1].day, meteo[1].month, meteo[1].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[1].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[1].min)}º/{Math.round(meteo[1].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[1].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 2 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[2].day, meteo[2].month, meteo[2].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[2].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[1].min)}º/{Math.round(meteo[2].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[2].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 3 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[3].day, meteo[3].month, meteo[3].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[3].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[3].min)}º/{Math.round(meteo[3].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[3].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 4 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[4].day, meteo[4].month, meteo[4].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[4].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[4].min)}º/{Math.round(meteo[4].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[4].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 5 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[5].day, meteo[5].month, meteo[5].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[5].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[5].min)}º/{Math.round(meteo[5].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[5].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 6 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[6].day, meteo[6].month, meteo[6].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[6].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[6].min)}º/{Math.round(meteo[6].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[6].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MeteoInfo;
