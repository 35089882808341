import { baseURL } from "../constants";
import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const getProvinces = (token: string) => {
    return Axios.get(`${baseURL}Provinces`, {
        headers: {
          Authorization: token,
        },
      })
        .then(({ data, status }) => {
          if (status !== 200) {
            return Promise.reject(locateError(status));
          } else {
            return Promise.resolve(data);
          }
        })
        .catch((err) => Promise.reject(err));
    };

    export const getProvinceIdByName = (province: string, token: string) => {
      let filter = {
        fields: ["id"],
        where: { name: province },
      };
      return Axios.get(`${baseURL}Provinces?filter=` + JSON.stringify(filter), {
        headers: {
          Authorization: token,
        },
      })
        .then(({ data, status }) => {
          if (status !== 200) {
            return Promise.reject(locateError(status));
          } else {
            return Promise.resolve(data[0]);
          }
        })
        .catch((err) => Promise.reject(err));
    };

    export const getProvinceNameById = (provinceId: string, token: string) => {
      let filter = {
        fields: ["name"],
        where: { id: provinceId },
      };
      return Axios.get(`${baseURL}Provinces?filter=` + JSON.stringify(filter), {
        headers: {
          Authorization: token,
        },
      })
        .then(({ data, status }) => {
          if (status !== 200) {
            return Promise.reject(locateError(status));
          } else {
            return Promise.resolve(data[0]);
          }
        })
        .catch((err) => Promise.reject(err));
    };