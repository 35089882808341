import Axios from "axios";
import IPlantation from "../../interfaces/IPlantation";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import IPlantationVariety from "../../interfaces/IPlantationVariety";
import {
  clearPlantationVarieties,
  selectOwnPltVarieties,
} from "../Auxiliar/VarietyAuxiliar";

import { baseURL } from "../constants";

export const getPVForAnalysisById = (id: number, token: string) => {
  /* 
    LLAMADA PARA RECOGER FINCA-VARIEDAD, 
    NOMBRE DE LA FINCA, CON NOMBRE DE PRODUCTO Y VARIEDAD, CALIBRES ASOCIADOS, 
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "plantation",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "variety",
        scope: {
          fields: ["name", "id", "productId"],
          include: {
            relation: "product",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantationVariety);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietiesByPlantationId = (
  id: number,
  sv: any,
  token: string
) => {
  /* 
    LLAMADA PARA RECOGER LAS VARIEDADES ASOCIADAS A UNA FINCA*/

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "variety",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "plantation",
        scope: {
          fields: ["name", "id"],
        },
      },
    ],
    where: {
      plantationId: id,
      active: true,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = response.data.filter(
          (v: any) => !v.variety.name.includes("Hoja")
        );

        sv(
          aux.sort(function (a: any, b: any) {
            if (a.variety.name > b.variety.name) {
              return 1;
            }
            if (a.variety.name < b.variety.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
        );
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationVarietyById = (
  id: number,
  seasonId: number,
  token: string
) => {
  /* 
    LLAMADA PARA RECOGER FINCA-VARIEDAD, 
    NOMBRE DE LA FINCA, CON NOMBRE DE PRODUCTO Y VARIEDAD, CALIBRES ASOCIADOS, 
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "plantation",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "variety",
        scope: {
          fields: ["name", "id", "productId"],
          include: {
            relation: "product",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
      {
        relation: "priorAnalyses",
        scope: {
          order: "createdAt DESC",
          include: {
            relation: "analysisCalibres",
            scope: {
              fields: ["percentage", "id", "calibreId"],
              include: {
                relation: "calibres",
                scope: {
                  fields: ["code"],
                },
              },
            },
          },
          where: {
            seasonId: seasonId,
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantationVariety);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationVarietyQualityById = (
  id: number,
  seasonId: number,
  token: string
) => {
  /* 
    LLAMADA PARA RECOGER FINCA-VARIEDAD, 
    NOMBRE DE LA FINCA, CON NOMBRE DE PRODUCTO Y VARIEDAD, CALIBRES ASOCIADOS, 
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "plantation",
        scope: {
          fields: ["name", "id", "locationId", "companyId"],
          include: {
            relation: "location",
            scope: {
              fields: ["provinceId", "name", "id"],
              include: {
                relation: "province",
                scope: {
                  fields: ["name", "id"],
                },
              },
            },
          },
        },
      },
      {
        relation: "variety",
        scope: {
          fields: ["name", "id", "productId"],
        },
      },
      {
        relation: "qualityReports",
        scope: {
          order: "record DESC",
          where: {
            seasonId: seasonId,
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAnalysesByPlantationId = (id: number, token: string) => {
  /* LLAMADA PARA RECOGER NOMBRE FINCA, 
    VARIEDADES ACTIVAS, Y LA FECHA DE SU ULTIMO INFORME SI EXISTIERA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: ["name", "plantationId", "id"],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                where: { capacity: { neq: 0 } },
                order: "createdAt DESC",
                limit: 1,
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                where: { capacity: { neq: 0 } },
                order: "createdAt DESC",
                limit: 1,
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllPlantationsVarieties = (
  plt: IPlantation[],
  spv: any,
  token: string
) => {
  let filter = {
    fields: ["id", "active", "plantationId", "varietyId"],
    include: {
      relation: "variety",
      scope: {
        fields: ["name", "id", "productId"],
        include: {
          relation: "product",
        },
        order: "id DESC",
      },
    },
    where: {
      active: 1,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        spv(selectOwnPltVarieties(response.data, plt));
        return Promise.resolve(clearPlantationVarieties(response.data, plt));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getActivesPlantationVarieties = (season: any, token: string) => {
  let filter = {
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "variety",
        scope: {
          fields: ["name", "id", "productId"],
          include: {
            relation: "product",
          },
        },
      },
      {
        relation: "plantation",
        scope: {
          fields: [
            "name",
            "id",
            "owner",
            "ggNumber",
            "gg",
            "grasp",
            "demeter",
            "biosuisse",
            "companyId",
          ],
          include: {
            relation: "company",
            scope: {
              fields: ["id", "name"],
            },
          },
        },
      },
      {
        relation: "qualityReports",
        scope: {
          fields: ["id", "record"],
          order: "record DESC",
          limit: 1,
          where: { seasonId: season.id },
        },
      },
    ],
    where: {
      active: 1,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let clear = response.data.filter((p: any) => p.plantation);
        let clear2 = clear.filter((p: any) => p.qualityReports.length > 0);
        let aux = clear2.sort(function (a: any, b: any) {
          if (a.qualityReports[0] && b.qualityReports[0]) {
            if (a.qualityReports[0].record > b.qualityReports[0].record) {
              return -1;
            }
            if (a.qualityReports[0].record < b.qualityReports[0].record) {
              return 1;
            }
            // a must be equal to b
            return 0;
          }
          if (a.qualityReports[0] && !b.qualityReports[0]) {
            return -1;
          }
          if (!a.qualityReports[0] && b.qualityReports[0]) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getActivesPlantationVarietiesByCompany = (
  season: any,
  companyId: any,
  token: string
) => {
  let filter = {
    fields: ["id", "active", "plantationId", "varietyId"],
    include: [
      {
        relation: "variety",
        scope: {
          fields: ["name", "id", "productId"],
          include: {
            relation: "product",
          },
        },
      },
      {
        relation: "plantation",
        scope: {
          fields: [
            "name",
            "id",
            "owner",
            "companyId",
            "gg",
            "ggNumber",
            "grasp",
            "demeter",
            "biosuisse",
          ],
          where: { companyId: companyId },
        },
      },
      {
        relation: "qualityReports",
        scope: {
          fields: ["id", "record"],
          order: "record DESC",
          limit: 1,
          where: { seasonId: season.id },
        },
      },
    ],
    where: {
      active: 1,
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let clear = response.data.filter((p: any) => p.plantation);
        let clear2 = clear.filter((p: any) => p.qualityReports.length > 0);
        let aux = clear2.sort(function (a: any, b: any) {
          if (a.qualityReports[0] && b.qualityReports[0]) {
            if (a.qualityReports[0].record > b.qualityReports[0].record) {
              return -1;
            }
            if (a.qualityReports[0].record < b.qualityReports[0].record) {
              return 1;
            }
            // a must be equal to b
            return 0;
          }
          if (a.qualityReports[0] && !b.qualityReports[0]) {
            return -1;
          }
          if (!a.qualityReports[0] && b.qualityReports[0]) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getActiveVarietiesByPlantationId = (id: number, token: string) => {
  let filter = {
    fields: ["id", "active", "plantationId", "varietyId"],
    include: {
      relation: "variety",
      scope: {
        fields: ["name", "id"],
      },
    },
    where: {
      and: [
        {
          active: 1,
        },
        { plantationId: id },
      ],
    },
  };

  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as IPlantationVariety[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deletePlantationVarietyRelations = (
  relationId: number,
  token: any
) => {
  return Axios.delete(`${baseURL}PlantationVarieties/${relationId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const savePlantationVarietyRelation = (
  plantationId: number,
  varietyId: number,
  token: any
) => {
  let info = {
    plantationId: plantationId,
    varietyId: varietyId,
  };
  return Axios.post(`${baseURL}PlantationVarieties`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
