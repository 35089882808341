import React from "react";
import "../theme/cutOrder.css";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface CutOrderCalibreListItemProps {
  cal: any;
  calibres: any;
  setCalibres: any;
  calibresShow: any;
  setCalibresShow: any;
  calibresStatic: any;
  disponible: number;
  setDisponible: any;
  calibresInit: any;
  calibresDeleted: any;
  setCalibresDeleted: any;
  calibresAdded: any;
  setCalibresAdded: any;
  destrio: number;
  setDestrio: any;
}

const WarehouseEditCalibreRow: React.FC<CutOrderCalibreListItemProps> = ({
  cal,
  calibres,
  setCalibres,
  calibresShow,
  setCalibresShow,
  calibresStatic,
  disponible,
  setDisponible,
  calibresInit,
  calibresDeleted,
  setCalibresDeleted,
  calibresAdded,
  setCalibresAdded,
  destrio,
  setDestrio,
}) => {
  const deleteCalibre = () => {
    let en = calibresInit.find((cs: any) => cs.calibre === cal.calibre);
    let en2 = calibresAdded.find((cs: any) => cs.calibre === cal.calibre);
    if (en) {
      let ax = calibresDeleted;
      ax.push(en);
      setCalibresDeleted(ax);
    }
    if (en2) {
      let ax = calibresAdded.filter((ca: any) => ca.calibre !== cal.calibre);
      setCalibresAdded(ax);
    }

    let aux = calibres.filter((c: any) => c.calibre !== cal.calibre);
    let showAux = calibresShow;
    let calAux = calibresStatic.find((cs: any) => cs.code === cal.calibre);
    showAux.push(calAux);
    showAux.sort(function (a: any, b: any) {
      if (a.code > b.code) {
        return 1;
      }
      if (a.code < b.code) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    setDestrio(parseFloat((destrio + parseFloat(cal.volume)).toFixed(2)));

    let total =
      parseFloat(disponible.toString()) - parseFloat(cal.volume.toString());
    setDisponible(parseFloat(total.toFixed(2)));
    setCalibresShow(showAux);
    setCalibres(aux);
  };

  return (
    <>
      <tr key={cal.calibre}>
        <td>{cal.calibre}</td>
        <td>{treatNumber(parseFloat(cal.volume))}</td>
        <td>
          <img
            className="iconsSize"
            onClick={() => deleteCalibre()}
            src="/icon/close.png"
            alt=""
          />
        </td>
      </tr>
    </>
  );
};

export default WarehouseEditCalibreRow;
