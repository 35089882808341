import React, { useState, useEffect } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";

import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import produce from "immer";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { IFullUser } from "../../interfaces/IFullUser";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getAllUsers,
  deleteUserFromDB,
} from "../../utils/Requests/UserRequests";
import { buildUserRole, sortUsers } from "../../utils/Auxiliar/UsersAux";
import { normalizer } from "../../utils/Auxiliar/EquivalentAuxiliar";

const AdminUsers: React.FC = () => {
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  //users
  const [users, setUsers] = useState<IFullUser[]>([]);
  const [usersToShow, setUsersToShow] = useState<IFullUser[]>([]);

  const [numberShow, setNumberShow] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (token) {
      loadUsers();
    }
  }, [token]);

  const loadUsers = async () => {
    const users = await getAllUsers(token);
    let sortedUsers = sortUsers(users, token);
    //TODO - sort users so you can see the actual company name and office
    setUsers(users);
    setUsersToShow(users);
  };

  const searchHanlder = ({ target: { value } }: any) => {
    setSearchText(value);
    value = value.toLowerCase();
    value === "" && setUsersToShow(users);
    let filteredUsers = users.filter(
      ({ name, surname1, surname2, username, email }: any) => {
        return (
          normalizer(username.toLowerCase()).includes(normalizer(value)) ||
          normalizer(name.toLowerCase()).includes(normalizer(value)) ||
          normalizer(surname1.toLowerCase()).includes(normalizer(value)) ||
          normalizer(surname2.toLowerCase()).includes(normalizer(value)) ||
          email.toLowerCase().includes(value)
        );
      }
    );
    setUsersToShow(filteredUsers);
  };

  const openModalDelete = (userToDeleteId: number) => {
    Swal.fire({
      icon: "warning",
      text: `¿Estás seguro quieres borrar este usuario de base de datos? ¡No hay vuelta atrás!`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(async ({ value }) => {
      if (value) {
        deleteUserFromDB(userToDeleteId, token).then((response) => {
          if (response) {
            removeUserFromUI(userToDeleteId);
            Swal.fire({
              icon: "success",
              text: "Usuario borrado correctamente",
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Se ha producido un error, intententelo más tarde",
              customClass: {
                cancelButton: "cancelButton",
                confirmButton: "finishButton",
              },
            });
          }
        });
      }
    });
  };

  const removeUserFromUI = (userToDeleteId: number) => {
    let [userToDelete] = users.filter(({ id }) => id === userToDeleteId);
    let index = users.indexOf(userToDelete);
    setUsers((state) => {
      const newArray = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newArray;
    });
    setUsersToShow((state) => {
      const newArray = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newArray;
    });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Usuarios {`(${users.length})`}</div>
        <button className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </button>
      </div>
      <div className="select-divider-search d-flex justify-content-start mt-4">
        <Form.Control
          className="search-select ml-3 mr-3"
          size="sm"
          type="text"
          placeholder="Buscar usuario"
          value={searchText}
          onChange={searchHanlder}
          style={{ width: 170, height: 31, marginLeft: 1 }}
        />
        <Link to={`/admin-usuarios/0`}>
          <button
            className="button-search"
            style={{ width: "11vw", height: "4vh" }}
          >
            <FontAwesomeIcon icon={faPlus} /> <b>Crear Usuario</b>
          </button>
        </Link>
      </div>

      <div className="table-style">
        <table
          className="table"
          style={{ textAlign: "center", background: "white" }}
        >
          <thead className="thead-dark" style={{ fontSize: 11 }}>
            <tr>
              <th scope="col">#</th>
              <th scope="col">NOMBRE</th>
              <th scope="col">APELLIDOS</th>
              <th scope="col">NOMBRE DE USUARIO</th>
              <th scope="col">EMAIL</th>
              <th scope="col">ROL</th>
              <th scope="col">COMPAÑÍA</th>
              <th scope="col">OFICINA</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {usersToShow.slice(0, numberShow).map((user: any) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  <Link
                    to={`/admin-usuarios/${user.id}`}
                    style={{ color: "black", fontWeight: 500 }}
                  >
                    {user.name}
                  </Link>
                </td>
                <td>
                  {user.surname1}, {user.surname2}
                </td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{buildUserRole(user.role)}</td>
                <td>{user.company?.name ? user.company.name : "n/a"}</td>
                <td>{user.office?.country ? user.office?.country : "n/a"}</td>
                <td className="ml-5">
                  <Link
                    to={`admin-usuarios/${user.id}`}
                    style={{ color: "white" }}
                  >
                    <button
                      className="btn btn-sm text-light mr-3"
                      style={{ backgroundColor: "#cad401" }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </Link>
                </td>
                <td>
                  <button
                    className="btn btn-sm bg-danger text-light"
                    style={{ borderRadius: "13px" }}
                    onClick={() => openModalDelete(user.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {usersToShow.length > numberShow && (
          <div className="more-button-align">
            <button
              className="more-button"
              onClick={() => setNumberShow(numberShow + 5)}
            >
              Ver más
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
