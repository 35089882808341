import { ILineChart } from "../../interfaces/ILineChart";
import { setWeekNumber } from "./WeekAuxiliar";
import weeks from "../../utils/statics/week.json";

import { IExcelProducerSalesData } from "../../pages/ProducerSales";

export const sortCompanyRawData = (
  rawData: any[],
  seasonId: number,
  companyId: number
) => {
  let sortedData: any[] = [];
  if (rawData) {
    rawData.forEach((v: any) => {
      if (
        v.purchase?.seasonId === seasonId &&
        v.purchase?.companyId === +companyId &&
        v.purchase?.send === true
      ) {
        sortedData.push({
          id: v.id,
          variety: v?.varietyCalibre?.variety?.name,
          saleDate: v?.purchase?.createdAt,
          week: v?.purchase.week,
          weekSort: v.purchase?.weekSort,
          volumen: v?.volumen / 1000,
          seasonId: v?.purchase?.seasonId,
          calibre: v?.varietyCalibre?.calibre?.code,
        });
      }
    });
  }

  return sortedData;
};

export const sortCompanyDataToEdit = (rawData: any[]) => {
  rawData.sort(function (a: any, b: any) {
    if (a.weekSort > b.weekSort) {
      return 1;
    }
    if (a.weekSort < b.weekSort) {
      return -1;
    }
    return 0;
  });

  return convertToProducerLineChartData(rawData);
};

export const convertToProducerLineChartData = (data: any[]) => {
  let lineChartData: ILineChart[] = [];

  //volume in tons already
  for (let i = 0; i < 52; i++) {
    if (data[i]?.weekSort === i + 1) {
      if (lineChartData.filter(({ x }) => x === data[i].week).length) {
        let index = lineChartData.findIndex(({ x }) => x === data[i].week);
        lineChartData[index].y += data[i].volumen;
      } else {
        lineChartData.push({ x: data[i].week, y: data[i].volumen });
      }
    } else {
      let [{ week }] = weeks.filter(({ sort }) => sort === i + 1);
      //if not week push coordenates as 0 and restart count
      data.splice(i, 0, {
        id: 0,
        week: week,
        weekSort: i + 1,
        volumen: 0,
      });
      i--;
    }
  }

  data.map(({ week, volumen }: any) => {
    if (lineChartData.filter(({ x }) => x === week).length) {
      let index = lineChartData.findIndex(({ x }) => x === week);
      lineChartData[index].y += volumen;
    } else {
      lineChartData.push({ x: week, y: volumen });
    }
  });

  return lineChartData;
};

export const sortTableData = (data: any) => {
  let newData: any[] = [];

  data.map(
    ({
      id,
      variety,
      saleDate,
      week,
      weekSort,
      volumen,
      seasonId,
      calibre,
    }: any) => {
      if (id !== 0) {
        newData.push({
          id,
          variety,
          saleDate,
          week,
          weekSort,
          volumen,
          seasonId,
          calibre,
        });
      }
    }
  );

  return groupDataByWeek(newData);
};

export const groupDataByWeek = (data: any) => {
  let tableData: any = [];

  data.map(({ id, week, volumen, count, saleDate, weekSort }: any) => {
    if (tableData.filter(({ w }: any) => w === week).length) {
      let index = tableData.findIndex(({ w }: any) => w === week);
      tableData[index].y += volumen;
    } else {
      tableData.push({
        w: week,
        t: volumen,
        d: saleDate,
        c: count,
        s: weekSort,
      });
    }
  });

  return sortTableDataToEdit(tableData);
};

export const sortTableDataToEdit = (solvedTableData: any[]) => {
  solvedTableData.sort(function (a: any, b: any) {
    if (a.s < b.s) {
      return 1;
    }
    if (a.s > b.s) {
      return -1;
    }
    return 0;
  });

  return solvedTableData;
};

export const sortCompanySalesData = (
  rawData: any[],
  week: number,
  seasonId: number,
  companyId: number
) => {
  let sortedData: any = [];
  rawData.forEach((v: any) => {
    if (
      v.purchase?.seasonId === seasonId &&
      v.purchase?.companyId === +companyId &&
      v.purchase?.send === true &&
      v.purchase.week === week
    ) {
      sortedData.push({
        pickedVariety: v.varietyCalibre.variety.name,
        pickedCalibre: v.varietyCalibre.calibre.code,
        tons: v.volumen / 1000,
      });
    }
  });
  return sortedData;
};

export const filterDataByVariety = (
  data: any[],
  pickedvariety: string | null
) => {
  let sortedData = data.filter(({ variety }) => {
    if (pickedvariety) {
      return variety === pickedvariety;
    }
  });
  if (!pickedvariety) {
    sortedData = data;
  }

  if (sortedData.length) {
    return sortCompanyDataToEdit(sortedData);
  } else {
    return [];
  }
};

export const addingProducerSeasonReference = (
  data: IExcelProducerSalesData[],
  currentSeasonId: number,
  lastSeasonId: number
) => {
  for (var i = 0; i < data.length; i++) {
    if (data[i].seasonId === currentSeasonId) {
      data[i].reference = "Año Actual";
    } else if (data[i].seasonId === lastSeasonId) {
      data[i].reference = "Año Anterior";
    }
  }
  return filteringIdZero(data);
};

export const filteringIdZero = (data:any) => {
  let newData: any[] = [];

  data.map(
    ({
      id,
      variety,
      saleDate,
      week,
      weekSort,
      volumen,
      seasonId,
      calibre,
      reference,
    }: any) => {
      if (id !== 0) {
        newData.push({
          id,
          variety,
          saleDate,
          week,
          weekSort,
          volumen,
          seasonId,
          calibre,
          reference,
        });
      }
    }
  );
  return replacingProducerDotByComma (newData);
}

export const replacingProducerDotByComma = (
  data: IExcelProducerSalesData[]
) => {
  let finalData: IExcelProducerSalesData[] = [];

  data.map(
    ({
      id,
      seasonId,
      reference,
      week,
      weekSort,
      saleDate,
      variety,
      calibre,
      volumen,
    }) => {
      finalData.push({
        id,
        seasonId,
        reference: reference!,
        week,
        weekSort,
        calibre,
        variety,
        saleDate,
        volumen: volumen.toLocaleString(),
      });
    }
  );
  return finalData;
};
