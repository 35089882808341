import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import "../theme/sourcing.css";

//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import {
  sortRawData,
  filterDataByCompanyAndProduct,
  getPreviousSeason,
  filterDataByYear,
  searchHighestNumber,
  searchLowestNumber,
  sortSalesRawData,
  addingSeasonReference,
} from "../utils/Auxiliar/SourcingSalesAux";
import { filterCompanyNames } from "../utils/Auxiliar/CompanyAux";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { filterProductNames } from "../utils/Auxiliar/ProductAuxiliar";
import { filterOfficeNames } from "../utils/Auxiliar/OfficeAux";
import {
  sortRawPurchaseForecast,
  sortRawDataToExcel,
} from "../utils/Auxiliar/PurchasesForecastAux";

//reqs
import {
  getAllCompanies,
  getCompanyIdByName,
} from "../utils/Requests/CompanyRequest";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getAllProducts } from "../utils/Requests/ProductsReq";
import { getCurrentSales } from "../utils/Requests/SalesReq";
import {
  getOffices,
  getOfficeIdByName,
  getOfficeNameById,
} from "../utils/Requests/OfficeReqs";
import { getAllForecastPurchases } from "../utils/Requests/PurchaseForecastReq";

//middleware
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

//components
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";

//interfaces
import { ISales } from "../interfaces/ISales";
import { ILineChart } from "../interfaces/ILineChart";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//constants
import weeksSort from "../utils/statics/week.json";

export interface IExcelSalesData {
  id?: number;
  reference?: string;
  season: string;
  seasonId?: number;
  week: number;
  producer: string;
  office?: string;
  variety: string;
  product: string;
  tons: any;
}

interface IIncrement {
  x: number;
  y: number;
  y0: 0;
}

const SourcingSales: React.FC = () => {
  const user = useSelector(({ user }: IStore) => user as IUser);
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [thisYearRawData, setThisYearRawData] = useState<ISales[]>([]);
  const [lastYearRawData, setLastYearRawData] = useState<ISales[]>([]);

  const [rawForecast, setRawForecast] = useState<ISales[]>([]);
  const [forecast, setForecast] = useState<ILineChart[]>([]);

  const [companies, setCompanies] = useState<string[]>([]);
  const [pickedCompany, setPickedCompany] = useState("");

  const [products, setProducts] = useState<string[]>([]);
  const [pickedProduct, setPickedProduct] = useState("");

  const [offices, setOfficess] = useState<string[]>([]);
  const [pickedOffice, setPickedOffice] = useState("");

  const [weeklyOrTotal, setWeeklyOrTotal] = useState<string>("Semanal");

  const [previousYearData, setPreviousYearData] = useState<ILineChart[]>([]);
  const [currentYearData, setCurrentYearData] = useState<ILineChart[]>([]);
  const [heightLineChart, setHeightLineChart] = useState(800);

  const [heightBarChart, setHeightBarChart] = useState(100);
  const [baseBarChart, setBaseBarChart] = useState(-100);

  const [weeklyIncrement, setWeeklyIncrement] = useState<IIncrement[]>([]);
  const [totalIncrement, setTotalIncrement] = useState<IIncrement[]>([]);

  const [searchWeek, setSearchWeek] = useState(0);
  const [searchOffice, setSearchOffice] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [searchCompanyId, setSearchCompanyId] = useState(0);

  const [excelForecastSalesData, setExcelForecastSalesData] = useState<
    IExcelSalesData[]
  >([]);
  const [excelSeasonsSalesData, setExcelSeasonsSalesData] = useState<
    IExcelSalesData[]
  >([]);
  const [excelTotalSalesData, setExcelTotalSalesData] = useState<
    IExcelSalesData[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let allCompanies = await getAllCompanies(token!);
    let companies = filterCompanyNames(allCompanies);
    setCompanies(companies);
    setPickedCompany("");

    let allProducts = await getAllProducts(token);
    let products = filterProductNames(allProducts);
    setProducts(products);
    setPickedProduct("");

    let allOffices = await getOffices(token!);
    let offices = filterOfficeNames(allOffices);
    setOfficess(offices);
    let office = user.officeId ? "" + user.officeId : "";
    if (user.officeId) {
      let { country } = await getOfficeNameById("" + user.officeId, token!);
      office = country;
    }
    setPickedOffice(office);

    //purchases
    let rawData = await getCurrentSales(token!);
    let sortedData = sortRawData(rawData);
    //season info
    const season = checkSeasonShort();
    const previousSeason = getPreviousSeason(season);
    const { id: currentSeasonId } = await getSeasonByName(season, token!);
    const { id: lastSeasonId } = await getSeasonByName(previousSeason, token!);

    //forecasts
    let rawPurchasesForecast = await getAllForecastPurchases(
      currentSeasonId,
      token!
    );
    let sortedPurchaseForecast = sortRawPurchaseForecast(rawPurchasesForecast);

    //filter by season
    let thisYearData = filterDataByYear(sortedData, currentSeasonId);
    let lastYearData = filterDataByYear(sortedData, lastSeasonId);
    let currentForecast = filterDataByYear(
      sortedPurchaseForecast,
      currentSeasonId
    );

    setThisYearRawData(thisYearData);
    setLastYearRawData(lastYearData);
    setRawForecast(currentForecast);

    //filter by company and product
    let companyId = null;
    let officeId = user.officeId ? user.officeId : null;
    let product = null;

    //constructing weeklys
    let weeklyThisYear = filterDataByCompanyAndProduct(
      thisYearData,
      companyId,
      officeId,
      product,
      "Semanal"
    );
    let weeklyPreviousYear = filterDataByCompanyAndProduct(
      lastYearData,
      companyId,
      officeId,
      product,
      "Semanal"
    );

    //constructing absolutes
    let totalThisYear = filterDataByCompanyAndProduct(
      thisYearData,
      companyId,
      officeId,
      product,
      "Acumulado"
    );
    let totalPreviousYear = filterDataByCompanyAndProduct(
      lastYearData,
      companyId,
      officeId,
      product,
      "Acumulado"
    );

    //constructing forecasts
    let forecastChart = filterDataByCompanyAndProduct(
      currentForecast,
      companyId,
      officeId,
      product,
      weeklyOrTotal
    );
    setCurrentYearData(weeklyThisYear);
    setPreviousYearData(weeklyPreviousYear);
    setForecast(forecastChart);

    let combinedArraysLineChart = weeklyThisYear.concat(
      weeklyPreviousYear,
      forecastChart
    );

    let heightLineChart = searchHighestNumber(combinedArraysLineChart);
    setHeightLineChart(heightLineChart * 1.1);

    let weeklyIncrement = constructIncrements(
      weeklyThisYear,
      weeklyPreviousYear
    );

    setWeeklyIncrement(weeklyIncrement);
    let totalIncrement = constructIncrements(totalThisYear, totalPreviousYear);
    setTotalIncrement(totalIncrement);

    //setting base and height
    let combinedArraysBarChart = weeklyIncrement.concat(totalIncrement);
    setHeightAndBaseBarChart(combinedArraysBarChart);

    //export data to excel
    let excelForecastData = sortRawDataToExcel(rawPurchasesForecast);
    setExcelForecastSalesData(excelForecastData);
    console.log(rawPurchasesForecast)
    console.log(excelForecastData)

    let excelThisYearSalesData = sortSalesRawData(rawData);
    let excelSortedSalesData = addingSeasonReference(
      excelThisYearSalesData,
      currentSeasonId,
      lastSeasonId
    );
    setExcelSeasonsSalesData(excelSortedSalesData);
    concateningExcelData(excelForecastData, excelSortedSalesData);
  };

  const concateningExcelData = (
    excelForecastSalesData: IExcelSalesData[],
    excelSeasonsSalesData: IExcelSalesData[]
  ) => {
    let totalExcelData = excelForecastSalesData.concat(excelSeasonsSalesData);
    setExcelTotalSalesData(totalExcelData);
  };

  const filtering = async (value: string, filter: string) => {
    let company = pickedCompany;
    let office = pickedOffice;
    let product = pickedProduct;
    let isTotal = weeklyOrTotal;

    if (filter === "company") {
      setPickedCompany(value);
      company = value;
    } else if (filter === "office") {
      setPickedOffice(value);
      office = value;
    } else if (filter === "product") {
      setPickedProduct(value);
      product = value;
    } else if (filter === "weeklyOrTotal") {
      setWeeklyOrTotal(value);
      isTotal = value;
    }
    let companyId = null;
    if (company) {
      let { id } = await getCompanyIdByName(company, token);
      companyId = id;
    }
    let officeId = null;
    if (office) {
      let { id } = await getOfficeIdByName(office, token);
      officeId = id;
    }

    //constructing weekly increments
    let weeklyThisYear = filterDataByCompanyAndProduct(
      thisYearRawData,
      companyId,
      officeId,
      product,
      "Semanal"
    );

    let weeklyPreviousYear = filterDataByCompanyAndProduct(
      lastYearRawData,
      companyId,
      officeId,
      product,
      "Semanal"
    );

    //constructing total increments
    let totalThisYear = filterDataByCompanyAndProduct(
      thisYearRawData,
      companyId,
      officeId,
      product,
      "Acumulado"
    );

    let totalPreviousYear = filterDataByCompanyAndProduct(
      lastYearRawData,
      companyId,
      officeId,
      product,
      "Acumulado"
    );

    //constructing forecast
    let weeklyForecast = filterDataByCompanyAndProduct(
      rawForecast,
      companyId,
      officeId,
      product,
      isTotal
    );

    //allowing chart to display "Weekly" or "Total"
    let thisYearDataToDisplay =
      isTotal === "Semanal" ? weeklyThisYear : totalThisYear;
    let lastYearDataToDisplay =
      isTotal === "Semanal" ? weeklyPreviousYear : totalPreviousYear;

    setCurrentYearData(thisYearDataToDisplay);
    setPreviousYearData(lastYearDataToDisplay);
    setForecast(weeklyForecast);

    let combinedArrays = thisYearDataToDisplay.concat(
      lastYearDataToDisplay,
      weeklyForecast
    );
    let heightLineChart = searchHighestNumber(combinedArrays);
    setHeightLineChart(heightLineChart * 1.1);

    let weeklyIncrement = constructIncrements(
      weeklyThisYear,
      weeklyPreviousYear
    );
    setWeeklyIncrement(weeklyIncrement);
    let totalIncrement = constructIncrements(totalThisYear, totalPreviousYear);
    setTotalIncrement(totalIncrement);

    //setting base and height
    let combinedArraysBarChart = weeklyIncrement.concat(totalIncrement);
    setHeightAndBaseBarChart(combinedArraysBarChart);
  };

  const setCompanyToSearch = async (e: any) => {
    setSearchCompany(e.target.value);
    let { id } = await getCompanyIdByName(e.target.value, token);
    setSearchCompanyId(id);
  };

  const constructIncrements = (
    thisYear: ILineChart[],
    lastYear: ILineChart[]
  ) => {
    let increments: IIncrement[] = [];
    for (let i = 0; i < 52; i++) {
      let thisYearIncrement = thisYear[i]?.y;
      let lastYearIncrement = lastYear[i]?.y;
      let increment =
        ((thisYearIncrement - lastYearIncrement) / lastYearIncrement) * 100;
      if (!increment || increment === Infinity) {
        increment = 0;
      }
      increments.push({
        x: weeksSort[i].week,
        y: Math.round(increment),
        y0: 0,
      });
    }
    return increments;
  };

  const setHeightAndBaseBarChart = (data: IIncrement[]) => {
    //finding highest bar chart point
    let barHeight = searchHighestNumber(data);
    setHeightBarChart(barHeight * 1.3);

    //finding lowest bar chart point
    let barBase = searchLowestNumber(data);
    setBaseBarChart(barBase * 1.1);
  };

  const totalSalesHeaders = [
    { label: "Campaña", key: "season" },
    { label: "Referencia", key: "reference" },
    { label: "Semana", key: "week" },
    { label: "Productor", key: "producer" },
    { label: "Oficina", key: "office" },
    { label: "Variedad", key: "variety" },
    { label: "Producto", key: "product" },
    { label: "Toneladas", key: "tons" },
  ];

  return (
    <div className="div-principal2">
      <div className="top-panel">
        Compras Reales {pickedOffice ? `- ${pickedOffice}` : null}
      </div>{" "}
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div className="no-margin">
        <CSVLink
          data={excelTotalSalesData}
          headers={totalSalesHeaders}
          filename={`comprasTotales.csv`}
        >
          <button className="btn btn-md text-light export-button3">
            Exportar a excel
          </button>
        </CSVLink>
      </div>
      <div className="container whiteContainer pt-1">
        <div className="container pl-4">
          <div className="row mb-3 mt-3">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <div
              className="circle ml-3 mr-1"
              style={{ backgroundColor: `darkGrey` }}
            />
            <span className="pt-1">Año Anterior</span>
            <div
              className="circle ml-3 mr-1"
              style={{ backgroundColor: `#cad401` }}
            />
            <span className="pt-1">Año Actual</span>
            <div
              className="circle ml-3 mr-1"
              style={{ backgroundColor: `darkGreen` }}
            />
            <span className="pt-1">Previsión Semanal</span>
            <select
              className="form-control form-control-sm ml-3"
              style={{ width: "7.2rem" }}
              data-spy="scroll"
              value={pickedCompany}
              onChange={(e) => filtering(e.target.value, "company")}
            >
              <option value="">Productores</option>
              {companies.map((company: string, index: number) => (
                <option key={index} value={company}>
                  {company}
                </option>
              ))}
            </select>
            {user.role !== "sales" && (
              <select
                className="form-control form-control-sm ml-2"
                style={{ width: "6.5rem" }}
                data-spy="scroll"
                value={pickedOffice}
                onChange={(e) => filtering(e.target.value, "office")}
              >
                <option value="">Oficinas</option>
                {offices.map((office: string, index: number) => (
                  <option key={index} value={office}>
                    {office}
                  </option>
                ))}
              </select>
            )}
            <select
              className="form-control form-control-sm ml-2"
              style={{ width: "9rem" }}
              data-spy="scroll"
              value={pickedProduct}
              onChange={(e) => filtering(e.target.value, "product")}
            >
              <option value="">Productos</option>
              {products.map((product: any, index: number) => (
                <option key={index} value={product}>
                  {product}
                </option>
              ))}
            </select>
            <select
              className="form-control form-control-sm mb-2 ml-2"
              style={{ width: "7rem" }}
              data-spy="scroll"
              value={weeklyOrTotal}
              onChange={(e) => filtering(e.target.value, "weeklyOrTotal")}
            >
              <option>Semanal</option>
              <option>Acumulado</option>
            </select>
            {Boolean(lastYearRawData.length) && (
              <LineChart
                data={currentYearData}
                data2={previousYearData}
                data3={forecast}
                height={[0, heightLineChart]}
                width={1050}
              />
            )}
          </div>

          <div
            style={{ marginTop: "-2%" }}
            className="row justify-content-center pb-3"
          >
            {!lastYearRawData.length ? (
              <span
                className="spinner-border spinner-border-lg mb-5"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span style={{ fontWeight: "bold" }}>Semanas</span>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="container whiteContainer pt-1">
        <div className="container pl-4">
          <div className="row mb-3 mt-3">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Porcentaje
            </span>
            <div
              className="circle ml-5 mr-1"
              style={{ backgroundColor: `#cad401` }}
            />
            <span className="pt-1">Porcentaje Semanal</span>

            <div
              className="circle ml-5 mr-1"
              style={{ backgroundColor: `darkGrey` }}
            />
            <span className="pt-1">Porcentaje Acumulado</span>
            {Boolean(weeklyIncrement.length) && (
              <BarChart
                data={weeklyIncrement}
                data2={totalIncrement}
                height={[baseBarChart, heightBarChart]}
                width={1050}
                type={"%"}
              />
            )}
          </div>
          <div
            style={{ marginTop: "-2%" }}
            className="row justify-content-center pb-3"
          >
            {!weeklyIncrement.length ? (
              <span
                className="spinner-border spinner-border-lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span style={{ fontWeight: "bold" }}>Semanas</span>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="container whiteContainer pb-4">
        <div className="select-divider-search">
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 160 }}
            value={searchWeek}
            onChange={(e) => setSearchWeek(+e.target.value)}
            className="search-select"
          >
            {!searchWeek && <option>Semana</option>}
            {weeksSort.map(({ week }: any, index: number) => (
              <option key={index} value={week}>
                {week}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 160 }}
            value={searchCompany}
            onChange={setCompanyToSearch}
            className="search-select"
          >
            {!searchCompany && <option>Proveedor</option>}
            {companies.map((company: string, index: number) => (
              <option key={index} value={company}>
                {company}
              </option>
            ))}
          </Form.Control>
          {user.role === "sourcing" && (
            <Form.Control
              as="select"
              size="sm"
              style={{ width: 170 }}
              className="search-select"
              onChange={(e) => setSearchOffice(e.target.value)}
              value={searchOffice}
            >
              {!searchOffice && <option>Oficina</option>}
              {offices.map((office: string, index: number) => (
                <option key={index} value={office}>
                  {office}
                </option>
              ))}
            </Form.Control>
          )}

          <Link
            className={
              !searchWeek ||
              !searchCompany ||
              (!searchOffice && user.role === "sourcing")
                ? "disabled-link"
                : ""
            }
            to={{
              pathname: `/compras-semanales/${searchCompanyId}`,
              state: { searchWeek, searchOffice },
            }}
          >
            {!searchWeek ||
            !searchCompany ||
            (!searchOffice && user.role === "sourcing") ? (
              <button
                disabled
                className="button-search-disabled"
                style={{ height: "30px" }}
              >
                Buscar
              </button>
            ) : (
              <button className="button-search" style={{ height: "30px" }}>
                Buscar
              </button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SourcingSales;
