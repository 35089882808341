import Axios from "axios";
import { baseURL } from "../constants";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const getCalibreIdByCode = (code: string, token: string) => {
  let filter = {
    where: {
      code: code,
    },
  };
  return Axios.get(`${baseURL}Calibres?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCalCodeFromCalId = (id: number, token: string) => {
  let filter = {
    where: {
      id: id,
    },
  };
  return Axios.get(`${baseURL}Calibres?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllCalibres = (token: string) => {
  return Axios.get(`${baseURL}Calibres`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
