import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { useLocation, RouteComponentProps } from "react-router-dom";
import { getPurchaseById } from "../utils/Requests/PurchaseRequest";
import { groupByVariety } from "../utils/Auxiliar/PurchaseAuxiliar";
import RadialChartPurchase from "../components/RadialChartPurchase";
import BarChartPurchase from "../components/BarChartPurchase";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationViewProps extends RouteComponentProps<{ id: string }> {}

const PurchaseResumeView: React.FC<PlantationViewProps> = ({ match }) => {
  //checking if location is create (id=0) or edit (id!=0)
  let { pathname } = useLocation();
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [varieties, setVarieties] = useState<any[]>([]);
  const [pickedVarCal, setPickedVarCal] = useState<number>(1);
  const [varCals, setVarCals] = useState([]);
  const [purchase, setPurchase] = useState();
  const [week, setWeek] = useState();
  const [chart, setChart] = useState([]);

  useEffect(() => {
    if (token) {
      getPurchaseById(parseInt(match.params.id), token).then(
        (purchase: any) => {
          setPurchase(purchase);
          setWeek(purchase.week);
          setVarCals(purchase.realPurchaseVarCals);

          setVarieties(groupByVariety(purchase.realPurchaseVarCals, setChart));
        }
      );
    }
  }, [match.params.id, token]);

  const handlePickedVarCal = (id: number) => {
    setPickedVarCal(id);

    //  let newRadialData = constructDataRadialChar(rawData, variety);
    // setRadialData(newRadialData);
  };

  const colorCondition = (id: number) => {
    if (id === pickedVarCal) return true;
    else {
      return false;
    }
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span className="top-panel-text">Resumen de compra</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>

      <div
        style={{ textAlign: "right", marginTop: "10px", marginRight: "20px" }}
      >
        {" "}
        <button
          className="resumen-button2"
          onClick={() =>
            history.push(`/editar-compras-reales/${match.params.id}`)
          }
        >
          <svg
            width="15"
            height="15"
            style={{ marginBottom: "2px", marginRight: "5px" }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="pencil-alt"
            className="svg-inline--fa fa-pencil-alt fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
            ></path>
          </svg>{" "}
          Editar
        </button>
      </div>

      <div
        style={{
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container pl-5">
          <div className="row mb-3 mt-3">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <BarChartPurchase data={chart} />
          </div>

          <div className="row" style={{ paddingTop: "2vh" }}></div>
        </div>
      </div>
      <div className="middle-panel" style={{ marginTop: 14 }}>
        <div
          style={{
            minHeight: "180px",
            width: "50%",
            backgroundColor: "white",
            marginLeft: "-1.5%",
            marginRight: "1%",
            marginTop: "1%",
            padding: "2%",
          }}
        >
          <table className="table text-center smallTable">
            <thead className="thead-dark">
              <tr>
                <th scope="col">SEMANA</th>
                <th scope="col">VARIEDAD</th>
                <th scope="col">VOLUMEN (Tn)</th>
              </tr>
            </thead>
            <tbody>
              {varieties.map((v: any) => (
                <tr
                  key={v.id}
                  style={{
                    backgroundColor: colorCondition(v.id) ? "#cad401" : "",
                  }}
                  onClick={() => handlePickedVarCal(v.id)}
                >
                  <td scope="row">{week}</td>
                  <td>{v.variety}</td>
                  <td>{treatNumber(v.total / 1000)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <RadialChartPurchase
          varieties={varieties}
          selectedVariety={pickedVarCal}
        />
      </div>
    </div>
  );
};

export default PurchaseResumeView;
