import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";

import "../theme/sourcing.css";
import {
  getCalibresByVarietyId,
  getVarietiesByCompanyWithId,
} from "../utils/Requests/VarietyRequests";
import { getCalibreCodes } from "../utils/Auxiliar/CalibreAuxiliar";
import produce from "immer";
import "../theme/plantation.css";

import more from "../icons/more.png";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import {
  getCompanyIdByName,
  getAllCompanies,
} from "../utils/Requests/CompanyRequest";
import { filterCompanyNames } from "../utils/Auxiliar/CompanyAux";
import { getCalibreIdByCode } from "../utils/Requests/CalibreReq";
import {
  postPurchase,
  getVarietyCalibreById,
  postPurchaseVarCal,
} from "../utils/Requests/PurchaseRequest";
import ModalPopup from "../components/ModalPopup";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import weeks from "../utils/statics/week.json";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

const CreatePurchaseView: React.FC = () => {
  const [isAddingOpen, setIsAddingOpen] = useState(false);
  const [companies, setCompanies] = useState<string[]>([]);
  const [pickedCompany, setPickedCompany] = useState("");
  const [noCountryError, setNoCountryError] = useState(false);
  const [varieties, setVarieties] = useState<any[]>([]);
  const [pickedVariety, setPickedVariety] = useState("");
  const [calibres, setCalibres] = useState<string[]>([]);
  const [pickedCalibre, setPickedCalibre] = useState("");
  const [tons, setTons] = useState<number>(0);
  const [totalTons, setTotalTons] = useState(0);
  const [addedPurchase, setAddedPurchase] = useState<any[]>([]);
  const [semanas, setSemanas] = useState<number[]>([]);
  const [week, setWeek] = useState<number | undefined>();
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const { officeId, token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      let i = 1;
      let aux: number[] = [];
      for (i = 1; i <= parseFloat(setWeekNumber(new Date())); i++) {
        aux.push(i);
      }
      setSemanas(aux);
      getAllCompanies(token)
        .then((response) => {
          let companies = filterCompanyNames(response);
          setCompanies(companies);
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [token, officeId]);

  const pickCompany = (event: any) => {
    setPickedCompany(event.target.value);
    getVarietiesByCompanyWithId(event.target.value, token).then(
      (response: any) => {
        let varAux = response.sort(function (a: any, b: any) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        setVarieties(varAux);
      }
    );
  };

  const pickVariety = (event: any) => {
    setPickedVariety(event.target.value);
    if (event.target.value !== "Selecciona Variedad") {
      let aux2 = varieties.find((v: any) => event.target.value === v.name);
      if (aux2) {
        getCalibresByVarietyId(aux2.id, token).then((response) => {
          let calibres = getCalibreCodes(response);
          setCalibres(calibres);
        });
      }
    } else {
      setCalibres([]);
      setPickedCalibre("");
      setTons(0);
    }
  };

  const pickCalibre = (event: any) => {
    setPickedCalibre(event.target.value);
    if (event.target.value === "Añadir Calibre") {
      setPickedCalibre("");
      setTons(0);
    }
  };

  const addPurchase = () => {
    if (tons) {
      if (tons.toString() === "0" || parseFloat(tons.toString()) === 0) {
        setModalText("No se pueden introducir valor 0 para las toneladas");
        setShow(true);
      } else {
        setAddedPurchase([
          ...addedPurchase,
          {
            pickedVariety,
            pickedCalibre,
            tons,
          },
        ]);
        let total =
          parseFloat(totalTons.toString()) + parseFloat(tons.toString());
        setTotalTons(total);

        setPickedCalibre("");
        setTons(0);
      }
    }
  };

  const deletePurchase = (index: number) => {
    let total =
      parseFloat(totalTons.toString()) -
      parseFloat(addedPurchase[index].tons.toString());
    setTotalTons(total);
    setAddedPurchase((state) => {
      const newAddedPurchase = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newAddedPurchase;
    });
  };

  const sendPurchase = async () => {
    //save Purchase report

    let repetido: string = "";

    addedPurchase.forEach((af: any) => {
      let aux = addedPurchase.filter(
        (a: any) =>
          a.pickedVariety === af.pickedVariety &&
          a.pickedCalibre === af.pickedCalibre
      );
      if (aux.length > 1) {
        repetido = af.pickedVariety;
      }
    });

    if (repetido === "") {
      getSeasonByName(checkSeasonShort(), token)
        .then((season: any) => {
          getCompanyIdByName(pickedCompany, token).then((company: any) => {
            let weekSort = weeks.find((w: any) => w.week === week);

            let purchase = {
              createdAt: new Date(),
              updatedAt: new Date(),
              companyId: company.id,
              seasonId: season.id,
              week: week,
              weekSort: weekSort?.sort,
              send: false,
              officeId: officeId,
            };
            postPurchase(purchase, token)
              .then((p: any) => {
                addedPurchase.forEach((purch: any) => {
                  getCalibreIdByCode(purch.pickedCalibre, token).then(
                    (c: any) => {
                      let calibreId: number = c.id;
                      let varietyId: number = varieties.find(
                        (v: any) => v.name === purch.pickedVariety
                      ).id;
                      getVarietyCalibreById(varietyId, calibreId, token)
                        .then((vc: any) => {
                          // id del varCal
                          let real = {
                            volumen: (purch.tons * 1000).toFixed(2),
                            purchaseId: p.id,
                            varietyCalibreId: vc.id,
                          };
                          postPurchaseVarCal(
                            real,
                            token
                          ).then((response: any) => {});
                        })
                        .catch((error) => {
                          setModalText(error.toString());
                          setShow(true);
                        });
                    }
                  );
                });
                setTimeout(() => openModalThanks(), 500);
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          });
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    } else {
      openModalRepetido(repetido);
    }
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Compra registrada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/compras-definitivas");
      }
    });
  };
  const openModalCreate = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres registrar esta compra?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendPurchase();
      }
    });
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setModalText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShow(true);
      setTons(0);
    } else {
      setTons(event.target.value);
    }
  };

  const openModalRepetido = (variedad: string) => {
    Swal.fire({
      icon: "warning",
      text:
        "La variedad " +
        variedad +
        " tiene calibres repetidos, por favor corríjalo.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(() => {});
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span className="top-panel-text">Añadir compra</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div className="cointainer">
        <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      </div>
      <div
        style={{
          width: "60vw",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <div className="container p-3">
          <div className="row mb-3">
            <div className="col-5">
              <select
                className="form-control form-control-sm"
                style={{ width: "15rem" }}
                data-spy="scroll"
                value={pickedCompany}
                onChange={pickCompany}
                disabled={Boolean(addedPurchase.length)}
              >
                <option>Productor</option>
                {companies.map((company: any, index: number) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <select
                className="form-control form-control-sm ml-3"
                style={{
                  width: "12rem",
                  border: noCountryError ? "solid red 2px" : "",
                }}
                data-spy="scroll"
                value={week}
                onChange={(event) => setWeek(parseInt(event.target.value))}
              >
                <option>Semana</option>
                {semanas.map((semana: number) => (
                  <option key={semana} value={semana}>
                    {semana}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="text"
                disabled
                value={
                  totalTons
                    ? treatNumber(totalTons) + " Tn"
                    : "Volumen Total Tn"
                }
              />
            </div>
          </div>
          <div className="row" style={{ paddingTop: "2vh" }}>
            <div className="col-12">
              <table className="table text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {addedPurchase.map(
                    ({ pickedVariety, pickedCalibre, tons }, index) => (
                      <tr key={index}>
                        <th scope="row">{pickedVariety}</th>
                        <td>{pickedCalibre}</td>
                        <td>{treatNumber(parseFloat(tons))}</td>
                        <td
                          className="boldFontLink"
                          onClick={() => deletePurchase(index)}
                        >
                          X
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container addVariety">
          <div
            className="row mt-2"
            onClick={() => setIsAddingOpen(!isAddingOpen)}
          >
            <img
              className="ml-2 mt-1"
              src={more}
              style={{ width: "1.2rem", height: "1.2rem" }}
            />
            <span className="ml-2">Añadir variedad</span>
          </div>
          {isAddingOpen && (
            <div className="row mt-3 pb-2">
              <select
                className="form-control form-control-sm ml-2"
                style={{ width: "12rem" }}
                data-spy="scroll"
                disabled={!varieties.length}
                value={pickedVariety}
                onChange={pickVariety}
              >
                <option key={0}> Selecciona Variedad</option>
                {varieties.map((variety: any) => (
                  <option key={variety.id} value={variety.name}>
                    {variety.name}
                  </option>
                ))}
              </select>
              {pickedVariety ? (
                <select
                  className="form-control form-control-sm ml-2"
                  style={{ width: "10rem" }}
                  value={pickedCalibre}
                  disabled={!calibres.length}
                  onChange={pickCalibre}
                >
                  <option>Añadir Calibre</option>
                  {calibres.map((calibreCode: string, index: number) => (
                    <option key={index} value={calibreCode}>
                      {calibreCode}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className="form-control form-control-sm ml-2"
                  style={{ width: "10rem" }}
                  value={pickedCalibre}
                  disabled
                  onChange={pickCalibre}
                >
                  <option>Añadir Calibre</option>
                  {calibres.map((calibreCode: string, index: number) => (
                    <option key={index} value={calibreCode}>
                      {calibreCode}
                    </option>
                  ))}
                </select>
              )}

              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="number"
                disabled={!pickedCalibre}
                value={tons === 0 ? "" : tons}
                placeholder="Toneladas"
                onChange={tonHandler}
              />
              <button
                className="btn btn-sm text-light ml-3 addButton"
                disabled={!pickedCalibre || !tons}
                onClick={addPurchase}
              >
                Añadir
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 d-flex flex-row-reverse">
          <button
            className="btn btn-md text-light mr-4 mt-3 finishButton"
            disabled={!addedPurchase.length || week === undefined}
            onClick={openModalCreate}
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePurchaseView;
