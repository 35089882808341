import React, { useState, useEffect } from "react";
import "../theme/plantationslist.css";
import "../theme/homepage.css";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  getPlantationsByCompany,
  getAllPlantations,
} from "../utils/Requests/PlantationRequests";
import { getAllPlantationsVarieties } from "../utils/Requests/PlantationVarietyRequests";
import MarkerItem from "../components/MarkerItem";
import { getIconUrl } from "../utils/Auxiliar/VarietyAuxiliar";
import { searchPlantationsByGGPlantationAndOwner } from "../utils/Auxiliar/PlantationAuxiliar";
import PlantationTableRow from "../components/PlantationTableRow";
import { filterHandlerPlantations } from "../utils/Auxiliar/SearchAuxiliar";
import Form from "react-bootstrap/Form";
import { getAllCompanies } from "../utils/Requests/CompanyRequest";
import { getAllCertifications } from "../utils/Requests/CertificationRequest";
import ModalPopup from "../components/ModalPopup";
import { checkMeteos } from "../utils/Requests/MeteoRequest";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { checkCollect } from "../utils/Auxiliar/CutOrderAuxiliar";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { normalizer } from "../utils/Auxiliar/EquivalentAuxiliar";

const PlantationsListView: React.FC = () => {
  const { role, companyId, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const center = { lat: 37.925508, lng: -4.762413 };
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [company, setCompany] = useState<string>("Cualquier productor");
  const [companies, setCompanies] = useState([]);
  const [rain, setRain] = useState("Cualquier clima");
  const [collect, setCollect] = useState("Recolección...");
  const [certifications, setCertificacions] = useState([]);
  const [certificacion, setCertificacion] = useState<string>(
    "Cualquier certificación"
  );

  const [plantations, setPlantations] = useState([]);
  const [plantationsTable, setPlantationsTable] = useState([]);
  const [pltVarieties, setPltVarieties] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [plantationsSearch, setPlantationsSearch] = useState([]);
  const [plantationsCollect, setPlantationsCollect] = useState([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [numberShow, setNumberShow] = useState<number>(5);
  const [searchText, setSearchText] = useState<string>("");

  const [rutaIcono, setRutaIcono] = useState<string>(
    "/icon/map-varieties/normal-marker.png"
  );

  useEffect(() => {
    if (token && role) {
      if (role === "producer" || role === "qualityAssistant") {
        const season = checkSeasonShort();
        getSeasonByName(season, token)
          .then((response) => {
            getPlantationsByCompany(
              companyId!,
              setProvinces,
              token,
              response.id
            )
              .then((pls: any) => {
                setPlantations(pls);
                setPlantationsSearch(pls);
                setPlantationsTable(pls.slice(0, numberShow));
                checkMeteos(pls, token);

                getAllPlantationsVarieties(pls, setPltVarieties, token)
                  .then((rVarieties: any) => {
                    let aux = rVarieties.filter(
                      (rv: any) => !rv.varietyName.includes("Hoja")
                    );

                    aux.sort(function (a: any, b: any) {
                      if (a.varietyName > b.varietyName) {
                        return 1;
                      }
                      if (a.varietyName < b.varietyName) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    });

                    aux.unshift({
                      id: -1,
                      varietyName: "Cualquier variedad",
                      productName: "none",
                    });

                    setVarieties(aux);

                    const season = checkSeasonShort();
                    getSeasonByName(season, token)
                      .then((response) => {
                        checkCollect(pls, setPlantationsCollect);
                      })
                      .catch((error) => {
                        setModalText(error.toString());
                        setShow(true);
                      });

                    getAllCertifications(token)
                      .then((ctf: any) => {
                        setCertificacions(ctf);
                      })
                      .catch((error) => {
                        setModalText(error.toString());
                        setShow(true);
                      });
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          })
          .catch((error) => {
            setModalText(error.toString());
            setShow(true);
          });
      } else if (
        role === "quality" ||
        role === "sales" ||
        role === "agribio" ||
        role === "sourcing" ||
        role === "marketing"
      ) {
        const season = checkSeasonShort();
        getSeasonByName(season, token)
          .then((response) => {
            getAllPlantations(token, response.id)
              .then((pls: any) => {
                checkMeteos(pls, token);
                getAllPlantationsVarieties(pls, setPltVarieties, token)
                  .then((rVarieties: any) => {
                    let aux = rVarieties.filter(
                      (rv: any) => !rv.varietyName.includes("Hoja")
                    );

                    aux.sort(function (a: any, b: any) {
                      if (a.varietyName > b.varietyName) {
                        return 1;
                      }
                      if (a.varietyName < b.varietyName) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    });

                    aux.unshift({
                      id: -1,
                      varietyName: "Cualquier variedad",
                      productName: "none",
                    });

                    setVarieties(aux);
                    setPlantations(pls);
                    setPlantationsSearch(pls);
                    setPlantationsTable(pls.slice(0, numberShow));

                    checkCollect(pls, setPlantationsCollect);

                    getAllCompanies(token)
                      .then((companies: any) => {
                        setCompanies(companies);
                        getAllCertifications(token)
                          .then((ctf: any) => {
                            setCertificacions(ctf);
                          })
                          .catch((error) => {
                            setModalText(error.toString());
                            setShow(true);
                          });
                      })
                      .catch((error) => {
                        setModalText(error.toString());
                        setShow(true);
                      });
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          })
          .catch((error) => {
            setModalText(error.toString());
            setShow(true);
          });
      }
    }
  }, [token, role]);

  const nextPage = () => {
    setPlantationsTable(plantationsSearch.slice(0, numberShow + 5));
    setNumberShow(numberShow + 5);
  };

  const clearSearch = () => {
    setCompany("Cualquier productor");
    setVariety("Cualquier variedad");
    setCertificacion("Cualquier certificación");
    setCollect("Recolección...");
    setRain("Cualquier clima");
    setSearchText("");
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsTable(plantations.slice(0, 5));
    setPlantationsSearch(plantations);
  };

  const recolectHandler = (event: any) => {
    setCollect(event.target.value);
    filterHandlerPlantations(
      company,
      variety,
      certificacion,
      rain,
      event.target.value,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      setPlantationsTable,
      plantationsCollect
    );
  };

  const companyHandler = (event: any) => {
    setCompany(event.target.value);
    filterHandlerPlantations(
      event.target.value,
      variety,
      certificacion,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      setPlantationsTable,
      plantationsCollect
    );
  };

  const certificationHandler = (event: any) => {
    setCertificacion(event.target.value);
    filterHandlerPlantations(
      company,
      variety,
      event.target.value,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      setPlantationsTable,
      plantationsCollect
    );
  };

  const varietyHandler = (event: any) => {
    setVariety(event.target.value);
    if (event.target.value === "Cualquier variedad") {
      setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    }
    filterHandlerPlantations(
      company,
      event.target.value,
      certificacion,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      setPlantationsTable,
      plantationsCollect
    );
  };

  const metHandler = (event: any) => {
    setRain(event.target.value);
    filterHandlerPlantations(
      company,
      variety,
      certificacion,
      event.target.value,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      setPlantationsTable,
      plantationsCollect
    );
  };

  const searchHanlder = (event: any) => {
    setCompany("Cualquier productor");
    setVariety("Cualquier variedad");
    setRain("Cualquier clima");
    setSearchText(event.target.value);

    let ax: any = searchPlantationsByGGPlantationAndOwner(
      plantations,
      normalizer(event.target.value)
    );

    setPlantationsSearch(ax);
    setPlantationsTable(ax.slice(0, 5));
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Fincas</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      {role === "quality" || role === "sales" ? (
        <>
          <div className="select-divider-search">
            <Form.Control
              as="select"
              className="search-select"
              size="sm"
              style={{ width: 170 }}
              onChange={companyHandler}
              value={company}
            >
              <option>Cualquier productor</option>
              {companies.length > 0 ? (
                companies.map((va: any) => (
                  <option key={va.name} value={va.name}>
                    {va.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
            <Form.Control
              as="select"
              size="sm"
              style={{ width: 160 }}
              value={variety}
              onChange={varietyHandler}
              className="search-select"
            >
              {varieties.length > 0 ? (
                varieties.map((va: any) => (
                  <option key={va.varietyName} value={va.varietyName}>
                    {va.varietyName}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
            <Form.Control
              as="select"
              size="sm"
              style={{ width: 180 }}
              onChange={certificationHandler}
              value={certificacion}
              className="search-select"
            >
              <option>Cualquier certificación</option>
              {certifications.length > 0 ? (
                certifications.map((va: any) => (
                  <option key={va.name} value={va.name}>
                    {va.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
            <Form.Control
              as="select"
              size="sm"
              style={{ width: 160 }}
              value={rain}
              onChange={metHandler}
              className="search-select"
            >
              <option>Cualquier clima</option>
              <option>Lluvia</option>
              <option>No lluvia</option>
              <option>Riesgo de helada</option>
            </Form.Control>
            <Form.Control
              as="select"
              size="sm"
              style={{ width: 160 }}
              value={collect}
              onChange={recolectHandler}
              className="search-select"
            >
              <option>Recolección...</option>
              <option>Recolectando</option>
              <option>No recolectando</option>
            </Form.Control>
          </div>

          <div className="select-divider-search">
            <Form.Control
              className="search-select"
              size="sm"
              type="text"
              placeholder="Buscar finca, agricultor, GGN ..."
              value={searchText}
              onChange={searchHanlder}
              style={{
                width: 300,
                height: 30,
                marginRight: 10,
                marginLeft: 8,
              }}
            />
          </div>

          <button className="button-search" onClick={() => clearSearch()}>
            Quitar filtros
          </button>
        </>
      ) : (
        <div className="select-divider-search">
          <Form.Control
            className="search-select"
            size="sm"
            type="text"
            placeholder="Buscar finca, agricultor, GGN ..."
            value={searchText}
            onChange={searchHanlder}
            style={{ width: 270, height: 31, marginRight: 1, marginLeft: 1 }}
          />
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 160 }}
            value={variety}
            onChange={varietyHandler}
            className="search-select"
          >
            {varieties.length > 0 ? (
              varieties.map((va: any) => (
                <option key={va.varietyName} value={va.varietyName}>
                  {va.varietyName}
                </option>
              ))
            ) : (
              <></>
            )}
          </Form.Control>
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 180 }}
            onChange={certificationHandler}
            value={certificacion}
            className="search-select"
          >
            <option>Cualquier certificación</option>
            {certifications.length > 0 ? (
              certifications.map((va: any) => (
                <option key={va.name} value={va.name}>
                  {va.name}
                </option>
              ))
            ) : (
              <></>
            )}
          </Form.Control>
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 160 }}
            value={rain}
            onChange={metHandler}
            className="search-select"
          >
            <option>Cualquier clima</option>
            <option>Lluvia</option>
            <option>No lluvia</option>
            <option>Riesgo de helada</option>
          </Form.Control>
          <Form.Control
            as="select"
            size="sm"
            style={{ width: 160 }}
            value={collect}
            onChange={recolectHandler}
            className="search-select"
          >
            <option>Recolección...</option>
            <option>Recolectando</option>
            <option>No recolectando</option>
          </Form.Control>
          <button className="button-search" onClick={() => clearSearch()}>
            Quitar filtros
          </button>
        </div>
      )}

      <Map
        className="leaflet-container2"
        style={{ marginTop: 10 }}
        center={center}
        zoom={6}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
        {plantationsSearch.map((plt: any) => (
          <MarkerItem key={plt.id} plt={plt} rutaIcono={rutaIcono} />
        ))}
      </Map>
      <div className="table-style">
        <table
          className="table"
          style={{ textAlign: "center", background: "white", fontSize: 12 }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">NOMBRE FINCA</th>
              <th scope="col">AGRICULTOR</th>
              <th scope="col">GG NUMBER</th>
              <th scope="col">MUNICIPIO</th>
              <th scope="col">PROVINCIA</th>
              <th scope="col">METEO (PRÓX. 7 DÍAS)</th>
            </tr>
          </thead>
          <tbody>
            {plantationsTable.map((plt: any) => (
              <PlantationTableRow key={plt.id} plt={plt} />
            ))}
          </tbody>
        </table>
        {plantationsSearch.length > numberShow ? (
          <div className="more-button-align">
            <button className="more-button" onClick={() => nextPage()}>
              Ver más
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PlantationsListView;
