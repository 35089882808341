import React, { useState } from "react";
import "react-vis/dist/style.css";
import "../theme/sourcing.css";

import {
  XYPlot,
  XAxis,
  YAxis,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  Crosshair,
} from "react-vis";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

const LineChart = ({ data, data2, data3, height, width }) => {
  let yAxisHeight = height;
  const [points, setPoints] = useState([]);
  const [points2, setPoints2] = useState([]);
  const [points3, setPoints3] = useState([]);


  const handleOnMouseLeave = () => {
    setPoints([]);
    setPoints2([]);
    setPoints3([]);
  };
  return (
    <XYPlot
      xType="ordinal"
      width={width}
      height={300}
      yDomain={yAxisHeight}
      onMouseLeave={handleOnMouseLeave}
    >
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis />
      <YAxis />
      <LineSeries
        color={"#cad401"}
        data={data}
        onNearestX={(v) => setPoints([v])}
      />
      <LineSeries
        color={"darkGrey"}
        data={data2}
        onNearestX={(v) => setPoints2([v])}
      />
      <LineSeries
        color={"darkGreen"}
        data={data3}
        onNearestX={(v) => setPoints3([v])}
      />
      {/* points2 passed because it´s data from the past year (always exists) */}
      <Crosshair values={points2}>
        <div className="tooltipLineChart">
          <p>
            <u>Semana: {points[0]?.x}</u>
          </p>
          <p>
            Año Actual: <b>{points[0] && treatNumber(points[0].y)}</b>
          </p>
          {data2 && (
            <p>
              Año Anterior: <b>{points2[0] && treatNumber(points2[0].y)}</b>
            </p>
          )}
          {data3 && (
            <p>
              Previsión: <b>{points3[0]?.y}</b>
            </p>
          )}
        </div>
      </Crosshair>
    </XYPlot>
  );
};
export default LineChart;
