import React, { useEffect } from "react";
import { RadialChart } from "react-vis";

const RadialCharts = ({ data }) => {
  useEffect(() => {}, [data]);
  return (
    <RadialChart
      animation
      data={data}
      colorType={"literal"}
      colorDomain={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
      innerRadius={37}
      radius={65}
      width={140}
      height={140}
    />
  );
};
export default RadialCharts;
