export const baseURL = "https://agribio-api.sombradoble.es/api/";
// export const baseURL = "http://localhost:3000/api/";

export const myPrivateKey = `MIIBOAIBAAJAcGJKrYHtiqH/+eA8DGNB2+49ATp4lqZ4x8ou5p
WXgJco+NiIf4qaqWpDD84X9sizRchq+/VZGlAvDZ4+BrTMHwIDAQABAkAVolZN4F0h+t2T9J6ao+Dhv
ZLw0Zi1J4bIrxsoOFPZ5P4dHtll2jA6DwKsWARnbZWdAiZMLS/unMzsQ7GvgBKBAiEAr+ajHPJ2iOmf
Zj3M7103GAWYJbyWxC02e5m0aUwl5bkCIQCjj0Rihv/RDePtooxs9BxMoPG0euAKYOkXbbtx9xKslwI
gOab2Pz6Y6zbnL7OBv9JlhFIRFA1DvPi0qr4uC3UR1sECIE+ODwAxk9qWfAgJKW6RafZnzkkv/yttKd
NIf4UWXVHvAiBeVc/mgxIcWX2eIN/N8h21g26sPm4Be2ziQOx74LH5gA==`;

/*
export const colorGama = [
  "#e6eb91",
  "#eff848",
  "#cad401",
  "#a0a71b",
  "#8f9606",
  "#585c0c",
  "#414606",
  "#252707",
  "#121304",
]; */

export const colorGama = [
  "#D57818",
  "#F08F02",
  "#F69F02",
  "#F8B705",
  "#FFFF1B",
  "#E7E600",
  "#CAD401",
  "#B4B301",
  "#7F9532",
  "#6E7F28",
];
