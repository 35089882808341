import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const getOffices = (token: string) => {
  return Axios.get(`${baseURL}Offices`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOfficeIdByName = (officeName: string, token: string) => {
  let filter = {
    fields: ["country", "id"],
    where: {
      country: officeName,
    },
  };

  return Axios.get(`${baseURL}Offices?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOfficeNameById = (officeId: string, token: string) => {
  let filter = {
    fields: ["country"],
    where: {
      id: officeId,
    },
  };
  return Axios.get(`${baseURL}Offices?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOfficeByUserId = (userId: any, token: string) => {
  let filter = {
    where: {
      ownerId: userId,
    },
  };
  return Axios.get(`${baseURL}OfficeMapping?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};
