import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";
import INotification from "../../interfaces/INotification";

export const postUserNotification = (
  notification: INotification,
  token: string
) => {
  return Axios.post(`${baseURL}Notifications`, notification, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
export const getNotificationsByUserId = (id: number, token: string) => {
  let filter = {
    where: {
      personId: id,
    },
  };

  return Axios.get(`${baseURL}Notifications?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getNotificationsByTypeAndDocId = (
  type: number,
  docId: number,
  token: string
) => {
  let filter = {
    where: {
      docId: docId,
      type: type,
    },
  };

  return Axios.get(`${baseURL}Notifications?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteNotificationById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}Notifications/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
