import Axios from "axios";

import { locateError } from "../Auxiliar/ErrorAuxiliar";

const baseURL = process.env.REACT_APP_BASE_URL_API;

export const changePassword = (password:any, token: string) => {
    
    return Axios.post(`${baseURL}people/oauth/resetPassword`, {password},{params:{t: token}},
   )
    .then(response => {
        if(response.status !== 200) return Promise.reject('ERROR');
        else return Promise.resolve(response.data);
    }).catch(err => Promise.reject('ERROR'));
}