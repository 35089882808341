import IPlantationVariety from "../../interfaces/IPlantationVariety";
import { getIconUrl } from "./VarietyAuxiliar";

export const filterHandler = (
  comp: string,
  vari: string,
  certi: string,
  rain: string,
  recol: string,
  plantations: any,
  pltVarieties: any,
  setRutaIcono: any,
  varieties: any,
  setPlantationsSearch: any,
  plantationsCollect: any
) => {
  let aux: any = plantations;
  if (comp !== "Cualquier productor") {
    // aplico filtro de productor
    aux = aux.filter((plt: any) => plt.company.name === comp);
  }

  if (vari !== "Cualquier variedad") {
    //aplico filtro de variedad
    let clean: any[] = [];
    let aux2: any;

    aux2 = pltVarieties.filter((c: any) => c.variety.name.toString() === vari);

    if (aux2.length > 0) {
      aux2.forEach((v: IPlantationVariety) => {
        let ax = aux.find((s: any) => s.id === v.plantationId);
        if (ax) {
          clean = clean.concat(ax);
        }
      });
      setRutaIcono(getIconUrl(varieties, aux2[0].variety.product.name));
      aux = clean;
    }
  }

  if (certi !== "Cualquier certificación") {
    if (certi === "Global GAP") {
      aux = aux.filter((p: any) => p.gg === 1);
    } else if (certi === "GRASP") {
      aux = aux.filter((p: any) => p.grasp === 1);
    } else if (certi === "Demeter") {
      aux = aux.filter((p: any) => p.demeter === 1);
    } else if (certi === "Biosuisse") {
      aux = aux.filter((p: any) => p.biosuisse === 1);
    }
  }

  if (recol !== "Recolección...") {
    //aplico filtro de recoleccion
    if (recol === "Recolectando") {
      let aux2: any[] = [];
      plantationsCollect.forEach((pc: any) => {
        let t = aux.find((p: any) => pc.plantation.name === p.name);
        if (t) {
          aux2.push(t);
        }
      });
      aux = aux2;
    } else if (recol === "No recolectando") {
      let aux2: any[] = [];
      aux.forEach((a: any) => {
        let t = plantationsCollect.find(
          (p: any) => p.plantation.name === a.name
        );
        if (!t) {
          aux2.push(a);
        }
      });
      aux = aux2;
    }
  }
  if (rain !== "Cualquier clima") {
    if (rain === "Riesgo de helada") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let hel = false;
        plt.meteos.forEach((m: any) => {
          if (parseFloat(m.min) <= 2.0) {
            hel = true;
          }
        });
        if (hel) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    } else if (rain === "Lluvia") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let lluvia = false;
        plt.meteos.forEach((m: any) => {
          if (
            m.group.toLowerCase().includes("rain") ||
            m.group.toLowerCase().includes("thunderstorm") ||
            m.group.toLowerCase().includes("snow")
          ) {
            lluvia = true;
          }
        });
        if (lluvia) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    } else if (rain === "No lluvia") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let cont = 0;
        plt.meteos.forEach((m: any) => {
          if (
            !m.group.toLowerCase().includes("rain") &&
            !m.group.toLowerCase().includes("snow") &&
            !m.group.toLowerCase().includes("thunderstorm")
          ) {
            cont++;
          }
        });
        if (cont === plt.meteos.length) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    }
  }

  setPlantationsSearch(aux);
};

export const filterHandlerForSourcing = (
  comp: string,
  vari: string,
  certi: string,
  rain: string,
  recol: string,
  plantations: any,
  pltVarieties: any,
  setRutaIcono: any,
  varieties: any,
  setPlantationsSearch: any,
  plantationsCollect: any,
  cities: any,
  setCitiesSearch: any
) => {
  let aux: any = plantations;
  let cits: any = cities;
  if (comp !== "Cualquier productor") {
    // aplico filtro de productor
    aux = aux.filter((plt: any) => plt.company.name === comp);
  }

  if (vari !== "Cualquier variedad") {
    //aplico filtro de variedad
    let clean: any[] = [];
    let aux2: any;

    aux2 = pltVarieties.filter((c: any) => c.variety.name.toString() === vari);

    if (aux2.length > 0) {
      aux2.forEach((v: IPlantationVariety) => {
        let ax = aux.find((s: any) => s.id === v.plantationId);
        if (ax) {
          clean = clean.concat(ax);
        }
      });
      setRutaIcono(getIconUrl(varieties, aux2[0].variety.product.name));
      aux = clean;
    }
  }

  if (certi !== "Cualquier certificación") {
    if (certi === "Global GAP") {
      aux = aux.filter((p: any) => p.gg === 1);
    } else if (certi === "GRASP") {
      aux = aux.filter((p: any) => p.grasp === 1);
    } else if (certi === "Demeter") {
      aux = aux.filter((p: any) => p.demeter === 1);
    } else if (certi === "Biosuisse") {
      aux = aux.filter((p: any) => p.biosuisse === 1);
    }
  }

  if (recol !== "Recolección...") {
    //aplico filtro de recoleccion
    if (recol === "Recolectando") {
      let aux2: any[] = [];
      plantationsCollect.forEach((pc: any) => {
        let t = aux.find((p: any) => pc.plantation.name === p.name);
        if (t) {
          aux2.push(t);
        }
      });
      aux = aux2;
    } else if (recol === "No recolectando") {
      let aux2: any[] = [];
      aux.forEach((a: any) => {
        let t = plantationsCollect.find(
          (p: any) => p.plantation.name === a.name
        );
        if (!t) {
          aux2.push(a);
        }
      });
      aux = aux2;
    }
  }

  if (rain !== "Cualquier clima") {
    if (rain === "Riesgo de helada") {
      let aux3: any = [];
      let aux4: any = [];
      aux.forEach((plt: any) => {
        let hel = false;
        plt.meteos.forEach((m: any) => {
          if (parseFloat(m.min) <= 2.0) {
            hel = true;
          }
        });
        if (hel) {
          aux3.push(plt);
        }
      });
      cities.forEach((plt: any) => {
        let hel = false;
        plt.meteos.forEach((m: any) => {
          if (parseFloat(m.min) <= 2.0) {
            hel = true;
          }
        });
        if (hel) {
          aux4.push(plt);
        }
      });
      cits = aux4;
      aux = aux3;
    } else if (rain === "Lluvia") {
      let aux4: any = [];
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let lluvia = false;
        plt.meteos.forEach((m: any) => {
          if (
            m.group.toLowerCase().includes("rain") ||
            m.group.toLowerCase().includes("thunderstorm") ||
            m.group.toLowerCase().includes("snow")
          ) {
            lluvia = true;
          }
        });
        if (lluvia) {
          aux3.push(plt);
        }
      });
      cities.forEach((plt: any) => {
        let lluvia = false;
        plt.meteos.forEach((m: any) => {
          if (
            m.group.toLowerCase().includes("rain") ||
            m.group.toLowerCase().includes("thunderstorm") ||
            m.group.toLowerCase().includes("snow")
          ) {
            lluvia = true;
          }
        });
        if (lluvia) {
          aux4.push(plt);
        }
      });
      cits = aux4;
      aux = aux3;
    } else if (rain === "No lluvia") {
      let aux3: any = [];
      let aux4: any = [];
      aux.forEach((plt: any) => {
        let cont = 0;
        plt.meteos.forEach((m: any) => {
          if (
            !m.group.toLowerCase().includes("rain") &&
            !m.group.toLowerCase().includes("snow") &&
            !m.group.toLowerCase().includes("thunderstorm")
          ) {
            cont++;
          }
        });
        if (cont === plt.meteos.length) {
          aux3.push(plt);
        }
      });
      cits.forEach((plt: any) => {
        let cont = 0;
        plt.meteos.forEach((m: any) => {
          if (
            !m.group.toLowerCase().includes("rain") &&
            !m.group.toLowerCase().includes("snow") &&
            !m.group.toLowerCase().includes("thunderstorm")
          ) {
            cont++;
          }
        });
        if (cont === plt.meteos.length) {
          aux4.push(plt);
        }
      });
      cits = aux4;
      aux = aux3;
    }
  }
  setCitiesSearch(cits);
  setPlantationsSearch(aux);
};

export const filterHandlerWarehouse = (
  vari: string,
  week: string,
  reports: any,
  setReportsTable: any,
  varieties: any,
  setReportsSearch: any
) => {
  let aux = reports;

  if (vari !== "Cualquier variedad") {
    aux = reports.filter((c: any) => c.variety.name.toString() === vari);
  }

  if (week !== "Todas las semanas") {
    let aux2 = aux.filter((c: any) => c.week.toString() === week.toString());
    aux = aux2;
  }

  setReportsTable(aux.slice(0, 10));
  setReportsSearch(aux);
};

export const filterHandlerPlantations = (
  comp: string,
  vari: string,
  certi: string,
  rain: string,
  recol: string,
  plantations: any,
  pltVarieties: any,
  setRutaIcono: any,
  varieties: any,
  setPlantationsSearch: any,
  setPlantationsTable: any,
  plantationsCollect: any
) => {
  let aux: any = plantations;
  if (comp !== "Cualquier productor") {
    // aplico filtro de productor
    aux = aux.filter((plt: any) => plt.company.name === comp);
  }

  if (vari !== "Cualquier variedad") {
    //aplico filtro de variedad
    let clean: any[] = [];
    let aux2: any;
    aux2 = pltVarieties.filter((c: any) => c.variety.name.toString() === vari);

    if (aux2.length > 0) {
      aux2.forEach((v: IPlantationVariety) => {
        let ax = aux.find((s: any) => s.id === v.plantationId);
        if (ax) {
          clean = clean.concat(ax);
        }
      });
      setRutaIcono(getIconUrl(varieties, aux2[0].variety.product.name));
      aux = clean;
    }
  }

  if (certi !== "Cualquier certificación") {
    if (certi === "Global GAP") {
      aux = aux.filter((p: any) => p.gg === 1);
    } else if (certi === "GRASP") {
      aux = aux.filter((p: any) => p.grasp === 1);
    } else if (certi === "Demeter") {
      aux = aux.filter((p: any) => p.demeter === 1);
    } else if (certi === "Biosuisse") {
      aux = aux.filter((p: any) => p.biosuisse === 1);
    }
  }

  if (recol !== "Recolección...") {
    //aplico filtro de recoleccion
    if (recol === "Recolectando") {
      let aux2: any[] = [];
      plantationsCollect.forEach((pc: any) => {
        let t = aux.find((p: any) => pc.plantation.name === p.name);
        if (t) {
          aux2.push(t);
        }
      });
      aux = aux2;
    } else if (recol === "No recolectando") {
      let aux2: any[] = [];
      aux.forEach((a: any) => {
        let t = plantationsCollect.find(
          (p: any) => p.plantation.name === a.name
        );
        if (!t) {
          aux2.push(a);
        }
      });
      aux = aux2;
    }
  }

  if (rain !== "Cualquier clima") {
    if (rain === "Riesgo de helada") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let hel = false;
        plt.meteos.forEach((m: any) => {
          if (parseFloat(m.min) <= 2.0) {
            hel = true;
          }
        });
        if (hel) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    } else if (rain === "Lluvia") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let lluvia = false;
        plt.meteos.forEach((m: any) => {
          if (
            m.group.toLowerCase().includes("rain") ||
            m.group.toLowerCase().includes("thunderstorm") ||
            m.group.toLowerCase().includes("snow")
          ) {
            lluvia = true;
          }
        });
        if (lluvia) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    } else if (rain === "No lluvia") {
      let aux3: any = [];
      aux.forEach((plt: any) => {
        let cont = 0;
        plt.meteos.forEach((m: any) => {
          if (
            !m.group.toLowerCase().includes("rain") &&
            !m.group.toLowerCase().includes("snow") &&
            !m.group.toLowerCase().includes("thunderstorm")
          ) {
            cont++;
          }
        });
        if (cont === plt.meteos.length) {
          aux3.push(plt);
        }
      });
      aux = aux3;
    }
  }

  setPlantationsSearch(aux);
  setPlantationsTable(aux.slice(0, 5));
};

export const filterHandlerNoRainNoRecolect = (
  comp: string,
  vari: string,
  certi: string,
  plantations: any,
  pltVarieties: any,
  setPlantationsSearch: any,
  setPlantationsTable: any
) => {
  let aux: any = plantations;
  if (comp !== "Cualquier productor") {
    // aplico filtro de productor
    aux = aux.filter((plt: any) => plt.pls.plantation.company.name === comp);
  }

  if (vari !== "Cualquier variedad") {
    //aplico filtro de variedad
    aux = aux.filter((c: any) => c.pls.variety.name.toString() === vari);
  }

  if (certi !== "Cualquier certificación") {
    if (certi === "Global GAP") {
      aux = aux.filter((p: any) => p.pls.plantation.gg === 1);
    } else if (certi === "GRASP") {
      aux = aux.filter((p: any) => p.pls.plantation.grasp === 1);
    } else if (certi === "Demeter") {
      aux = aux.filter((p: any) => p.pls.plantation.demeter === 1);
    } else if (certi === "Biosuisse") {
      aux = aux.filter((p: any) => p.pls.plantation.biosuisse === 1);
    }
  }

  setPlantationsTable(aux.slice(0, 10));
  setPlantationsSearch(aux);
};
