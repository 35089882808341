import React from "react";
import "../theme/qualityinfo.css";
import "leaflet/dist/leaflet.css";

interface PlantationListItemProps {
  plantation: any;
}

const TitlePlantationInfo: React.FC<PlantationListItemProps> = ({
  plantation,
}) => {
  return (
    <div className="title-plant-container">
      <div style={{ fontSize: 18, marginRight: 20, marginLeft: 20 }}>
        {plantation?.variety.name}
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 20,
          color: "#cad401",
        }}
      >
        |
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 20,
          color: "#cad401",
        }}
      >
        {plantation?.plantation.name}
      </div>
      <div
        style={{
          fontSize: 18,
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        {plantation?.plantation.location.name},{" "}
        {plantation?.plantation.location.province.name}
      </div>
    </div>
  );
};

export default TitlePlantationInfo;
