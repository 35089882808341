import ICalibre from "../../interfaces/ICalibre";

export const clearCalibres = (pt: any) => {
  let a: any[] = [];
  pt.forEach((v: any) => {
    // Solo añado las que existen, es decir, tienen provincia asociada
    if (v.calibre) {
      a.push(v.calibre);
    }
  });
  return a;
};

export const getCodeByIdCalibre = (calibres: ICalibre[], id: number) => {
  let a: string = "";
  calibres.forEach((v: ICalibre) => {
    if (v.id) {
      if (v.id.toString() === id.toString()) {
        a = v.code;
      }
    }
  });

  return a;
};

export const getCodeSemiCalibre = (calibres: any[], id: number) => {
  // id del calibre
  let a: string = "";
  calibres.forEach((v: any) => {
    if (v.id) {
      if (v.calibreId.toString() === id.toString()) {
        a = v.calibre.code;
      }
    }
  });

  return a;
};

export const getCalibreCodes = (calibres: ICalibre[]) => {
  let calibreCodes: string[] = [];
  calibres.map(({ code }) => {
    calibreCodes.push(code);
  });
  return calibreCodes;
};
