import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

/* NOTIFICATION TYPES 
1 - QUALITY REPORT
2 - CUT ORDER
3 - HARVEST REPORT
4 - FORECAST PURCHASE (PREVISIÓN DE COMPRA)
5 - AGRIBIO FORECAST (DISPONIBILIDAD AGRIBIO) */

export const getProvincesByUserId = (id: number, token: string) => {
  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades
    where: { ownerId: id },
  };

  return Axios.get(`${baseURL}UserProvinces?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllUsers = (token: string) => {
  let filter = {
    include: [
      {
        relation: "company",
        scope: {
          fields: ["id", "name"],
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["id", "country"],
        },
      },
    ],
  };
  return Axios.get(`${baseURL}People?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getUser = (id: number, token: string) => {
  return Axios.get(`${baseURL}People/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSourcingAndQualityUsers = (token: string) => {
  let filter = {
    fields: ["id"],
    where: {
      or: [{ role: "sourcing" }, { role: "quality" }],
    },
  };

  return Axios.get(`${baseURL}People?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
export const editUser = (info: any, id: number, token: any) => {
  return Axios.patch(`${baseURL}People/${id}`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });
};

export const getProvinceUserRelations = (userId: number, token: string) => {
  let filter = {
    fields: ["id", "provinceId"],
    where: { ownerId: userId },
  };
  return Axios.get(`${baseURL}UserProvinces?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteProvinceUserRelations = (relationId: number, token: any) => {
  return Axios.delete(`${baseURL}UserProvinces/${relationId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getUsersByRolAndCompany = (
  companyId: number,
  rol: string,
  rol2: string,
  token: string
) => {
  let filter = {
    fields: ["id"],
    where: {
      and: [{ companyId: companyId }, { or: [{ role: rol }, { role: rol2 }] }],
    },
  };

  return Axios.get(`${baseURL}People?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const saveProvinceUserRelation = (
  userId: number,
  provinceId: number,
  token: any
) => {
  let info = {
    ownerId: userId,
    provinceId: provinceId,
  };
  return Axios.post(`${baseURL}UserProvinces`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteUserFromDB = (userId: number, token: any) => {
  return Axios.delete(`${baseURL}People/${userId}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const saveNewUser = (info: any, token: any) => {
  return Axios.post(`${baseURL}People`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
    });
};

export const getUsersByUniqueRolAndCompany = (
  companyId: number,
  rol: string,
  token: string
) => {
  let filter = {
    fields: ["id"],
    where: {
      and: [{ companyId: companyId }, { role: rol }],
    },
  };

  return Axios.get(`${baseURL}People?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSourcing = (token: string) => {
  let filter = {
    fields: ["id"],
    where: { role: "sourcing" },
  };

  return Axios.get(`${baseURL}People?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
