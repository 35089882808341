import React, { useEffect, useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { checkVarietyByName } from "../utils/Auxiliar/VarietyAuxiliar";
import { ListItem } from "@material-ui/core";

interface PlantationListItemProps {
  variety: any;
  setVarietyId: any;
  varietyId: number | undefined;
}

const VarietyItem: React.FC<PlantationListItemProps> = ({
  variety: {
    variety: { id, name },
  },
  setVarietyId,
  varietyId,
}) => {
  const [route, setRoute] = useState("");

  useEffect(() => {
    setRoute(checkVarietyByName(name));
  }, [id]);

  const buildClass = () =>
    varietyId === id
      ? "button-variety-selected"
      : "button-variety-not-selected";

  return (
    <ListItem onClick={() => setVarietyId(id)} className="pointer ml-5">
      <img width="60" src={route} alt="variety" />
      <span className={`${buildClass()} pt-2`}>{name}</span>
    </ListItem>
  );
};

export default VarietyItem;
