import Axios from "axios";
import { baseURL } from "../constants";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { pickUniqueWeek } from "../Auxiliar/WeekAuxiliar";
import { createForecastToExport } from "../Auxiliar/PurchasesForecastAux";

export const savePurchaseForecast = (report: any, token: string) => {
  return Axios.post(`${baseURL}ForecastPurchases`, report, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editPurchaseForecast = (edit: any, id: number, token: string) => {
  return Axios.patch(`${baseURL}ForecastPurchases/${id}`, edit, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const saveToPurchaseVarietyCalibre = (report: any, token: string) => {
  return Axios.post(`${baseURL}PurchaseVarietyCalibres`, report, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const resetPurchaseVarietyCalibre = (id: number, token: string) => {
  return Axios.delete(
    `${baseURL}ForecastPurchases/${id}/purchaseVarietyCalibres`
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecast = (
  currentSeasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["country", "id"],
        },
      },
    ],
    order: "weekSort DESC",
    where: {
      seasonId: currentSeasonId,
    },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecastById = (id: number, token: string) => {
  let filter = {
    include: [
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["country", "id"],
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecastByWeek = (week: number, token: string) => {
  let filter = {
    where: {
      week: week,
      isSent: 1,
    },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecastDetailsById = (
  forecastPurchaseId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "forecastPurchase",
        scope: {
          include: [
            {
              relation: "company",
              scope: {
                fields: ["name", "id"],
              },
            },
            {
              relation: "office",
              scope: {
                fields: ["country", "id"],
              },
            },
          ],
        },
      },
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },
    ],
    where: { forecastPurchaseId: forecastPurchaseId },
  };

  return Axios.get(
    `${baseURL}PurchaseVarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllForecastPurchases = (
  currentSeasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "purchaseVarietyCalibres",
        scope: {
          include: {
            relation: "varietyCalibre",
            scope: {
              include: [
                {
                  relation: "variety",
                  scope: {
                    fields: ["name", "id", "productId"],
                    include: {
                      relation: "product",
                      scope: {
                        fields: ["name", "id"],
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      },
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["country", "id"],
        },
      },
      {
        relation: "season",
        scope: {
          fields: ["id", "name"],
        },
      },
    ],
    order: "week ASC",
    where: {
      isSent: 1,
      seasonId: currentSeasonId,
    },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteForecastPurchaseFromDB = (id: number, token: string) => {
  return Axios.delete(`${baseURL}ForecastPurchases/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deletePurchaseForecastDetailsById = (
  id: number,
  token: string
) => {
  return Axios.delete(`${baseURL}PurchaseVarietyCalibres/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecastByCompanyId = (id: number, token: string) => {
  let filter = {
    where: {
      companyId: id,
    },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchaseForecastWeeksByCompanyId = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    fields: ["week"],

    where: { and: [{ companyId: id }, { seasonId: seasonId }, { isSent: 1 }] },
    order: "week DESC",
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        let weeks: string[] = [];
        data.map((w: any) => weeks.push(w.week));

        return Promise.resolve(pickUniqueWeek(weeks));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesForecastByWeekAndCompanyId = (
  companyId: number,
  week: number,
  token: string
) => {
  let filter = {
    where: { and: [{ companyId: companyId }, { week: week }, { isSent: 1 }] },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getLastPurchasesForecastByWeek = (
  companyId: number,
  token: string,
  seasonId: number
) => {
  let filter = {
    fields: ["week"],

    where: { and: [{ companyId: companyId }, { isSent: 1 }, { seasonId: seasonId }] },
    order: "week DESC",
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      console.log(data)
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchaseForecastInfo = (
  companyId: any,
  week: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "purchaseVarietyCalibres",
        scope: {
          include: {
            relation: "varietyCalibre",
            scope: {
              include: [
                {
                  relation: "variety",
                  scope: {
                    fields: ["name", "id", "productId"],
                    include: {
                      relation: "product",
                      scope: {
                        fields: ["name", "id"],
                      },
                    },
                  },
                },
                {
                  relation: "calibre",
                  scope: {
                    fields: ["code", "id"],
                  },
                },
              ],
            },
          },
        },
      },
    ],
    where: { and: [{ companyId: companyId }, { week: week }, { isSent: 1 }] },
    order: "id DESC",
  };
  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchaseForecastInfoToExport = (
  forecastPurchaseId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "forecastPurchase",
        scope: {
          include: [
            {
              relation: "company",
              scope: {
                fields: ["name", "id"],
              },
            },
            {
              relation: "office",
              scope: {
                fields: ["country", "id"],
              },
            },
          ],
        },
      },
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },
    ],
    where: { forecastPurchaseId: forecastPurchaseId },
  };
  return Axios.get(
    `${baseURL}PurchaseVarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(createForecastToExport(data));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const checkIfRepeatedForecast = (
  companyId: string,
  officeId: string,
  token: string
) => {
  let filter = {
    where: { and: [{ companyId }, { officeId }, { isSent: 0 }] },
  };

  return Axios.get(
    `${baseURL}ForecastPurchases?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
