import React, { useState } from "react";
import "../../theme/dashboard.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function BotSide() {
  const [loading, setLoading] = useState(false);

  const logout = () => {
    setLoading(true);

    sessionStorage.removeItem("token2");
    sessionStorage.removeItem("notifications");
    window.location.href = "/";
  };

  return (
    <div className="bot-bar">
      <List disablePadding dense>
        <ListItem style={{ paddingLeft: 18 }} button onClick={logout}>
          <ListItemText style={{ textAlign: "center" }}>
            <svg
              style={{ marginRight: 12, width: 20, height: 20 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sign-out-alt"
              className="svg-inline--fa fa-sign-out-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
              ></path>
            </svg>
            {!loading ? (
              "Cerrar Sesión"
            ) : (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
