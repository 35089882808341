import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import "../theme/agribioForecast.css";

import "../theme/sourcing.css";
import {
  getAgribioForecastId,
  getAgribioForecastVarieties,
  postAgribioVisibility,
} from "../utils/Requests/AgribioForecastRequest";
import IForecast from "../interfaces/IForecast";
import {
  constructForecastData,
  extractForecastCalibres,
} from "../utils/Auxiliar/ForecastAuxiliar";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import {
  getUsersByUniqueRolAndCompany,
  getSourcing,
} from "../utils/Requests/UserRequests";
import { postUserNotification } from "../utils/Requests/NotificationRequest";
import INotification from "../interfaces/INotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
import { IAddedForecast } from "./ManagePurchasesForecast";

interface IAgribioForecast {
  variety: string;
  product: string;
  calibre: string;
  tons: number;
  week?:number;
}

const AgribioForecast: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showFin, setShowFin] = useState<boolean>(false);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const [isSent, setIsSent] = useState<boolean>(false);

  const [detail, setDetail] = useState<any[]>([]);

  const [forecast, setForecast] = useState<any[]>([]);
  const [forecastId, setForecastId] = useState<number>();
  const [editedForecast, setEditedForecast] = useState<IAgribioForecast[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [pickedVariety, setPickedVariety] = useState<string>("");
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  const [excelForecastData, setExcelForecastData] = useState<IAgribioForecast[]>(
    []
  );

  useEffect(() => {
    if (companyId && token) {
      getAgribioForecastId(companyId, token)
        .then((id) => {
          setIsSent(id.visible);
          setForecastId(id.id);
          getAgribioForecastVarieties(id.id, token)
            .then((forecast) => {
              setForecast(forecast);
              getData(forecast);
            })
            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [token, companyId]);

  const getData = (forecast: any) => {
    console.log(forecast)
    let editedForecasts: IAgribioForecast[] = [];
    forecast.map((f: any) => {
      editedForecasts.push({
        variety: f?.varietyCalibre?.variety?.name,
        product: f.varietyCalibre?.variety?.product?.name,
        calibre: f.varietyCalibre?.calibre?.code,
        tons: f?.volume / 1000,
        week: f?.forecast?.week
      });
    });

    setEditedForecast(editedForecasts);
    console.log(editedForecasts);
    let filteredData = constructForecastData(editedForecasts);
    setFilteredData(filteredData);
    setPickedVariety(editedForecasts[0].variety);
    let detail = extractForecastCalibres(forecast, editedForecasts[0].variety);
    setDetail(detail);
    createExcelData(editedForecasts)
  };

  const createExcelData = (editedForecast: IAgribioForecast[]) => {
    console.log(editedForecast)
    const excelForecastData: IAgribioForecast[] = [];
    editedForecast.map(({ variety, product, calibre, tons, week }:any) =>
      excelForecastData.push({
        week,
        variety,
        product,
        calibre,
        tons: tons.toLocaleString(),
      })
     
    );
    setExcelForecastData(excelForecastData)
  };

  const splitCode = (code: string) => {
    let num = code.match(/\d+/g);

    let letr = code.match(/[a-zA-Z]+/g);

    if (letr !== null && num !== null) {
      let newLetr = ",Sin Calibrar";
      let result = num!.toString();
      let char = result.concat(newLetr);
      return char;
    } else if (letr === null && num !== null) {
      let result = num[0].split("");
      let char = result.toString();
      return char;
    } else {
      let char = "Sin calibrar";
      return char;
    }
  };

  const colorCondition = (variety: string) => {
    if (variety === pickedVariety) return true;
    else {
      return false;
    }
  };

  const handleVariety = (variety: string) => {
    setPickedVariety(variety);
    let detail = extractForecastCalibres(forecast, variety);

    setDetail(detail);
  };

  const sendAgribioForecast = async (isSent: boolean) => {
    getSourcing(token).then((users: any) => {
      users.forEach((user: any) => {
        let not: INotification = {
          type: 5,
          docId: forecastId!,
          personId: user.id,
        };

        postUserNotification(not, token)
          .then((resp: any) => {})
          .catch((error) => {
            console.log(error);
          });
      });
    });

    setIsSent(isSent);
    postAgribioVisibility(isSent, forecastId, token).then((response) => {
      setTimeout(() => openModalThanks(), 250);
    });
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Disponibilidad enviada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: "finishButton",
      },
    });
  };

  const openModalQuestion = () => {
    Swal.fire({
      icon: "question",
      title: "¿Quieres enviar las disponibilidades a Agribio?",
      text: "¡Una vez envíes la disponibilidad no podrás cambiarla!",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendAgribioForecast(!isSent);
      }
    });
  };

   //Excel headers
   const forecastHeaders = [
    { label: "Semana", key: "week" },
    { label: "Variedad", key: "variety" },
    { label: "Variedad", key: "product" },
    { label: "Calibre", key: "calibre" },
    { label: "Toneladas", key: "tons" },
  ];

  const thisWeek = setWeekNumber(new Date());
  console.log(filteredData)

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span className="top-panel-text">Disponibilidad Agribio</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div  style={{
          width: "3%",
          marginLeft: "77%",
          marginTop: "1%",
        }}>
      
          <div style={{ marginBottom: "2%" }}>
            <CSVLink
              data={excelForecastData}
              headers={forecastHeaders}
              filename={`disponibilidad-semana/${excelForecastData[0]?.week}.csv`}
            >
              <button className="btn btn-md text-light export-button">
                Exportar a excel
              </button>
            </CSVLink>
          </div>
       
      </div>
      <div
        className="create-row"
        style={{ marginTop: "1%", marginLeft: "78.5%" }}
      >
        {isSent === false && filteredData.length ? (
          <button
            type="button"
            className="btn bt-small"
            style={{
              width: "60px !importance",
              backgroundColor: "#cad401",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() =>
              history.push(`/gestionar-disponibilidad/${forecastId}`)
            }
          >
            <FontAwesomeIcon icon={faEdit} />
            {"   "}
            Editar disponibilidad
          </button>
        ) : (
          <button
            type="button"
            className="btn bt-small"
            style={{
              width: "60px !importance",
              backgroundColor: "#cad401",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => history.push(`/gestionar-disponibilidad/0`)}
          >
            Añadir disponibilidad
          </button>
        )}
      </div>
      <div className="create-row" style={{ marginTop: "1%", marginLeft: "5%" }}>
        {isSent === false && filteredData.length ? (
          <button
            type="button"
            className="btn bt-small"
            style={{
              width: "60px !importance",
              backgroundColor: "#cad401",
              color: "white",
              borderRadius: "20px",
            }}
            onClick={() => openModalQuestion()}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            {"   "}
            Enviar disponibilidad a Agribio
          </button>
        ) : (
          <h5>
            Disponibilidad enviada para la semana {filteredData[0]?.w}
          </h5>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div
          className="cointainer mt-3 ml-5"
          style={{
            width: "45vw",
            backgroundColor: "white",
            marginLeft: "5%",
            marginTop: "2%",
            paddingBottom: "2%",
          }}
        >
          <div className="row" style={{ paddingTop: "2vh" }}>
            <u
              style={{
                fontSize: 14,
                fontWeight: 500,
                textAlign: "center",
                marginLeft: 25,
                marginBottom: 5,
              }}
            >
              ¡Atención! Sólo se puede envíar las disponibilidades una vez.
            </u>
            <div className="col-12" style={{ display: "flex" }}>
              <table className="table text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">PRODUCTO</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(({ v, c, p, t }, index) => (
                    <tr
                      key={index}
                      style={{
                        cursor: "pointer",
                        backgroundColor: colorCondition(v) ? "#cad401" : "",
                      }}
                      onClick={() => handleVariety(v)}
                    >
                      <th scope="row">{p}</th>
                      <td>{v}</td>
                      <td>{splitCode(c)}</td>
                      <td>{treatNumber(t)}</td>
                      <td
                        className="boldFontLink"
                        style={{
                          color: pickedVariety === v ? "white" : "",
                        }}
                      >
                        {">"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            minWidth: "17vw",
            minHeight: "30vh",
            marginLeft: "5%",
            marginTop: "2%",
          }}
        >
          <div className="row mt-3">
            <div
              className="col-6"
              style={{ textAlign: "right", fontWeight: "bold" }}
            >
              Tn/calibre
            </div>
            <div className="col-6">{pickedVariety}</div>
          </div>
          <div className="ro mt-3">
            {detail.map(({ calibre, volume, color }, index) => (
              <div
                key={index}
                className="d-flex"
                style={{ justifyContent: "space-evenly" }}
              >
                <div
                  className="circle"
                  style={{ backgroundColor: `${color}` }}
                />
                {calibre !== "Cualquiera" && <p> Calibre {calibre} : </p>}
                {calibre === "Cualquiera" && <p> Sin calibrar : </p>}
                <span style={{ textAlign: "left" }}>
                  {treatNumber(volume / 1000)}Tn
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgribioForecast;
