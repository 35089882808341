import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import {
  equivalentCondition,
  treatNumber,
} from "../utils/Auxiliar/EquivalentAuxiliar";
import ModalColorationPopup from "./ModalColorationPopup";

import ModalConditionPopup from "./ModalConditionPopup";

interface PlantationListItemProps {
  currentVariety: number | undefined;
  analysis: any;
}

const PlantationData: React.FC<PlantationListItemProps> = ({
  currentVariety,
  analysis,
}) => {
  const [values, setValues] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showCondition, setShowCondition] = useState<boolean>(false);
  useEffect(() => {
    if (analysis && currentVariety) {
      let ax = analysis.find((s: any) => s.varietyId === currentVariety);
      setValues(ax?.analysisShow);
    }
  }, [currentVariety, analysis]);

  return (
    <div className="data-panel">
      {currentVariety && values ? (
        <div>
          <ModalColorationPopup
            show={show}
            setShow={setShow}
            coloration={values.colouring}
            analysis={analysis}
            currentVariety={currentVariety}
          />
          <ModalConditionPopup
            show={showCondition}
            setShow={setShowCondition}
            condition={values.condition}
            analysis={analysis}
            currentVariety={currentVariety}
          />
          <div className="data-panel2">
            <div style={{ fontWeight: 500, marginRight: 10 }}>
              Recolección actual
            </div>
            {values.inicioActual ? (
              <div style={{ marginRight: 10 }}>{values?.inicioActual}</div>
            ) : (
              <div style={{ marginRight: 10 }}>Sin datos</div>
            )}

            <div
              style={{
                fontWeight: "bold",
                marginRight: 5,
                color: "#cad401",
              }}
            >
              |
            </div>

            <div style={{ fontWeight: 500, marginRight: 10 }}>
              Recolección N-1
            </div>
            {values.inicioAnterior ? (
              <div style={{ marginRight: 10 }}>{values?.inicioAnterior}</div>
            ) : (
              <div style={{ marginRight: 10 }}>Sin datos</div>
            )}
            <div style={{ marginRight: 10 }}>{values?.inicioAnterior}</div>
            <div
              style={{
                fontWeight: "bold",
                marginRight: 5,
                color: "#cad401",
              }}
            >
              |
            </div>

            <div style={{ fontWeight: 500, marginRight: 10 }}>Volumen N-1</div>
            {values?.tnAnterior ? (
              <div style={{ marginRight: 10 }}>
                {treatNumber(values?.tnAnterior / 1000)} Tn
              </div>
            ) : (
              <div style={{ marginRight: 10 }}>Sin datos</div>
            )}
          </div>
          <div className="data-panel2">
            <div style={{ marginRight: 20 }}>
              <div className="data-panel3">
                <div>
                  {values.conRoute ? (
                    <img
                      alt="condition"
                      width="20"
                      style={{ marginRight: 10, marginLeft: -3 }}
                      src={values?.conRoute}
                    ></img>
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ fontWeight: 500, marginRight: 8 }}>
                  Estado producto
                </div>
                <button
                  style={{
                    background: "white",
                    border: "none",
                  }}
                  onClick={() => setShowCondition(true)}
                >
                  <svg
                    width="15"
                    style={{
                      marginRight: 12,
                      marginBottom: 5,
                      color: "#cad401",
                    }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="info-circle"
                    className="svg-inline--fa fa-info-circle fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="data-panel3">
                <div>
                  <svg
                    width="15"
                    style={{ marginRight: 12, marginBottom: 3 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="warehouse"
                    className="svg-inline--fa fa-warehouse fa-w-20"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                    ></path>
                  </svg>
                </div>
                <div style={{ fontWeight: 500, marginRight: 10 }}>
                  Aforo inicial
                </div>
              </div>
              <div className="data-panel3">
                <div>
                  <svg
                    width="15"
                    style={{ marginRight: 12, marginBottom: 3 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="warehouse"
                    className="svg-inline--fa fa-warehouse fa-w-20"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                    ></path>
                  </svg>
                </div>
                <div style={{ fontWeight: 500, marginRight: 10 }}>
                  Aforo disponible
                </div>
              </div>
            </div>

            <div style={{ marginRight: 80 }}>
              {values?.condition ? (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  <div>{equivalentCondition(values?.condition)}</div>
                </div>
              ) : (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  <div>Sin datos</div>
                </div>
              )}

              {values?.aforo ? (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  <div>{treatNumber(values?.aforo / 1000)} Tn</div>
                </div>
              ) : (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  <div>Sin datos</div>
                </div>
              )}

              {values?.aforo ? (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  {values?.restarKilos > values?.aforo ? (
                    <div style={{ color: "red" }}>
                      {treatNumber(
                        (values?.aforo - values?.restarKilos) / 1000
                      )}{" "}
                      Tn
                    </div>
                  ) : (
                    <div>
                      {treatNumber(
                        (values?.aforo - values?.restarKilos) / 1000
                      )}{" "}
                      Tn
                    </div>
                  )}
                </div>
              ) : (
                <div className="data-panel3" style={{ marginBottom: 6 }}>
                  <div>Sin datos</div>
                </div>
              )}
            </div>

            <div>
              <div className="data-panel3">
                <div>
                  <svg
                    width="15"
                    style={{ marginRight: 12, marginBottom: 3 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="paint-brush"
                    className="svg-inline--fa fa-paint-brush fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M167.02 309.34c-40.12 2.58-76.53 17.86-97.19 72.3-2.35 6.21-8 9.98-14.59 9.98-11.11 0-45.46-27.67-55.25-34.35C0 439.62 37.93 512 128 512c75.86 0 128-43.77 128-120.19 0-3.11-.65-6.08-.97-9.13l-88.01-73.34zM457.89 0c-15.16 0-29.37 6.71-40.21 16.45C213.27 199.05 192 203.34 192 257.09c0 13.7 3.25 26.76 8.73 38.7l63.82 53.18c7.21 1.8 14.64 3.03 22.39 3.03 62.11 0 98.11-45.47 211.16-256.46 7.38-14.35 13.9-29.85 13.9-45.99C512 20.64 486 0 457.89 0z"
                    ></path>
                  </svg>
                </div>
                <div style={{ fontWeight: 500, marginRight: 10 }}>
                  Coloración
                </div>
                <button
                  style={{
                    background: "white",
                    border: "none",
                  }}
                  onClick={() => setShow(true)}
                >
                  <svg
                    width="15"
                    style={{
                      marginRight: 12,
                      marginBottom: 5,
                      color: "#cad401",
                    }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="info-circle"
                    className="svg-inline--fa fa-info-circle fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="data-panel3">
                <div>
                  <img
                    alt="brix"
                    width="20"
                    style={{ marginRight: 10, marginLeft: -3 }}
                    src="/icon/condition/brix.png"
                  ></img>
                </div>
                <div style={{ fontWeight: 500, marginRight: 10 }}>
                  Grado Brix
                </div>
              </div>
              <div className="data-panel3">
                <div>
                  <svg
                    width="15"
                    style={{ marginRight: 12, marginBottom: 4 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="seedling"
                    className="svg-inline--fa fa-seedling fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M64 96H0c0 123.7 100.3 224 224 224v144c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V320C288 196.3 187.7 96 64 96zm384-64c-84.2 0-157.4 46.5-195.7 115.2 27.7 30.2 48.2 66.9 59 107.6C424 243.1 512 147.9 512 32h-64z"
                    ></path>
                  </svg>
                </div>
                <div style={{ fontWeight: 500, marginRight: 10 }}>
                  Índice de madurez
                </div>
              </div>
            </div>

            <div style={{ marginLeft: 20 }}>
              {values?.colouring ? (
                <div className="data-panel3" style={{ marginBottom: 8 }}>
                  <div>{values?.colouring}</div>
                </div>
              ) : (
                <div className="data-panel3" style={{ marginBottom: 8 }}>
                  <div>Sin datos</div>
                </div>
              )}

              {values?.brix ? (
                <div className="data-panel3" style={{ marginBottom: 5.5 }}>
                  <div> {treatNumber(parseFloat(values?.brix))}º</div>
                </div>
              ) : (
                <div className="data-panel3" style={{ marginBottom: 5.5 }}>
                  <div>Sin datos</div>
                </div>
              )}
              {values?.madurity ? (
                <div className="data-panel3">
                  <div>{treatNumber(parseFloat(values?.madurity))}</div>
                </div>
              ) : (
                <div className="data-panel3">
                  <div>Sin datos</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="data-panel2" style={{ fontWeight: 500, fontSize: 20 }}>
          Seleccione variedad
        </div>
      )}
    </div>
  );
};

export default PlantationData;
