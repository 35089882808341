import React, { useState, useEffect } from "react";
import "../theme/qualitylist.css";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { filterHandlerWarehouse } from "../utils/Auxiliar/SearchAuxiliar";
import Form from "react-bootstrap/Form";
import ModalPopup from "../components/ModalPopup";
import { getWarehouseReportsByCompanyAndSeason } from "../utils/Requests/WarehouseRequest";
import { getVarietiesByCompanyId } from "../utils/Requests/VarietyRequests";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

const WarehouseReportListView: React.FC = () => {
  const [week, setWeek] = useState("Todas las semanas");
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [reports, setReports] = useState([]);
  const [reportsTable, setReportsTable] = useState([]);
  const [reportsSearch, setReportsSearch] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [numberShow, setNumberShow] = useState<number>(10);
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const weeks = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
  ];
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (companyId && token) {
      getSeasonByName(checkSeasonShort(), token)
        .then((season: any) => {
          getWarehouseReportsByCompanyAndSeason(
            companyId!,
            season.id,
            token
          ).then((wr: any) => {
            setReports(wr);
            setReportsTable(wr.slice(0, 10));
            setReportsSearch(wr);
            getVarietiesByCompanyId(companyId!, token)
              .then((va: any) => {
                let aux = va.filter((rv: any) => !rv.name.includes("Hoja"));

                setVarieties(
                  aux.sort(function (a: any, b: any) {
                    if (a.name > b.name) {
                      return 1;
                    }
                    if (a.name < b.name) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  })
                );
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          });
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [companyId, token]);

  const nextPage = () => {
    setReportsTable(reportsSearch.slice(0, numberShow + 5));
    setNumberShow(numberShow + 5);
  };

  const varietyHandler = (event: any) => {
    setNumberShow(10);
    setVariety(event.target.value);
    filterHandlerWarehouse(
      event.target.value,
      week,
      reports,
      setReportsTable,
      varieties,
      setReportsSearch
    );
  };

  const weekHandler = (event: any) => {
    setNumberShow(10);
    setWeek(event.target.value);
    filterHandlerWarehouse(
      variety,
      event.target.value,
      reports,
      setReportsTable,
      varieties,
      setReportsSearch
    );
  };
  const clearSearch = () => {
    setVariety("Cualquier variedad");
    setWeek("Todas las semanas");
    setReportsSearch(reports);
    setReportsTable(reports.slice(0, 10));
    setNumberShow(10);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Partes de almacén</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      <div className="select-divider-search">
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160, marginRight: 20 }}
          value={variety}
          onChange={varietyHandler}
          className="search-select"
        >
          <option>Cualquier variedad</option>
          {varieties.length > 0 ? (
            varieties.map((va: any) => (
              <option key={va.id} value={va.name}>
                {va.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>

        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160, marginRight: 20 }}
          value={week}
          onChange={weekHandler}
          className="search-select"
        >
          <option>Todas las semanas</option>
          {weeks.map((w: any) => (
            <option key={w} value={w}>
              {w}
            </option>
          ))}
        </Form.Control>

        <button className="button-search" onClick={() => clearSearch()}>
          Quitar filtros
        </button>
        <button
          style={{ position: "absolute", right: 50, fontSize: 14 }}
          disabled={variety === "Variedad"}
          className="add-report-button"
          onClick={() => history.push("/crear-parte-almacen")}
        >
          <div className="button-flex-warehouse">
            <div>
              <svg
                style={{ marginTop: 5, marginRight: 12 }}
                width="22"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="plus-circle"
                className="svg-inline--fa fa-plus-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                ></path>
              </svg>
            </div>
            <div>
              <div style={{ marginBottom: -8 }}>Crear nuevo</div>
              <div>parte de almacén</div>
            </div>
          </div>
        </button>
      </div>

      <div className="table-style">
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">FECHA CREACIÓN</th>
              <th scope="col">VARIEDAD</th>
              <th scope="col">VOLUMEN DISPONIBLE (Tn)</th>
              <th scope="col">PARTE DE ALMACEN</th>
            </tr>
          </thead>
          <tbody>
            {reportsTable.map((wr: any) => (
              <tr key={wr.id}>
                <td>
                  {new Date(wr.createdAt).toLocaleDateString()}{" "}
                  {new Date(wr.createdAt).toLocaleTimeString(
                    navigator.language,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                </td>
                <td>{wr.variety.name}</td>
                <td> {treatNumber(wr.harvestVolume / 1000)}</td>

                <td>
                  <Link to={`/parte-almacen/${wr.id}`}>
                    <b style={{ color: "#cad401" }}>Ver</b>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {reportsSearch.length > numberShow ? (
          <div className="more-button-align">
            <button className="more-button" onClick={() => nextPage()}>
              Ver más
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default WarehouseReportListView;
