export const getWeekNumber = (d: any) => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  // console.log(weekNo)
  let weekN = weekNo.toString();
  // console.log(weekN)
  return weekN;
};

// Agribio week starts on thursday
export const setWeekNumber = (d: any) => {
  // console.log(d)
  let daysToAdd = 4;
  var result = new Date(d);
  result.setDate(result.getDate() + daysToAdd);
  // console.log(result)
  return getWeekNumber(result);
};

export const pickUniqueWeek = (week: any[]) => {
  const uniqueSet: any = new Set(week);
  const uniqueWeekNo = [...uniqueSet];
  // console.log(uniqueWeekNo);
  return uniqueWeekNo;
};
