import React, { useState, useEffect } from "react";
import "../../theme/plantationslist.css";
import "../../theme/admin.css";

//middleware
import produce from "immer";
import {
  getAllCompanies,
  getCompanyNameById,
  getCompanyIdByName,
} from "../../utils/Requests/CompanyRequest";

import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { ICompany } from "../../interfaces/ICompany";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

//aux
import history from "../../utils/Auxiliar/HistoryAuxiliar";
import {
  getPlantationById,
  editPlantation,
  saveNewPlantation,
} from "../../utils/Requests/PlantationRequests";
import { sortPlantationVarieties } from "../../utils/Auxiliar/PlantationAuxiliar";
import {
  getAllVarieties,
  getVarietyIdByName,
} from "../../utils/Requests/VarietyRequests";
import {
  getLocations,
  getLocationIdByName,
} from "../../utils/Requests/LocationsReq";
import {
  getActiveVarietiesByPlantationId,
  deletePlantationVarietyRelations,
  savePlantationVarietyRelation,
} from "../../utils/Requests/PlantationVarietyRequests";
import { useSelector } from "react-redux";

const EditPlantationAdmin: React.FC = () => {
  let { pathname } = useLocation();
  let plantationId = +pathname.split("/")[2];

  //finca
  const [name, setName] = useState("");
  const [calendar, setCalendar] = useState("");
  const [area, setArea] = useState(0);
  const [staticAforo, setStaticAforo] = useState(0);
  const [owner, setOwner] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [company, setCompany] = useState("");
  const [ggNumber, setGgNumber] = useState(0);
  const [gg, setGg] = useState(false);
  const [grasp, setGrasp] = useState(false);
  const [demeter, setDemeter] = useState(false);
  const [biosuisse, setBiosuisse] = useState(false);
  const [otherCertification, setOtherCertification] = useState("");

  //default data
  const [varieties, setVarieties] = useState<string[]>([]);
  const [pickedVarieties, setPickedVarieties] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [locationValue, setLocationValue] = useState("");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const [varietyValue, setVarietyValue] = useState<string>(varieties[0]);
  const [inputVarietyValue, setInputVarietyValue] = useState("");

  const [errorText, setErrorText] = useState("");
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      loadCompanies();
      loadLocations();
      loadVarieties();
      Boolean(plantationId) && loadPlantation();
    }
  }, [token]);

  const loadPlantation = async () => {
    const {
      name,
      area,
      latitude,
      longitude,
      location,
      companyId,
      owner,
      biosuisse,
      demeter,
      gg,
      ggNumber,
      grasp,
      otros,
      plantationVarieties,
      calendar,
      staticAforo,
    } = await getPlantationById(plantationId, token);
    const company = await getCompanyNameById(`${companyId}`, token);
    let plantationVar = sortPlantationVarieties(plantationVarieties);
    setName(name!);
    setArea(+area!);
    setLatitude(`${latitude!}`);
    setLongitude(`${longitude!}`);
    setOwner(owner!);
    setBiosuisse(Boolean(biosuisse));
    setDemeter(Boolean(demeter));
    setGg(Boolean(gg));
    setGgNumber(ggNumber!);
    setGrasp(Boolean(grasp));
    setOtherCertification(otros!);
    setCalendar(calendar!);
    setCompany(company.name);
    setStaticAforo(+staticAforo!);

    location && setLocationValue(location.name);
    setPickedVarieties(plantationVar);
  };

  const loadCompanies = () => {
    getAllCompanies(token).then((companies) => {
      setCompanies(companies);
    });
  };

  const loadLocations = async () => {
    let locations = await getLocations(token);
    let sortedLocations = locations.map(({ name }: any) => name);
    setLocations(sortedLocations);
  };

  const loadVarieties = async () => {
    let varieties = await getAllVarieties(token);
    let sortedVarieties = varieties.map(({ name }: any) => name);
    setVarieties(sortedVarieties);
  };

  const onVarietyChange = (event: any, value: any) => {
    setVarietyValue(value);
    setVarieties(varieties.filter((variety) => variety !== value));

    value !== null &&
      setPickedVarieties((state: any) => {
        const addedVariety = produce(state, (drafState) => {
          drafState.push(value);
        });
        return addedVariety;
      });
  };

  const removePickedVariety = (value: string) => {
    setVarieties((state) => {
      const newVarieties = produce(state, (drafState) => {
        drafState.splice(0, 0, value);
      });
      return newVarieties;
    });
    setPickedVarieties(pickedVarieties.filter((variety) => variety !== value));
  };

  const onLocationChange = (event: any, value: any) => {
    value !== null && setLocationValue(value);
    setLocations(locations.filter((location) => location !== value));
  };

  const openCreatePlantationModal = () => {
    if (!name || !latitude || !longitude) {
      setErrorText("Por favor rellena los campos con asterisco");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    Swal.fire({
      icon: "question",
      text: "¿Quieres crear una nueva finca?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(async ({ value }) => {
      if (value) {
        let companyId = 1;
        let locationId = 0;
        if (company) {
          let { id } = await getCompanyIdByName(company, token);
          companyId = id;
        }
        if (locationValue) {
          let { id } = await getLocationIdByName(locationValue, token);
          locationId = id;
        }
        let newPlantation = {
          name,
          gg: gg ? 1 : 0,
          ggNumber,
          grasp: grasp ? 1 : 0,
          demeter: demeter ? 1 : 0,
          biosuisse: biosuisse ? 1 : 0,
          otros: otherCertification,
          calendar,
          area,
          staticAforo,
          owner,
          latitude,
          longitude,
          updatedAt: new Date(),
          createdAt: new Date(),
          companyId,
          locationId,
        };
        saveNewPlantation(newPlantation, token).then(
          ({ id: newPlantationId }) => {
            if (newPlantationId) {
              if (pickedVarieties) {
                pickedVarieties.map(async (variety) => {
                  let { id: varietyId } = await getVarietyIdByName(
                    variety,
                    token
                  );
                  savePlantationVarietyRelation(
                    newPlantationId,
                    varietyId,
                    token
                  );
                });
              }
              Swal.fire({
                icon: "success",
                text: "Finca creada correctamente",
                customClass: {
                  cancelButton: "cancelButton",
                  confirmButton: "finishButton",
                },
              });
              history.push("/");
            } else {
              Swal.fire({
                icon: "error",
                text: "Se ha producido un error, intente nuevamente más tarde",
                customClass: {
                  cancelButton: "cancelButton",
                  confirmButton: "finishButton",
                },
              });
            }
          }
        );
      }
    });
  };

  const openEditPlantationModal = () => {
    if (!name || !latitude || !longitude) {
      setErrorText("Por favor rellena los campos con asterisco");
      setTimeout(() => setErrorText(""), 3000);
      return;
    }
    Swal.fire({
      icon: "question",
      text: "¿Quieres guardar los cambios para este finca?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(async ({ value }) => {
      if (value) {
        let companyId = 0;
        let locationId = 0;
        if (company) {
          let { id } = await getCompanyIdByName(company, token);
          companyId = id;
        }
        if (locationValue) {
          let { id } = await getLocationIdByName(locationValue, token);
          locationId = id;
        }
        let plantationPatch = {
          name,
          gg: gg ? 1 : 0,
          ggNumber,
          grasp: grasp ? 1 : 0,
          demeter: demeter ? 1 : 0,
          biosuisse: biosuisse ? 1 : 0,
          otros: otherCertification,
          calendar,
          area,
          staticAforo,
          owner,
          latitude,
          longitude,
          updatedAt: new Date(),
          companyId,
          locationId,
        };
        if (pickedVarieties) {
          let plantationVarieties = await getActiveVarietiesByPlantationId(
            plantationId,
            token
          );
          plantationVarieties.map(({ id }: any) => {
            deletePlantationVarietyRelations(id, token);
          });
          pickedVarieties.map(async (variety) => {
            let { id: varietyId } = await getVarietyIdByName(variety, token);
            savePlantationVarietyRelation(plantationId, varietyId, token);
          });
        }
        editPlantation(plantationPatch, plantationId, token).then(
          (response) => {
            if (response) {
              Swal.fire({
                icon: "success",
                text: "Finca guardada correctamente",
                customClass: {
                  cancelButton: "cancelButton",
                  confirmButton: "finishButton",
                },
              });
              history.push("/");
            } else {
              Swal.fire({
                icon: "error",
                text: "Se ha producido un error, intente guardando nuevamente",
                customClass: {
                  cancelButton: "cancelButton",
                  confirmButton: "finishButton",
                },
              });
            }
          }
        );
      }
    });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">
          {!Boolean(plantationId) ? "Nueva" : "Editar"} Finca - {name}
        </div>
        <button className="semana-div3">
          Semana actual
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </button>
      </div>
      <div className="container mt-4 ml-4 mb-4">
        <div className="row pt-4" style={{ backgroundColor: "white" }}>
          <div className="col-6">
            <form>
              <div className="form-group">
                <label>*Nombre de La Finca</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>

              <div className="form-group">
                <label>Productor</label>
                <select
                  className="form-control"
                  value={company}
                  onChange={({ target: { value } }) => setCompany(value)}
                >
                  {companies.map(({ id, name }) => (
                    <option key={id}>{name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Calendario</label>
                <input
                  type="text"
                  className="form-control"
                  value={calendar}
                  onChange={({ target: { value } }) => setCalendar(value)}
                />
              </div>
              <div className="form-group">
                <label>Área (en hectáreas)</label>
                <input
                  type="number"
                  className="form-control"
                  value={area}
                  onChange={({ target: { value } }) => setArea(+value)}
                />
              </div>
              <div className="form-group">
                <label>Aforo estático (en toneladas)</label>
                <input
                  type="number"
                  className="form-control"
                  value={staticAforo}
                  onChange={({ target: { value } }) => setStaticAforo(+value)}
                />
              </div>
              <div className="form-group">
                <label>Agricultor</label>
                <input
                  type="text"
                  className="form-control"
                  value={owner}
                  onChange={({ target: { value } }) => setOwner(value)}
                />
              </div>
              <div className="form-group">
                <label>Asociar Variedades a la Finca</label>
                <Autocomplete
                  value={varietyValue ?? null}
                  onChange={onVarietyChange}
                  inputValue={inputVarietyValue}
                  onInputChange={(event, newInputValue) => {
                    setInputVarietyValue(newInputValue);
                  }}
                  id="varieties1"
                  size="small"
                  options={varieties}
                  style={{ borderColor: "green" }}
                  renderInput={(params) => (
                    <TextField
                      style={{ borderColor: "green" }}
                      {...params}
                      label="Variedades"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}
                />
                {pickedVarieties.map((variety, index) => (
                  <span
                    key={index}
                    style={{ cursor: "pointer" }}
                    className="badge badge-warning mr-1 mt-3  "
                    onClick={() => removePickedVariety(variety)}
                  >
                    {variety} X
                  </span>
                ))}
              </div>
              <span className="text-danger">{errorText}</span>
            </form>

            {Boolean(plantationId) ? (
              <button
                className="btn btn-md text-light mt-3 mb-3 finishButton"
                disabled={!name || !latitude || !longitude || !locationValue}
                onClick={openEditPlantationModal}
              >
                Guardar Edición
              </button>
            ) : (
              <button
                className="btn btn-md text-light mt-3 mb-3 finishButton"
                disabled={!name || !latitude || !longitude}
                onClick={openCreatePlantationModal}
              >
                Guardar
              </button>
            )}
          </div>
          <div className="col-6">
            <form>
              <div className="form-group">
                <label>Número de GG</label>
                <input
                  type="number"
                  className="form-control"
                  value={ggNumber}
                  onChange={({ target: { value } }) => setGgNumber(+value)}
                />
              </div>
              <div className="form-group">
                <label>*Ubicación de la Finca</label>
                <Autocomplete
                  value={locationValue ?? null}
                  onChange={onLocationChange}
                  inputValue={inputLocationValue}
                  onInputChange={(event, newInputValue) => {
                    setInputLocationValue(newInputValue);
                  }}
                  id="locations1"
                  size="small"
                  options={locations}
                  style={{ borderColor: "green" }}
                  renderInput={(params) => (
                    <TextField
                      style={{ borderColor: "green" }}
                      {...params}
                      label="Localidades"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <label>*Latitud (ej. 36.7042660362)</label>
                <input
                  type="text"
                  className="form-control"
                  value={latitude}
                  onChange={({ target: { value } }) => setLatitude(value)}
                />
              </div>
              <div className="form-group">
                <label>*Longitud (ej. -4.5548314760)</label>
                <input
                  type="text"
                  className="form-control"
                  value={longitude}
                  onChange={({ target: { value } }) => setLongitude(value)}
                />
              </div>
              <div className="form-group">
                <label>Certificaciones</label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={gg}
                    onChange={() => setGg(!gg)}
                  />
                  <label className="form-check-label">Gg</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={grasp}
                    onChange={() => setGrasp(!grasp)}
                  />
                  <label className="form-check-label">Grasp</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={demeter}
                    onChange={() => setDemeter(!demeter)}
                  />
                  <label className="form-check-label">Demeter</label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={biosuisse}
                    onChange={() => setBiosuisse(!biosuisse)}
                  />
                  <label className="form-check-label">Biosuisse</label>
                </div>
              </div>
              <div className="form-group">
                <label>Otras Certificaciones</label>
                <input
                  type="text"
                  className="form-control"
                  value={otherCertification}
                  onChange={({ target: { value } }) =>
                    setOtherCertification(value)
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlantationAdmin;
