import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import IQualityReport from "../../interfaces/IQualityReport";
import { createAnalysisToExport } from "../Auxiliar/AnalysisAuxiliar";
import { baseURL } from "../constants";

export const getPriorAnalysesBySeasonAndId = (
  id: number,
  season: string,
  token: string
) => {
  let filter = {
    where: {
      plantationVarietyId: id,
      seasonId: season,
    },
  };
  return Axios.get(`${baseURL}PriorAnalyses?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getQualityReportByPlantationVariety = (
  id: number,
  season: string,
  token: string
) => {
  let filter = {
    fields: ["record", "madurity", "plantationVarietyId"],
    where: {
      and: [{ plantationVarietyId: id }, { seasonId: season }],
    },
    order: "record DESC",
    limit: 1,
  };
  return Axios.get(
    `${baseURL}qualityReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postQualityReport = (
  qualityReport: IQualityReport,
  token: string
) => {
  return Axios.post(`${baseURL}qualityReports`, qualityReport, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteQualityReportById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}QualityReports/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getQualityReportByPlantationVarietyId = (
  id: number,
  season: string,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "plantationVariety",
        scope: {
          include: [
            {
              relation: "plantation",
              scope: {
                fields: ["name", "id", "locationId"],
                include: {
                  relation: "location",
                  scope: {
                    fields: ["provinceId", "name", "id"],
                    include: {
                      relation: "province",
                      scope: {
                        fields: ["name", "id"],
                      },
                    },
                  },
                },
              },
            },
            {
              relation: "variety",
              scope: {
                fields: ["name", "id"],
              },
            },
          ],
        },
      },
    ],

    where: {
      and: [{ plantationVarietyId: id }, { seasonId: season }],
    },
    order: "record DESC",
  };
  return Axios.get(
    `${baseURL}qualityReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(createAnalysisToExport(data));
      }
    })
    .catch((err) => Promise.reject(err));
};
