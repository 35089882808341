import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import ICutOrder from "../../interfaces/ICutOrder";
import ICutCalibre from "../../interfaces/ICutCalibre";
import IVarietyCalibre from "../../interfaces/IVarietyCalibre";
import ICutOrderReport from "../../interfaces/ICutOrderReport";
import { setWeekNumber, pickUniqueWeek } from "../Auxiliar/WeekAuxiliar";
import { baseURL } from "../constants";

export const getCutOrderByCompanyId = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    // Company finished CutOrders filter
    //   fields: ["state", "createdAt", "ownerId", "plantationVarietyId", "id"],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "plantation",
              scope: {
                fields: ["name"],
              },
            },
          ],
        },
      },
      {
        relation: "harvestReports",
        scope: {
          fields: ["id"],
        },
      },
      {
        relation: "cutCalibres",
        scope: {
          include: {
            relation: "calibre",
            scope: {
              fields: ["id", "code", "volume"],
            },
          },
        },
      },
    ],
    where: { and: [{ companyId: id }, { seasonId: seasonId }, { visible: 1 }] },
    order: "createdAt DESC",
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as ICutOrder[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getActiveCutOrders = (token: string) => {
  let filter = {
    where: { state: { lt: 4 } },
    scope: { fields: ["id", "state"] },
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as ICutOrder[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCutOrderByCompanyIdNotSent = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    // Company finished CutOrders filter
    //   fields: ["state", "createdAt", "ownerId", "plantationVarietyId", "id"],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "plantation",
              scope: {
                fields: ["name"],
              },
            },
          ],
        },
      },
      {
        relation: "harvestReports",
        scope: {
          fields: ["id"],
        },
      },
      {
        relation: "cutCalibres",
        scope: {
          include: {
            relation: "calibre",
            scope: {
              fields: ["id", "code", "volume"],
            },
          },
        },
      },
    ],
    where: { and: [{ companyId: id }, { seasonId: seasonId }, { visible: 0 }] },
    order: "createdAt DESC",
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as ICutOrder[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCutOrderWeekByCompanyId = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    fields: ["createdAt"],

    where: {
      and: [{ companyId: id }, { seasonId: seasonId }, { visible: { neq: 0 } }],
    },
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let weeks: string[] = [];
        response.data.map((w: any) => weeks.push(setWeekNumber(w.createdAt)));
        return Promise.resolve(pickUniqueWeek(weeks));
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCutOrdersByPlantationVariety = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    where: { and: [{ plantationVarietyId: id }, { seasonId: seasonId }] },
    include: {
      relation: "harvestReports",
    },
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSingleCutOrderById = (id: number, token: string) => {
  let filter = {
    // Company finished CutOrders filter
    // fields: ["state", "arrive", "ownerId", "plantationVarietyId", "id"],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "plantation",
              scope: {
                fields: ["name", "id", "companyId", "locationId"],
                include: {
                  relation: "location",
                  scope: {
                    fields: ["name", "provinceId"],
                    include: {
                      relation: "province",
                      scope: {
                        fields: ["name"],
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
      {
        relation: "cutCalibres",
        scope: {
          include: {
            relation: "calibre",
            scope: {
              fields: ["id", "code"],
            },
          },
        },
      },
    ],
    where: { id: id },
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getProvinceByCutOrderId = (id: number, token: string) => {
  let filter = {
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          include: [
            {
              relation: "plantation",
              scope: {
                fields: ["name", "id", "companyId", "locationId"],
                include: {
                  relation: "location",
                  scope: {
                    fields: ["name", "provinceId"],
                    include: {
                      relation: "province",
                      scope: {
                        fields: ["name"],
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
    ],
    where: { id: id },
  };

  return Axios.get(`${baseURL}CutOrders?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(
          response.data[0].plantationVarieties.plantation.location.province
        );
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSingleCutOrderCalibreById = (id: number, token: string) => {
  //Cut order and cut calibre
  let filter = {
    include: {
      relation: "calibre",
      scope: {
        fields: ["id", "code"],
      },
    },
  };

  return Axios.get(
    `${baseURL}CutOrders/${id}/CutCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as ICutCalibre[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietyCalibres = (id: number, token: string) => {
  let filter = {
    // Company finished CutOrders filter

    include: [
      {
        relation: "calibre",
      },
    ],
    where: { varietyId: id },
  };

  return Axios.get(
    `${baseURL}VarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as IVarietyCalibre[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postCutOrderReport = (
  coReport: ICutOrderReport,
  token: string
) => {
  return Axios.post(`${baseURL}CutOrders`, coReport, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postCutCalibre = (cutCalibre: any, token: string) => {
  //Cut order and cut calibre

  return Axios.post(`${baseURL}CutCalibres`, cutCalibre, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postVisibility = (visible: any, id: any, token: string) => {
  return Axios.patch(
    `${baseURL}CutOrders/${id}`,
    { visible },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteCutCalibre = (id: number, token: string) => {
  return Axios.delete(`${baseURL}CutOrders/${id}/cutCalibres`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 204) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteCutOrder = (id: number, token: string) => {
  return Axios.delete(`${baseURL}CutOrders/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editCutOrder = (edit: any, id: number, token: string) => {
  return Axios.patch(`${baseURL}CutOrders/${id}`, edit, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const resetCutCalibre = (id: number, token: string) => {
  return Axios.delete(`${baseURL}cutOrders/${id}/cutCalibres`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
