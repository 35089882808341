import React, { useEffect, useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import AnalysisTableRow from "./AnalysisTableRow";

interface PlantationListItemProps {
  analyses: any;
  currentVariety: number | undefined;
}

const AnalysisTable: React.FC<PlantationListItemProps> = ({
  analyses,
  currentVariety,
}) => {
  const [currents, setCurrents] = useState([]);
  useEffect(() => {
    if (analyses) {
      let ax = analyses.find((s: any) => s.varietyId === currentVariety);

      if (ax && ax.analyses) {
        setCurrents(ax?.analyses);
      }
    }
  }, [currentVariety, analyses]);
  return (
    <>
      {currentVariety ? (
        <div className="table-panel">
          <div style={{ marginBottom: 10 }}>
            <b style={{ fontSize: 20 }}>Informes previos</b>
          </div>
          {currents && currents.length > 0 ? (
            <table
              className="table"
              style={{
                textAlign: "center",
                background: "white",
              }}
            >
              <thead className="thead-dark">
                <tr style={{ fontSize: 13 }}>
                  <th scope="col">FECHA CREACIÓN</th>
                  <th scope="col">CREADOR/A</th>
                  <th scope="col">ESTADO PRODUCTO</th>
                  <th scope="col">AFORO</th>
                  <th scope="col">COLORACIÓN</th>
                  <th scope="col">ºBx</th>
                  <th scope="col">IMÁGENES</th>
                  <th scope="col">TN/CALIBRES</th>
                </tr>
              </thead>
              <tbody>
                {currents?.map((analysis: any) => (
                  <AnalysisTableRow key={analysis.id} analysis={analysis} />
                ))}
              </tbody>
            </table>
          ) : (
            <div>No existen informes previos para esta variedad</div>
          )}
          <div style={{ marginTop: 100 }}></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AnalysisTable;
