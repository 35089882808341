import Axios from "axios";
import { baseURL } from "../constants";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const login = (username: string, password: string) => {
  return Axios.post(`${baseURL}people/login`, { username, password })
    .then((response) => {
      if (response.status !== 200) return Promise.reject("ERROR");
      else return Promise.resolve(response.data);
    })
    .catch((err) => Promise.reject("ERROR"));
};

export const getUserInfo = (userId: number, token: string) => {
  let filter = {
    include: {
      relation: "company",
      scope: {
        fields: ["name", "type"],
      },
    },
  };
  return Axios.get(
    `${baseURL}People/${userId}?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
