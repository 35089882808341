import React, { useState, useEffect } from "react";
import "../../theme/homepage.css";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { getAllPlantations2 } from "../../utils/Requests/PlantationRequests";
import { getAllPlantationsVarieties } from "../../utils/Requests/PlantationVarietyRequests";
import MarkerItem from "../../components/MarkerItem";
import { getIconUrl } from "../../utils/Auxiliar/VarietyAuxiliar";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";

import { getAllCompanies } from "../../utils/Requests/CompanyRequest";
import { getAllCertifications } from "../../utils/Requests/CertificationRequest";
import { filterHandler } from "../../utils/Auxiliar/SearchAuxiliar";
import Form from "react-bootstrap/Form";
import ModalPopup from "../../components/ModalPopup";
import { checkMeteos } from "../../utils/Requests/MeteoRequest";
import { checkCollect } from "../../utils/Auxiliar/CutOrderAuxiliar";
import { checkSeasonShort } from "../../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../../utils/Requests/SeasonRequests";
import { calculatePurchaseQuantity } from "../../utils/Auxiliar/PurchaseAuxiliar";
import {
  getPurchasesBySeasonAndOffice,
  getPurchasesBySeason,
} from "../../utils/Requests/PurchaseRequest";
//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { treatNumber } from "../../utils/Auxiliar/EquivalentAuxiliar";

const HomeComercialView: React.FC = () => {
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [company, setCompany] = useState<string>("Cualquier productor");
  const [companies, setCompanies] = useState([]);
  const [certificacion, setCertificacion] = useState<string>(
    "Cualquier certificación"
  );
  const [certifications, setCertificacions] = useState([]);
  const center = { lat: 37.925508, lng: -4.762413 };
  const [plantations, setPlantations] = useState([]);
  const [pltVarieties, setPltVarieties] = useState<any[]>([]);
  const [varieties, setVarieties] = useState([]);
  const [plantationsSearch, setPlantationsSearch] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState<string>("Error");
  const [rain, setRain] = useState<string>("Cualquier clima");
  const [collect, setCollect] = useState<string>("Recolección...");
  const [ventas, setVentas] = useState([]);
  const [kgWeek, setKgWeek] = useState<number>(0);
  const [kgMonth, setKgMonth] = useState<number>(0);
  const [kgTotal, setKgTotal] = useState<number>(0);
  const [plantationsCollect, setPlantationsCollect] = useState([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [rutaIcono, setRutaIcono] = useState(
    "/icon/map-varieties/normal-marker.png"
  );

  const { officeId, role, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  useEffect(() => {
    if (token && role) {
      const season = checkSeasonShort();
      getSeasonByName(season, token)
        .then((season) => {
          getAllPlantations2(season.id, token)
            .then((pls: any) => {
              checkMeteos(pls, token);
              if (
                role === "marketing" ||
                role === "agribio" ||
                role === "quality"
              ) {
                getPurchasesBySeason(season.id, token)
                  .then((purchases: any) => {
                    calculatePurchaseQuantity(
                      purchases,
                      setKgWeek,
                      setKgMonth,
                      setKgTotal
                    );
                    setVentas(purchases);
                  })
                  .catch((error) => {
                    setAlertText(error.toString());
                    setShowAlert(true);
                  });
              } else {
                getPurchasesBySeasonAndOffice(season.id, officeId!, token)
                  .then((purchases: any) => {
                    calculatePurchaseQuantity(
                      purchases,
                      setKgWeek,
                      setKgMonth,
                      setKgTotal
                    );
                    setVentas(purchases);
                  })
                  .catch((error) => {
                    setAlertText(error.toString());
                    setShowAlert(true);
                  });
              }

              getAllPlantationsVarieties(pls, setPltVarieties, token)
                .then((rVarieties: any) => {
                  let aux = rVarieties.filter(
                    (rv: any) => !rv.varietyName.includes("Hoja")
                  );

                  aux.sort(function (a: any, b: any) {
                    if (a.varietyName > b.varietyName) {
                      return 1;
                    }
                    if (a.varietyName < b.varietyName) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });

                  aux.unshift({
                    id: -1,
                    varietyName: "Cualquier variedad",
                    productName: "none",
                  });

                  setVarieties(aux);

                  getAllCompanies(token)
                    .then((companies: any) => {
                      setCompanies(companies);
                      getAllCertifications(token)
                        .then((ctf: any) => {
                          setCertificacions(ctf);
                          getAllPlantations2(season.id, token).then(
                            (plts: any) => {
                              setPlantations(plts);
                              setPlantationsSearch(plts);
                              checkCollect(plts, setPlantationsCollect);
                            }
                          );
                        })
                        .catch((error) => {
                          setModalText(error.toString());
                          setShow(true);
                        });
                    })
                    .catch((error) => {
                      setModalText(error.toString());
                      setShow(true);
                    });
                })
                .catch((error) => {
                  setModalText(error.toString());
                  setShow(true);
                });
            })
            .catch((error) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error) => {
          setAlertText(error.toString());
          setShowAlert(true);
        });
    }
  }, [officeId, role, token]);

  function format(input: any) {
    var num = input;
    if (!isNaN(num)) {
      num = num
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      return num;
    } else {
      alert("Solo se permiten numeros");
      input.value = input.value.replace(/[^\d\.]*/g, "");
    }
  }

  const recolectHandler = (event: any) => {
    setCollect(event.target.value);
    filterHandler(
      company,
      variety,
      certificacion,
      rain,
      event.target.value,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const clearSearch = () => {
    setVariety("Cualquier variedad");
    setRain("Cualquier clima");
    setCertificacion("Cualquier certificación");
    setCompany("Cualquier productor");
    setCollect("Recolección...");
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(plantations);
  };

  const companyHandler = (event: any) => {
    setCompany(event.target.value);
    filterHandler(
      event.target.value,
      variety,
      certificacion,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const certificationHandler = (event: any) => {
    setCertificacion(event.target.value);
    filterHandler(
      company,
      variety,
      event.target.value,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const varietyHandler = (event: any) => {
    if (event.target.value === "Cualquier variedad") {
      setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    }
    setVariety(event.target.value);
    filterHandler(
      company,
      event.target.value,
      certificacion,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const metHandler = (event: any) => {
    setRain(event.target.value);
    filterHandler(
      company,
      variety,
      certificacion,
      event.target.value,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  return (
    <div className="div-principal">
      <div className="top-panel">
        <div className="top-panel-text">Inicio</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      <div className="select-divider-search">
        <Form.Control
          onChange={companyHandler}
          value={company}
          as="select"
          size="sm"
          style={{ width: 170 }}
          className="search-select"
        >
          <option>Cualquier productor</option>
          {companies.length > 0 ? (
            companies.map((va: any) => (
              <option key={va.name} value={va.name}>
                {va.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>

        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={variety}
          onChange={varietyHandler}
          className="search-select"
        >
          {varieties.length > 0 ? (
            varieties.map((va: any) => (
              <option key={va.varietyName} value={va.varietyName}>
                {va.varietyName}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 180 }}
          onChange={certificationHandler}
          value={certificacion}
          className="search-select"
        >
          <option>Cualquier certificación</option>
          {certifications.length > 0 ? (
            certifications.map((va: any) => (
              <option key={va.name} value={va.name}>
                {va.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={rain}
          onChange={metHandler}
          className="search-select"
        >
          <option>Cualquier clima</option>
          <option>Lluvia</option>
          <option>No lluvia</option>
          <option>Riesgo de helada</option>
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={collect}
          onChange={recolectHandler}
          className="search-select"
        >
          <option>Recolección...</option>
          <option>Recolectando</option>
          <option>No recolectando</option>
        </Form.Control>
        <button className="button-search" onClick={() => clearSearch()}>
          Quitar filtros
        </button>
      </div>
      <Map style={{ marginTop: 10 }} center={center} zoom={6}>
        <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
        {plantationsSearch.map((plt: any) => (
          <MarkerItem key={plt.id} plt={plt} rutaIcono={rutaIcono} />
        ))}
      </Map>
      <div className="bot-div">
        <button className="item-map">
          <div className="item-map-title">TN'S TOTALES</div>
          <div className="item-map-subtitle">SEMANA ANTERIOR</div>
          <div className="item-map-number">{treatNumber(kgWeek / 1000)}</div>
        </button>
        <button className="item-map">
          <div className="item-map-title">TN'S TOTALES</div>
          <div className="item-map-subtitle">MES ACTUAL</div>
          <div className="item-map-number">{treatNumber(kgMonth / 1000)}</div>
        </button>
        <button className="item-map">
          <div className="item-map-title">TN'S TOTALES</div>
          <div className="item-map-subtitle">CAMPAÑA ACTUAL</div>
          <div className="item-map-number">{treatNumber(kgTotal / 1000)}</div>
        </button>
      </div>
    </div>
  );
};

export default HomeComercialView;
