import { ISales } from "../../interfaces/ISales";
import { ILineChart } from "../../interfaces/ILineChart";
import { setWeekNumber } from "./WeekAuxiliar";
import { IRadialChartData } from "../../interfaces/IRadialCharData";
import { IBarChartData } from "../../interfaces/IBarCharData";
import { IExcelWeeklySalesData } from "../../pages/SourcingWeeklySales";
import { IExcelSalesData } from "../../pages/SourcingSales";

//constants
import weeks from "../../utils/statics/week.json";
import { colorGama } from "../constants";
import { IExcelProducerSalesData } from "../../pages/ProducerSales";

export const getPreviousSeason = (season: string) => {
  let from = +season.split("/")[0] - 1;
  let to = +season.split("/")[1] - 1;
  return from + "/" + to;
};

export const sortRawData = (rawData: any[]) => {
  let sortedData: ISales[] = [];
  rawData.map(
    ({
      id,
      seasonId,
      week: rawWeek,
      weekSort,
      companyId,
      officeId,
      realPurchaseVarCals,
    }) => {
      realPurchaseVarCals.map(({ volumen, varietyCalibre }: any) => {
        sortedData.push({
          id,
          seasonId,
          week: rawWeek,
          weekSort,
          companyId,
          officeId,
          volumen,
          product: varietyCalibre.variety.product.name,
          variety: varietyCalibre.variety.name,
          calibre: varietyCalibre.calibre?.code,
        });
      });
    }
  );
  return sortedData;
};

export const filterDataByYear = (data: ISales[], season: number) => {
  let sortedData = data.filter(({ seasonId }) => {
    return seasonId === season;
  });
  let finalData: ISales[] = [];
  if (sortedData[0]?.calibre) {
    finalData = addRepeatedVarieties(sortedData);
  } else {
    finalData = addRepeatedProducts(sortedData);
  }
  return finalData;
};

export const addRepeatedProducts = (sortedData: ISales[]) => {
  let data: ISales[] = [];
  sortedData.map(
    ({
      id,
      seasonId,
      week: rawWeek,
      weekSort,
      companyId,
      officeId,
      product: producto,
      volumen,
    }) => {
      //if week && company && product, etc is repeated
      if (
        data.some(
          ({
            seasonId: season,
            week,
            companyId: company,
            officeId: office,
            product,
          }) =>
            week === rawWeek &&
            company === companyId &&
            office === officeId &&
            product === producto &&
            season === seasonId
        )
      ) {
        //only add the volumen
        let index = data.findIndex(
          ({
            seasonId: season,
            week,
            companyId: company,
            officeId: office,
            product,
          }) =>
            week === rawWeek &&
            company === companyId &&
            office === officeId &&
            product === producto &&
            season === seasonId
        );
        data[index].volumen += volumen;
      } else {
        //else add everything to array
        data.push({
          id,
          seasonId,
          week: rawWeek,
          weekSort,
          companyId,
          officeId,
          product: producto,
          volumen,
        });
      }
    }
  );
  return data;
};

export const addRepeatedVarieties = (sortedData: ISales[]) => {
  let data: ISales[] = [];
  sortedData.map(
    ({
      id,
      seasonId,
      week: rawWeek,
      weekSort,
      companyId,
      officeId,
      product: producto,
      variety: variedad,
      calibre,
      volumen,
    }) => {
      //if variety is repeated POTENTIALLY ADD OFFICE HERE OR PERHAPS BELOW?
      if (
        data.some(
          ({ seasonId: season, variety, calibre: cal }) =>
            variety === variedad && calibre === cal && season === seasonId
        )
      ) {
        //only add the volumen
        let index = data.findIndex(
          ({ seasonId: season, variety, calibre: cal }) =>
            variety === variedad && calibre === cal && season === seasonId
        );
        data[index].volumen += volumen;
      } else {
        //else add everything to array
        data.push({
          id,
          seasonId,
          week: rawWeek,
          weekSort,
          companyId,
          officeId,
          product: producto,
          variety: variedad,
          calibre,
          volumen,
        });
      }
    }
  );
  return data;
};

export const filterDataByCompanyAndProduct = (
  data: ISales[],
  company: number | null,
  office: number | null,
  produce: string | null,
  weekly: string
) => {
  let sortedData = data.filter(({ companyId, product, officeId }) => {
    if (company && office && produce) {
      return (
        companyId === company && product === produce && office === officeId
      );
    } else if (!company && office && produce) {
      return product === produce && office === officeId;
    } else if (company && !office && !produce) {
      return companyId === company;
    } else if (!company && !office && produce) {
      return product === produce;
    } else if (company && office && !produce) {
      return companyId === company && office === officeId;
    } else if (company && !office && produce) {
      return companyId === company && product === produce;
    } else if (!company && office && !produce) {
      return office === officeId;
    }
  });
  if (!company && !office && !produce) {
    sortedData = data;
  }
  sortedData = addRepeatedWeeksVolumes(sortedData);
  if (sortedData.length) {
    return convertToLineChartData(sortedData, weekly);
  } else {
    return [];
  }
};

const addRepeatedWeeksVolumes = (data: ISales[]) => {
  let sortedData: ISales[] = [];
  data.map(
    ({
      id,
      seasonId,
      companyId,
      officeId,
      product,
      week: rawWeek,
      weekSort,
      volumen,
    }) => {
      if (sortedData.some(({ week }) => week === rawWeek)) {
        //if week repeated, meaning different office, only add the volumen
        let index = sortedData.findIndex(({ week }) => week === rawWeek);
        sortedData[index].volumen += volumen;
      } else {
        //else add everything to array
        sortedData.push({
          id,
          seasonId,
          week: rawWeek,
          weekSort,
          companyId,
          officeId,
          product,
          volumen,
        });
      }
    }
  );
  return sortedData;
};

export const convertToLineChartData = (data: ISales[], weekly: string) => {
  let lineChartData: ILineChart[] = [];
  let totalVolumen = 0;
  
  for (let i = 0; i < 52; i++) {
    //if week push coordenates
    if (data[i]?.weekSort === i + 1) {
      totalVolumen = data[i]?.volumen / 1000 + totalVolumen;
      //if weekly show weekly, !weekly and add numbers
      let volumen =
        weekly === "Semanal" ? data[i]?.volumen / 1000 : totalVolumen;
      lineChartData.push({ x: data[i].week, y: volumen });
    } else {
      let [{ week }] = weeks.filter(({ sort }) => sort === i + 1);
      //if not week push coordenates as 0 and restart count
      data.splice(i, 0, {
        id: 0,
        seasonId: 0,
        week: week,
        weekSort: i + 1,
        companyId: 0,
        officeId: 0,
        volumen: 0,
        product: "n/a",
      });
      i--;
    }
  }
  return lineChartData;
};

//DELETE FUNCTION BELOW IF ABOVE IS WORKING
export const convertToLineChartData2 = (data: ISales[], weekly: string) => {
  let lineChartData: ILineChart[] = [];
  let totalVolumen = 0;
  let dataFromWeekThirtySix: ISales[] = [];
  let dataUntilWeekThirtySix: ISales[] = [];
  data.map((weeklyData) => {
    if (weeklyData.week < 36) {
      //TODO - Remove this restriction with week 31 once found the wrong value
      if (weeklyData.week !== 31) {
        dataUntilWeekThirtySix.push(weeklyData);
      }
    } else {
      dataFromWeekThirtySix.push(weeklyData);
    }
    //if week push coordenates
    // if (data[i]?.week === i + 1) {
    //   totalVolumen = data[i]?.volumen / 1000 + totalVolumen;
    //if weekly show weekly, !weekly and add numbers
    //   let volumen =
    //     weekly === "Semanal" ? data[i]?.volumen / 1000 : totalVolumen;
    //   lineChartData.push({ x: i + 1, y: volumen });
    // } else {
    //if not week push coordenates as 0 and restart count
    //   data.splice(i, 0, {
    //     id: 0,
    //     seasonId: 0,
    //     week: i + 1,
    //     companyId: 0,
    //     officeId: 0,
    //     volumen: 0,
    //     product: "n/a",
    //   });
    //   i--;
    // }
  });
  let weeklyRearrangedData: ISales[] = [
    ...dataFromWeekThirtySix,
    ...dataUntilWeekThirtySix,
  ];
  weeklyRearrangedData.map(({ week, volumen }) => {
    lineChartData.push({ x: week, y: volumen / 1000 });
  });

  return lineChartData;
};

export const constructSalesDataBarChart = (data: any) => {
  let dataByVariety: IBarChartData[] = [];
  data.map(({ variety, volumen }: any) => {
    //if the variety already exists
    if (dataByVariety.filter(({ x }) => x === variety).length) {
      //only add the tons to the variety
      let index = dataByVariety.findIndex(({ x }) => x === variety);
      dataByVariety[index].y += volumen / 1000;
    } else {
      dataByVariety.push({ y: volumen / 1000, x: variety });
    }
  });
  return dataByVariety;
};

export const constructSalesDataRadialChar = (
  data: any[],
  variedad?: string
) => {
  let dataByCalibre: IRadialChartData[] = [];
  let variety = variedad ?? data[0]?.variety;
  let filteredData = data.filter(({ variety: variedad }) => {
    return variedad === variety;
  });
  //if the calibre already exists
  filteredData.map(({ calibre, volumen }, index) => {
    if (dataByCalibre.filter(({ label }) => label === calibre).length) {
      //add tons to the calibre
      let index = dataByCalibre.findIndex(({ label }) => label === calibre);
      dataByCalibre[index].angle += volumen / 1000;
    } else {
      dataByCalibre.push({
        angle: volumen / 1000,
        label: calibre,
        color: colorGama[index],
      });
    }
  });
  return dataByCalibre;
};

export const searchHighestNumber = (data: any) => {
  let highestNumber = 0;
  for (var i = 0; i < data.length; i++) {
    if (highestNumber < data[i].y) {
      highestNumber = data[i].y;
    }
  }
  return highestNumber;
};

export const searchLowestNumber = (data: any) => {
  let lowestNumber = 0;
  for (var i = 0; i < data.length; i++) {
    if (lowestNumber > data[i].y) {
      lowestNumber = data[i].y;
    }
  }
  return lowestNumber;
};

export const sortExcelWeekleyRawData = (rawData: any[]) => {
  let sortedData: IExcelWeeklySalesData[] = [];
  rawData.map(
    ({ season, week: rawWeek, company, office, realPurchaseVarCals }) => {
      realPurchaseVarCals.map(({ volumen, varietyCalibre }: any) => {
        sortedData.push({
          season: season?.name,
          week: rawWeek,
          producer: company?.name,
          office: office?.country,
          product: varietyCalibre.variety.product.name,
          variety: varietyCalibre.variety.name,
          calibre: varietyCalibre.calibre?.code,
          tons: volumen / 1000,
        });
      });
    }
  );
  return replacingWeekleyDataDotByComma(sortedData);
};

export const replacingWeekleyDataDotByComma = (
  data: IExcelWeeklySalesData[]
) => {
  let finalData: IExcelWeeklySalesData[] = [];

  data.map(
    ({ season, week, producer, office, variety, product, calibre, tons }) => {
      finalData.push({
        season,
        week,
        producer,
        office,
        variety,
        product,
        calibre,
        tons: tons.toLocaleString(),
      });
    }
  );
  return finalData;
};

export const sortSalesRawData = (rawData: any[]) => {
  let sortedData: IExcelSalesData[] = [];

  rawData.map(
    ({
      seasonId,
      season,
      week: rawWeek,
      company,
      office,
      realPurchaseVarCals,
    }) => {
      realPurchaseVarCals.map(({ volumen, varietyCalibre }: any) => {
        sortedData.push({
          seasonId,
          season: season.name,
          week: rawWeek,
          producer: company?.name,
          office: office?.country,
          product: varietyCalibre.variety.product.name,
          variety: varietyCalibre.variety.name,
          tons: volumen / 1000,
        });
      });
    }
  );
  return sortedData;
};

export const addingSeasonReference = (
  data: IExcelSalesData[],
  currentSeasonId: number,
  lastSeasonId: number
) => {
  for (var i = 0; i < data.length; i++) {
    if (data[i].seasonId === currentSeasonId) {
      data[i].reference = "Año Actual";
    } else if (data[i].seasonId === lastSeasonId) {
      data[i].reference = "Año Anterior";
    }
  }
  return filteringSeason(data, currentSeasonId, lastSeasonId);
};

export const filteringSeason = (
  data: IExcelSalesData[],
  currentSeasonId: number,
  lastSeasonId: number
) => {
  let sortedData: IExcelSalesData[] = [];

  data.map(
    ({
      seasonId,
      season,
      reference,
      week,
      producer,
      office,
      product,
      variety,
      tons,
    }: any) => {
      if (seasonId === currentSeasonId || seasonId === lastSeasonId) {
        sortedData.push({
          seasonId,
          season,
          reference,
          week,
          producer,
          office,
          product,
          variety,
          tons: tons.toLocaleString(),
        });
      }
    }
  );
  return sortedData;
};


// export const convertToLineChartData = (data: ISales[], weekly: string) => {
//   let lineChartData: ILineChart[] = [];
//   let totalVolumen = 0;
//   for (let i = 0; i < +setWeekNumber(new Date()) - 1; i++) {
//     //if week push coordenates
//     if (data[i]?.week === i + 1) {
//       totalVolumen = data[i]?.volumen / 1000 + totalVolumen;
//       //if weekly show weekly, !weekly and add numbers
//       let volumen =
//         weekly === "Semanal" ? data[i]?.volumen / 1000 : totalVolumen;
//       lineChartData.push({ x: i + 1, y: volumen });
//     } else {
//       //if not week push coordenates as 0 and restart count
//       data.splice(i, 0, {
//         id: 0,
//         seasonId: 0,
//         week: i + 1,
//         companyId: 0,
//         officeId: 0,
//         volumen: 0,
//         product: "n/a",
//       });
//       i--;
//     }
//   }
//   return lineChartData;
// };

// let sortedData = data.filter(({ companyId, product, officeId }) => {
//   if (company && office) {
//     return (
//       companyId === company && product === produce && office === officeId
//     );
//   } else if (!company && office) {
//     return product === produce && office === officeId;
//   } else if (!company && !office) {
//     return product === produce;
//   } else if (company && !office) {
//     return companyId === company && product === produce;
//   }
// });
