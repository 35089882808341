import { baseURL } from "../constants";
import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const postPurchase = (purchase: any, token: string) => {
  return Axios.post(`${baseURL}Purchases`, purchase, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postPurchaseVarCal = (real: any, token: string) => {
  return Axios.post(`${baseURL}RealPurchaseVarCals`, real, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietyCalibreById = (
  varietyId: number,
  calibreId: number,
  token: string
) => {
  let filter = {
    where: {
      varietyId: varietyId,
      calibreId: calibreId,
    },
  };

  return Axios.get(
    `${baseURL}VarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        // console.log(response);
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getNotSentPurchasesByOffice = (
  officeId: number,
  token: string
) => {
  let filter = {
    order: "week DESC",
    where: {
      send: false,
      officeId: officeId,
    },

    include: [
      {
        relation: "realPurchaseVarCals",
      },
      {
        relation: "company",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteVarCalById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}RealPurchaseVarCals/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deletePurchaseById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}Purchases/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesByOffice = (
  seasonId: number,
  officeId: number | null,
  token: string
) => {
  let filter = {
    order: "weekSort DESC",
    where: {
      seasonId: seasonId,
      officeId: officeId,
    },

    include: [
      {
        relation: "realPurchaseVarCals",
      },
      {
        relation: "company",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSentPurchasesByWeek = (
  week: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    order: "week DESC",
    where: {
      send: true,
      week: week,
      seasonId: seasonId,
    },

    include: [
      {
        relation: "realPurchaseVarCals",
      },
      {
        relation: "company",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchaseById = (id: number, token: string) => {
  let filter = {
    where: {
      id: id,
    },
    include: [
      {
        relation: "realPurchaseVarCals",
        scope: {
          include: {
            relation: "varietyCalibre",
            scope: {
              include: [
                { relation: "calibre", scope: { fields: ["code", "id"] } },
                { relation: "variety", scope: { fields: ["id", "name"] } },
              ],
            },
          },
        },
      },
      {
        relation: "company",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postPurchaseToSent = (send: any, id: any, token: string) => {
  return Axios.patch(
    `${baseURL}Purchases/${id}`,
    { send },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesBySeasonAndOffice = (
  seasonId: number,
  officeId: number,
  token: string
) => {
  let filter = {
    where: {
      seasonId: seasonId,
      officeId: officeId,
    },
    include: [
      {
        relation: "realPurchaseVarCals",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPurchasesBySeason = (seasonId: number, token: string) => {
  let filter = {
    where: {
      seasonId: seasonId,
    },
    include: [
      {
        relation: "realPurchaseVarCals",
      },
    ],
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
