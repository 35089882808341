import { IAddedForecast } from "../../pages/ManagePurchasesForecast";
import {
  getVarCalIds,
  getVarietyNameFromVarietyId,
} from "../Requests/VarietyRequests";
import { getCalCodeFromCalId } from "../Requests/CalibreReq";
import { IBarChartData } from "../../interfaces/IBarCharData";
import { IRadialChartData } from "../../interfaces/IRadialCharData";
import { colorGama } from "../constants";
import { ISales } from "../../interfaces/ISales";
import { IExcelSalesData } from "../../pages/SourcingSales";

export const constructForecasts = (forecasts: [], token: string) => {
  let editedForecasts: IAddedForecast[] = [];
  Promise.all(
    forecasts.map(async ({ varietyCalibreId, volumen }) => {
      let { calibreId, varietyId } = await getVarCalIds(
        varietyCalibreId,
        token
      );
      let { name: pickedVariety } = await getVarietyNameFromVarietyId(
        varietyId,
        token
      );
      let { code: pickedCalibre } = await getCalCodeFromCalId(calibreId, token);
      editedForecasts.push({
        pickedVariety,
        pickedCalibre,
        tons: volumen / 1000,
      });
    })
  ).then(() => {
    return Promise.resolve(editedForecasts);
  });
};

export const constructDataBarChar = (data: any) => {
  let dataByVariety: IBarChartData[] = [];
  data.map(({ pickedVariety, tons }: any) => {
    //if the variety already exists
    if (dataByVariety.filter(({ x }) => x === pickedVariety).length) {
      //only add the tons to the variety
      let index = dataByVariety.findIndex(({ x }) => x === pickedVariety);
      dataByVariety[index].y += tons;
    } else {
      dataByVariety.push({ y: tons, x: pickedVariety });
    }
  });
  return dataByVariety;
};

export const constructDataRadialChar = (data: any[], variedad?: string) => {
  let dataByCalibre: IRadialChartData[] = [];
  let variety = variedad ?? data[0]?.pickedVariety;
  let filteredData = data.filter(({ pickedVariety }) => {
    return pickedVariety === variety;
  });
  //if the calibre already exists
  filteredData.map(({ pickedCalibre, tons }, index) => {
    if (dataByCalibre.filter(({ label }) => label === pickedCalibre).length) {
      //add tons to the calibre
      let index = dataByCalibre.findIndex(
        ({ label }) => label === pickedCalibre
      );
      dataByCalibre[index].angle += tons;
    } else {
      dataByCalibre.push({
        angle: tons,
        label: pickedCalibre,
        color: colorGama[index],
      });
    }
  });
  return dataByCalibre;
};

export const sortRawPurchaseForecast = (rawData: any[]) => {
  let sortedData: ISales[] = [];
  rawData.map(
    ({ id, seasonId, week, weekSort, companyId, officeId, purchaseVarietyCalibres }) => {
      purchaseVarietyCalibres.map(({ volumen, varietyCalibre }: any) => {
        sortedData.push({
          id,
          seasonId,
          week,
          weekSort,
          companyId,
          officeId: officeId,
          volumen: volumen,
          product: varietyCalibre.variety.product.name,
        });
      });
    }
  );
  let finalData: ISales[] = [];
  //filtering out repeated weeks / products
  sortedData.map(
    ({
      id,
      companyId,
      officeId,
      seasonId,
      week: rawWeek,
      weekSort,
      product,
      volumen,
    }) => {
      if (
        finalData.some(
          ({
            week,
            companyId: company,
            officeId: office,
            seasonId: season,
            product: producto,
          }) =>
            week === rawWeek &&
            product === producto &&
            company === companyId &&
            office === officeId &&
            season === seasonId
        )
      ) {
        //only add the volumen
        let index = finalData.findIndex(
          ({
            week,
            companyId: company,
            officeId: office,
            seasonId: season,
            product: producto,
          }) =>
            week === rawWeek &&
            product === producto &&
            company === companyId &&
            office === officeId &&
            season === seasonId
        );
        finalData[index].volumen += volumen;
      } else {
        //else add everything to array
        finalData.push({
          id,
          seasonId,
          week: rawWeek,
          weekSort,
          companyId,
          officeId,
          product,
          volumen,
        });
      }
    }
  );
  return finalData;
};

// const data = [
//   { angle: 1, label: "calibre1" },
//   { angle: 5, label: "calibre2" },
//   { angle: 2, label: "calibre2" },
// ];

export const createForecastToExport = (rawData: any[]) => {
  let newForecastToExport: any[] = [];
  rawData.forEach((v: any) => {
    newForecastToExport.push({
      week: v.forecastPurchase.week,
      producer: v.forecastPurchase.company.name,
      office: v.forecastPurchase.office.country,
      variety: v.varietyCalibre.variety.name,
      calibre: v.varietyCalibre.calibre.code,
      tons: v.volumen / 1000,
    });
  });
  return newForecastToExport;
};

export const sortRawDataToExcel = (rawData: any[]) => {
  let sortedData: IExcelSalesData[] = [];
  rawData.map(
    ({ id, season, week, company, office, purchaseVarietyCalibres }) => {
      purchaseVarietyCalibres.map(({ volumen, varietyCalibre }: any) => {
        sortedData.push({
          id,
          season: season.name,
          week,
          producer: company.name,
          office: office.country,
          variety: varietyCalibre.variety.name,
          product: varietyCalibre.variety.product.name,
          tons: volumen / 1000,
        });
      });
    }
  );
  let finalData: IExcelSalesData[] = [];
  //filtering out repeated weeks / products
  sortedData.map(
    ({
      id,
      season,
      week: rawWeek,
      producer,
      office,
      variety,
      product,
      tons,
    }) => {
      if (
        finalData.some(
          ({
            week,
            producer: company,
            office: office,
            season: season,
            product: producto,
          }) =>
            week === rawWeek &&
            product === producto &&
            company === producer &&
            office === office &&
            season === season
        )
      ) {
        //only add the volumen
        let index = finalData.findIndex(
          ({
            week,
            producer: company,
            office: office,
            season: season,
            product: producto,
          }) =>
            week === rawWeek &&
            product === producto &&
            company === producer &&
            office === office &&
            season === season
        );
        finalData[index].tons += tons;
      } else {
        //else add everything to array
        finalData.push({
          id,
          season,
          week: rawWeek,
          producer,
          office,
          variety,
          product,
          tons,
        });
      }
    }
  );
  return addingReference(finalData);
};

export const addingReference = (data: IExcelSalesData[]) => {
  for (var i = 0; i < data.length; i++) {
    data[i].reference = "Previsión semanal";
  }
  return replacingDotByComma(data);
};

export const replacingDotByComma = (data: IExcelSalesData[]) => {
  let finalData: IExcelSalesData[] = [];

  data.map(
    ({
      id,
      season,
      reference,
      week: rawWeek,
      producer,
      office,
      variety,
      product,
      tons,
    }) => {
      finalData.push({
        id,
        season,
        reference: reference!,
        week: rawWeek,
        producer,
        office,
        variety,
        product,
        tons: tons.toLocaleString(),
      });
    }
  );
  return finalData;
};
