import { IFullUser } from "../../interfaces/IFullUser";
import { getCompanyNameById } from "../Requests/CompanyRequest";
import { getOfficeNameById } from "../Requests/OfficeReqs";

export const sortUsers = async (users: [], token: string) => {
  let sortedUsers: IFullUser[] = [];
  Promise.all(
    users.map(async (user: IFullUser) => {
      const companyName = await getCompanyNameById(`${user.companyId}`, token);
      const officeName = await getOfficeNameById(`${user.officeId}`, token);
      user.officeId = officeName?.country;
      user.companyId = companyName?.name;
      sortedUsers.push(user);
    })
  ).then(() => {
    return sortedUsers;
  });
};

export const validateEmail = (email: string) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const buildUserRole = (role: string) => {
  switch (role) {
    case "sourcing":
      return "Sourcing";
    case "sales":
      return "Comercial";
    case "producer":
      return "Productor";
    case "fieldManager":
      return "Jefe de Campo";
    case "quality":
      return "Calidad";
    case "qualityAssistant":
      return "Asistente de Calidad";
    case "admin":
      return "Administrador";
    case "agribio":
      return "Agribio";
    case "marketing":
      return "Marketing";
    default:
      return "";
  }
};
