import React, { useState, useEffect } from "react";
import "../../theme/plantationslist.css";
import "../../theme/admin.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer";

import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalPopup from "../../components/ModalPopup";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import Swal from "sweetalert2";
import IVariety from "../../interfaces/IVariety";
import {
  getProduct,
  getProductVarieties,
  getProductCategories,
  editProduct,
  postNewCategory,
} from "../../utils/Requests/ProductsReq";
import { getCategoryName } from "../../utils/Requests/CategoryReqs";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

interface IDetailedProduct {
  id: number;
  name: string;
  varieties?: IVariety[];
}
export interface IProduct {
  categoryId: number;
  categoryName?: string;
  product: IDetailedProduct[];
}

interface ICategory {
  id: number;
  name: string;
}

const EditProductAdmin: React.FC = () => {
  let { pathname } = useLocation();
  let productId = +pathname.split("/")[2];

  //form to edit
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [pickedCategory, setPickedCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [scientificName, setScientificName] = useState("");
  const [productDescription, setProductDescription] = useState("");

  //varieties to load
  const [varieties, setVarieties] = useState<IVariety[]>([]);

  //variety to add
  const [newVarietyName, setNewVarietyName] = useState("");
  const [newVarietyOrigin, setNewVarietyOrigin] = useState("");
  const [newVarietyDescription, setNewVarietyDescription] = useState("");
  const [newVarColouringLevel, setNewVarColouringLevel] = useState("");
  const [newVarietyCalendar, setNewVarietyCalendar] = useState("");

  //modal categories
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState("");

  //modal edit
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editVarietyName, setEditVarietyName] = useState<string | undefined>(
    ""
  );
  const [editVarietyOrigin, setEditVarietyOrigin] = useState<
    string | undefined
  >("");
  const [editVarietyDescription, setEditVarietyDescription] = useState<
    string | undefined
  >("");
  const [editVarColouringLevel, setEditVarColouringLevel] = useState<
    string | number | undefined
  >(0);
  const [editVarietyCalendar, setEditVarietyCalendar] = useState<
    string | undefined
  >("");

  //modal error
  const [errorText, setErrorText] = useState("");
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      loadProduct();
    }
  }, [token]);

  const loadProduct = async () => {
    const { categoryId, name, scientificName, description } = await getProduct(
      productId,
      token
    );
    let { name: categoryName } = await getCategoryName(categoryId, token);
    setProductName(name);
    setScientificName(scientificName);
    setProductDescription(description);

    //setting categories
    const categories = await getProductCategories(token);
    const sortedCategories = categories.map(({ id, name }: any) => {
      return { id, name };
    });
    setCategories(sortedCategories);
    setPickedCategory(categoryName);

    const varieties = await getProductVarieties(productId, token);
    setVarieties(varieties);
  };

  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: `¿Estás seguro quieres borrar esta compañía de base de datos? ¡No hay vuelta atrás!`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        // deleteForecastFromDB();
      }
    });
  };

  const editVariety = (index: number) => {
    const { name, calendar, description, origin, colouringLevel } = varieties[
      index
    ];
    setEditVarietyName(name);
    setEditVarietyOrigin(origin);
    setEditVarColouringLevel(colouringLevel);
    setEditVarietyDescription(description);
    setEditVarietyCalendar(calendar);
    setShowEditModal(true);
  };

  const openEditProductModal = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres guardar los cambios para este producto?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        let [{ id: categoryId }] = categories.filter(
          ({ name }) => name === pickedCategory
        );
        let productPatch = {
          categoryId,
          name: productName,
          description: productDescription,
          scientificName,
          updatedAt: new Date(),
        };
        editProduct(productPatch, productId, token).catch((error) =>
          setErrorText(error)
        );
      }
    });
  };

  const addNewCategory = async () => {
    postNewCategory(newCategory, token)
      .then(({ id, name }) => {
        setCategories((state) => {
          const newAddedForecast = produce(state, (drafState) => {
            drafState.push({ id, name });
          });
          return newAddedForecast;
        });
      })
      .catch((error) => setErrorText(error));
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Editar producto - {productName}</div>
        <button className="semana-div3">
          Semana actual
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </button>
      </div>
      <div className="container mt-4 ml-4">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label>
                Categoría{" "}
                <u
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowCategoryModal(true)}
                >
                  (Administrar categorías)
                </u>
              </label>
              <select
                className="form-control"
                value={pickedCategory}
                onChange={({ target: { value } }) => setPickedCategory(value)}
              >
                {categories.map(({ id, name }) => (
                  <option key={id}>{name}</option>
                ))}
              </select>
            </div>
            <form>
              <div className="form-group">
                <label>Nombre de producto</label>
                <input
                  type="text"
                  className="form-control"
                  value={productName}
                  onChange={({ target: { value } }) => setProductName(value)}
                />
              </div>
              <div className="form-group">
                <label>Nombre Científico</label>
                <input
                  type="text"
                  className="form-control"
                  value={scientificName}
                  onChange={({ target: { value } }) => setScientificName(value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Descripción</label>
                <textarea
                  className="form-control"
                  rows={3}
                  value={productDescription}
                  onChange={({ target: { value } }) =>
                    setProductDescription(value)
                  }
                />
              </div>
            </form>
            <button
              className="btn btn-md text-light mb-5 finishButton"
              // disabled={!addedForecast.length}
              onClick={openEditProductModal}
            >
              Guardar
            </button>
          </div>
        </div>
        <h3>Variedades</h3>
        <div className="row mt-4">
          {varieties.map(
            (
              { name, origin, description, colouringLevel, calendar },
              index
            ) => (
              <div key={index} className="col-sm-6 mb-2">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      {name} <span>({origin})</span>
                    </h5>
                    <label>
                      <u>Descripción: </u>
                    </label>
                    <p className="card-text">{description}</p>
                    <label>
                      <u>Coloración: </u>
                    </label>
                    <p className="card-text">{colouringLevel}</p>
                    <label>
                      <u>Calendario: </u>
                    </label>
                    <p className="card-text">{calendar}</p>
                    <button
                      className="btn text-white"
                      style={{ backgroundColor: "#cad401" }}
                      onClick={() => editVariety(index)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn bg-danger text-light ml-2 "
                      style={{ borderRadius: "13px" }}
                      // onClick={openModalDelete}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            )
          )}
          <div className="col-sm-6 mb-4">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Nombre de la variedad"
                      className="form-control"
                      value={newVarietyName}
                      onChange={({ target: { value } }) =>
                        setNewVarietyName(value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Origen"
                      value={newVarietyOrigin}
                      onChange={({ target: { value } }) =>
                        setNewVarietyOrigin(value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Descripcion"
                      rows={1}
                      value={newVarietyDescription}
                      onChange={({ target: { value } }) =>
                        setNewVarietyDescription(value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Coloración"
                      value={newVarColouringLevel}
                      onChange={({ target: { value } }) =>
                        setNewVarColouringLevel(value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Calendario"
                      value={newVarietyCalendar}
                      onChange={({ target: { value } }) =>
                        setNewVarietyCalendar(value)
                      }
                    />
                  </div>
                </form>
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#cad401" }}
                >
                  Añadir nueva variedad
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={Boolean(showEditModal)}
        onHide={() => setShowEditModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Variedad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <input
                type="text"
                placeholder="Nombre de la variedad"
                className="form-control"
                value={editVarietyName}
                onChange={({ target: { value } }) => setEditVarietyName(value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Origen"
                value={editVarietyOrigin}
                onChange={({ target: { value } }) =>
                  setEditVarietyOrigin(value)
                }
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Descripcion"
                rows={1}
                value={editVarietyDescription}
                onChange={({ target: { value } }) =>
                  setEditVarietyDescription(value)
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Coloración"
                value={editVarColouringLevel}
                onChange={({ target: { value } }) =>
                  setEditVarColouringLevel(value)
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Calendario"
                value={editVarietyCalendar}
                onChange={({ target: { value } }) =>
                  setEditVarietyCalendar(value)
                }
              />
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn text-white"
            style={{ backgroundColor: "#cad401" }}
          >
            Añadir nueva variedad
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={Boolean(showCategoryModal)}
        onHide={() => setShowCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Categorías</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="mb-4"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control mr-1"
                placeholder="Nueva categoría"
                onChange={({ target: { value } }) => setNewCategory(value)}
                value={newCategory}
              />
              <button
                className="btn text-white"
                style={{ backgroundColor: "#cad401" }}
                onClick={addNewCategory}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          {categories.map(({ name }, index) => (
            <div
              key={index}
              className="mt-3"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control mr-1"
                    value={name}
                    onChange={({ target: { value } }) => setNewCategory(value)}
                  />
                  <button
                    className="btn text-white"
                    style={{ backgroundColor: "#cad401" }}
                  >
                    Editar
                  </button>
                </div>
              </div>
              <div>
                <button
                  className="btn bg-danger text-light ml-2 "
                  // style={{ borderRadius: "13px" }}
                  // onClick={openModalDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      <ModalPopup
        show={Boolean(errorText)}
        setShow={setErrorText}
        modalText={errorText}
      />
    </div>
  );
};

export default EditProductAdmin;
