import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import "../../theme/dashboard.css";
import { List, ListItem, withStyles } from "@material-ui/core";
import { checkSeason } from "../../utils/Auxiliar/SeasonAuxiliar";
import BotSide from "./BotSide";
import { getNavbarIndex } from "../../utils/Auxiliar/NavbarAuxiliar";
import { useSelector } from "react-redux";

//logo
import logo from "../../icons/logo-agribio.png";

/*            GUÍA DE ÍNDICES
          /home / -> 0
          /informes-calidad -> 1
          /crear-informe-calidad -> 2
          /fincas -> 3
          /previsión compras -> 4
          /compras-reales -> 5
          /órdenes de corte -> 6
          /crear orden de corte -> 7
          /partes de recogida -> 8
          /disponibilidad -> 9
          /multimedia -> 10
          /añadir disponibilidad -> 11
          /compras-definitivas -> 12
          /crear-compras-definitivas -> 13
          /prevision-compras-productor -> 14
          /crear-prevision-productor -> 15
          /partes-almacen -> 16
          /crear-parte-almacen -> 17
*/

export default function ProductorNavbar() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pc, setPc] = useState(false);
  const [hr, setHr] = useState(false);
  const notifications = useSelector(({ notifications }) => notifications);
  const { type } = useSelector(({ user }) => user);
  useEffect(() => {
    let newHr = notifications.find((n) => n.type === 3);
    let newPc = notifications.find((n) => n.type === 4);

    if (newHr) {
      setHr(true);
    } else {
      setHr(false);
    }
    if (newPc) {
      setPc(true);
    } else {
      setPc(false);
    }
    getNavbarIndex(window.location.href, setSelectedIndex);
  }, [notifications]);

  const StyledListItem = withStyles({
    root: {
      backgroundColor: "#242424",
      "&$selected": {
        backgroundColor: "#000000",
        color: "#cad401",
      },
      "&$selected, &$selected:hover": {
        backgroundColor: "#000000",
      },

      "&:hover": {
        textDecoration: "none",
        color: "#cad401",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    selected: {},
  })(ListItem);

  const season = checkSeason();
  return (
    <div>
      <div className="top-bar">
        <img className="logo" src={logo} alt="" />
      </div>{" "}
      <div className="mid-bar">CAMPAÑA {season}</div>
      <List className="sidebar" component="nav" disablePadding dense>
        <StyledListItem
          selected={selectedIndex === 0}
          style={{ paddingLeft: 18 }}
          button
          component={Link}
          to="/"
          onClick={() => setSelectedIndex(0)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="home"
              className="svg-inline--fa fa-home fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
              ></path>
            </svg>
            Inicio
          </ListItemText>
        </StyledListItem>
        {pc ? (
          <>
            {" "}
            <StyledListItem
              selected={selectedIndex === 4}
              className="title-sidebar"
              style={{ paddingLeft: 18, textSize: 10 }}
              button
              component={Link}
              to="/agribio-prevision-compras"
              onClick={() => setSelectedIndex(4)}
            >
              <ListItemText>
                <svg
                  heigth="15"
                  width="15"
                  style={{ marginRight: 12, textSize: 10 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chart-bar"
                  className="svg-inline--fa fa-chart-bar fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
                  ></path>
                </svg>
                Previsión de compras
                <svg
                  color="#15d60f"
                  width="7"
                  style={{ marginLeft: 7, marginBottom: 7 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="circle"
                  className="svg-inline--fa fa-circle fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                  ></path>
                </svg>
                {selectedIndex === 4 || selectedIndex === 5 ? (
                  <svg
                    heigth="10"
                    width="10"
                    style={{ marginLeft: 15 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    heigth="6"
                    width="6"
                    style={{ marginLeft: 15 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-left"
                    className="svg-inline--fa fa-caret-left fa-w-6"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 512"
                  >
                    <path
                      fill="currentColor"
                      d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                    ></path>
                  </svg>
                )}
              </ListItemText>
            </StyledListItem>
            {selectedIndex === 4 || selectedIndex === 5 ? (
              <StyledListItem
                style={{ paddingLeft: 36 }}
                selected={selectedIndex === 5}
                component={Link}
                to="/compras-reales-productor"
                onClick={() => setSelectedIndex(5)}
                button
                dense
              >
                <ListItemText>
                  <span className="sidebar-subitem-text">Compras reales</span>
                </ListItemText>
              </StyledListItem>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <StyledListItem
              selected={selectedIndex === 4}
              className="title-sidebar"
              style={{ paddingLeft: 18, textSize: 10 }}
              button
              component={Link}
              to="/agribio-prevision-compras"
              onClick={() => setSelectedIndex(4)}
            >
              <ListItemText>
                <svg
                  heigth="15"
                  width="15"
                  style={{ marginRight: 12, textSize: 10 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chart-bar"
                  className="svg-inline--fa fa-chart-bar fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
                  ></path>
                </svg>
                Previsión de compras
                {selectedIndex === 4 || selectedIndex === 5 ? (
                  <svg
                    heigth="10"
                    width="10"
                    style={{ marginLeft: 29 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    heigth="6"
                    width="6"
                    style={{ marginLeft: 29 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-left"
                    className="svg-inline--fa fa-caret-left fa-w-6"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 512"
                  >
                    <path
                      fill="currentColor"
                      d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                    ></path>
                  </svg>
                )}
              </ListItemText>
            </StyledListItem>
            {selectedIndex === 4 || selectedIndex === 5 ? (
              <StyledListItem
                style={{ paddingLeft: 36 }}
                selected={selectedIndex === 5}
                component={Link}
                to="/compras-reales-productor"
                onClick={() => setSelectedIndex(5)}
                button
                dense
              >
                <ListItemText>
                  <span className="sidebar-subitem-text">Compras reales</span>
                </ListItemText>
              </StyledListItem>
            ) : (
              <></>
            )}
          </>
        )}

        <StyledListItem
          selected={selectedIndex === 6}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/ordenes-corte"
          onClick={() => setSelectedIndex(6)}
        >
          <ListItemText>
            <svg
              heigth="12"
              width="12"
              style={{ marginRight: 12, textSize: 10 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="cut"
              className="svg-inline--fa fa-cut fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M278.06 256L444.48 89.57c4.69-4.69 4.69-12.29 0-16.97-32.8-32.8-85.99-32.8-118.79 0L210.18 188.12l-24.86-24.86c4.31-10.92 6.68-22.81 6.68-35.26 0-53.02-42.98-96-96-96S0 74.98 0 128s42.98 96 96 96c4.54 0 8.99-.32 13.36-.93L142.29 256l-32.93 32.93c-4.37-.61-8.83-.93-13.36-.93-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-12.45-2.37-24.34-6.68-35.26l24.86-24.86L325.69 439.4c32.8 32.8 85.99 32.8 118.79 0 4.69-4.68 4.69-12.28 0-16.97L278.06 256zM96 160c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm0 256c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"
              ></path>
            </svg>
            Órdenes de corte
            {selectedIndex === 6 || selectedIndex === 7 ? (
              <svg
                heigth="10"
                width="10"
                style={{ marginLeft: 60 }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                className="svg-inline--fa fa-caret-down fa-w-10"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            ) : (
              <svg
                heigth="6"
                width="6"
                style={{ marginLeft: 60 }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-left"
                className="svg-inline--fa fa-caret-left fa-w-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
              >
                <path
                  fill="currentColor"
                  d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                ></path>
              </svg>
            )}
          </ListItemText>
        </StyledListItem>
        {selectedIndex === 6 || selectedIndex === 7 ? (
          <StyledListItem
            style={{ paddingLeft: 36 }}
            selected={selectedIndex === 7}
            component={Link}
            to="/gestionar-orden-corte/0"
            onClick={() => setSelectedIndex(7)}
            button
            dense
          >
            <ListItemText>
              <span className="sidebar-subitem-text">Crear orden de corte</span>
            </ListItemText>
          </StyledListItem>
        ) : (
          <></>
        )}
        {hr ? (
          <>
            <StyledListItem
              selected={selectedIndex === 8}
              className="title-sidebar"
              style={{ paddingLeft: 18, textSize: 10 }}
              button
              component={Link}
              to="/partes-recogida"
              onClick={() => setSelectedIndex(8)}
            >
              <ListItemText>
                <svg
                  heigth="15"
                  width="15"
                  style={{ marginRight: 12 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="truck-moving"
                  className="svg-inline--fa fa-truck-moving fa-w-20"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M621.3 237.3l-58.5-58.5c-12-12-28.3-18.7-45.3-18.7H480V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v336c0 44.2 35.8 80 80 80 26.3 0 49.4-12.9 64-32.4 14.6 19.6 37.7 32.4 64 32.4 44.2 0 80-35.8 80-80 0-5.5-.6-10.8-1.6-16h163.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H624c8.8 0 16-7.2 16-16v-85.5c0-17-6.7-33.2-18.7-45.2zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm272-224h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480v-48zm48 224c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"
                  ></path>
                </svg>
                Partes de recogida
                <svg
                  color="#15d60f"
                  width="7"
                  style={{ marginLeft: 7, marginBottom: 7 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="circle"
                  className="svg-inline--fa fa-circle fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                  ></path>
                </svg>
              </ListItemText>
            </StyledListItem>
          </>
        ) : (
          <>
            <StyledListItem
              selected={selectedIndex === 8}
              className="title-sidebar"
              style={{ paddingLeft: 18, textSize: 10 }}
              button
              component={Link}
              to="/partes-recogida"
              onClick={() => setSelectedIndex(8)}
            >
              <ListItemText>
                <svg
                  heigth="15"
                  width="15"
                  style={{ marginRight: 12 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="truck-moving"
                  className="svg-inline--fa fa-truck-moving fa-w-20"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M621.3 237.3l-58.5-58.5c-12-12-28.3-18.7-45.3-18.7H480V64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v336c0 44.2 35.8 80 80 80 26.3 0 49.4-12.9 64-32.4 14.6 19.6 37.7 32.4 64 32.4 44.2 0 80-35.8 80-80 0-5.5-.6-10.8-1.6-16h163.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H624c8.8 0 16-7.2 16-16v-85.5c0-17-6.7-33.2-18.7-45.2zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm272-224h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480v-48zm48 224c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32z"
                  ></path>
                </svg>
                Partes de recogida
              </ListItemText>
            </StyledListItem>
          </>
        )}

        {type === 1 ? (
          <>
            <StyledListItem
              selected={selectedIndex === 16}
              className="title-sidebar"
              style={{ paddingLeft: 18, textSize: 10 }}
              button
              component={Link}
              to="/partes-almacen"
              onClick={() => setSelectedIndex(16)}
            >
              <ListItemText>
                <svg
                  heigth="13"
                  width="13"
                  style={{ marginRight: 12, marginBottom: 3 }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="industry"
                  className="svg-inline--fa fa-industry fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M475.115 163.781L336 252.309v-68.28c0-18.916-20.931-30.399-36.885-20.248L160 252.309V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56v400c0 13.255 10.745 24 24 24h464c13.255 0 24-10.745 24-24V184.029c0-18.917-20.931-30.399-36.885-20.248z"
                  ></path>
                </svg>
                Partes de almacén
                {selectedIndex === 16 || selectedIndex === 17 ? (
                  <svg
                    heigth="10"
                    width="10"
                    style={{ marginLeft: 49 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    className="svg-inline--fa fa-caret-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    heigth="6"
                    width="6"
                    style={{ marginLeft: 49 }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-left"
                    className="svg-inline--fa fa-caret-left fa-w-6"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 512"
                  >
                    <path
                      fill="currentColor"
                      d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                    ></path>
                  </svg>
                )}
              </ListItemText>
            </StyledListItem>
            {selectedIndex === 16 || selectedIndex === 17 ? (
              <StyledListItem
                selected={selectedIndex === 17}
                className="title-sidebar"
                style={{ paddingLeft: 18, textSize: 10 }}
                button
                component={Link}
                to="/crear-parte-almacen"
                onClick={() => setSelectedIndex(17)}
              >
                <ListItemText>
                  <span className="sidebar-subitem-text">
                    Generar parte de almacén
                  </span>
                </ListItemText>
              </StyledListItem>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <StyledListItem
          selected={selectedIndex === 9}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/disponibilidad"
          onClick={() => setSelectedIndex(9)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12, textSize: 10, marginBottom: 3 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="dolly"
              className="svg-inline--fa fa-dolly fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M294.2 277.7c18 5 34.7 13.4 49.5 24.7l161.5-53.8c8.4-2.8 12.9-11.9 10.1-20.2L454.9 47.2c-2.8-8.4-11.9-12.9-20.2-10.1l-61.1 20.4 33.1 99.4L346 177l-33.1-99.4-61.6 20.5c-8.4 2.8-12.9 11.9-10.1 20.2l53 159.4zm281 48.7L565 296c-2.8-8.4-11.9-12.9-20.2-10.1l-213.5 71.2c-17.2-22-43.6-36.4-73.5-37L158.4 21.9C154 8.8 141.8 0 128 0H16C7.2 0 0 7.2 0 16v32c0 8.8 7.2 16 16 16h88.9l92.2 276.7c-26.1 20.4-41.7 53.6-36 90.5 6.1 39.4 37.9 72.3 77.3 79.2 60.2 10.7 112.3-34.8 113.4-92.6l213.3-71.2c8.3-2.8 12.9-11.8 10.1-20.2zM256 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z"
              ></path>
            </svg>
            Disponibilidad Agribio
          </ListItemText>
        </StyledListItem>

        <StyledListItem
          selected={selectedIndex === 3}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/fincas"
          onClick={() => setSelectedIndex(3)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="warehouse"
              className="svg-inline--fa fa-warehouse fa-w-20"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
              ></path>
            </svg>
            Fincas
          </ListItemText>
        </StyledListItem>

        <StyledListItem
          selected={selectedIndex === 10}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/mostrar-multimedia"
          onClick={() => setSelectedIndex(10)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="images"
              className="svg-inline--fa fa-images fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"
              ></path>
            </svg>
            Multimedia
          </ListItemText>
        </StyledListItem>
        <StyledListItem
          selected={selectedIndex === 18}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/reset-password"
          onClick={() => setSelectedIndex(10)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12, marginBottom: 3 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-cog"
              className="svg-inline--fa fa-user-cog fa-w-20"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M610.5 373.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 400.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm201.2 226.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9 4.6c-6 3.4-12.8 5.3-19.6 5.3-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-5.5-17.7 1.9-36.4 17.9-45.7l7.9-4.6c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7.9-2.9 2.2-5.8 3.2-8.7-3.8-.3-7.5-1.2-11.4-1.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c10.1 0 19.5-3.2 27.2-8.5-1.2-3.8-2-7.7-2-11.8v-9.2z"
              ></path>
            </svg>
            Cambiar Contraseña
          </ListItemText>
        </StyledListItem>
      </List>
      <BotSide />
    </div>
  );
}
