import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const getAllCompanies = (token: string) => {
  return Axios.get(`${baseURL}Companies?filter=`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCompanyIdByName = (companyName: string, token: string) => {
  let filter = {
    fields: ["id"],
    where: {
      name: companyName,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCompanyNameById = (companyId: string, token: string) => {
  let filter = {
    fields: ["name"],
    where: {
      id: companyId,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCompanyById = (id: number, token: string) => {
  let filter = {
    fields: ["name", "id"],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editCompany = (info: any, id: number, token: any) => {
  return Axios.patch(`${baseURL}Companies/${id}`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });
};

export const deleteCompany = (id: number, token: any) => {
  return Axios.delete(`${baseURL}Companies/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });
};

export const createCompany = (info: any, token: any) => {
  return Axios.post(`${baseURL}Companies`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });
};
