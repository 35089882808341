import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import { equivalentColouring } from "../utils/Auxiliar/EquivalentAuxiliar";
import colourings from "../utils/statics/colouring.json";

interface PlantationListItemProps {
  show: boolean;
  setShow: any;
  coloration: any;
  analysis: any;
  currentVariety: any;
}

const ModalColorationPopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  coloration,
  analysis,
  currentVariety,
}) => {
  const [product, setProduct] = useState<string>("");
  useEffect(() => {
    setProduct(
      analysis.find((a: any) => a.varietyId === currentVariety).productName
    );
  }, [currentVariety, coloration]);
  return (
    <Modal
      style={{ textAlign: "center" }}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Coloración</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product === "Lima" ? (
          <>
            <img
              width="400"
              alt="brix"
              style={{ marginRight: 10, marginLeft: -3 }}
              src="/images/colorations/lima-gama.jpg"
            ></img>
          </>
        ) : (
          <></>
        )}

        {product === "Limón" ? (
          <>
            <img
              width="400"
              alt="brix"
              style={{ marginRight: 10, marginLeft: -3 }}
              src="/images/colorations/limon-gama.png"
            ></img>
          </>
        ) : (
          <></>
        )}

        {product === "Mandarina" || product === "Clementina" ? (
          <img
            width="400"
            alt="brix"
            style={{ marginRight: 10, marginLeft: -3 }}
            src="/images/colorations/mandarina1-gama.jpg"
          ></img>
        ) : (
          <></>
        )}

        {product === "Naranja de mesa" || product === "Naranja de zumo" ? (
          <img
            width="400"
            alt="brix"
            style={{ marginRight: 10, marginLeft: -3 }}
            src="/images/colorations/naranja1-gama.jpg"
          ></img>
        ) : (
          <></>
        )}

        {product === "Pomelo" ? (
          <>
            <img
              width="400"
              alt="brix"
              style={{ marginRight: 10, marginLeft: -3 }}
              src="/images/colorations/pomelo-gama.jpg"
            ></img>
          </>
        ) : (
          <></>
        )}
        {coloration ? (
          <div style={{ marginTop: 10, fontWeight: 450, fontSize: 20 }}>
            Coloración: {coloration ? coloration : ""}
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            textAlign: "center",
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalColorationPopup;
