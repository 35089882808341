import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import {
  getCalibresByVarietyId,
  getVarietiesByCompanyId,
} from "../utils/Requests/VarietyRequests";
import { getCalibreCodes } from "../utils/Auxiliar/CalibreAuxiliar";
import produce from "immer";
import "../theme/plantation.css";

import more from "../icons/more.png";

import { getCalibreIdByCode } from "../utils/Requests/CalibreReq";
import {
  getVarietyCalibreById,
  postPurchaseVarCal,
  getPurchaseById,
  deleteVarCalById,
  deletePurchaseById,
} from "../utils/Requests/PurchaseRequest";
import ModalPopup from "../components/ModalPopup";
import { RouteComponentProps } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface PurchaseViewProps extends RouteComponentProps<{ id: string }> {}

const ManagePurchaseView: React.FC<PurchaseViewProps> = ({ match }) => {
  const [isAddingOpen, setIsAddingOpen] = useState(false);

  const [noCountryError, setNoCountryError] = useState(false);

  const [varieties, setVarieties] = useState<any[]>([]);
  const [pickedVariety, setPickedVariety] = useState("");

  const [calibres, setCalibres] = useState<string[]>([]);
  const [pickedCalibre, setPickedCalibre] = useState("");

  const [tons, setTons] = useState<number>(0);

  const [totalTons, setTotalTons] = useState(0);

  const [addedPurchase, setAddedPurchase] = useState<any[]>([]);
  const [deletedPurchase, setDeletedPurchase] = useState<any[]>([]);
  const [createPurchase, setCreatePurchase] = useState<any[]>([]);
  const [createPurchaseStatic, setCreatePurchaseStatic] = useState<any[]>([]);

  const [purchase, setPurchase] = useState<any>(undefined);

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const [acept, setAcept] = useState<boolean>(false);
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      getPurchaseById(parseInt(match.params.id), token)
        .then((purchase) => {
          setPurchase(purchase);

          setCreatePurchase(purchase.realPurchaseVarCals);
          setCreatePurchaseStatic(purchase.realPurchaseVarCals);
          let total = 0;
          purchase.realPurchaseVarCals.forEach((rp: any) => {
            total = rp.volumen + total;
          });
          setTotalTons(total / 1000);
          getVarietiesByCompanyId(purchase.companyId, token).then(
            (response: any) => {
              let varAux = response.sort(function (a: any, b: any) {
                if (a.id > b.id) {
                  return 1;
                }
                if (a.id < b.id) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
              setVarieties(varAux);
            }
          );
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [token]);

  const excludeVarCal = (index: number) => {
    let aux = createPurchase.find((p: any) => p.id === index); // la que quito
    let total =
      parseFloat(totalTons.toString()) - parseFloat(aux.volumen) / 1000;
    setTotalTons(total);
    let aux2 = createPurchase.filter((p: any) => p.id !== index); // quito la varcal
    setCreatePurchase(aux2);
    let aux3 = createPurchaseStatic.find((p: any) => p.id === index); // compruebo si era static
    if (aux3) {
      // si era static la añado a deleted
      let aux4 = deletedPurchase;
      aux4.push(aux3);
      setDeletedPurchase(aux4);
    }
  };

  const pickVariety = (event: any) => {
    setPickedVariety(event.target.value);
    if (event.target.value !== "Selecciona Variedad") {
      let aux2 = varieties.find((v: any) => event.target.value === v.name);
      if (aux2) {
        getCalibresByVarietyId(aux2.id, token).then((response) => {
          let calibres = getCalibreCodes(response);
          setCalibres(calibres);
        });
      }
    } else {
      setCalibres([]);
      setPickedCalibre("");
      setTons(0);
    }
  };

  const pickCalibre = (event: any) => {
    setPickedCalibre(event.target.value);
    if (event.target.value === "Añadir Calibre") {
      setPickedCalibre("");
      setTons(0);
    }
  };

  const addPurchase = () => {
    if (tons) {
      if (tons.toString() === "0" || parseFloat(tons.toString()) === 0) {
        setModalText("No se pueden introducir valor 0 para las toneladas");
        setShow(true);
      } else {
        setAddedPurchase([
          ...addedPurchase,
          {
            pickedVariety,
            pickedCalibre,
            tons,
          },
        ]);
        let total =
          parseFloat(totalTons.toString()) + parseFloat(tons.toString());
        setTotalTons(total);
        setPickedCalibre("");
        setTons(0);
      }
    }
  };

  const deletePurchase = (index: number) => {
    let total =
      parseFloat(totalTons.toString()) -
      parseFloat(addedPurchase[index].tons.toString());
    setTotalTons(total);
    createPurchaseStatic.find((cp: any) => cp.id === index);
    setAddedPurchase((state) => {
      const newAddedPurchase = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newAddedPurchase;
    });
  };

  const sendPurchase = async () => {
    let addedAux: any[] = [];
    addedPurchase.forEach((x: any) => {
      addedAux.push({
        varietyCalibre: {
          calibre: { code: x.pickedCalibre },
          variety: { name: x.pickedVariety },
        },
      });
    });
    let union = addedAux.concat(createPurchase);
    let repetido: string = "";

    union.forEach((af: any) => {
      let aux = union.filter(
        (a: any) =>
          a.varietyCalibre.variety.name === af.varietyCalibre.variety.name &&
          a.varietyCalibre.calibre.code === af.varietyCalibre.calibre.code
      );
      if (aux.length > 1) {
        repetido = af.varietyCalibre.variety.name;
      }
    });

    if (repetido === "") {
      addedPurchase.forEach((purch: any) => {
        getCalibreIdByCode(purch.pickedCalibre, token).then((c: any) => {
          let calibreId: number = c.id;
          let varietyId: number = varieties.find(
            (v: any) => v.name === purch.pickedVariety
          ).id;
          getVarietyCalibreById(varietyId, calibreId, token)
            .then((vc: any) => {
              // id del varCal
              let real = {
                volumen: (purch.tons * 1000).toFixed(2),
                purchaseId: purchase.id,
                varietyCalibreId: vc.id,
              };
              postPurchaseVarCal(real, token).then((response: any) => {});
            })
            .catch((error) => {
              setModalText(error.toString());
              setShow(true);
            });
        });
      });
      deletedPurchase.forEach((d: any) => {
        deleteVarCalById(d.id, token).then((response: any) => {});
      });
      setTimeout(() => openModalThanks(), 600);
    } else {
      openModalRepetido(repetido);
    }
  };

  const deleteTotalPurchase = async () => {
    createPurchaseStatic.forEach((d: any) => {
      deleteVarCalById(d.id, token).then((response: any) => {});
    });

    deletePurchaseById(purchase.id, token).then((response: any) => {
      setTimeout(() => openModalConfirm(), 600);
    });
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Compra real actualizada correctamente.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/resumen-compra/" + match.params.id);
      }
    });
  };
  const openModalUpdate = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres actualizar la compra real?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendPurchase();
      }
    });
  };

  const openModalConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Compra real eliminada correctamente.",
      showCancelButton: false,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/compras-definitivas");
      }
    });
  };
  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Quieres eliminar la compra real?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        deleteTotalPurchase();
      }
    });
  };

  const openModalRepetido = (variedad: string) => {
    Swal.fire({
      icon: "warning",
      text:
        "La variedad " +
        variedad +
        " tiene calibres repetidos, por favor corríjalo.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(() => {});
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setModalText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShow(true);
      setTons(0);
    } else {
      setTons(event.target.value);
    }
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span className="top-panel-text">Editar compras reales</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>

      <div className="cointainer">
        <ModalPopup show={show} setShow={setShow} modalText={modalText} />

        <div className="row " style={{ marginTop: 20, marginRight: 40 }}>
          <div className="col-12 d-flex flex-row-reverse">
            <button
              style={{ marginTop: 2, marginLeft: 20 }}
              className="delete-report-button"
              onClick={openModalDelete}
            >
              <div className="button-flex-warehouse">
                <div>
                  <svg
                    style={{ marginBottom: 2 }}
                    width="12"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="trash-alt"
                    className="svg-inline--fa fa-trash-alt fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            {createPurchase.length + addedPurchase.length <= 0 ? (
              <></>
            ) : (
              <>
                <button
                  className="btn text-light finishButton"
                  style={{ height: 35, textAlign: "center" }}
                  disabled={
                    !(addedPurchase.length > 0 || deletedPurchase.length > 0)
                  }
                  onClick={openModalUpdate}
                >
                  Finalizar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "60vw",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <div className="container p-3">
          <div className="row mb-3">
            <div className="col-5">
              <select
                className="form-control form-control-sm"
                style={{ width: "15rem" }}
                data-spy="scroll"
                disabled={true}
              >
                <option>{purchase?.company.name}</option>
              </select>
            </div>
            <div className="col-4">
              <select
                className="form-control form-control-sm ml-3"
                style={{
                  width: "12rem",
                  border: noCountryError ? "solid red 2px" : "",
                }}
                data-spy="scroll"
                disabled={true}
              >
                <option>{purchase?.week}</option>
              </select>
            </div>
            <div className="col-3">
              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="text"
                disabled
                value={
                  totalTons
                    ? treatNumber(totalTons) + " Tn"
                    : "Volumen Total Tn"
                }
              />
            </div>
          </div>
          <div className="row" style={{ paddingTop: "2vh" }}>
            <div className="col-12">
              <table className="table text-center">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {createPurchase.map((p) => (
                    <tr key={p.id}>
                      <th scope="row">{p.varietyCalibre.variety.name}</th>
                      <td>{p.varietyCalibre.calibre.code}</td>
                      <td>{treatNumber(p.volumen / 1000)}</td>
                      <td
                        className="boldFontLink"
                        onClick={() => excludeVarCal(p.id)}
                      >
                        X
                      </td>
                    </tr>
                  ))}
                  {addedPurchase.map(
                    ({ pickedVariety, pickedCalibre, tons }, index) => (
                      <tr key={index}>
                        <th scope="row">{pickedVariety}</th>
                        <td>{pickedCalibre}</td>
                        <td>{tons}</td>
                        <td
                          className="boldFontLink"
                          onClick={() => deletePurchase(index)}
                        >
                          X
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="container addVariety">
          <div
            className="row mt-2"
            onClick={() => setIsAddingOpen(!isAddingOpen)}
          >
            <img
              className="ml-2 mt-1"
              src={more}
              style={{ width: "1.2rem", height: "1.2rem" }}
            />
            <span className="ml-2">Añadir variedad</span>
          </div>
          {isAddingOpen && (
            <div className="row mt-3 pb-2">
              <select
                className="form-control form-control-sm ml-2"
                style={{ width: "12rem" }}
                data-spy="scroll"
                disabled={!varieties.length}
                value={pickedVariety}
                onChange={pickVariety}
              >
                <option key={0}> Selecciona Variedad</option>
                {varieties.map((variety: any) => (
                  <option key={variety.id} value={variety.name}>
                    {variety.name}
                  </option>
                ))}
              </select>
              {pickedVariety ? (
                <select
                  className="form-control form-control-sm ml-2"
                  style={{ width: "10rem" }}
                  value={pickedCalibre}
                  disabled={!calibres.length}
                  onChange={pickCalibre}
                >
                  <option>Añadir Calibre</option>
                  {calibres.map((calibreCode: string, index: number) => (
                    <option key={index} value={calibreCode}>
                      {calibreCode}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  className="form-control form-control-sm ml-2"
                  style={{ width: "10rem" }}
                  value={pickedCalibre}
                  disabled
                  onChange={pickCalibre}
                >
                  <option>Añadir Calibre</option>
                  {calibres.map((calibreCode: string, index: number) => (
                    <option key={index} value={calibreCode}>
                      {calibreCode}
                    </option>
                  ))}
                </select>
              )}

              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                type="number"
                disabled={!pickedCalibre}
                value={tons === 0 ? "" : tons}
                placeholder="Toneladas"
                onChange={tonHandler}
              />
              <button
                className="btn btn-sm text-light ml-3 addButton"
                disabled={!pickedCalibre || !tons}
                onClick={addPurchase}
              >
                Añadir
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePurchaseView;
