import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { getPlantationByIdForProductor } from "../utils/Requests/PlantationRequests";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import MeteoInfo from "../components/MeteoInfo";
import PlantationInfo from "../components/PlantationInfo";
import VarietyItem from "../components/VarietyItem";
import AnalysisTable from "../components/AnalysisTable";
import { getPriorAnalysesBySeasonAndId } from "../utils/Requests/PriorAnalylisisRequests";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import ModalPopup from "../components/ModalPopup";
import IPlantation from "../interfaces/IPlantation";
import RadialChartPlantation from "../components/RadialChartPlantation";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import PlantationData from "../components/PlantationData";
import { createPriorAnalysis } from "../utils/Auxiliar/AnalysisAuxiliar";
import { getHarvestReportForPlantationView } from "../utils/Requests/HarvestReportRequest";
import { getQualityReportByPlantationVariety } from "../utils/Requests/QualityReportRequests";
import { checkMeteoByPlantation } from "../utils/Requests/MeteoRequest";
//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

interface PlantationViewProps extends RouteComponentProps<{ id: string }> {}

const PlantationView: React.FC<PlantationViewProps> = ({ match }) => {
  const [plantation, setPlantation] = useState<IPlantation>();
  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState("Error");
  const [varietyId, setVarietyId] = useState(undefined);
  const [varietyAnalysis, setVarietyAnalysis] = useState();
  const [meteo, setMeteo] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [mostrar, setMostrar] = useState<boolean>(true);
  const { token, companyId, role } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  useEffect(() => {
    if (token) {
      if (
        role === "sourcing" ||
        role === "marketing" ||
        role === "agribio" ||
        role === "quality" ||
        role === "sales"
      ) {
        getPlantationByIdForProductor(parseInt(match.params.id), token)
          .then((plt: any) => {
            checkMeteoByPlantation(plt, setMeteo, token);
            setPlantation(plt);
            let arr: any = [];
            getSeasonByName(checkSeasonShort(), token)
              .then((season) => {
                setVarieties(
                  plt.plantationVarieties.filter(
                    (pv: any) => !pv.variety.name.includes("Hoja")
                  )
                );

                plt.plantationVarieties.forEach((v: any) => {
                  if (!v.variety.name.includes("Hoja")) {
                    getPriorAnalysesBySeasonAndId(v.id, season.id, token).then(
                      (analyses) => {
                        let auxx = analyses.sort(function (a: any, b: any) {
                          if (a.createdAt >= b.createdAt) {
                            return 1;
                          }
                          if (a.createdAt < b.createdAt) {
                            return -1;
                          }
                          // a must be equal to b
                          return 0;
                        });
                        // recojo los analisis de cada variedad
                        getHarvestReportForPlantationView(
                          v.id,
                          season.id - 1,
                          token
                        )
                          .then((reports) => {
                            getHarvestReportForPlantationView(
                              v.id,
                              season.id,
                              token
                            )
                              .then((reportsNow) => {
                                getQualityReportByPlantationVariety(
                                  v.id,
                                  season.id,
                                  token
                                )
                                  .then((quality) => {
                                    let aux = {
                                      plantationVarietyId: v.id,
                                      varietyName: v.variety.name,
                                      productName: v.variety.product.name,
                                      varietyId: v.variety.id,
                                      analyses:
                                        auxx.length > 0 ? auxx : undefined,
                                      analysisShow: createPriorAnalysis(
                                        analyses,
                                        reports,
                                        reportsNow,
                                        quality
                                      ),
                                    };

                                    arr.push(aux);
                                  })
                                  .catch((error) => {
                                    console.log(error.toString());
                                  });
                              })
                              .catch((error) => {
                                console.log(error.toString());
                              });
                          })
                          .catch((error) => {
                            console.log(error.toString());
                          });
                      }
                    );
                  }
                });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
            setVarietyAnalysis(arr);
          })
          .catch((error) => {
            setModalText(error.toString());
            setShow(true);
          });
      } else {
        getPlantationByIdForProductor(parseInt(match.params.id), token)
          .then((plt: any) => {
            if (plt.companyId === companyId) {
              checkMeteoByPlantation(plt, setMeteo, token);
              setPlantation(plt);
              let arr: any = [];
              getSeasonByName(checkSeasonShort(), token)
                .then((season) => {
                  setVarieties(
                    plt.plantationVarieties.filter(
                      (pv: any) => !pv.variety.name.includes("Hoja")
                    )
                  );

                  plt.plantationVarieties.forEach((v: any) => {
                    if (!v.variety.name.includes("Hoja")) {
                      getPriorAnalysesBySeasonAndId(
                        v.id,
                        season.id,
                        token
                      ).then((analyses) => {
                        let auxx = analyses.sort(function (a: any, b: any) {
                          if (a.createdAt >= b.createdAt) {
                            return 1;
                          }
                          if (a.createdAt < b.createdAt) {
                            return -1;
                          }
                          // a must be equal to b
                          return 0;
                        });
                        // recojo los analisis de cada variedad
                        getHarvestReportForPlantationView(
                          v.id,
                          season.id - 1,
                          token
                        )
                          .then((reports) => {
                            getHarvestReportForPlantationView(
                              v.id,
                              season.id,
                              token
                            )
                              .then((reportsNow) => {
                                getQualityReportByPlantationVariety(
                                  v.id,
                                  season.id,
                                  token
                                )
                                  .then((quality) => {
                                    let aux = {
                                      plantationVarietyId: v.id,
                                      varietyName: v.variety.name,
                                      productName: v.variety.product.name,
                                      varietyId: v.variety.id,
                                      analyses:
                                        auxx.length > 0 ? auxx : undefined,
                                      analysisShow: createPriorAnalysis(
                                        analyses,
                                        reports,
                                        reportsNow,
                                        quality
                                      ),
                                    };

                                    arr.push(aux);
                                  })
                                  .catch((error) => {
                                    console.log(error.toString());
                                  });
                              })
                              .catch((error) => {
                                console.log(error.toString());
                              });
                          })
                          .catch((error) => {
                            console.log(error.toString());
                          });
                      });
                    }
                  });
                })
                .catch((error) => {
                  setModalText(error.toString());
                  setShow(true);
                });
              setVarietyAnalysis(arr);
            } else {
              setMostrar(false);
            }
          })
          .catch((error) => {
            setModalText(error.toString());
            setShow(true);
          });
      }
    }
  }, [match.params.id, token, role, companyId]);

  return (
    <div className="div-principal3">
      <div className="top-panel">
        {mostrar ? (
          <div className="top-panel-text" style={{ color: "#cad401" }}>
            {plantation?.name}
          </div>
        ) : (
          <div className="top-panel-text" style={{ color: "#cad401" }}>
            Finca privada
          </div>
        )}

        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {!!mostrar && (
        <>
          <div className="bg-white mr-5 ml-5 d-flex mb-3 mt-3 justify-content-center">
            <MeteoInfo meteo={meteo} />
          </div>
          <div className="bg-white mr-5 ml-5 ">
            <PlantationInfo plantation={plantation} token={token} />
          </div>
          <div className="bg-white ml-5 mr-5">
            <div className="variety-title">Variedades</div>
            {plantation?.plantationVarieties.length && (
              <>
                <div className="row d-flex justify-content-center">
                  {varieties.map((va: any, index) => (
                    <div key={index} className="col-3">
                      <VarietyItem
                        key={va.id}
                        variety={va}
                        setVarietyId={setVarietyId}
                        varietyId={varietyId}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="middle-panel mt-3">
            <RadialChartPlantation
              selectedVariety={varietyId}
              varieties={varietyAnalysis}
            />
            <PlantationData
              analysis={varietyAnalysis}
              currentVariety={varietyId}
            />
          </div>
          <AnalysisTable
            analyses={varietyAnalysis}
            currentVariety={varietyId}
          />
          <ModalPopup show={show} setShow={setShow} modalText={modalText} />
        </>
      )}
    </div>
  );
};

export default PlantationView;
