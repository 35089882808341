import React from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import {
  treatNumber,
  treatNumber2,
} from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationListItemProps {
  comp: any;
}

const LegendComponent: React.FC<PlantationListItemProps> = ({ comp }) => {
  return (
    <div
      style={{
        textAlign: "left",
        marginBottom: 5,
      }}
    >
      <svg
        style={{ marginRight: 10 }}
        color={comp.color}
        width="15"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="circle"
        className="svg-inline--fa fa-circle fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
        ></path>
      </svg>
      <div
        style={{
          textAlign: "left",
          marginBottom: 5,
          display: "inline-flex",
        }}
      >
        {comp.value === "Cualquiera" ? (
          <div style={{ fontWeight: 500, marginRight: 5 }}>Sin calibrar: </div>
        ) : (
          <div style={{ fontWeight: 500, marginRight: 5 }}>
            calibre {comp.value}:{" "}
          </div>
        )}{" "}
        <div style={{ fontWeight: 450, color: "grey" }}>
          {treatNumber2(comp.pct)} %
        </div>
      </div>
    </div>
  );
};

export default LegendComponent;
