import React, { useEffect, useState } from "react";

//css
import "../theme/homepage.css";
import "../theme/sourcing.css";

//components
import BarChart from "../components/BarChart";
import RadialCharts from "../components/RadialCharts";

//aux
import {
  constructDataBarChar,
  constructDataRadialChar,
} from "../utils/Auxiliar/PurchasesForecastAux";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { searchHighestNumber } from "../utils/Auxiliar/SourcingSalesAux";

//req
import {
  getAvailabilityAgribioById,
  getValCalAvailabilityById,
  getAvailabilityInfoToExport,
} from "../utils/Requests/AgribioForecastRequest";
import {
  getVarCalIds,
  getVarietyNameFromVarietyId,
} from "../utils/Requests/VarietyRequests";
import { getCalCodeFromCalId } from "../utils/Requests/CalibreReq";
import { getCompanyNameById } from "../utils/Requests/CompanyRequest";
import { getOfficeNameById } from "../utils/Requests/OfficeReqs";
import {
  getPurchasesForecastDetailsById,
  getPurchasesForecastById,
  getPurchaseForecastInfoToExport,
} from "../utils/Requests/PurchaseForecastReq";

//interfaces
import { IBarChartData } from "../interfaces/IBarCharData";
import { IRadialChartData } from "../interfaces/IRadialCharData";

//middleware
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import INotification from "../interfaces/INotification";
import { RemoveNotificationAction } from "../redux/actions";
import { deleteNotificationById } from "../utils/Requests/NotificationRequest";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

export interface IChartData {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
}

export interface IExcelData {
  week: number;
  producer: string;
  office?: string;
  variety: string;
  calibre: number;
  tons: number;
}

const SourcingGraphView: React.FC = () => {
  let { pathname } = useLocation();
  let path = pathname.split("/")[1];
  let id = +pathname.split("/")[2];

  const [rawData, setRawData] = useState<IChartData[]>([]);
  const [barData, setBarData] = useState<IBarChartData[]>([]);
  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);

  const [excelForecastData, setExcelForecastData] = useState<IExcelData[]>([]);
  const [excelAvailabilityData, setExcelAvailabilityData] = useState<
    IExcelData[]
  >([]);

  const [height, setHeight] = useState(0);

  const [pickedCompany, setPickedCompany] = useState("");
  const [pickedCountry, setPickedCountry] = useState("");
  const [week, setWeek] = useState(0);
  const [pickedVariety, setPickedVariety] = useState("");
  const [isSent, setIsSent] = useState(false);

  const dispatch = useDispatch();
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );

  const { token, role } = useSelector(({ user }: IStore) => user as IUser);
  useEffect(() => {
    if (token) {
      if (path.includes("compras")) {
        getForecastPurchaseData();
      } else if (path.includes("disponibilidad")) {
        let local: INotification | undefined = notifications?.find(
          (n: INotification) => n.docId === id && n.type === 5
        );
        if (local) {
          dispatch(RemoveNotificationAction(local?.id!));
          // eliminarlo de la base de datos
          deleteNotificationById(local?.id!, token)
            .then((not: any) => {})
            .catch((error: string) => {
              console.log(error.toString());
            });
        }
        getAvailabilityData();
      }
    }
  }, [pathname, notifications]);

  const getForecastPurchaseData = async () => {
    //Export to Excel
    let dataToExport = await getPurchaseForecastInfoToExport(id, token);
    setExcelForecastData(dataToExport);
    let forecastsToEdit = await getPurchasesForecastDetailsById(id, token);

    setWeek(forecastsToEdit[0]?.forecastPurchase.week);
    setIsSent(Boolean(forecastsToEdit[0]?.forecastPurchase.isSent));
    setPickedCompany(forecastsToEdit[0]?.forecastPurchase.company.name);
    setPickedCountry(forecastsToEdit[0]?.forecastPurchase.office.country);
    let editedForecasts: IChartData[] = [];
    forecastsToEdit.map(({ varietyCalibre, volumen }: any) => {
      editedForecasts.push({
        pickedVariety: varietyCalibre.variety.name,
        pickedCalibre: varietyCalibre.calibre.code,
        tons: volumen / 1000,
      });
    });
    handleEditedData(editedForecasts);
  };

  const getAvailabilityData = async () => {
    //Export to excel
    let dataToExport = await getAvailabilityInfoToExport(id, token);
    setExcelAvailabilityData(dataToExport);

    let { companyId, createdAt } = await getAvailabilityAgribioById(id, token);
    let { name: companyName } = await getCompanyNameById(companyId, token);
    setWeek(+setWeekNumber(createdAt));
    setPickedCompany(companyName);
    //so I don´t get the "Edit" button
    setIsSent(true);
    let valCalAvailability = await getValCalAvailabilityById(id, token);
    let editedValCalAvailability: IChartData[] = [];
    Promise.all(
      valCalAvailability.map(async ({ varietyCalibreId, volume }: any) => {
        let { calibreId, varietyId } = await getVarCalIds(
          varietyCalibreId,
          token
        );
        let { name: pickedVariety } = await getVarietyNameFromVarietyId(
          varietyId,
          token
        );
        let { code: pickedCalibre } = await getCalCodeFromCalId(
          calibreId,
          token
        );
        editedValCalAvailability.push({
          pickedVariety,
          pickedCalibre,
          tons: volume / 1000,
        });
      })
    ).then(() => {
      handleEditedData(editedValCalAvailability);
    });
  };

  const handleEditedData = (data: IChartData[]) => {
    setRawData(data);
    let barData = constructDataBarChar(data);
    let highestNumber = searchHighestNumber(barData);
    setHeight(highestNumber * 1.1);
    setBarData(barData);
    let radialData = constructDataRadialChar(data);
    setRadialData(radialData);
    setPickedVariety(data[0]?.pickedVariety);
  };

  const handlePickedVariety = (variety: string) => {
    setPickedVariety(variety);

    let newRadialData = constructDataRadialChar(rawData, variety);
    setRadialData(newRadialData);
  };

  const colorCondition = (variety: string) => {
    if (variety === pickedVariety) return true;
    else {
      return false;
    }
  };

  //Excel headers
  const forecastHeaders = [
    { label: "Semana", key: "week" },
    { label: "Productor", key: "producer" },
    { label: "Oficina", key: "office" },
    { label: "Variedad", key: "variety" },
    { label: "Calibre", key: "calibre" },
    { label: "Toneladas", key: "tons" },
  ];

  const availabilityHeaders = [
    { label: "Semana", key: "week" },
    { label: "Productor", key: "producer" },
    { label: "Variedad", key: "variety" },
    { label: "Calibre", key: "calibre" },
    { label: "Toneladas", key: "tons" },
  ];

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span>Resumen de previsión</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div  style={{
          width: "3%",
          marginLeft: "77%",
          marginTop: "1%",
        }}>
      {path.includes("compras") && (
          <div style={{ marginBottom: "2%" }}>
            <CSVLink
              data={excelForecastData}
              headers={forecastHeaders}
              filename={`previsión${pickedCompany}${pickedCountry}semana${week}.csv`}
            >
              <button className="btn btn-md text-light export-button">
                Exportar a excel
              </button>
            </CSVLink>
          </div>
        )}
        {path.includes("disponibilidad") && (
          <div style={{ marginBottom: "2%" }}>
            <CSVLink
              data={excelAvailabilityData}
              headers={availabilityHeaders}
              filename={`previsión${pickedCompany}semana${week}.csv`}
            >
              <button className="btn btn-md text-light export-button">
                Exportar a excel
              </button>
            </CSVLink>
          </div>
        )}
      </div>
      <div
        style={{
          width: "89%",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
       
        <div className="container">
          <div className="row mb-3 mt-3 d-flex justify-content-between">
            {pickedCompany ? (
              <h4>
                {pickedCompany}
                {pickedCountry && " | " + pickedCountry}
                {week && ` | Semana ${week}`}
              </h4>
            ) : (
              <h4></h4>
            )}
            {!isSent && role === "sourcing" && (
              <button
                className="btn btn-md text-light finishButton"
                onClick={() =>
                  history.push(`/gestionar-previsiones-compras/${id}`)
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                {"   "}
                Editar
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
        }}
      >
        <div className="container pl-5">
          <div className="row mb-3 mt-3 pt-4">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <BarChart
              data={barData}
              data2={false}
              height={[0, height]}
              width={800}
              type={"Tn"}
            />
          </div>
          <div className="row justify-content-center pb-3">
            <span style={{ fontWeight: "bold" }}>Variedades</span>
          </div>
        </div>
      </div>

      <div
        className="whiteContainer"
        style={{
          minHeight: "180px",
          width: "89%",
        }}
      >
        <div className="container pl-3 pt-2">
          <div className="row mb-3 mt-3">
            <div className="col-sm-7 col-12">
              <table className="table text-center smallTable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">PRODUCTOR</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {barData.map(({ x: variety, y: tons }, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: colorCondition(variety)
                          ? "#cad401"
                          : "",
                      }}
                      onClick={() => handlePickedVariety(variety)}
                    >
                      <th scope="row">{pickedCompany}</th>
                      <td>{variety}</td>
                      <td>{treatNumber(tons)}</td>
                      <td
                        className="boldFontLink"
                        style={{
                          color: pickedVariety === variety ? "white" : "",
                        }}
                      >
                        {">"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="col-sm-2 col-md-2 col-12">
              <h4>Tn/Calibre</h4>
              <RadialCharts data={radialData} />
            </div>
            <div className="col-sm- col-md-2 col-12 text-right">
              <h6 className="mb-4 mr-4">
                {pickedVariety ? pickedVariety : barData[0]?.x}
              </h6>

              {radialData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  {/* {colorGama[index]} */}
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  <p>
                    Calibre {label !== "Cualquiera" ? label : "Sin Calibrar "}
                  </p>
                  {label === "Cualquiera" && <br />}
                  <span>: {treatNumber(angle)} Tn</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcingGraphView;
