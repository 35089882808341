import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import {
  getUsersByUniqueRolAndCompany,
  getProvincesByUserId,
} from "../utils/Requests/UserRequests";
import {
  getCutOrderByCompanyId,
  getCutOrderWeekByCompanyId,
  getCutOrderByCompanyIdNotSent,
  postVisibility,
  getProvinceByCutOrderId,
} from "../utils/Requests/CutOrderRequest";
import ICutOrder from "../interfaces/ICutOrder";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getPlantationsByCompanyId } from "../utils/Requests/PlantationRequests";
import IPlantation from "../interfaces/IPlantation";
import { getAllPlantationsVarieties } from "../utils/Requests/PlantationVarietyRequests";
import { Link } from "react-router-dom";
import { searchCutOrder } from "../utils/Auxiliar/CutOrderAuxiliar";
import { getHarvestReportIdCutOrderId } from "../utils/Requests/HarvestReportRequest";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { postUserNotification } from "../utils/Requests/NotificationRequest";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
// import { searchCutOrder } from "../utils/Auxiliar/CutOrderAuxiliar";

interface IChecked {
  id: any;
  check: boolean;
}
const CutOrderListView: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showFin, setShowFin] = useState<boolean>(false);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [cutOrders, setCutOrders] = useState<ICutOrder[]>([]);
  const [isSentCutOrders, setIsSentCutOrders] = useState<ICutOrder[]>([]);
  const [plantations, setPlantations] = useState<IPlantation[]>([]);
  const [pltVarieties, setPltVarieties] = useState<[]>([]);
  const [varieties, setVarieties] = useState<[]>([]);
  const [weeks, setWeeks] = useState<any[]>([]);
  const [cutOrderSearch, setCutOrderSearch] = useState<ICutOrder[]>([]);
  const [variety, setVariety] = useState<any>("Cualquier variedad");
  const [week, setWeek] = useState<any>("Todas las semanas");
  const [plantation, setPlantation] = useState<any>("Todas las fincas");
  const [checked, setChecked] = useState<IChecked[]>([]);

  const [cutOrderIndex, setCutOrderIndex] = useState(5);
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token && companyId) {
      const season = checkSeasonShort();
      getSeasonByName(season, token)
        .then((response) => {
          getCutOrderByCompanyIdNotSent(companyId!, response.id, token)
            .then((isSentCutOrders) => {
              setIsSentCutOrders(isSentCutOrders);
              getCutOrderByCompanyId(companyId!, response.id, token)
                .then((cutOrders) => {
                  setCutOrders(cutOrders);
                  getPlantationsByCompanyId(companyId!, token)
                    .then((plantations) => {
                      setPlantations(plantations);
                      getAllPlantationsVarieties(
                        plantations,
                        setPltVarieties,
                        token
                      )
                        .then((varieties: any) => {
                          let aux = varieties.filter(
                            (rv: any) => !rv.varietyName.includes("Hoja")
                          );

                          aux.sort(function (a: any, b: any) {
                            if (a.varietyName > b.varietyName) {
                              return 1;
                            }
                            if (a.varietyName < b.varietyName) {
                              return -1;
                            }
                            // a must be equal to b
                            return 0;
                          });

                          aux.unshift({
                            id: -1,
                            varietyName: "Cualquier variedad",
                            productName: "none",
                          });

                          setVarieties(aux);
                          setCutOrderSearch(cutOrders);
                          getCutOrderWeekByCompanyId(
                            companyId!,
                            response.id,
                            token
                          ).then((weeks) => {
                            setWeeks(weeks);
                          });
                        })
                        .catch((error: string) => {
                          setModalText(error.toString());
                          setShow(true);
                        });
                    })
                    .catch((error: string) => {
                      setModalText(error.toString());
                      setShow(true);
                    });
                })
                .catch((error: string) => {
                  setModalText(error.toString());
                  setShow(true);
                });
            })
            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [token, companyId]);

  const searchPlantationHandler = (event: any) => {
    let plantation = event.target.value;
    setPlantation(plantation);
    searchCutOrder(plantation, variety, week, setCutOrderSearch, cutOrders);
  };

  const searchVarietyHandler = (event: any) => {
    let variety = event.target.value;
    setVariety(variety);

    searchCutOrder(plantation, variety, week, setCutOrderSearch, cutOrders);
  };

  const searchWeekHandler = (event: any) => {
    let week = event.target.value;
    setWeek(week);
    searchCutOrder(plantation, variety, week, setCutOrderSearch, cutOrders);
  };

  const clearFilters = () => {
    setPlantation("Todas las fincas");
    setVariety("Cualquier variedad");
    setWeek("Todas las semanas");
    setCutOrderSearch(cutOrders);
  };

  const handleCheck = (event: any) => {
    let check = event.target.checked;
    let id = event.target.id;
    let newChecked: any = [...checked];
    let isChecked: any = [];

    if (event.target.checked) {
      isChecked = [{ id, check }, ...checked];
    } else {
      isChecked = newChecked.filter((tool: any) => event.target.id != tool.id);
    }

    setChecked(isChecked);
  };

  const setCutOrderVisible = async () => {
    setShowFin(false);
    let aux: any[] = [];
    checked.forEach((cc: any) => {
      postVisibility(true, cc.id, token)
        .then(() => {
          openModalThanks();
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });

      // cojo la provincia de cada finca
      if (cc.check === true) {
        getProvinceByCutOrderId(cc.id, token)
          .then((province: any) => {
            aux.push({ provinceId: province.id, check: cc });
          })
          .catch((error) => {
            setModalText(error);
            setShow(true);
          });
      }
    });

    // tengo cada orden de corte asociada a su provincia
    getUsersByUniqueRolAndCompany(companyId!, "fieldManager", token)
      .then((users: any) => {
        // cojo todo los jefes de campo de este productor
        users.forEach((user: any) => {
          getProvincesByUserId(user.id, token)
            .then((provinces: any) => {
              // compruebo cuales son las provincias de cada jc
              aux.forEach((pf: any) => {
                // por cada orden de corte
                let enc = provinces.find(
                  (p: any) => p.provinceId === pf.provinceId
                );
                if (enc) {
                  // si encuentra significa que el jf esta asociado a esa provincia
                  // por lo tanto hay que notificarle
                  let not = {
                    type: 2,
                    docId: pf.check.id,
                    personId: user.id,
                  };

                  postUserNotification(not, token)
                    .then((resp: any) => {})
                    .catch((error) => {
                      setModalText(
                        "No se han podido asociar las notificaciones"
                      );
                      setShow(true);
                    });
                }
              });
            })
            .catch((error) => {
              setModalText("No se han podido asociar las notificaciones");
              setShow(true);
            });
        });
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });

    setShowRedirect(true);
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Orden de corte enviada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      // cancelButtonText: "Cancelar",
      customClass: {
        // cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setTimeout(() => (window.location.href = "/ordenes-corte"), 250);
      }
    });
  };
  const openModalQuestion = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres enviar las órdenes de corte a los jefes de campo?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setCutOrderVisible();
      }
    });
  };

  const redirectToHarvestReport = async (id: number) => {
    getHarvestReportIdCutOrderId(id, token)
      .then((response) => {
        history.push(`/parte-recogida/${response.id}`);
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Órdenes de corte</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <div
        className="create-row"
        style={{ marginTop: "1%", marginLeft: "75%" }}
      >
        <Link to="/gestionar-orden-corte/0">
          <button
            type="button"
            className="btn bt-small"
            style={{
              width: "60px !importance",
              backgroundColor: "#cad401",
              color: "white",
              borderRadius: "20px",
            }}
          >
            Nueva orden de corte
          </button>
        </Link>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div
          className="container"
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "20px",
            width: "85%",
            marginLeft: "5%",
            marginTop: "3%",
          }}
        >
          <h5 style={{ textAlign: "center" }}>Órdenes pendientes de envío</h5>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <button
              type="button"
              className="btn bt-small redirect-buttonCO"
              disabled={!checked.length}
              onClick={() => openModalQuestion()}
            >
              Enviar a jefes de campo
            </button>{" "}
          </div>
          {isSentCutOrders.length ? (
            <table className="table" style={{ textAlign: "center" }}>
              <thead className="thead-dark">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">SEMANA</th>
                  <th scope="col">VARIEDAD</th>
                  <th scope="col">FINCA</th>
                  <th scope="col">VOLUMEN TOTAL(Tn)</th>
                  <th scope="col">ORDEN DE CORTE</th>
                </tr>
              </thead>

              <tbody>
                {isSentCutOrders.map((cut: any) => (
                  <tr key={cut.id}>
                    <td>
                      {" "}
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={cut.id}
                          onClick={handleCheck}
                        />
                      </div>
                    </td>
                    <td>{setWeekNumber(cut.createdAt)}</td>

                    <td>{cut.plantationVarieties.variety.name}</td>
                    <td>{cut.plantationVarieties.plantation.name}</td>
                    <td>
                      {treatNumber(
                        cut.cutCalibres.reduce(
                          (a: any, c: any) => a + c.volume,
                          0
                        ) / 1000
                      )}
                    </td>
                    <td
                      style={{
                        color: "#cad401",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push(`/ordenCorte/${cut.id}`)}
                    >
                      {cut.visible === false ? "Ver/Editar" : "Ver"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h5
              style={{
                textAlign: "center",
                marginBottom: 40,
                paddingBottom: 40,
              }}
            >
              "No hay órdenes pendiente de envío"
            </h5>
          )}
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div
          className="container"
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "20px",
            width: "85%",
            marginLeft: "5%",
            marginTop: "3%",
          }}
        >
          {" "}
          <h5 style={{ textAlign: "center" }}>Órdenes enviadas</h5>
          {cutOrders.length > 0 ? (
            <>
              <div className="select-groupCO2">
                <select
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem" }}
                  data-spy="scroll"
                  value={plantation}
                  onChange={searchPlantationHandler}
                >
                  <option>Todas las fincas</option>
                  {plantations.map((pla: any) => (
                    <option key={pla.name} value={pla.name}>
                      {pla.name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem" }}
                  data-spy="scroll"
                  value={variety}
                  onChange={searchVarietyHandler}
                >
                  {varieties.map((va: any) => (
                    <option key={va.varietyName} value={va.varietyName}>
                      {va?.varietyName}
                    </option>
                  ))}
                </select>

                <select
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem" }}
                  data-spy="scroll"
                  value={week}
                  onChange={searchWeekHandler}
                >
                  <option>Todas las semanas</option>
                  {weeks.map((wk: any) => (
                    <option key={wk} value={wk}>
                      {wk}
                    </option>
                  ))}
                </select>

                <button
                  className="button-search"
                  style={{ height: 30 }}
                  onClick={() => clearFilters()}
                >
                  Quitar filtros
                </button>
              </div>
              <table className="table" style={{ textAlign: "center" }}>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">SEMANA</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">FINCA</th>
                    <th scope="col">VOLUMEN TOTAL(Tn)</th>
                    <th scope="col">ORDEN DE CORTE</th>
                    <th scope="col">PARTE DE RECOGIDA</th>
                  </tr>
                </thead>

                <tbody>
                  {cutOrderSearch.slice(0, cutOrderIndex)?.map((cut: any) => (
                    <tr key={cut.id}>
                      <td>{setWeekNumber(cut?.createdAt)}</td>

                      <td>{cut?.plantationVarieties?.variety?.name}</td>
                      <td>{cut?.plantationVarieties?.plantation?.name}</td>
                      <td>
                        {treatNumber(
                          cut.cutCalibres.reduce(
                            (a: any, c: any) => a + c.volume,
                            0
                          ) / 1000
                        )}
                      </td>
                      <td
                        style={{
                          color: "#cad401",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => history.push(`/ordenCorte/${cut.id}`)}
                      >
                        {cut.visible === false ? "Ver/Editar" : "Ver"}
                      </td>
                      <td
                        style={{
                          color: "#cad401",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => redirectToHarvestReport(cut.id)}
                      >
                        {cut.state === 4 ? "Ver" : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {cutOrderSearch.length &&
              cutOrderSearch.length > cutOrderIndex ? (
                <div className="d-flex justify-content-center mb-3 ">
                  <span
                    className="boldFontLink"
                    onClick={() => setCutOrderIndex(cutOrderIndex + 5)}
                  >
                    Ver más
                  </span>
                </div>
              ) : null}
            </>
          ) : (
            <div>
              <div style={{ textAlign: "center", fontSize: 15 }}>
                No hay órdenes de corte anteriores
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CutOrderListView;
