export const filterCompanyNames = (companyArray: any[]) => {
  let companies: string[] = [];
  companyArray.map(({ name }) => {
    companies.push(name);
  });
  return companies;
};

export const filterCompanyNames2 = (companyArray: any[]) => {
  let companies: string[] = [];
  companyArray.map(({ company }) => {
    if (!companies.includes(company)) {
      companies.push(company);
    }
  });
  return companies;
};
