export const checkSeason = () => {
  let now = new Date();
  let name: string = "";

  const i1819 = new Date(2018, 8, 1, 0, 0, 0);
  const f1819 = new Date(2019, 7, 31, 23, 59, 0);

  const i1920 = new Date(2019, 8, 1, 0, 0, 0);
  const f1920 = new Date(2020, 7, 31, 23, 59, 0);

  const i2021 = new Date(2020, 8, 1, 0, 0, 0);
  const f2021 = new Date(2021, 7, 31, 23, 59, 0);

  const i2122 = new Date(2021, 8, 1, 0, 0, 0);
  const f2122 = new Date(2022, 7, 31, 23, 59, 0);

  const i2223 = new Date(2022, 8, 1, 0, 0, 0);
  const f2223 = new Date(2023, 7, 31, 23, 59, 0);

  const i2324 = new Date(2023, 8, 1, 0, 0, 0);
  const f2324 = new Date(2024, 7, 31, 23, 59, 0);

  const i2425 = new Date(2024, 8, 1, 0, 0, 0);
  const f2425 = new Date(2025, 7, 31, 23, 59, 0);

  const i2526 = new Date(2025, 8, 1, 0, 0, 0);
  const f2526 = new Date(2026, 7, 31, 23, 59, 0);

  const i2627 = new Date(2026, 8, 1, 0, 0, 0);
  const f2627 = new Date(2027, 7, 31, 23, 59, 0);

  const i2728 = new Date(2027, 8, 1, 0, 0, 0);
  const f2728 = new Date(2028, 7, 31, 23, 59, 0);

  const i2829 = new Date(2028, 8, 1, 0, 0, 0);
  const f2829 = new Date(2029, 7, 31, 23, 59, 0);

  const i2930 = new Date(2029, 8, 1, 0, 0, 0);
  const f2930 = new Date(2030, 7, 31, 23, 59, 0);

  if (now >= i1819 && now <= f1819) {
    name = "2018/2019";
  } else if (now >= i1920 && now <= f1920) {
    name = "2019/2020";
  } else if (now >= i2021 && now <= f2021) {
    name = "2020/2021";
  } else if (now >= i2122 && now <= f2122) {
    name = "2021/2022";
  } else if (now >= i2223 && now <= f2223) {
    name = "2022/2023";
  } else if (now >= i2324 && now <= f2324) {
    name = "2023/2024";
  } else if (now >= i2425 && now <= f2425) {
    name = "2024/2025";
  } else if (now >= i2526 && now <= f2526) {
    name = "2025/2026";
  } else if (now >= i2627 && now <= f2627) {
    name = "2026/2027";
  } else if (now >= i2728 && now <= f2728) {
    name = "2027/2028";
  } else if (now >= i2829 && now <= f2829) {
    name = "2028/2029";
  } else if (now >= i2930 && now <= f2930) {
    name = "2029/2030";
  }

  return name;
};

export const checkSeasonShort = () => {
  let now = new Date();
  let name: string = "";

  const i1819 = new Date(2018, 8, 1, 0, 0, 0);
  const f1819 = new Date(2019, 7, 31, 23, 59, 0);

  const i1920 = new Date(2019, 8, 1, 0, 0, 0);
  const f1920 = new Date(2020, 7, 31, 23, 59, 0);

  const i2021 = new Date(2020, 8, 1, 0, 0, 0);
  const f2021 = new Date(2021, 7, 31, 23, 59, 0);

  const i2122 = new Date(2021, 8, 1, 0, 0, 0);
  const f2122 = new Date(2022, 7, 31, 23, 59, 0);

  const i2223 = new Date(2022, 8, 1, 0, 0, 0);
  const f2223 = new Date(2023, 7, 31, 23, 59, 0);

  const i2324 = new Date(2023, 8, 1, 0, 0, 0);
  const f2324 = new Date(2024, 7, 31, 23, 59, 0);

  const i2425 = new Date(2024, 8, 1, 0, 0, 0);
  const f2425 = new Date(2025, 7, 31, 23, 59, 0);

  const i2526 = new Date(2025, 8, 1, 0, 0, 0);
  const f2526 = new Date(2026, 7, 31, 23, 59, 0);

  const i2627 = new Date(2026, 8, 1, 0, 0, 0);
  const f2627 = new Date(2027, 7, 31, 23, 59, 0);

  const i2728 = new Date(2027, 8, 1, 0, 0, 0);
  const f2728 = new Date(2028, 7, 31, 23, 59, 0);

  const i2829 = new Date(2028, 8, 1, 0, 0, 0);
  const f2829 = new Date(2029, 7, 31, 23, 59, 0);

  const i2930 = new Date(2029, 8, 1, 0, 0, 0);
  const f2930 = new Date(2030, 7, 31, 23, 59, 0);

  if (now >= i1819 && now <= f1819) {
    name = "18/19";
  } else if (now >= i1920 && now <= f1920) {
    name = "19/20";
  } else if (now >= i2021 && now <= f2021) {
    name = "20/21";
  } else if (now >= i2122 && now <= f2122) {
    name = "21/22";
  } else if (now >= i2223 && now <= f2223) {
    name = "22/23";
  } else if (now >= i2324 && now <= f2324) {
    name = "23/24";
  } else if (now >= i2425 && now <= f2425) {
    name = "24/25";
  } else if (now >= i2526 && now <= f2526) {
    name = "25/26";
  } else if (now >= i2627 && now <= f2627) {
    name = "26/27";
  } else if (now >= i2728 && now <= f2728) {
    name = "27/28";
  } else if (now >= i2829 && now <= f2829) {
    name = "28/29";
  } else if (now >= i2930 && now <= f2930) {
    name = "29/30";
  }
  return name;
};
