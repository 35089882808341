import { TAction } from "../types";
import { IUser } from "../../interfaces/IUser";
import produce from "immer";

const initialState: IUser = {
  userId: null,
  username: "",
  role: "",
  companyId: null,
  officeId: null,
  token: "",
  fullname: "",
};

export default (state = initialState, action: TAction): IUser =>
  produce(state, (draftState) => {
    switch (action.type) {
      case "SET_USER":
        draftState = action.payload;
        return draftState;
      default:
        return state;
    }
  });
