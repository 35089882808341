import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const postWarehouseReport = (waReport: any, token: string) => {
  return Axios.post(`${baseURL}WarehouseReports`, waReport, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postHarvestReportAssociated = (
  warehouseReportId: any,
  id: any,
  token: string
) => {
  return Axios.patch(
    `${baseURL}HarvestReports/${id}`,
    { warehouseReportId },
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const updateWarehouseReport = (id: any, data: any, token: string) => {
  return Axios.put(`${baseURL}WarehouseReports/${id}`, data, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postWarehouseCalibre = (waCalibre: any, token: string) => {
  return Axios.post(`${baseURL}warehouseCalibres`, waCalibre, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getWarehouseReportsByCompanyAndSeason = (
  companyId: any,
  seasonId: number,
  token: string
) => {
  let filter = {
    include: {
      relation: "variety",
    },
    where: {
      companyId: companyId,
      seasonId: seasonId,
    },
    order: "createdAt DESC",
  };

  return Axios.get(
    `${baseURL}WarehouseReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getWarehouseReportsById = (id: any, token: string) => {
  let filter = {
    include: [
      {
        relation: "variety",
        scope: { include: { relation: "product" } },
      },
      {
        relation: "warehouseCalibres",
        scope: { include: { relation: "calibre" } },
      },
      {
        relation: "harvestReports",
        scope: {
          include: [
            {
              relation: "cutOrder",
              scope: {
                include: {
                  relation: "plantationVarieties",
                  scope: {
                    include: {
                      relation: "plantation",
                      scope: { fields: ["name", "id"] },
                    },
                  },
                },
              },
            },
            { relation: "harvestCalibres" },
          ],
        },
      },
    ],
    where: {
      id: id,
    },
    order: "createdAt DESC",
  };

  return Axios.get(
    `${baseURL}WarehouseReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteWarehouseReportById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}WarehouseReports/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteWarehouseCalibreById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}WarehouseCalibres/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
