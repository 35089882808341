import React from "react";
import { Link } from "react-router-dom";
import "../../theme/dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const AdminPlantationTableRow = ({ plt }: any) => {
  return (
    <tr key={plt?.id}>
      <td>
        <Link
          to={`/admin-fincas/${plt.id}`}
          style={{ color: "black", fontWeight: 500 }}
        >
          {plt?.name}
        </Link>
      </td>
      <td>{plt?.company?.name}</td>
      <td>{plt?.owner}</td>
      <td>{plt?.location?.name}</td>
      <td>{plt?.location?.province?.name}</td>
      <td className="ml-5">
        <Link to={`admin-fincas/${plt.id}`} style={{ color: "white" }}>
          <button
            className="btn btn-sm text-light mr-3"
            style={{ backgroundColor: "#cad401" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </Link>
      </td>
    </tr>
  );
};

export default AdminPlantationTableRow;
