import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";

import {
  getVarCalIds,
  getVarietyNameFromVarietyId,
} from "../utils/Requests/VarietyRequests";

import { getCompanyNameById } from "../utils/Requests/CompanyRequest";
import { getOfficeNameById } from "../utils/Requests/OfficeReqs";

import {
  getPurchasesForecastDetailsById,
  getPurchaseForecastWeeksByCompanyId,
  getPurchasesForecastByWeekAndCompanyId,
  getLastPurchasesForecastByWeek,
  getPurchaseForecastInfo,
} from "../utils/Requests/PurchaseForecastReq";
import { getCalCodeFromCalId } from "../utils/Requests/CalibreReq";
import {
  constructDataBarChar,
  constructDataRadialChar,
} from "../utils/Auxiliar/PurchasesForecastAux";
import BarChart from "../components/BarChart";
import { IBarChartData } from "../interfaces/IBarCharData";
import RadialCharts from "../components/RadialCharts";
import { IRadialChartData } from "../interfaces/IRadialCharData";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { constructAgribioPurchaseForecastData } from "../utils/Auxiliar/ForecastAuxiliar";
import { useSelector, useDispatch } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import INotification from "../interfaces/INotification";
import { RemoveNotificationAction } from "../redux/actions";
import { deleteNotificationById } from "../utils/Requests/NotificationRequest";
import { IExcelData } from "./SourcingGraphView";
import { EditRounded } from "@material-ui/icons";
import { CSVLink } from "react-csv";

export interface IAddedForecast {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
  week?:number;
}

const AgribioPurchaseForecast: React.FC = () => {
  const [rawData, setRawData] = useState<IAddedForecast[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [barData, setBarData] = useState<IBarChartData[]>([]);
  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);
  const [weeks, setWeeks] = useState<any[]>([]);
  const [forecastId, setForecastId] = useState<number>();
  const [pickedVariety, setPickedVariety] = useState<string>("");
  const [pickedWeek, setPickedWeek] = useState<number>();
  const [height, setHeight] = useState(50);
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);
  const dispatch = useDispatch();
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );

  const [excelForecastData, setExcelForecastData] = useState<IAddedForecast[]>([]);

  useEffect(() => {
    if (token) {
      let notsPc: INotification[] | undefined = notifications?.filter(
        (n: INotification) => n.type === 4
      );

      notsPc.forEach((n: INotification) => {
        // Lo elimino de redux
        dispatch(RemoveNotificationAction(n?.id!));
        // eliminarlo de la base de datos
        deleteNotificationById(n?.id!, token)
          .then((not: any) => {})
          .catch((error: string) => {
            console.log(error.toString());
          });
      });
      getData();
    }
  }, [notifications, token, companyId]);

  const getData = async () => {
    const seasonName = checkSeasonShort();
    let { id: seasonId } = await getSeasonByName(seasonName, token);
    console.log(seasonId);
    let weeks = await getPurchaseForecastWeeksByCompanyId(
      companyId!,
      seasonId,
      token
    );
    setWeeks(weeks);

    if (weeks.length) {
      let week = await getLastPurchasesForecastByWeek(
        companyId!,
        token,
        seasonId
      );

      let pickedWeek = week?.week;

      setPickedWeek(pickedWeek!);
      getForecastData(pickedWeek);
    } else {
      let previousSeasonid = seasonId - 1;

      let weeks = await getPurchaseForecastWeeksByCompanyId(
        companyId!,
        previousSeasonid,
        token
      );
      setWeeks(weeks);

      let week = await getLastPurchasesForecastByWeek(
        companyId!,
        token,
        previousSeasonid
      );

      let pickedWeek = week?.week;
      setPickedWeek(pickedWeek!);
      getForecastData(pickedWeek);
    }
  };

  const getForecastData = async (pickedWeek: number) => {
    if(pickedWeek){
    let { id: forecastId } = await getPurchasesForecastByWeekAndCompanyId(
      companyId!,
      pickedWeek!,
      token
    );
    setForecastId(forecastId);
    let forecastsToEdit = await getPurchasesForecastDetailsById(
      forecastId,
      token
    );
    let editedForecasts: IAddedForecast[] = [];
    Promise.all(
      forecastsToEdit.map(async ({ varietyCalibreId, volumen, forecastPurchase}: any) => {
        let { calibreId, varietyId } = await getVarCalIds(
          varietyCalibreId,
          token
        );
        let { name: pickedVariety } = await getVarietyNameFromVarietyId(
          varietyId,
          token
        );
        let { code: pickedCalibre } = await getCalCodeFromCalId(
          calibreId,
          token
        );
        editedForecasts.push({
          pickedVariety,
          pickedCalibre,
          tons: volumen / 1000,
          week:forecastPurchase?.week,
        });
      })
    ).then(() => {
      setRawData(editedForecasts);
      let barData = constructDataBarChar(editedForecasts);
      setBarData(barData);
      setHighestNumber(barData);
      replaceTonsToExport(editedForecasts)

      let filteredData = constructAgribioPurchaseForecastData(editedForecasts);
      setFilteredData(filteredData);
      let radialData = constructDataRadialChar(editedForecasts);
      setRadialData(radialData);
      setPickedVariety(editedForecasts[0].pickedVariety);
    });
  }
  };


  const handlePickedWeek = async (pickedWeek: number) => {
    setPickedWeek(pickedWeek);
    getPurchaseForecastInfo(companyId!, pickedWeek!, token).then((forecast) => {
      let editedForecasts: IAddedForecast[] = [];
      forecast?.purchaseVarietyCalibres.map((f: any) => {
        editedForecasts.push({
          pickedVariety: f.varietyCalibre.variety.name,
          pickedCalibre: f.varietyCalibre.calibre.code,
          tons: f.volumen / 1000,
        });
      });
      setRawData(editedForecasts);
      let barData = constructDataBarChar(editedForecasts);
      setBarData(barData);

      getDataToExport(editedForecasts, pickedWeek)

      let filteredData = constructAgribioPurchaseForecastData(editedForecasts);
      setFilteredData(filteredData);
      let radialData = constructDataRadialChar(editedForecasts);
      setRadialData(radialData);
      setPickedVariety(editedForecasts[0]?.pickedVariety);
    });
  };

  const getDataToExport = (editedForecasts:IAddedForecast[], pickedWeek:number) => {
    editedForecasts.map(({tons}:any, index:number)=>(
      editedForecasts[index].week = pickedWeek
    ))
    replaceTonsToExport(editedForecasts)
  }

  const handlePickedVariety = (variety: string) => {
    setPickedVariety(variety);

    let newRadialData = constructDataRadialChar(rawData, variety);
    setRadialData(newRadialData);
  };

  const splitCode = (code: string) => {
    let num = code.match(/\d+/g);

    let letr = code.match(/[a-zA-Z]+/g);

    if (letr !== null && num !== null) {
      let newLetr = ",Sin Calibrar";
      let result = num!.toString();
      let char = result.concat(newLetr);
      return char;
    } else if (letr === null && num !== null) {
      let result = num[0].split("");
      let char = result.toString();
      return char;
    } else {
      let char = "Sin calibrar";
      return char;
    }
  };

  const colorCondition = (variety: string) => {
    if (variety === pickedVariety) return true;
    else {
      return false;
    }
  };

  const setHighestNumber = (array: IBarChartData[]) => {
    let highestNumber = 0;

    for (var i = 0; i < array.length; i++) {
      if (highestNumber < array[i].y) {
        highestNumber = array[i].y;
      }
    }

    setHeight(highestNumber * 1.1);
  };

  const replaceTonsToExport =(editedForecast:IAddedForecast[]) => {
    let excelFerecastData: IAddedForecast[] =[];
    editedForecast.map(({pickedVariety,pickedCalibre,tons,week}:any)=>(
      excelFerecastData.push({
        pickedVariety,
        pickedCalibre,
        tons:tons.toLocaleString(),
        week
      })
    ))
    setExcelForecastData(excelFerecastData)
  }

   //Excel headers
   const forecastHeaders = [
    { label: "Semana", key: "week" },
    { label: "Variedad", key: "pickedVariety" },
    { label: "Calibre", key: "pickedCalibre" },
    { label: "Toneladas", key: "tons" },
  ];

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span>Previsiones de compras</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div  style={{
          width: "3%",
          marginLeft: "77%",
          marginTop: "1%",
        }}>
      
          <div style={{ marginBottom: "2%" }}>
            <CSVLink
              data={excelForecastData}
              headers={forecastHeaders}
              filename={`disponibilidad-semana${pickedWeek}.csv`}
            >
              <button className="btn btn-md text-light export-button">
                Exportar a excel
              </button>
            </CSVLink>
          </div>
       
      </div>
      <div className="cointainer">
        <div className="row">
          <div className="col-4" style={{ marginLeft: "5%" }}>
            <select
              className="form-control form-control-sm mt-2  filterWeekInput"
              style={{ width: "12rem" }}
              data-spy="scroll"
              value={pickedWeek}
              onChange={(e) => handlePickedWeek(+e.target.value)}
            >
              {weeks.map((wk: any) => (
                <option key={wk} value={wk}>
                  {wk}
                </option>
              ))}
            </select>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
      <div
        style={{
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container pl-5">
          <div className="row mb-3 mt-2">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <BarChart
              data={barData}
              data2={false}
              height={[0, height]}
              width={900}
              type={"Tn"}
            />
          </div>
          <div className="row justify-content-center">
            <span style={{ fontWeight: "bold" }}>Variedades</span>
          </div>
          <div className="row" style={{ paddingTop: "2vh" }}></div>
        </div>
      </div>

      <div
        style={{
          minHeight: "180px",
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container pl-3 pt-2">
          <div className="row mb-3 mt-3">
            <div className="col-sm-8 col-12">
              <table className="table text-center smallTable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">SEMANA</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(
                    ({ t: tons, v: variety, c: calibre }, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: colorCondition(variety)
                            ? "#cad401"
                            : "",
                        }}
                        onClick={() => handlePickedVariety(variety)}
                      >
                        <th scope="row">{pickedWeek}</th>
                        <td>{variety}</td>
                        <td>{splitCode(calibre)}</td>
                        <td>{tons.toFixed(2)}</td>
                        <td
                          className="boldFontLink"
                          style={{
                            color: pickedVariety === variety ? "white" : "",
                          }}
                        >
                          {">"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div className="col-sm-2 col-md-2 col-12">
              <h4>Tn/Calibre</h4>
              <RadialCharts data={radialData} />
            </div>
            <div className="col-sm-2 col-md-2 col-12 text-right">
              <h6 className="mb-4 mr-4">
                {pickedVariety ? pickedVariety : barData[0]?.x}
              </h6>

              {radialData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  {label !== "Cualquiera" && <p> Calibre: {angle} </p>}
                  {label === "Cualquiera" && <p> Sin calibrar: {angle} </p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgribioPurchaseForecast;
