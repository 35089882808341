import { getIconUrl, varietySelected } from "./VarietyAuxiliar";
import IPlantationVariety from "../../interfaces/IPlantationVariety";
import { normalizer } from "./EquivalentAuxiliar";

export const searchPlantations = (va: any[], e: string) => {
  let a: any[] = [];
  va.forEach((v: any) => {
    if (v.name.toLowerCase().includes(e.toLowerCase())) {
      a.push(v);
    }
  });

  return a;
};

export const searchPlantationsByGGPlantationAndOwner = (
  va: any[],
  e: string
) => {
  let a: any[] = [];
  va.forEach((v: any) => {
    if (
      normalizer(v.name.toLowerCase()).includes(e.toLowerCase()) ||
      (v.owner &&
        normalizer(v.owner.toLowerCase()).includes(e.toLowerCase())) ||
      (v.ggNumber &&
        v.ggNumber.toString().toLowerCase().includes(e.toLowerCase()))
    ) {
      a.push(v);
    }
  });

  return a;
};

export const searchPlantationsByGGPlantationAndOwner2 = (
  va: any[],
  e: string
) => {
  let a: any[] = [];
  va.forEach((v: any) => {
    if (
      normalizer(v.pls.plantation.name.toLowerCase()).includes(
        e.toLowerCase()
      ) ||
      (v.pls.plantation.owner &&
        normalizer(v.pls.plantation.owner.toLowerCase()).includes(
          e.toLowerCase()
        )) ||
      (v.pls.plantation.ggNumber &&
        v.pls.plantation.ggNumber
          .toString()
          .toLowerCase()
          .includes(e.toLowerCase()))
    ) {
      a.push(v);
    }
  });

  return a;
};

export const searchPlantationVariety = (va: any[], e: string) => {
  let a: any[] = [];
  va.forEach((v: any) => {
    if (v.plantation.name.toLowerCase().includes(e.toLowerCase())) {
      a.push(v);
    }
  });

  return a;
};

export const searchByProvince = (
  value: string,
  variety: string,
  met: string,
  setPlantationsSearch: any,
  plantations: any,
  varieties: any,
  setRutaIcono: any,
  pltVarieties: any
) => {
  if (
    // todas las provincias / todos los climas / todas las variedades
    value === "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);
  } else if (
    // todas las provincias / todos los climas / variedad concreta
    value === "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(variety, pltVarieties, plantations, setPlantationsSearch);
  } else if (
    // todas las provincias / un clima / todas las variedades
    value === "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);

    // Por definir meteorologia
  } else if (
    // todas las provincias / un clima / variedad concreta
    value === "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(variety, pltVarieties, plantations, setPlantationsSearch);
  } else if (
    // provincia concreta / todos los climas / todas las variedades
    value !== "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    );
    setPlantationsSearch(aux);
  } else if (
    // provincia concreta / todos los climas / variedad concreta
    value !== "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(variety, pltVarieties, aux, setPlantationsSearch);
  } else if (
    value !== "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    );
    setPlantationsSearch(aux);
  } else if (
    value !== "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(variety, pltVarieties, aux, setPlantationsSearch);
  }
};

export const createAforoByAnalyses = (varieties: IPlantationVariety[]) => {
  // De cada variedad tengo que sacar el aforo del ultimo analisis si existiera
  let aforo: number = 0;
  varieties.forEach((v: IPlantationVariety) => {
    if (v.priorAnalyses[0]) {
      if (v.priorAnalyses[0].capacity !== null) {
        aforo = aforo + parseFloat(v.priorAnalyses[0].capacity);
      }
    }
  });
  return aforo;
};

export const searchByProvinceTable = (
  value: string,
  variety: string,
  met: string,
  setPlantationsSearch: any,
  plantations: any,
  varieties: any,
  setRutaIcono: any,
  pltVarieties: any,
  setPlantationsTable: any
) => {
  if (
    // todas las provincias / todos los climas / todas las variedades
    value === "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 5));
  } else if (
    // todas las provincias / todos los climas / variedad concreta
    value === "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        variety,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  } else if (
    // todas las provincias / un clima / todas las variedades
    value === "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 5));

    // Por definir meteorologia
  } else if (
    // todas las provincias / un clima / variedad concreta
    value === "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        variety,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  } else if (
    // provincia concreta / todos los climas / todas las variedades
    value !== "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    );
    setPlantationsSearch(aux);
    setPlantationsTable(aux.slice(0, 5));
  } else if (
    // provincia concreta / todos los climas / variedad concreta
    value !== "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(variety, pltVarieties, aux, setPlantationsSearch).slice(
        0,
        5
      )
    );
  } else if (
    value !== "Todas las provincias" &&
    variety === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    );
    setPlantationsSearch(aux);
    setPlantationsTable(aux.slice(0, 5));
  } else if (
    value !== "Todas las provincias" &&
    variety !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === value
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === variety
    );
    if (ax) {
      setRutaIcono(getIconUrl(varieties, ax.productName));
    } else {
      setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    }

    setPlantationsTable(
      varietySelected(variety, pltVarieties, aux, setPlantationsSearch).slice(
        0,
        5
      )
    );
  }
};

export const searchByVariety = (
  value: string,
  province: string,
  met: string,
  setPlantationsSearch: any,
  setRutaIcono: any,
  plantations: any,
  varieties: any,
  pltVarieties: any
) => {
  if (
    // todas las provincias / todos los climas / todas las variedades
    province === "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
  } else if (
    // todas las provincias / todos los climas / variedad concreta
    province === "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(value, pltVarieties, plantations, setPlantationsSearch);
  } else if (
    // todas las provincias / un clima / todas las variedades
    province === "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(plantations);

    // Por definir meteorologia
  } else if (
    // todas las provincias / un clima / variedad concreta
    province === "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(value, pltVarieties, plantations, setPlantationsSearch);
  } else if (
    // provincia concreta / todos los climas / todas las variedades
    province !== "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    );
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(aux);
  } else if (
    // provincia concreta / todos los climas / variedad concreta
    province !== "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(value, pltVarieties, aux, setPlantationsSearch);
  } else if (
    province !== "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    );
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(aux);
  } else if (
    province !== "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    ); // Filtro por provincias
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    varietySelected(value, pltVarieties, aux, setPlantationsSearch);
  }
};

export const searchByVarietyTable = (
  value: string,
  province: string,
  met: string,
  setPlantationsSearch: any,
  setRutaIcono: any,
  plantations: any,
  varieties: any,
  pltVarieties: any,
  setPlantationsTable: any
) => {
  if (
    // todas las provincias / todos los climas / todas las variedades
    province === "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 5));
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
  } else if (
    // todas las provincias / todos los climas / variedad concreta
    province === "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        value,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  } else if (
    // todas las provincias / un clima / todas las variedades
    province === "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 5));
    // Por definir meteorologia
  } else if (
    // todas las provincias / un clima / variedad concreta
    province === "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        value,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  } else if (
    // provincia concreta / todos los climas / todas las variedades
    province !== "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    );
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(aux);
    setPlantationsTable(aux.slice(0, 5));
  } else if (
    // provincia concreta / todos los climas / variedad concreta
    province !== "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met === "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        value,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  } else if (
    province !== "Todas las provincias" &&
    value === "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    // provincia concreta / cualquier variedad / clima concreto
    let aux = plantations.filter(
      (c: any) => c.location.province.name.toString() === province
    );
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(aux);
    setPlantationsTable(aux.slice(0, 5));
  } else if (
    province !== "Todas las provincias" &&
    value !== "Cualquier variedad" &&
    met !== "Cualquier clima"
  ) {
    let ax: any = varieties.find(
      (s: any) => s.varietyName.toString() === value
    );
    setRutaIcono(getIconUrl(varieties, ax.productName));
    setPlantationsTable(
      varietySelected(
        value,
        pltVarieties,
        plantations,
        setPlantationsSearch
      ).slice(0, 5)
    );
  }
};

export const sortPlantationVarieties = (plantationVarieties: []) => {
  let sortedPlantationVarieties = plantationVarieties.map(
    ({ variety: { name } }) => name
  );
  return sortedPlantationVarieties;
};
