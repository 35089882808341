import { setWeekNumber } from "./WeekAuxiliar";

export const calculatePurchaseQuantity = (
  purchases: any,
  setWeek: any,
  setMonth: any,
  setTotal: any
) => {
  let semAnterior = parseInt(setWeekNumber(new Date())) - 1;
  let dias = new Date(
    new Date().getUTCFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate(); // me devuelve los dias que tiene el mes en curso
  let inicioWeek = setWeekNumber(
    new Date(new Date().getMonth() + 1 + "/1/" + new Date().getUTCFullYear())
  );

  let finalWeek = setWeekNumber(
    new Date(
      new Date().getMonth() + 1 + "/" + dias + "/" + new Date().getUTCFullYear()
    )
  );

  let total: number = 0;
  let semana: number = 0;
  let mes: number = 0;
  purchases.forEach((p: any) => {
    if (p.realPurchaseVarCals) {
      p.realPurchaseVarCals.forEach((e: any) => {
        if (p.week === semAnterior) {
          semana = semana + e.volumen;
        }
        if (p.week >= inicioWeek && p.week <= finalWeek) {
          mes = mes + e.volumen;
        }
        total = total + e.volumen;
      });
    }
  });
  setTotal(total);
  setWeek(semana);
  setMonth(mes);
};

export const groupByVariety = (varCals: any, setChart: any) => {
  let variedades: string[] = [];
  varCals.forEach((v: any) => {
    let aux2 = undefined;
    aux2 = variedades.find((vc: any) => v.varietyCalibre.variety.name === vc);
    if (!aux2) {
      variedades.push(v.varietyCalibre.variety.name);
    }
  });
  let vcs: any[] = [];
  let calibres: any[] = [];
  let calibresBarChart: any[] = [];
  let id: number = 1;
  variedades.forEach((va: any) => {
    vcs = varCals.filter((vc: any) => vc.varietyCalibre.variety.name === va);
    // agrupo las ventas por variedad
    let auxCal: any[] = [];
    let total: number = 0;
    vcs.forEach((c: any) => {
      total = total + c.volumen;
      auxCal.push({
        angle: c.volumen,
        label: c.varietyCalibre.calibre.code,
      });
    });
    calibres.push({ id: id, variety: va, total: total, data: auxCal });
    calibresBarChart.push({ x: va, y: total / 1000 });
    id++;
  });
  setChart(calibresBarChart);
  return calibres;
};
