import React, { useState, useEffect } from "react";
import IPlantation from "../interfaces/IPlantation";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { createAforoByAnalyses } from "../utils/Auxiliar/PlantationAuxiliar";
import PlantationsOwnerPopup from "./PlantationsOwnerPopup";
import { getPlantationImagesById } from "../utils/Requests/AttachmentsRequest";
import { useParams } from "react-router-dom";
import { baseURL } from "../utils/constants";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationListItemProps {
  plantation: any;
  token: string;
}

const PlantationInfo: React.FC<PlantationListItemProps> = ({
  plantation,
  token,
}) => {
  const { id }: any = useParams();

  const [show, setShow] = useState<boolean>(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    loadImages();
  }, []);

  const loadImages = async () => {
    let image = await getPlantationImagesById(id, token);
    image ? setImage(baseURL + image.url) : setImage("/images/finca.png");
  };
  return (
    <div className="middle-panel">
      <PlantationsOwnerPopup
        show={show}
        setShow={setShow}
        plantation={plantation}
        token={token}
      />
      <div className="finca-panel">
        <div style={{ marginTop: 20 }}>
          <img
            style={{
              width: "300px",
              height: "200px",
              objectFit: "cover",
            }}
            src={`${image}`}
          />
        </div>
        <div className="datos-finca-panel">
          <div className="item-info">
            <svg
              color="#848484"
              width="15"
              style={{ marginRight: 10, marginBottom: 5 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="map-marked-alt"
              className="svg-inline--fa fa-map-marked-alt fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
              ></path>
            </svg>
            <b>Ubicación: </b>
            <a
              href={`https://maps.google.com/?q=${plantation?.latitude},${plantation?.longitude}`}
              target="_blank"
              style={{
                border: "none",
                background: "white",
                color: "#cad401",
                fontWeight: 500,
              }}
            >
              {plantation?.location.name}, {plantation?.location.province.name}
            </a>
          </div>
          <div className="item-info">
            <svg
              color="#848484"
              width="15"
              style={{ marginRight: 10, marginBottom: 5 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="address-card"
              className="svg-inline--fa fa-address-card fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"
              ></path>
            </svg>
            <b>Agricultor/a:</b>
            <button
              style={{
                border: "none",
                background: "white",
                color: "#cad401",
                fontWeight: 500,
              }}
              onClick={() => setShow(true)}
            >
              {" "}
              {plantation?.owner}
            </button>
          </div>
          {plantation ? (
            <div className="item-info">
              <img
                alt="icono-hectareas"
                width="18"
                height="18"
                style={{ marginRight: 10, marginLeft: -2 }}
                src="/icon/hectarea-icon.png"
              ></img>
              <b>Hectáreas:</b> {plantation.area}
            </div>
          ) : (
            <></>
          )}

          {plantation?.staticAforo ? (
            <div className="item-info">
              <svg
                color="#848484"
                width="15"
                style={{ marginRight: 10, marginBottom: 5 }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="warehouse"
                className="svg-inline--fa fa-warehouse fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                ></path>
              </svg>
              <b>Aforo:</b> {plantation.staticAforo}
              Tn
            </div>
          ) : (
            <></>
          )}

          {plantation?.plantationVarieties ? (
            <div className="item-info">
              <svg
                color="#848484"
                width="15"
                style={{ marginRight: 10, marginBottom: 5 }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="warehouse"
                className="svg-inline--fa fa-warehouse fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                ></path>
              </svg>
              <b>Aforo temporada:</b>{" "}
              {treatNumber(
                createAforoByAnalyses(plantation?.plantationVarieties) / 1000
              )}{" "}
              Tn
            </div>
          ) : (
            <></>
          )}
          <div className="item-info">
            <svg
              color="#848484"
              width="15"
              style={{ marginRight: 10, marginBottom: 5 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="calendar-alt"
              className="svg-inline--fa fa-calendar-alt fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
              ></path>
            </svg>
            <b>Calendario:</b> {plantation?.calendar}
          </div>
        </div>
      </div>
      <div className="certify-panel">
        <div>
          <b>Certificaciones</b>
        </div>
        <div>
          <div className="middle-panel">
            {plantation?.gg === 1 ? (
              <div style={{ textAlign: "center" }}>
                <img
                  alt="certificacion-gg"
                  width="80"
                  height="80"
                  style={{ marginRight: 15 }}
                  src="/certificaciones/GG.png"
                ></img>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: "grey",
                    marginRight: 12,
                  }}
                >
                  {plantation?.ggNumber}
                </div>
              </div>
            ) : (
              <></>
            )}
            {plantation?.biosuisse === 1 ? (
              <div>
                <img
                  alt="certificacion-biosuisse"
                  width="80"
                  height="80"
                  style={{ marginRight: 15 }}
                  src="/certificaciones/Biosuisse.png"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="middle-panel">
            {plantation?.grasp === 1 ? (
              <div>
                <img
                  alt="certificacion--grasp"
                  width="80"
                  height="80"
                  style={{ marginRight: 15 }}
                  src="/certificaciones/GRASP.png"
                ></img>
              </div>
            ) : (
              <></>
            )}
            {plantation?.demeter === 1 ? (
              <div>
                <img
                  alt="certificacion-demeter"
                  width="80"
                  height="80"
                  style={{ marginRight: 15 }}
                  src="/certificaciones/Demeter.png"
                ></img>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="otros-item" style={{ marginTop: 10 }}>
            {plantation?.otros}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantationInfo;
