import React from "react";
import { Link } from "react-router-dom";
import "../theme/dashboard.css";

export default function PlantationTableRow({ plt }) {
  return (
    <tr key={plt.id}>
      <td>
        <Link
          to={`/fincas/${plt.id}`}
          style={{ color: "black", fontWeight: 500 }}
        >
          {plt.name}
        </Link>
      </td>
      <td>{plt.owner}</td>
      <td>{plt.ggNumber}</td>
      <td>{plt.location.name}</td>
      <td>{plt.location.province.name}</td>
      <td>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginLeft: 2, marginRight: -5 }}
          src={plt.meteos[0].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[1].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[2].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[3].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[4].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[5].icon}
        ></img>
        <img
          alt="climate"
          width="32"
          height="32"
          style={{ marginRight: -5 }}
          src={plt.meteos[6].icon}
        ></img>
      </td>
    </tr>
  );
}
