import Axios from "axios";
import { baseURL } from "../constants";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import FileSaver, { saveAs } from 'file-saver';

export const getHarvestReportImagesById = (
  harvestReportId: any,
  token: string
) => {
  let filter = {
    fields: ["id", "name", "url"],

    where: { harvestReportId: harvestReportId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getMultimediaByCompanyIdAndSeasonId = (
  id: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "cutOrder",
        scope: {
          include: [
            {
              relation: "plantationVarieties",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                      include: {
                        relation: "product",
                      },
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId", "locationId"],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        relation: "attachments",
      },
    ],
    where: { and: [{ companyId: id, seasonId: seasonId }] },
    order: "createdAt DESC",
  };

  return Axios.get(
    `${baseURL}HarvestReports?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPriorAnalysesImages = (token: string) => {
  let filter = {
    fields: [
      "id",
      "name",
      "type",
      "url",
      "priorAnalysisId",
      "harvestReportId",
      "qualityReportId",
    ],

    include: [
      {
        relation: "priorAnalysis",
        scope: {
          fields: ["id", "plantationVarietyId", "seasonId"],
          include: [
            {
              relation: "plantationVariety",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId"],
                      include: {
                        relation: "company",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    where: { priorAnalysisId: { neq: "null" } },
    order: "id DESC",
  };

  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getQualityReportImages = (token: string) => {
  let filter = {
    fields: [
      "id",
      "name",
      "url",
      "type",
      "priorAnalysisId",
      "harvestReportId",
      "qualityReportId",
    ],

    include: [
      {
        relation: "qualityReport",
        scope: {
          fields: ["id", "plantationVarietyId", "seasonId"],
          include: [
            {
              relation: "plantationVariety",
              scope: {
                include: [
                  {
                    relation: "variety",
                    scope: {
                      fields: ["name", "id", "productId"],
                    },
                  },
                  {
                    relation: "plantation",
                    scope: {
                      fields: ["name", "id", "companyId"],
                      include: {
                        relation: "company",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    where: { qualityReportId: { neq: "null" } },
    order: "id DESC",
  };

  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getHarvestReportImages = (token: string) => {
  let filter = {
    fields: [
      "id",
      "name",
      "url",
      "type",
      "priorAnalysisId",
      "harvestReportId",
      "qualityReportId",
    ],

    include: [
      {
        relation: "harvestReport",
        scope: {
          include: [
            {
              relation: "cutOrder",
              scope: {
                include: [
                  {
                    relation: "plantationVarieties",
                    scope: {
                      include: [
                        {
                          relation: "variety",
                        },
                        {
                          relation: "plantation",
                        },
                      ],
                    },
                  },
                  {
                    relation: "company",
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    where: { harvestReportId: { neq: "null" } },
    order: "id DESC",
  };

  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postFiles = (formData: any, container: any, token: string) => {
  return Axios.post(
    `${baseURL}attachments/` + `${container}` + `/upload`,
    formData,
    { headers: { "Content-type": "multipart/form-data", Authorization: token } }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getQualityReportImagesById = (
  qualityReportId: any,
  token: string
) => {
  let filter = {
    fields: ["id", "name", "url"],

    where: { qualityReportId: qualityReportId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPriorAnalysiImagesById = (
  priorAnalysisId: any,
  token: string
) => {
  let filter = {
    fields: ["id", "name", "url", "type"],

    where: { priorAnalysisId: priorAnalysisId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getImagesIdByQualityReport = (
  qualityReportId: any,
  token: string
) => {
  let filter = {
    fields: ["id", "qualityReportId"],

    where: { qualityReportId: qualityReportId },
  };
  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const deleteImageById = (id: number, token: string) => {
  return Axios.delete(`${baseURL}attachments/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationImagesById = (plantationId: any, token: string) => {
  let filter = {
    fields: ["url"],

    where: { plantationId: plantationId },
    order: "id DESC",
  };
  return Axios.get(`${baseURL}attachments?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getImageById = (url: any, token: string) => {
 
  return Axios.get(`${baseURL}${url}`, { responseType:'blob',
  
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      console.log(response)
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
     
      }
    })
    .catch((err) => Promise.reject(err));
};

