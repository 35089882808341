import React, { useState, useEffect } from "react";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import "../theme/sourcing.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import {
  postCutOrderReport,
  postCutCalibre,
  getSingleCutOrderById,
  editCutOrder,
  deleteCutCalibre,
} from "../utils/Requests/CutOrderRequest";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import {
  getPlantationsByCompanyId,
  getPlantationIdByName,
} from "../utils/Requests/PlantationRequests";
import IPlantation from "../interfaces/IPlantation";
import { getActiveVarietiesByPlantationId } from "../utils/Requests/PlantationVarietyRequests";
import more from "../icons/more.png";
import DatePicker from "react-datepicker";
import produce from "immer";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  getVarietyIdByName,
  getCalibresByVarietyId,
} from "../utils/Requests/VarietyRequests";
import ICutOrderReport from "../interfaces/ICutOrderReport";
import { useLocation } from "react-router-dom";
import { getCalibreCodes } from "../utils/Auxiliar/CalibreAuxiliar";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import { IStore } from "../interfaces/IStore";
import { getCalibreIdByCode } from "../utils/Requests/CalibreReq";
import ModalPopup from "../components/ModalPopup";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface IAddedCutOrder {
  pickedCalibre: string;
  tons: number;
}

const alertNegative = "No se pueden poner valores negativos.";
const alertZeroCalibre =
  "No se pueden poner cantidad de un calibre con valor 0.";

const ManageCutOrder: React.FC = () => {
  //forecastId = 0. Edit; forecastId !=0. Create
  let { pathname } = useLocation();
  let cutOrderId = +pathname.split("/")[2];

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [plantations, setPlantations] = useState<IPlantation[]>([]);

  const [plantation, setPlantation] = useState<any>("Finca");
  const [variety, setVariety] = useState<any>("Variedad");
  const [startDate, setStartDate] = useState<any>(null);
  const [isAddingOpen, setIsAddingOpen] = useState(false);
  const [cutOrder, setCutOrder] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [varieties, setVarieties] = useState<any[]>([]);
  const [pickedVariety, setPickedVariety] = useState("");

  const [calibres, setCalibres] = useState<string[]>([]);
  const [pickedCalibre, setPickedCalibre] = useState("Calibre");

  const [tons, setTons] = useState<number>(0);

  const [totalTons, setTotalTons] = useState(0);

  const [addedCutOrder, setAddedCutOrder] = useState<IAddedCutOrder[]>([]);
  const [staticOrders, setStaticOrders] = useState<IAddedCutOrder[]>([]);

  const [cambios, setCambios] = useState<boolean>(false);

  const [mostrar, setMostrar] = useState<boolean>(true);

  const { userId, companyId, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  useEffect(() => {
    const season = checkSeasonShort();

    getSeasonByName(season, token)
      .then((response) => {
        getPlantationsByCompanyId(companyId!, token)
          .then((plantations) => {
            setPlantations(plantations);
          })
          .catch((error) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      })
      .catch((error) => {
        setAlertText(error.toString());
        setShowAlert(true);
      });

    if (Boolean(cutOrderId)) {
      getCutOrderDataToEdit();
    }
  }, [pathname, companyId, token]);

  //edit data
  const getCutOrderDataToEdit = async () => {
    getSingleCutOrderById(cutOrderId, token).then((cutOrderToEdit) => {
      if (cutOrderToEdit.companyId === companyId) {
        setCutOrder(cutOrderToEdit);
        setPlantation(cutOrderToEdit.plantationVarieties.plantation.name);
        setPickedVariety(cutOrderToEdit.plantationVarieties.variety.name!);
        getVarietyIdByName(
          cutOrderToEdit.plantationVarieties.variety.name,
          token
        )
          .then(({ id }) => {
            getCalibresByVarietyId(id, token)
              .then((response) => {
                let calibres = getCalibreCodes(response);
                setCalibres(calibres);
                setStartDate(new Date(cutOrderToEdit.arrive!));
                getVariety(cutOrderToEdit.plantationVarieties.plantation.name);
                let editedClibres: IAddedCutOrder[] = [];
                cutOrderToEdit.cutCalibres.map((c: any) => {
                  editedClibres.push({
                    tons: c.volume / 1000,
                    pickedCalibre: c.calibre.id.toString(),
                  });
                });

                let totalTons = editedClibres.reduce(
                  (a: any, c: any) => a + c.tons,
                  0
                );
                setTotalTons(totalTons);

                setAddedCutOrder(editedClibres);
                setStaticOrders(editedClibres);
              })
              .catch((error: string) => {
                setModalText(error.toString());
                setShow(true);
              });
          })
          .catch((error: string) => {
            setModalText(error.toString());
            setShow(true);
          });
      } else {
        setMostrar(false);
      }
    });
  };

  const searchPlantationHandler = (event: any) => {
    let plantation = event.target.value;
    setPlantation(plantation);
    if (plantation !== "Finca") {
      getVariety(plantation);
      setCalibres([]);
      setTotalTons(0);
      setTons(0);
    } else {
      let variety = "Variedad";
      setVariety(variety);
      setTotalTons(0);
      setTons(0);
    }
  };

  const getVariety = async (plantation: any) => {
    getPlantationIdByName(plantation, token)
      .then((response) => {
        getActiveVarietiesByPlantationId(response.id, token).then(
          (varieties) => {
            let aux = varieties.filter(
              (rv: any) => !rv.variety.name.includes("Hoja")
            );

            setVarieties(
              aux.sort(function (a: any, b: any) {
                if (a.variety.name > b.variety.name) {
                  return 1;
                }
                if (a.variety.name < b.variety.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              })
            );
          }
        );
      })
      .catch((error) => {
        setAlertText(error.toString());
        setShowAlert(true);
      });
  };

  const handleDate = (date: any) => {
    let d: any = new Date(date).toLocaleDateString();

    setStartDate(date);
  };

  const pickVariety = async (event: any) => {
    setAddedCutOrder([]);
    setPickedVariety(event.target.value);
    if (event.target.value !== "Selecciona Variedad") {
      setTons(0);
      setTotalTons(0);
      getVarietyIdByName(event.target.value, token)
        .then(({ id }) => {
          getCalibresByVarietyId(id, token)
            .then((response) => {
              let calibres = getCalibreCodes(response);
              setCalibres(calibres);
            })
            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    } else {
      setCalibres([]);
      setPickedCalibre("");
      setTons(0);
    }
  };

  const pickCalibre = (event: any) => {
    let pickedCalibre = event.target.value;
    setPickedCalibre(pickedCalibre);

    if (event.target.value === "Añadir Calibre") {
      setTons(0);
    }
  };

  const addCutOrder = () => {
    if (tons) {
      if (tons.toString() === "0" || parseFloat(tons.toString()) === 0) {
        setAlertText(alertZeroCalibre);
        setShowAlert(true);
      } else {
        let aux = [
          ...addedCutOrder,
          {
            pickedCalibre,
            tons,
          },
        ];
        setAddedCutOrder([
          ...addedCutOrder,
          {
            pickedCalibre,
            tons,
          },
        ]);

        if (aux === staticOrders) {
          setCambios(false);
        } else {
          setCambios(true);
        }

        let total =
          parseFloat(totalTons.toString()) + parseFloat(tons.toString());
        setTotalTons(total);

        setPickedCalibre("Calibre");
        setTons(0);
      }
    }
  };

  const deleteForecast = (index: number, pickedCalibre: string) => {
    // comprobar si es de las iniciales

    let total =
      parseFloat(totalTons.toString()) -
      parseFloat(addedCutOrder[index].tons.toString());
    setTotalTons(total);
    setAddedCutOrder((state) => {
      const newAddedForecast = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });

      if (newAddedForecast === staticOrders) {
        setCambios(false);
      } else {
        setCambios(true);
      }

      return newAddedForecast;
    });
  };

  const openModalRepetido = () => {
    Swal.fire({
      icon: "warning",
      text: "Existen calibres repetidos, por favor corríjalo.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(() => {});
  };

  const createCutOrder = async () => {
    let repetido: boolean = false;

    addedCutOrder.forEach((af: any) => {
      let aux = addedCutOrder.filter(
        (a: any) => a.pickedCalibre === af.pickedCalibre
      );
      if (aux.length > 1) {
        repetido = true;
      }
    });

    if (!repetido) {
      getSeasonByName(checkSeasonShort(), token).then(async (season: any) => {
        let planVarId = varieties!.find(
          (plt) => pickedVariety?.toString() === plt.variety.name.toString()
        ).id;

        let coReport: ICutOrderReport = {
          state: 1,
          arrive: startDate,
          ownerId: userId!,
          seasonId: season?.id,
          companyId: companyId!,
          plantationVarietyId: planVarId,
        };

        //edit forecast report
        if (Boolean(cutOrderId)) {
          let editedCutOrdertReport = {
            updatedAt: new Date(),
            state: 1,
            arrive: startDate,
            ownerId: userId!,
            seasonId: season?.id,
            companyId: companyId!,
            plantationVarietyId: planVarId,
          };
          editCutOrder(editedCutOrdertReport, cutOrderId, token);
          deleteCutCalibre(cutOrderId, token).then((result) => {
            if (addedCutOrder.length) {
              addedCutOrder.forEach(async (c: any) => {
                let { id: calibreId } = await getCalibreIdByCode(
                  c.pickedCalibre,
                  token
                );

                let CutCalibreReport = {
                  volume: (c.tons * 1000).toFixed(2),
                  cutOrderId,
                  calibreId,
                };

                postCutCalibre(CutCalibreReport, token).then((response) => {
                  openModalThanks();
                });
              });
            }
          });
        } else {
          let { id } = await postCutOrderReport(coReport, token);
          cutOrderId = id;

          if (addedCutOrder.length) {
            addedCutOrder.forEach(async (c: any) => {
              let { id: calibreId } = await getCalibreIdByCode(
                c.pickedCalibre,
                token
              );

              let CutCalibreReport = {
                volume: (c.tons * 1000).toFixed(2),
                cutOrderId,
                calibreId,
              };

              postCutCalibre(CutCalibreReport, token).then((response) => {
                openModalThanks();
              });
            });
          }
        }
      });
    } else {
      openModalRepetido();
    }
  };

  const openModal = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres finalizar la orden de corte?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        createCutOrder();
      }
    });
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Orden de corte creada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "finishButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setTimeout(() => history.push("/ordenes-corte"), 250);
      }
    });
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setAlertText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShowAlert(true);
      setTons(0);
    } else {
      setTons(event.target.value);
    }
  };

  return (
    <div className="div-principal2">
      <ModalPopup
        show={showAlert}
        setShow={setShowAlert}
        modalText={alertText}
      />
      <div className="top-panel">
        <div className="top-panel-text">Órdenes de Corte</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {mostrar ? (
        <>
          <div
            style={{
              width: "650px",
              height: "270px",
              backgroundColor: "white",
              marginLeft: "10%",
              marginTop: "3%",
            }}
          >
            {Boolean(!cutOrder) ? (
              <h5 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                Nueva orden de corte
              </h5>
            ) : (
              <h5 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                Editar orden de corte
              </h5>
            )}
            <div className="create-order1">
              <select
                className="form-control form-control-sm ml-3"
                style={{ width: "12rem" }}
                data-spy="scroll"
                value={plantation}
                disabled={Boolean(cutOrder)}
                onChange={searchPlantationHandler}
              >
                <option>Finca</option>
                {plantations.map((pla: any) => (
                  <option key={pla.name} value={pla.name}>
                    {pla.name}
                  </option>
                ))}
              </select>

              {Boolean(cutOrder) ? (
                <input
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem" }}
                  type="text"
                  disabled
                  readOnly
                  value={pickedVariety}
                />
              ) : (
                <select
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem" }}
                  data-spy="scroll"
                  disabled={plantation === "Finca"}
                  value={pickedVariety}
                  onChange={pickVariety}
                >
                  <option>Variedad</option>
                  {varieties?.map((va: any) => (
                    <option key={va.id} value={va.variety.name}>
                      {va.variety.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="create-order2">
              <div className=" date-pickerCO ml-3">
                <DatePicker
                  showPopperArrow={false}
                  selected={startDate}
                  placeholderText="Fecha y hora requerida en almacén"
                  onChange={(date) => handleDate(date)}
                  isClearable
                  className="form-control form-control-sm "
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <input
                className="form-control form-control-sm ml-3"
                style={{ width: "12rem" }}
                type="text"
                disabled
                readOnly
                value={
                  totalTons
                    ? treatNumber(totalTons) + " Tn"
                    : "Volumen Total Tn"
                }
                placeholder="Tn"
              />
            </div>

            <div className="container addVariety" style={{ marginTop: "3%" }}>
              <div
                className="row mt-2"
                onClick={() => setIsAddingOpen(!isAddingOpen)}
              >
                <img
                  className="ml-2 mt-1"
                  src={more}
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <span className="ml-2">Añadir calibre y volumen</span>
                {pickedVariety === "Variedad" && isAddingOpen == true && (
                  <span className="ml-2 text-danger">
                    (Por favor añada una variedad)
                  </span>
                )}
              </div>
              {isAddingOpen && (
                <div className="row mt-3 pb-2">
                  <select
                    className="form-control form-control-sm ml-3"
                    style={{ width: "12rem" }}
                    data-spy="scroll"
                    value={pickedCalibre}
                    disabled={pickedVariety === "Variedad"}
                    onChange={pickCalibre}
                  >
                    <option>Calibre</option>
                    {calibres.map((calibreCode: any, index: number) => (
                      <option key={index} value={calibreCode}>
                        {calibreCode}
                      </option>
                    ))}
                  </select>

                  <input
                    className="form-control form-control-sm ml-4"
                    style={{ width: "12rem" }}
                    type="number"
                    placeholder="Toneladas"
                    disabled={pickedCalibre === "Calibre"}
                    value={tons === 0 ? "" : tons}
                    onChange={tonHandler}
                    // onChange={handleKg}
                  />

                  <button
                    type="button"
                    className="btn btn-sm text-light ml-3 addButton"
                    disabled={!pickedCalibre || !tons}
                    onClick={addCutOrder}
                  >
                    Añadir
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: "650px",
              minHeight: "100px",
              backgroundColor: "white",
              marginLeft: "10%",
              marginTop: "3%",
            }}
          >
            <div style={{ paddingTop: "3px" }}>
              <table className="table calibre-tableCO">
                <thead className="thead-dark calibre-tableheadCO">
                  <tr>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {addedCutOrder.map(({ pickedCalibre, tons }, index) => (
                    <tr key={index}>
                      <td>{pickedCalibre}</td>
                      <td>{treatNumber(parseFloat(tons.toString()))}</td>
                      <td>
                        <img
                          className="iconsSize"
                          onClick={() => deleteForecast(index, pickedCalibre)}
                          src="/icon/close.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="cointainer">
            <div className="row ">
              <div className="col-9"></div>
              <div className="col-3 d-flex">
                {plantation && pickedVariety && startDate ? (
                  <>
                    {cutOrderId === 0 ? (
                      <>
                        {addedCutOrder.length ? (
                          <>
                            <button
                              type="button"
                              onClick={openModal}
                              className="btn send-buttonCO"
                            >
                              Finalizar
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn disabled-buttonCO"
                              disabled
                            >
                              Finalizar
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {cambios && addedCutOrder.length ? (
                          <>
                            <button
                              type="button"
                              onClick={openModal}
                              className="btn send-buttonCO"
                            >
                              Finalizar
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn disabled-buttonCO"
                              disabled
                            >
                              Finalizar
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Orden de corte privada
        </div>
      )}
    </div>
  );
};

export default ManageCutOrder;
