import React from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";

interface PlantationListItemProps {
  plantation: any;
  setShow: any;
}

const OwnerPlantationTableRow: React.FC<PlantationListItemProps> = ({
  plantation,
  setShow,
}) => {
  return (
    <tr>
      <td>
        <Link
          style={{ fontWeight: 500, color: "#cad401" }}
          to={`/fincas/${plantation.id}`}
          onClick={() => setShow(false)}
        >
          {plantation.name}
        </Link>
      </td>
      <td>{plantation.location.name}</td>
      <td>{plantation.location.province.name}</td>
    </tr>
  );
};

export default OwnerPlantationTableRow;
