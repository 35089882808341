import React, { useEffect, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "../theme/homepage.css";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function MarkerItem(props) {
  // let coords = props.coords || null;

  const [hoy, setHoy] = useState(undefined);
  const [meteo, setMeteo] = useState([]);
  const [size, setSize] = useState([55, 55]);

  function diaSemana(dia, mes, anio) {
    var dias = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    var dt = new Date(mes + " " + dia + ", " + anio + " 12:00:00");
    return dias[dt.getUTCDay()];
  }

  const meses = [
    "Ene",
    "Feb",
    "Mar",
    "Abril",
    "May",
    "Jun",
    "Jul",
    "Agost",
    "Sept",
    "Oct",
    "Nov",
    "Dic",
  ];

  useEffect(() => {
    let h = new Date().toLocaleDateString();

    if (props.rutaIcono !== "/icon/map-varieties/normal-marker.png") {
      setSize([35, 55]);
    } else {
      setSize([55, 55]);
    }
    if (props.plt && props.plt.meteos.length > 0) {
      setMeteo(props.plt.meteos);
      let aux = props.plt.meteos.find((p) => p.dayDate === h);
      if (aux) {
        setHoy({ met: aux, dia: diaSemana(aux.day, aux.month, aux.year) });
      }
    }
  }, [props]);

  let ruta = props.rutaIcono;

  return props.plt !== null ? (
    <Marker
      icon={L.icon({
        iconUrl: ruta,
        iconSize: size, // size of the icon
        iconAnchor: [30, 46], // point of the icon which will correspond to marker's location
        popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
      })}
      position={[props.plt.latitude, props.plt.longitude]}
    >
      <Popup className="popup-map">
        <span className="img-center">
          <div className="plantation-title2">
            {hoy ? (
              <div>
                <div
                  style={{
                    color: "white",
                    fontSize: 10,
                    fontWeight: 450,
                    marginTop: 2,
                    marginBottom: 10,
                  }}
                >
                  {hoy.dia} {hoy.met.day} {meses[hoy.met.month - 1]}.
                </div>

                <div className="popup-white">
                  <div className="subpopup">
                    <div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 15,
                          marginLeft: 10,
                        }}
                      >
                        {Math.round(hoy.met.min)}º/{Math.round(hoy.met.max)}º
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          marginLeft: 10,
                        }}
                      >
                        {hoy.met.mm} mm
                      </div>
                    </div>
                    <div>
                      <img
                        alt="clima-hoy"
                        width="70"
                        height="70"
                        src={hoy.met.icon}
                      ></img>
                    </div>
                  </div>
                  <div style={{ marginRight: 1 }}>
                    <div style={{ marginTop: 15, fontSize: 15 }}>
                      {props.plt.name}
                    </div>
                    <div className="plantation-subtitle3">
                      {props.plt.location.name},{" "}
                      {props.plt.location.province.name}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="dia-container">
              {meteo.length > 1 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[1].day, meteo[1].month, meteo[1].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[1].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[1].min)}º/{Math.round(meteo[1].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[1].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 2 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[2].day, meteo[2].month, meteo[2].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[2].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[1].min)}º/{Math.round(meteo[2].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[2].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 3 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[3].day, meteo[3].month, meteo[3].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[3].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[3].min)}º/{Math.round(meteo[3].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[3].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 4 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[4].day, meteo[4].month, meteo[4].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[4].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[4].min)}º/{Math.round(meteo[4].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[4].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 5 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[5].day, meteo[5].month, meteo[5].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[5].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[5].min)}º/{Math.round(meteo[5].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[5].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
              {meteo.length > 6 ? (
                <div style={{ marginLeft: 10 }}>
                  <div>
                    {diaSemana(meteo[6].day, meteo[6].month, meteo[6].year)}
                  </div>
                  <div>
                    <img
                      alt="clima-hoy"
                      width="35"
                      height="35"
                      src={meteo[6].icon}
                    ></img>
                  </div>
                  <div>
                    {Math.round(meteo[6].min)}º/{Math.round(meteo[6].max)}º
                  </div>
                  <div style={{ fontWeight: "lighter" }}>{meteo[6].mm} mm</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <Button
              as={Link}
              to={`/fincas/${props.plt.id}`}
              className="marker-button"
              type="button"
              style={{
                color: "white",
                paddingTop: 5,
                paddingRight: 10,
                paddingLeft: 10,
                paddingBottom: 5,
                marginBottom: 5,
              }}
            >
              Ver más información
            </Button>
          </div>
        </span>
      </Popup>
    </Marker>
  ) : null;
}
