import React, { useEffect, useState } from "react";

//css
import "../theme/homepage.css";

//req
import { getCompanyNameById } from "../utils/Requests/CompanyRequest";
import {
  getFullAgribioForecast,
  getValCalAvailabilityById,
} from "../utils/Requests/AgribioForecastRequest";

//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import {
  constructTotalTonsFromVarCal,
  sortByWeekAndCompany,
} from "../utils/Auxiliar/SourcingAvailabilityAgribioAux";
import { filterCompanyNames2 } from "../utils/Auxiliar/CompanyAux";

//middleware
import { useHistory, Link } from "react-router-dom";

//interfaces
import { IAgribioAvailability } from "../interfaces/IAgribioAvailability";
import INotification from "../interfaces/INotification";
import { IStore } from "../interfaces/IStore";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";

const SourcingAvailabilityAgribio: React.FC = () => {
  let history = useHistory();

  const [availability, setAvailability] = useState<IAgribioAvailability[]>([]);
  const [availabilityToShow, setAvailabilityToShow] = useState<
    IAgribioAvailability[]
  >([]);
  const [availabilityIndex, setAvailabilityIndex] = useState(8);
  const [companies, setCompanies] = useState<string[]>([]);

  const [pickedCompany, setPickedCompany] = useState("Productor Asignado");
  const [week, setWeek] = useState(0);
  const [error, setError] = useState("");
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );

  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      fetchdata();
    }
  }, [notifications]);

  const fetchdata = async () => {
     //filter by season
    const season = checkSeasonShort();
    const { id: currentSeasonId } = await getSeasonByName(season, token);

    let data: [] = await getFullAgribioForecast(currentSeasonId, token);
    let notsD: INotification[] | undefined = notifications?.filter(
      (n: INotification) => n.type === 5
    );
    let agribioAvailability: IAgribioAvailability[] = [];
    Promise.all(
      data.map(async ({ id, companyId, createdAt }) => {
        let nuevo: boolean = false;
        let aux = notsD?.find((n: INotification) => n.docId === id);
        if (aux) {
          nuevo = true;
        }
        let { name: company } = await getCompanyNameById(companyId, token);
        let week = +setWeekNumber(createdAt);
        let valCalAvailability = await getValCalAvailabilityById(id, token);
        let totalTons = constructTotalTonsFromVarCal(valCalAvailability);
        agribioAvailability.push({
          id,
          week,
          company,
          totalTons,
          nuevo,
        });
      })
    ).then(() => {
      let companies = filterCompanyNames2(agribioAvailability);
      setCompanies(companies);
      setAvailability(agribioAvailability);
      setAvailabilityToShow(agribioAvailability);
    });
  };

  const sortWeek = async ({ key }: any) => {
    if (key === "Enter") {
      setError("");
      if (pickedCompany !== "Productor Asignado") {
        let availabilityByWeekAndCompany = sortByWeekAndCompany(
          availability,
          pickedCompany,
          week
        );
        setAvailabilityToShow(availabilityByWeekAndCompany);
      } else {
        let availabilityByWeek = availability.filter(({ week: anyWeek }) => {
          return anyWeek === week;
        });

        setAvailabilityToShow(availabilityByWeek);
      }
    }
  };

  const sortByCompany = (e: any) => {
    setWeek(0);
    setAvailabilityIndex(availabilityIndex);
    setPickedCompany(e.target.value);
    if (e.target.value !== "Productor Asignado") {
      let availabilityByCompany: IAgribioAvailability[] = availability.filter(
        ({ company }) => {
          return company === e.target.value;
        }
      );
      setAvailabilityToShow(availabilityByCompany);
    } else {
      setAvailabilityIndex(10);
      setAvailabilityToShow(availability);
    }
  };

  const removeFilters = () => {
    setWeek(0);
    setPickedCompany("Productor Asignado");
    setAvailabilityToShow(availability);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">Disponibilidad Agribio</div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div
        className="container"
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "20px",
          width: "85%",
          marginLeft: "5%",
          marginTop: "3%",
          marginBottom: "2%",
        }}
      >
        <div className="row mb-2">
          <div className="col-12 d-flex">
            <select
              className="form-control form-control-sm mr-2"
              style={{ width: "11rem" }}
              data-spy="scroll"
              value={pickedCompany}
              onChange={(e) => sortByCompany(e)}
            >
              <option>Productor Asignado</option>
              {companies.map((company: string, index: number) => (
                <option key={index} value={company}>
                  {company}
                </option>
              ))}
            </select>
            <input
              className="form-control form-control-sm mr-2"
              style={{ width: "11rem", textAlign: "center" }}
              type="number"
              placeholder="Semana"
              value={week === 0 ? "" : week!}
              onChange={(e) => setWeek(Math.abs(+e.target.value))}
              onKeyDown={(e) => sortWeek(e)}
            />
            <button className="button-search" onClick={removeFilters}>
              Quitar filtros
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <table className="table text-center">
              <thead className="thead-dark">
                <tr>
                  <th></th>
                  <th scope="col">SEMANA</th>
                  <th scope="col">PRODUCTOR</th>
                  <th scope="col">VOLÚMEN DISPONIBLE (Tn)</th>
                  <th scope="col">MÁS INFORMACIÓN</th>
                </tr>
              </thead>

              <tbody>
                {availabilityToShow
                  ?.slice(0, availabilityIndex)
                  .map(({ id, week, company, totalTons, nuevo }) => (
                    <tr key={id}>
                      <td>
                        {nuevo ? (
                          <svg
                            color="#15d60f"
                            width="15"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="circle"
                            className="svg-inline--fa fa-circle fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{week}</td>
                      <td>{company}</td>
                      <td>{treatNumber(totalTons / 1000)}</td>
                      <td>
                        <Link
                          to={`/resumen-disponibilidad-agribio/${id}`}
                          className="boldFontLink"
                        >
                          Ver
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {(!availabilityToShow.length || error) && (
              <div className="d-flex justify-content-center mb-3">
                <h5>-No hay disponibilidad para mostrar-</h5>
              </div>
            )}
            {availabilityToShow.length &&
            availabilityToShow.length > availabilityIndex ? (
              <div className="d-flex justify-content-center mb-3 ">
                <span
                  className="boldFontLink"
                  onClick={() => setAvailabilityIndex(availabilityIndex + 5)}
                >
                  Ver más
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcingAvailabilityAgribio;
