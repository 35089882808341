import React, { useState, useEffect } from "react";
import "../../theme/homepage.css";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { getOnlyPlantationsByCompany } from "../../utils/Requests/PlantationRequests";
import { getAllPlantationsVarieties } from "../../utils/Requests/PlantationVarietyRequests";
import MarkerItem from "../../components/MarkerItem";
import { getIconUrl } from "../../utils/Auxiliar/VarietyAuxiliar";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import { getAllCertifications } from "../../utils/Requests/CertificationRequest";
import { filterHandler } from "../../utils/Auxiliar/SearchAuxiliar";
import Form from "react-bootstrap/Form";
import ModalPopup from "../../components/ModalPopup";
import { checkMeteos } from "../../utils/Requests/MeteoRequest";
import { checkCollect } from "../../utils/Auxiliar/CutOrderAuxiliar";
import { checkSeasonShort } from "../../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../../utils/Requests/SeasonRequests";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { getActiveCutOrders } from "../../utils/Requests/CutOrderRequest";
import { getHarvestReportsForProductorHome } from "../../utils/Requests/HarvestReportRequest";
import { treatNumber } from "../../utils/Auxiliar/EquivalentAuxiliar";

const HomeProductorView: React.FC = () => {
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [company, setCompany] = useState<string>("Cualquier productor");
  const [companies, setCompanies] = useState([]);
  const [certificacion, setCertificacion] = useState<string>(
    "Cualquier certificación"
  );
  const [certifications, setCertificacions] = useState([]);
  const center = { lat: 37.925508, lng: -4.762413 };
  const [plantations, setPlantations] = useState([]);
  const [pltVarieties, setPltVarieties] = useState<any[]>([]);
  const [varieties, setVarieties] = useState([]);
  const [plantationsSearch, setPlantationsSearch] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState<string>("Error");
  const [rain, setRain] = useState<string>("Cualquier clima");
  const [collect, setCollect] = useState<string>("Recolección...");
  const [plantationsCollect, setPlantationsCollect] = useState([]);
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [numCo, setNumCo] = useState<number>(0);
  const [numTn, setNumTn] = useState<number>(0);
  const [rutaIcono, setRutaIcono] = useState(
    "/icon/map-varieties/normal-marker.png"
  );

  function sumarDias(fecha: Date, dias: number) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  useEffect(() => {
    if (token && companyId) {
      const season = checkSeasonShort();

      if (token !== undefined) {
        getSeasonByName(season, token)
          .then((response) => {
            getHarvestReportsForProductorHome(
              sumarDias(new Date(), -7),
              response.id,
              token
            )
              .then((reps) => {
                let total = 0;
                reps.forEach((h: any) => {
                  if (setWeekNumber(h.start) === setWeekNumber(new Date())) {
                    total = total + h.volumeEstimated;
                  }
                });
                setNumTn(total / 1000);
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });

            getOnlyPlantationsByCompany(companyId!, token, response.id)
              .then((pls: any) => {
                checkMeteos(pls, token);

                checkCollect(pls, setPlantationsCollect);

                getAllPlantationsVarieties(pls, setPltVarieties, token)
                  .then((rVarieties: any) => {
                    let aux = rVarieties.filter(
                      (rv: any) => !rv.varietyName.includes("Hoja")
                    );

                    aux.sort(function (a: any, b: any) {
                      if (a.varietyName > b.varietyName) {
                        return 1;
                      }
                      if (a.varietyName < b.varietyName) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    });

                    aux.unshift({
                      id: -1,
                      varietyName: "Cualquier variedad",
                      productName: "none",
                    });

                    setVarieties(aux);

                    getAllCertifications(token)
                      .then((ctf: any) => {
                        setCertificacions(ctf);
                        getOnlyPlantationsByCompany(
                          companyId!,
                          token,
                          response.id
                        ).then((plts: any) => {
                          setPlantations(plts);
                          setPlantationsSearch(plts);
                          getActiveCutOrders(token)
                            .then((cuts: any) => {
                              setNumCo(cuts.length);
                            })
                            .catch((error) => {
                              setModalText(error.toString());
                              setShow(true);
                            });
                        });
                      })
                      .catch((error) => {
                        setModalText(error.toString());
                        setShow(true);
                      });
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          })
          .catch((error) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      }
    }
  }, [token]);

  const recolectHandler = (event: any) => {
    setCollect(event.target.value);
    filterHandler(
      company,
      variety,
      certificacion,
      rain,
      event.target.value,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const clearSearch = () => {
    setVariety("Cualquier variedad");
    setRain("Cualquier clima");
    setCertificacion("Cualquier certificación");
    setCompany("Cualquier productor");
    setCollect("Recolección...");
    setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    setPlantationsSearch(plantations);
  };

  const certificationHandler = (event: any) => {
    setCertificacion(event.target.value);
    filterHandler(
      company,
      variety,
      event.target.value,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const varietyHandler = (event: any) => {
    if (event.target.value === "Cualquier variedad") {
      setRutaIcono(getIconUrl(varieties, "Cualquier variedad"));
    }
    setVariety(event.target.value);
    filterHandler(
      company,
      event.target.value,
      certificacion,
      rain,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  const metHandler = (event: any) => {
    setRain(event.target.value);
    filterHandler(
      company,
      variety,
      certificacion,
      event.target.value,
      collect,
      plantations,
      pltVarieties,
      setRutaIcono,
      varieties,
      setPlantationsSearch,
      plantationsCollect
    );
  };

  return (
    <div className="div-principal">
      <div className="top-panel">
        <div className="top-panel-text">Inicio</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      <div className="select-divider-search">
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={variety}
          onChange={varietyHandler}
          className="search-select"
        >
          {varieties.length > 0 ? (
            varieties.map((va: any) => (
              <option key={va.varietyName} value={va.varietyName}>
                {va.varietyName}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 180 }}
          onChange={certificationHandler}
          value={certificacion}
          className="search-select"
        >
          <option>Cualquier certificación</option>
          {certifications.length > 0 ? (
            certifications.map((va: any) => (
              <option key={va.name} value={va.name}>
                {va.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={rain}
          onChange={metHandler}
          className="search-select"
        >
          <option>Cualquier clima</option>
          <option>Lluvia</option>
          <option>No lluvia</option>
          <option>Riesgo de helada</option>
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={collect}
          onChange={recolectHandler}
          className="search-select"
        >
          <option>Recolección...</option>
          <option>Recolectando</option>
          <option>No recolectando</option>
        </Form.Control>
        <button className="button-search" onClick={() => clearSearch()}>
          Quitar filtros
        </button>
      </div>
      <Map style={{ marginTop: 10 }} center={center} zoom={6}>
        <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
        {plantationsSearch.map((plt: any) => (
          <MarkerItem key={plt.id} plt={plt} rutaIcono={rutaIcono} />
        ))}
      </Map>
      <div className="bot-div">
        <button className="item-map">
          <div className="item-map-title">Nº DE FINCAS</div>
          <div className="item-map-subtitle2">PRO</div>
          <div className="item-map-number">{plantations.length}</div>
        </button>
        <button className="item-map">
          <div className="item-map-title">TN CÍTRICOS RECOGIDOS</div>
          <div className="item-map-subtitle">SEMANA ACTUAL</div>
          <div className="item-map-number">{treatNumber(numTn)}</div>
        </button>
        <button className="item-map">
          <div className="item-map-title">Nº DE VARIEDADES</div>
          <div className="item-map-subtitle">ACTIVAS</div>
          <div className="item-map-number">{varieties.length - 1}</div>
        </button>
        <button className="item-map">
          <div className="item-map-title">Nº DE ÓRDENES DE CORTE</div>
          <div className="item-map-subtitle">PENDIENTES DE RECOGIDA</div>
          <div className="item-map-number">{numCo}</div>
        </button>
      </div>
    </div>
  );
};

export default HomeProductorView;
