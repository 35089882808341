import React, { useState, useEffect } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import IVariety from "../../interfaces/IVariety";

//reqs
import { getAllProducts } from "../../utils/Requests/ProductsReq";
import { getCategoryName } from "../../utils/Requests/CategoryReqs";

//aux
import { buildAdminProducts } from "../../utils/Auxiliar/ProductAuxiliar";

interface IDetailedProduct {
  id: number;
  name: string;
  varieties?: IVariety[];
}
export interface IProduct {
  categoryId: number;
  categoryName?: string;
  product: IDetailedProduct[];
}

const AdminProducts: React.FC = () => {
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsToShow, setProductsToShow] = useState<IProduct[]>([]);
  const [numberShow, setNumberShow] = useState<number>(5);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (token) {
      loadProducts();
    }
  }, [token]);

  const loadProducts = () => {
    getAllProducts(token).then(async (response) => {
      let products: IProduct[] = buildAdminProducts(response);
      Promise.all(
        products.map(async (product) => {
          let { name } = await getCategoryName(product.categoryId, token);
          product.categoryName = name;
          return product;
        })
      ).then(() => {
        setProducts(products);
        setProductsToShow(products);
      });
    });
  };

  const nextPage = () => {
    setNumberShow(numberShow + 2);
  };

  const clearSearch = () => {};

  const searchHanlder = ({ target: { value } }: any) => {
    setSearchText(value);
    value = value.toLowerCase();
    value === "" && setProductsToShow(products);
    let filteredProducts = products.filter(({ categoryName, product }: any) => {
      return categoryName.toLowerCase().includes(value);
    });
    setProductsToShow(filteredProducts);
  };

  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: `¿Estás seguro quieres borrar esta compañía de base de datos? ¡No hay vuelta atrás!`,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        // deleteForecastFromDB();
      }
    });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Productos</div>
        <button className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </button>
      </div>
      <div className="container-fluid mt-4">
        <div className="row">
          <Form.Control
            className="search-select ml-4"
            size="sm"
            type="text"
            placeholder="Buscar por categoría"
            value={searchText}
            onChange={searchHanlder}
            style={{ width: 170, height: 31, marginLeft: 1 }}
          />
          {/* //TODO - link to create new finca */}
          <button
            style={{ width: "11rem" }}
            className="button-search mr-4"
            onClick={() => clearSearch()}
          >
            <FontAwesomeIcon icon={faPlus} /> <b>Crear Producto</b>
          </button>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-9">
            <table
              className="table ml-3"
              style={{ textAlign: "center", background: "white" }}
            >
              <thead className="thead-dark">
                <tr>
                  <th scope="col">CATEGORÍA</th>
                  <th scope="col">PRODUCTO</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {productsToShow
                  .slice(0, numberShow)
                  .map(({ categoryName, product }: any) =>
                    product.map(({ name, id }: any, index: number) => (
                      <tr key={index}>
                        <td>{categoryName}</td>
                        <td>{name}</td>
                        <td>
                          <Link
                            to={`admin-productos/${id}`}
                            style={{ color: "white" }}
                          >
                            <button
                              className="btn btn-sm text-light mr-3"
                              style={{ backgroundColor: "#cad401" }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </Link>

                          <button
                            className="btn btn-sm bg-danger text-light"
                            style={{ borderRadius: "13px" }}
                            onClick={openModalDelete}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </div>
          <div className="col-1" />
          <div className="col-2" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              {products.length > numberShow && (
                <div className="more-button-align">
                  <button
                    className="more-button"
                    onClick={() => setNumberShow(numberShow + 2)}
                  >
                    Ver más
                  </button>
                </div>
              )}
            </div>

            <div className="col-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProducts;
