import React, { useState, useEffect } from "react";

//middleware
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";

//reqs
import { getAllPlantationsForAdmin } from "../../utils/Requests/PlantationRequests";

//aux
import { setWeekNumber } from "../../utils/Auxiliar/WeekAuxiliar";

//components
import AdminPlantationTableRow from "../../components/Admin/AdminPlantationTableRow";
import { normalizer } from "../../utils/Auxiliar/EquivalentAuxiliar";

const AdminPlantation: React.FC = () => {
  const [plantations, setPlantations] = useState<any[]>([]);
  const [plantationsSearch, setPlantationsSearch] = useState<any[]>([]);

  const [numberShow, setNumberShow] = useState<number>(8);
  const [searchText, setSearchText] = useState<string>("");
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      loadPlantations();
    }
  }, [token]);

  const loadPlantations = async () => {
    const plantations = await getAllPlantationsForAdmin(token);
    setPlantationsSearch(plantations);
    setPlantations(plantations);
  };

  const searchHanlder = ({ target: { value } }: any) => {
    setSearchText(value);
    value = value.toLowerCase();
    value === "" && setPlantationsSearch(plantations);
    let filteredPlantations = plantations.filter(
      ({ company, name, owner, location }: any) => {
        return (
          normalizer(company?.name?.toLowerCase()).includes(
            normalizer(value)
          ) ||
          normalizer(name?.toLowerCase()).includes(normalizer(value)) ||
          normalizer(owner?.toLowerCase()).includes(normalizer(value)) ||
          normalizer(location?.province?.name?.toLowerCase()).includes(
            normalizer(value)
          ) ||
          normalizer(location?.name?.toLowerCase()).includes(normalizer(value))
        );
      }
    );
    setPlantationsSearch(filteredPlantations);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Fincas {`(${plantations.length})`}</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <div className="select-divider-search d-flex justify-content-start mt-4">
        <Form.Control
          className="search-select ml-3 mr-3"
          size="sm"
          type="text"
          placeholder="Buscar finca"
          value={searchText}
          onChange={searchHanlder}
          style={{ width: 170, height: 31, marginLeft: 1 }}
        />
        <Link to={`/admin-fincas/0`}>
          <button
            className="button-search"
            style={{ width: "11vw", height: "4vh" }}
          >
            <FontAwesomeIcon icon={faPlus} /> <b>Crear Finca</b>
          </button>
        </Link>
      </div>

      <div className="table-style">
        <table
          className="table"
          style={{ textAlign: "center", background: "white" }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">NOMBRE FINCA</th>
              <th scope="col">COMPAÑÍA</th>
              <th scope="col">AGRICULTOR</th>
              <th scope="col">MUNICIPIO</th>
              <th scope="col">PROVINCIA</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {plantationsSearch.slice(0, numberShow).map((plt: any) => (
              <AdminPlantationTableRow key={plt.id} plt={plt} />
            ))}
          </tbody>
        </table>
        {plantationsSearch.length > numberShow && (
          <div className="more-button-align">
            <button
              className="more-button"
              onClick={() => setNumberShow(numberShow + 10)}
            >
              Ver más
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPlantation;
