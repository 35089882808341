import React, { useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import {
  equivalentCondition,
  treatNumber,
} from "../utils/Auxiliar/EquivalentAuxiliar";
import ModalCalibrePopup from "./ModalCalibrePopup";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { baseURL } from "../utils/constants";
import { getPriorAnalysiImagesById } from "../utils/Requests/AttachmentsRequest";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

interface PlantationListItemProps {
  analysis: any;
}

const AnalysisTableRow: React.FC<PlantationListItemProps> = ({ analysis }) => {
  const [showCalibres, setShowCalibres] = useState<boolean>(false);

  const [modalText, setModalText] = useState<string>("Error");
  const [show, setShow] = useState<boolean>(false);
  const [showImages, setShowImages] = useState<boolean>(false);
  const [images, setImages] = useState<any[]>([]);
  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const showReportImages = async (id: number) => {
    getPriorAnalysiImagesById(id, token)
      .then((multimedia) => {
        const images = multimedia.filter(({ type }: any) =>
          type.includes("image")
        );
        setImages(images);
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });

    setShowImages(true);
  };

  return (
    <>
      <Modal
        show={showImages}
        onHide={() => {
          setShowImages(false);
        }}
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Carousel>
            {images.map(({ id, url }: any) => (
              <Carousel.Item key={id}>
                <div
                  className="image-carousel"
                  style={{
                    backgroundImage: `url(${baseURL}${url})`,
                  }}
                ></div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      <tr key={analysis.id} style={{ fontSize: 14 }}>
        <ModalCalibrePopup
          show={showCalibres}
          setShow={setShowCalibres}
          calibres={analysis.analysisCalibres}
        />
        <td>
          {new Date(analysis.createdAt).toLocaleDateString()}{" "}
          {new Date(analysis.createdAt).toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>{analysis.owner}</td>
        {analysis.condition ? (
          <td>{equivalentCondition(analysis.condition)}</td>
        ) : (
          <td></td>
        )}
        {analysis.capacity ? (
          <td>{treatNumber(analysis.capacity / 1000)} Tn</td>
        ) : (
          <td></td>
        )}

        {analysis.colouring ? <td>{analysis.colouring}</td> : <td></td>}
        {analysis.brix ? (
          <td>{treatNumber(parseFloat(analysis.brix))}</td>
        ) : (
          <td></td>
        )}

        <td
          style={{ color: "#cad401", fontWeight: "bold", cursor: "pointer" }}
          onClick={() => {
            showReportImages(analysis.id);
          }}
        >
          {"Ver"}
        </td>

        {analysis.analysisCalibres.length > 0 ? (
          <td>
            <button
              style={{ border: "none", background: "white" }}
              onClick={() => setShowCalibres(true)}
            >
              <b style={{ color: "#cad401" }}>Ver</b>
            </button>
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    </>
  );
};

export default AnalysisTableRow;
