import { baseURL } from "../constants";
import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const getAllProducts = (token: any) => {
  return Axios.get(`${baseURL}Products`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getProduct = (id: number, token: any) => {
  return Axios.get(`${baseURL}Products/${id}`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getProductVarieties = (id: number, token: any) => {
  return Axios.get(`${baseURL}Products/${id}/varieties`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getProductCategories = (token: string) => {
  return Axios.get(`${baseURL}Categories`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editProduct = (info: any, id: number, token: string) => {
  return Axios.patch(`${baseURL}Products/${id}`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const postNewCategory = (name: string, token: any) => {
  let data = {
    name: name,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  return Axios.post(`${baseURL}Categories`, data, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
