import { setWeekNumber } from "./WeekAuxiliar";
import { IRadialChartData } from "../../interfaces/IRadialCharData";
import { colorGama } from "../constants";

export const searchCutOrder = (
  plantation: string,
  variety: any,
  week: string,
  setCutOrderSearch: any,
  cutOrders: any
) => {
  if (
    // todas las fincas / todos las variedades / todas las semanas
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    setCutOrderSearch(cutOrders);
  } else if (
    // finca concreta / todos las variedades / todas las semanas
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) =>
        c.plantationVarieties.plantation.name.toString() === plantation
    );

    setCutOrderSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / todas las semanas
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) => c.plantationVarieties.variety.name.toString() === variety
    );
    setCutOrderSearch(aux);
  } else if (
    // todas las fincas / todas las variedades / semana concreta
    plantation === "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) => setWeekNumber(c.createdAt).toString() === week
    );
    setCutOrderSearch(aux);
  } else if (
    // finca concreta / variedad concreta / todas las semanas
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week === "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) =>
        c.plantationVarieties.plantation.name.toString() === plantation &&
        c.plantationVarieties.variety.name.toString() === variety
    );

    setCutOrderSearch(aux);
  } else if (
    // finca concreta / todas las variedades / semana concreta
    plantation !== "Todas las fincas" &&
    variety === "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) =>
        c.plantationVarieties.plantation.name.toString() === plantation &&
        setWeekNumber(c.createdAt).toString() === week
    );

    setCutOrderSearch(aux);
  } else if (
    // todas las fincas / variedad concreta / semana concreta
    plantation === "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    // provincia concreta / cualquier variedad / clima concreto
    let aux = cutOrders.filter(
      (c: any) =>
        c.plantationVarieties.variety.name.toString() === variety &&
        setWeekNumber(c.createdAt).toString() === week
    );

    setCutOrderSearch(aux);
  } else if (
    plantation !== "Todas las fincas" &&
    variety !== "Cualquier variedad" &&
    week !== "Todas las semanas"
  ) {
    let aux = cutOrders.filter(
      (c: any) =>
        c.plantationVarieties.plantation.name.toString() === plantation &&
        c.plantationVarieties.variety.name.toString() === variety &&
        setWeekNumber(c.createdAt).toString() === week
    );

    setCutOrderSearch(aux);
  }
};

export const constructReportDataRadialChar = (data: any[]) => {
  let dataByCalibre: IRadialChartData[] = [];

  //if the calibre already exists
  data.map(({ pickedCalibre, tons }, index) => {
    if (dataByCalibre.filter(({ label }) => label === pickedCalibre).length) {
      //add tons to the calibre
      let index = dataByCalibre.findIndex(
        ({ label }) => label === pickedCalibre
      );
      dataByCalibre[index].angle += tons;
    } else {
      dataByCalibre.push({
        angle: tons,
        label: pickedCalibre,
        color: colorGama[index],
      });
    }
  });

  return dataByCalibre;
};

export const checkCollect = (pls: any, setPlantationsCollect: any) => {
  let arr: any = [];
  pls.forEach((p: any) => {
    // para cada finca
    let collect = false;
    if (p.plantationVarieties.length > 0) {
      // Para cada variedad (si una variedad está en recogida el resto da igual)
      p.plantationVarieties.forEach((pv: any) => {
        // recorro las pv
        if (!collect) {
          // si existen ordenes de corte...
          if (pv.cutOrders.length > 0) {
            // tengo que comprobar si el stock no es cero
            if (pv.priorAnalyses.length > 0) {
              // si existen informes previos compruebo su capacidad
              let aforo = 0.0;
              let encontrado = false;
              pv.priorAnalyses.forEach((a: any) => {
                if (!encontrado && a.capacity) {
                  encontrado = true;
                  aforo = parseFloat(a.capacity);
                }
              });
              if (aforo === 0) {
                collect = true;
              } else {
                // si el aforo no es cero compruebo cuanto se ha recogido
                let recogida = 0.0;
                pv.cutOrders.forEach((c: any) => {
                  // para cada orden de corte recojo su informe de recogida
                  c.harvestReports.forEach((h: any) => {
                    // si los hubiera
                    recogida = recogida + h.volumeEstimated;
                  });
                });
                if (aforo > recogida) {
                  collect = true;
                }
              }
            } else {
              collect = true;
            }
          }
          if (collect) {
            arr.push({ plantation: p, collect: collect });
          }
        }
      });
    }
  });
  setPlantationsCollect(arr);
};
