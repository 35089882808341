import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const getCategoryName = (categoryId: number, token: string) => {
  let filter = {
    where: {
      id: categoryId,
    },
  };

  return Axios.get(`${baseURL}Categories?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};
