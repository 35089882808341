import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import { getPlantationByOwnerName } from "../utils/Requests/PlantationRequests";
import OwnerPlantationTableRow from "./OwnerPlantationTableRow";

interface PlantationListItemProps {
  show: boolean;
  setShow: any;
  plantation: any;
  token: string;
}

const PlantationsOwnerPopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  plantation,
  token,
}) => {
  const [plantations, setPlantations] = useState([]);

  useEffect(() => {
    if (plantation) {
      getPlantationByOwnerName(plantation?.owner, plantation?.companyId, token)
        .then((plt: any) => {
          setPlantations(plt);
        })
        .catch((error) => {
          console.log(error.toString());
        });
    }
  }, [plantation]);
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>FINCAS DE {plantation?.owner.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">NOMBRE FINCA</th>
              <th scope="col">MUNICIPIO</th>
              <th scope="col">PROVINCIA</th>
            </tr>
          </thead>
          <tbody>
            {plantations?.map((plant: any) => (
              <OwnerPlantationTableRow
                key={plant.id}
                plantation={plant}
                setShow={setShow}
              />
            ))}
          </tbody>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlantationsOwnerPopup;
