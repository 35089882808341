import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import {
  equivalentColouring,
  equivalentCondition,
} from "../utils/Auxiliar/EquivalentAuxiliar";
import conditions from "../utils/statics/condition.json";

interface PlantationListItemProps {
  show: boolean;
  setShow: any;
  condition: any;
  analysis: any;
  currentVariety: any;
}

const ModalConditionPopup: React.FC<PlantationListItemProps> = ({
  show,
  setShow,
  condition,
  analysis,
  currentVariety,
}) => {
  const [cond, setCond] = useState<string>("");
  useEffect(() => {
    if (condition) {
      setCond(equivalentCondition(condition));
    }
  }, [currentVariety, condition]);
  return (
    <Modal
      style={{ textAlign: "center" }}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Estado del producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: 20, textAlign: "left" }}>
          <div
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Categoría I:
          </div>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            Buena calidad con alguna ligera malformación o defecto de piel que
            no afecte a la pulpa.
          </div>
          <div
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Categoría II:
          </div>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            Calidad aceptable pero con malformaciones o defectos de piel que no
            afecten a la pulpa.
          </div>
        </div>
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
          }}
        >
          <thead className="thead-dark">
            <tr>
              <th scope="col">Estado</th>
              <th scope="col">% Categoría I</th>
              <th scope="col">% Categoría II</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Muy bueno</td>
              <td>90% I</td>
              <td>10% II</td>
            </tr>
            <tr>
              <td>Bueno</td>
              <td>80% I</td>
              <td>20% II</td>
            </tr>
            <tr>
              <td>Regular</td>
              <td>60% I</td>
              <td>40% II</td>
            </tr>
            <tr>
              <td>Malo</td>
              <td>40% I</td>
              <td>60% II</td>
            </tr>
            <tr>
              <td>Muy malo</td>
              <td>20% I</td>
              <td>80% II</td>
            </tr>
          </tbody>
        </table>
        {cond ? (
          <div style={{ marginTop: 10, fontWeight: 450, fontSize: 20 }}>
            Estado: {cond ? cond : ""}
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => setShow(false)}
          className="send-button"
          type="button"
          style={{
            textAlign: "center",
            color: "white",
            paddingTop: 5,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 5,
          }}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConditionPopup;
