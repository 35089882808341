import React, { useState, useEffect } from "react";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import "../theme/warehouse.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import ICutCalibre from "../interfaces/ICutCalibre";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import more from "../icons/more.png";
import ICalibre from "../interfaces/ICalibre";
import { getHarvestReportsForWarehouse } from "../utils/Requests/HarvestReportRequest";
import ModalPopup from "../components/ModalPopup";
import { getAllCalibres } from "../utils/Requests/CalibreReq";
import {
  postHarvestReportAssociated,
  postWarehouseCalibre,
  getWarehouseReportsById,
  updateWarehouseReport,
  deleteWarehouseCalibreById,
} from "../utils/Requests/WarehouseRequest";
import Swal from "sweetalert2";
import { RouteComponentProps } from "react-router-dom";
import ModalHarvestEditPopup from "../components/ModalHaverstEditPopup";
import WarehouseEditCalibreRow from "../components/WarehouseEditCalibreRow";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

const alertNegative = "No se pueden poner valores negativos.";
const alertZeroCalibre =
  "No se pueden poner cantidad de un calibre con valor 0.";

interface IChecked {
  id: any;
  check: boolean;
  report: any;
  total: number;
}

interface PlantationViewProps extends RouteComponentProps<{ id: string }> {}

const EditWarehouseReportView: React.FC<PlantationViewProps> = ({ match }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [harvestReports, setHarvestReports] = useState<any[]>([]);
  const [harvestInit, setHarvestInit] = useState<any[]>([]);
  const [harvestDeleted, setHarvestDeleted] = useState<any[]>([]);
  const [variety, setVariety] = useState<any>("Variedad");
  const [calibresShow, setCalibresShow] = useState<ICalibre[]>([]);
  const [calibresStatic, setCalibresStatic] = useState<ICalibre[]>([]);
  const [calibres, setCalibres] = useState<ICutCalibre[]>([]);
  const [calibresInit, setCalibresInit] = useState<ICutCalibre[]>([]);
  const [calibresDeleted, setCalibresDeleted] = useState<ICutCalibre[]>([]);
  const [calibresAdded, setCalibresAdded] = useState<ICutCalibre[]>([]);
  const [calForm, setCalForm] = useState<string>("Calibre");
  const [kgForm, setKgForm] = useState<number>(0);
  const [isAddingOpen, setIsAddingOpen] = useState(false);
  const [showHarvest, setShowHarvest] = useState<boolean>(false);
  const [checked, setChecked] = useState<IChecked[]>([]);
  const [deleted, setDeleted] = useState<IChecked[]>([]);
  const [estimado, setEstimado] = useState<number>(0);
  const [destrio, setDestrio] = useState<number>(0);
  const [recogido, setRecogido] = useState<number>(0);
  const [destrioInit, setDestrioInit] = useState<number>();
  const [recogidoInit, setRecogidoInit] = useState<number>();
  const [disponible, setDisponible] = useState<number>(0);
  const [report, setReport] = useState<any>();
  const [show, setShow] = useState<boolean>();
  const [modalText, setModalText] = useState<string>();
  const [mostrar, setMostrar] = useState<boolean>(true);

  const { token, companyId } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      getWarehouseReportsById(match.params.id, token)
        .then((va) => {
          if (va.companyId === companyId) {
            getAllCalibres(token)
              .then((cal: any) => {
                setHarvestInit(va.harvestReports);
                setCalibresStatic(cal);
                setVariety(va.variety.name);
                setReport(va);
                setDestrio(va.discard / 1000);
                setRecogido(va.harvestVolume / 1000);
                setDestrioInit(va.discard / 1000);
                setRecogidoInit(va.harvestVolume / 1000);
                let companyId: any = sessionStorage.getItem("companyId");
                const season = checkSeasonShort();

                getSeasonByName(season, token)
                  .then((response) => {
                    getHarvestReportsForWarehouse(
                      companyId,
                      response.id,
                      va.variety.name,
                      token
                    ).then((resp: any) => {
                      setHarvestReports(resp);
                    });
                  })
                  .catch((error) => {
                    setAlertText(error.toString());
                    setShowAlert(true);
                  });
                let est = 0;
                let a: any = [];
                va.harvestReports.forEach((hr: any) => {
                  let check = true;
                  let id = hr.id;
                  let report = hr;
                  let newChecked: any = [...a];

                  let total = 0;
                  if (hr.harvestCalibres.length > 0) {
                    hr.harvestCalibres.forEach((c: any) => {
                      total = total + c.volume;
                    });
                  }
                  est = est + total;
                  a = [{ id, check, report, total }, ...a];
                });
                setEstimado(est);

                setChecked(a);

                let totalCalibres = 0;

                va.warehouseCalibres.forEach((wc: any) => {
                  let c: ICutCalibre = {
                    volume: wc.volumen / 1000,
                    calibre: wc.calibre.code,
                    id: wc.id,
                  };
                  let arrayAux = calibres;
                  arrayAux.push(c);

                  setCalibres(arrayAux);
                  setCalibresInit(arrayAux);
                  cal = cal.filter((c: any) => c.code !== wc.calibre.code);
                  totalCalibres = totalCalibres + wc.volumen / 1000;
                });
                setCalibresShow(cal);
                setDisponible(totalCalibres);
              })
              .catch((error) => {
                setAlertText(error.toString());
                setShowAlert(true);
              });
          } else {
            setMostrar(false);
          }
        })
        .catch((error) => {
          setAlertText(error.toString());
          setShowAlert(true);
        });
    }
  }, [token, companyId]);

  const addCalibre = () => {
    if (kgForm) {
      if (kgForm.toString() === "0" || parseFloat(kgForm.toString()) === 0) {
        setAlertText(alertZeroCalibre);
        setShowAlert(true);
      } else {
        let c: ICutCalibre = {
          volume: kgForm,
          calibre: calForm,
          id: undefined,
        };
        let arrayAux = calibres;
        let arrayAux2 = calibresAdded;
        arrayAux2.push(c);
        setCalibresAdded(arrayAux2);
        arrayAux.push(c);
        setCalibres(arrayAux);
        let aux = calibresShow.filter((c) => c.code !== calForm.toString());
        let total =
          parseFloat(disponible.toString()) + parseFloat(kgForm.toString());

        setDisponible(parseFloat(total.toFixed(2)));
        setDestrio(parseFloat((recogido - total).toFixed(2)));

        setCalibresShow(aux);
        setKgForm(0);

        setCalForm("Calibre");
      }
    }
  };

  const createWarehouseReport = async () => {
    let waReport = {
      discard: destrio ? destrio * 1000 : undefined,
      harvestVolume: recogido ? recogido * 1000 : undefined,
      createdAt: report.creadtedAt,
      updtatedAt: new Date(),
      seasonId: report.seasonId,
      companyId: report.companyId,
      varietyId: report.varietyId,
      week: report.week,
    };

    updateWarehouseReport(report.id, waReport, token).then((result) => {
      calibresDeleted.forEach((c: any) => {
        deleteWarehouseCalibreById(c.id, token)
          .then((res: any) => {})
          .catch((error) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      });

      calibresAdded.forEach((c: any) => {
        let aux = calibresStatic.find(
          (cs: any) => cs.code === c.calibre.toString()
        );
        let cal = {
          volumen: c.volume * 1000,
          calibreId: aux?.id,
          warehouseReportId: report.id,
        };
        postWarehouseCalibre(cal, token)
          .then((cr: any) => {})
          .catch((error) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      });

      harvestDeleted.forEach((hd: any) => {
        postHarvestReportAssociated(null, hd.id, token)
          .then((res: any) => {})
          .catch((error: any) => {
            setModalText(error.toString());
            setShow(true);
          });
      });

      checked.forEach((hd: any) => {
        let aux = harvestInit.find((hi: any) => hd.id === hi.id);
        if (!aux) {
          postHarvestReportAssociated(report.id, hd.id, token)
            .then((res: any) => {})
            .catch((error: any) => {
              setModalText(error.toString());
              setShow(true);
            });
        }
      });

      openModalThanks();
    });
  };

  const recogidoHandler = (event: any) => {
    if (event.target.value <= 0) {
      setAlertText(
        "No se pueden poner valores 0 o negativos para el volumen recogido"
      );
      setShowAlert(true);
      setRecogido(0);
    } else {
      setRecogido(event.target.value);
      setDestrio(event.target.value - disponible);
    }
  };

  const handleCheck = (id: number) => {
    let ax = harvestInit.find((hi: any) => hi.id === id);

    if (ax) {
      let dAux = harvestDeleted;
      dAux.push(ax);
      setHarvestDeleted(dAux);
    }

    let check = false;
    let newChecked: any = [...checked];
    let a: any = [];
    let aux2 = checked.find((c: any) => c.id === id);
    if (aux2) {
      setEstimado(estimado - aux2.total);
    }
    let aux = newChecked.find((hv: any) => parseInt(hv.id) === id);

    a = newChecked.filter((tool: any) => id != tool.id);

    setChecked(a);
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Parte de almacén actualizado correctamente.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/parte-almacen/" + report.id);
      }
    });
  };
  const openModalCreate = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres actualizar el parte de almacén?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        createWarehouseReport();
      }
    });
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setAlertText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShowAlert(true);
      setKgForm(0);
    } else {
      setKgForm(event.target.value);
    }
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Editar parte de almacén</div>

        <ModalPopup
          show={showAlert}
          setShow={setShowAlert}
          modalText={alertText}
        />
        <ModalHarvestEditPopup
          show={showHarvest}
          setShow={setShowHarvest}
          reports={harvestReports}
          checked={checked}
          setChecked={setChecked}
          estimado={estimado}
          setEstimado={setEstimado}
          deleted={deleted}
          setDeleted={setDeleted}
        />
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {mostrar ? (
        <>
          <div
            style={{
              width: "80%",
              backgroundColor: "white",
              marginLeft: "10%",
              marginTop: "3%",
            }}
          >
            <div className="title-warehouse">
              <div
                style={{
                  marginRight: 3,
                  textDecoration: "underline",
                  marginTop: 3.5,
                }}
              >
                Selecciona uno o más partes de recogida
              </div>
              <div style={{ marginTop: 4 }}>
                a los que quieres vincular un parte de almacén
              </div>
              <select
                className="form-control form-control-sm ml-3"
                style={{ width: "8rem" }}
                data-spy="scroll"
                value={variety}
                disabled
              >
                <option>{variety}</option>
              </select>
              {harvestReports.length > 0 ? (
                <button
                  disabled={variety === "Variedad"}
                  className="add-report-button"
                  onClick={() => setShowHarvest(true)}
                >
                  <div className="button-flex-warehouse">
                    <div>
                      <svg
                        style={{ marginTop: 5, marginRight: 12 }}
                        width="22"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="plus-circle"
                        className="svg-inline--fa fa-plus-circle fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <div style={{ marginBottom: -8 }}>Añadir parte de</div>
                      <div>recogida</div>
                    </div>
                  </div>
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="button-flex-warehouse">
              <div>
                <table
                  style={{
                    textAlign: "center",
                    background: "white",
                    marginTop: 20,
                  }}
                  className="table"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">CÓDIGO</th>
                      <th scope="col">SEMANA</th>
                      <th scope="col">VARIEDAD</th>
                      <th scope="col">FINCA</th>
                      <th scope="col">VOLUMEN TOTAL (Tn)</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {checked.map((c: any) => (
                      <tr key={c.id}>
                        <td>AGR-{c.report.id}</td>
                        <td>{setWeekNumber(new Date(c.report.arrive))}</td>
                        <td>{report.variety.name}</td>
                        <td>
                          {
                            c.report.cutOrder.plantationVarieties.plantation
                              .name
                          }
                        </td>
                        <td>{treatNumber(c.total / 1000)}</td>
                        <td>
                          <img
                            className="iconsSize"
                            src="/icon/close.png"
                            onClick={() => handleCheck(c.id)}
                            alt=""
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{ marginLeft: 10, textAlign: "center", marginTop: 14 }}
              >
                <div style={{ fontSize: 14, marginBottom: 4, marginLeft: 12 }}>
                  volumen de recogida estimado
                </div>
                <input
                  className="form-control form-control-sm ml-3"
                  style={{ width: "12rem", textAlign: "center" }}
                  type="text"
                  disabled
                  readOnly
                  value={treatNumber(estimado / 1000) + " " + "Tn"}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "80%",
              minHeight: "100px",
              backgroundColor: "white",
              marginLeft: "10%",
              marginTop: "3%",
            }}
          >
            <div className="button-flex-warehouse">
              <div style={{ paddingTop: "3px" }}>
                <table className="table calibre-tableWA">
                  <thead className="thead-dark calibre-tableheadCO">
                    <tr>
                      <th scope="col">CALIBRE</th>
                      <th scope="col">VOLUMEN (Tn)</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {calibres.map((c: ICutCalibre) => (
                      <WarehouseEditCalibreRow
                        key={c.calibre}
                        cal={c}
                        calibres={calibres}
                        setCalibres={setCalibres}
                        calibresShow={calibresShow}
                        setCalibresShow={setCalibresShow}
                        calibresStatic={calibresStatic}
                        disponible={disponible}
                        setDisponible={setDisponible}
                        calibresInit={calibresInit}
                        calibresDeleted={calibresDeleted}
                        setCalibresDeleted={setCalibresDeleted}
                        calibresAdded={calibresAdded}
                        setCalibresAdded={setCalibresAdded}
                        destrio={destrio}
                        setDestrio={setDestrio}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                <div
                  style={{ marginLeft: 10, textAlign: "center", marginTop: 4 }}
                >
                  <div
                    style={{ fontSize: 14, marginBottom: 4, marginLeft: 12 }}
                  >
                    Volumen real recogido (Tn)
                  </div>
                  <input
                    className="form-control form-control-sm ml-3"
                    style={{ width: "12rem", textAlign: "center" }}
                    type="number"
                    value={recogido}
                    onChange={recogidoHandler}
                  />
                </div>
                <div
                  style={{ marginLeft: 10, textAlign: "center", marginTop: 4 }}
                >
                  <div
                    style={{ fontSize: 14, marginBottom: 4, marginLeft: 12 }}
                  >
                    volumen disponible
                  </div>
                  <input
                    className="form-control form-control-sm ml-3"
                    style={{ width: "12rem", textAlign: "center" }}
                    type="text"
                    disabled
                    readOnly
                    value={treatNumber(disponible) + " " + "Tn"}
                  />
                </div>
                <div
                  style={{ marginLeft: 10, textAlign: "center", marginTop: 4 }}
                >
                  <div
                    style={{ fontSize: 14, marginBottom: 4, marginLeft: 12 }}
                  >
                    Destrio
                  </div>
                  <input
                    className="form-control form-control-sm ml-3"
                    style={{ width: "12rem", textAlign: "center" }}
                    type="text"
                    disabled
                    readOnly
                    value={treatNumber(destrio) + " " + "Tn"}
                  />
                </div>
              </div>
            </div>
            {variety === "Variedad" ? (
              <></>
            ) : (
              <div className="container addVariety" style={{ marginTop: "3%" }}>
                <div
                  className="row mt-2"
                  onClick={() => setIsAddingOpen(!isAddingOpen)}
                >
                  <img
                    className="ml-2 mt-1"
                    src={more}
                    style={{ width: "1.2rem", height: "1.2rem" }}
                  />
                  <span className="ml-2">Añadir calibre y volumen</span>
                  {variety === "Variedad" && isAddingOpen == true && (
                    <span className="ml-2 text-danger">
                      (Por favor añada una variedad)
                    </span>
                  )}
                </div>
                {isAddingOpen && (
                  <div className="row mt-3 pb-2">
                    <select
                      className="form-control form-control-sm ml-3"
                      style={{ width: "12rem" }}
                      data-spy="scroll"
                      disabled={variety === "Variedad"}
                      value={calForm}
                      onChange={(e) => setCalForm(e.target.value)}
                    >
                      <option>Calibre</option>
                      {calibresShow.map((cut: any) => (
                        <option key={`${cut.id}`} value={`${cut.code}`}>
                          {cut.code}
                        </option>
                      ))}
                    </select>

                    <input
                      className="form-control form-control-sm ml-4"
                      style={{ width: "12rem" }}
                      type="number"
                      placeholder="Tn"
                      disabled={variety === "Variedad"}
                      value={kgForm === 0 ? "" : kgForm}
                      onChange={tonHandler}
                      // onChange={handleKg}
                    />

                    <button
                      type="button"
                      className="btn btn-sm text-light ml-3 addButton"
                      disabled={kgForm === 0 || calForm === "Calibre"}
                      onClick={() => addCalibre()}
                    >
                      Añadir
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={{ marginTop: "10px", marginLeft: "60%" }}>
            {recogido &&
            checked.length > 0 &&
            calibres.length > 0 &&
            (calibresDeleted.length > 0 ||
              calibresAdded.length > 0 ||
              harvestDeleted.length > 0 ||
              checked.length - harvestInit.length > 0 ||
              destrioInit !== destrio ||
              recogidoInit !== recogido) ? (
              <button
                type="button"
                onClick={openModalCreate}
                className="btn send-buttonCO"
              >
                Finalizar
              </button>
            ) : (
              <button type="button" className="btn disabled-buttonCO" disabled>
                Finalizar
              </button>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Parte de almacén privado
        </div>
      )}
    </div>
  );
};

export default EditWarehouseReportView;
