export const equivalentCondition = (id: number) => {
  // Transformo un número de color en su texto equivalente
  let valor = "";
  switch (id) {
    case 1:
      valor = "Muy bueno";
      break;
    case 2:
      valor = "Bueno";
      break;
    case 3:
      valor = "Regular";
      break;
    case 4:
      valor = "Malo";
      break;
    case 5:
      valor = "Muy malo";
      break;
    default:
      valor = "Data Error";
  }
  return valor;
};

export const conditionRoute = (id: string) => {
  // consigo la ruta del icono
  let valor = "";
  if (id) {
    switch (id.toString()) {
      case "1":
        valor = "/icon/condition/muybueno-icon.png";
        break;
      case "2":
        valor = "/icon/condition/bueno-icon.png";
        break;
      case "3":
        valor = "/icon/condition/regular-icon.png";
        break;
      case "4":
        valor = "/icon/condition/malo-icon.png";
        break;
      case "5":
        valor = "/icon/condition/muymalo-icon.png";
        break;
      default:
        valor = "";
    }
  }

  return valor;
};

export const equivalentColouring = (items: any, id: any) => {
  // Mando lista e id y me devuelve el nombre
  let res;
  res = items.find((c: any) => c.id.toString() === id.toString());

  return res.name;
};

export const treatNumber = (number: number) => {


  if (number.toString().includes(".")) {
    return number.toFixed(2).toString().replace(/\./g, ",");
  } else {
    return number;
  }
};

export const treatNumber2 = (number: number) => {
  if (number.toString().includes(".")) {
    return number.toString().replace(/\./g, ",");
  } else {
    return number;
  }
};

export var normalizer = (function () {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping: any = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str: string) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join("");
  };
})();
