/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../theme/qualityform.css";
import IPlantation from "../interfaces/IPlantation";
import { getOnlyPlantationsByCompany2 } from "../utils/Requests/PlantationRequests";
import { getVarietiesByPlantationId } from "../utils/Requests/PlantationVarietyRequests";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { postQualityReport } from "../utils/Requests/QualityReportRequests";
import IQualityReport from "../interfaces/IQualityReport";
import Form from "react-bootstrap/Form";
import { Button } from "semantic-ui-react";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { postFiles } from "../utils/Requests/AttachmentsRequest";
import Swal from "sweetalert2";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import { IStore } from "../interfaces/IStore";
import { getSourcingAndQualityUsers } from "../utils/Requests/UserRequests";
import { postUserNotification } from "../utils/Requests/NotificationRequest";

interface IRipeness {
  editBrix?: number;
  editAcidity?: number;
}
const CreateQualityView: React.FC = () => {
  interface IFile {
    file: any;
    index?: number;
    url?: any;
  }

  const zeroBrix = "No se pueden poner números negativos en grado ºBx";
  const zeroOrNegativeZumo =
    "No se pueden poner cero o números negativos en % Zumo";
  const zeroOrNegativeNaoh =
    "No se pueden poner cero o números negativos en NaOH";
  const zeroOrNegativeAcidez =
    "No se pueden poner cero o números negativos en Acidez";
  const zeroOrNegativeMadurez =
    "No se pueden poner cero o números negativos en I. Madurez";
  const [brix, setBrix] = useState<number>();
  const [zumo, setZumo] = useState<number>();
  const [naoh, setNaoh] = useState<number>();
  const [acidez, setAcidez] = useState<number>();
  const [madurez, setMadurez] = useState<number>();
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [plantations, setPlantations] = useState<IPlantation[]>([]);
  const [varieties, setVarieties] = useState<any[]>([]);
  const [plantation, setPlantation] = useState<string>("Seleccione finca");
  const [variety, setVariety] = useState<string>("Seleccione variedad");
  const fileInput = useRef(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesToSend, setFilesToSend] = useState<IFile[]>([]);
  const { companyId, fullname, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  useEffect(() => {
    getOnlyPlantationsByCompany2(companyId!, token)
      .then((plt) => {
        setPlantations(plt);
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });
  }, [token, companyId]);

  const brixHandler = ({ target: { value } }: any) => {
    setBrix(value);
    acidez && calculateRipeness({ editBrix: value });
  };

  const zumoHandler = (event: any) => {
    setZumo(event.target.value);
  };

  const naohHandler = (event: any) => {
    setNaoh(event.target.value);
  };

  // const madurezHandler = (event: any) => {
  //   setMadurez(event.target.value);
  // };

  const acidezHandler = ({ target: { value } }: any) => {
    if (value < 0) {
      setModalText(zeroOrNegativeAcidez);
      setShow(true);
      setAcidez(undefined);
    } else {
      setAcidez(value);
      brix && calculateRipeness({ editAcidity: value });
    }
  };

  const calculateRipeness = ({ editBrix, editAcidity }: IRipeness) => {
    let ripeness = editBrix ? editBrix / acidez! : brix! / editAcidity!;
    setMadurez(+ripeness.toFixed(2));
  };

  const plantationHandler = (event: any) => {
    setPlantation(event.target.value);
    if (event.target.value !== "Seleccione finca") {
      let id = plantations.filter(
        (plt) => plt.name?.toString() === event.target.value.toString()
      )[0].id;
      getVarietiesByPlantationId(id, setVarieties, token)
        .then((response) => {})
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    } else {
      setVarieties([]);
      setVariety("Seleccione variedad");
    }
  };

  const varietyHandler = (event: any) => {
    setVariety(event.target.value);
  };

  const createQualityReport = async () => {
    // aqui se hace todo para crear un informe
    // debo crear las tablas analisis calibre asociadas
    if (brix) {
      if (brix < 0) {
        setModalText(zeroBrix);
        setShow(true);
        setBrix(undefined);
      } else if (zumo) {
        if (zumo <= 0) {
          setModalText(zeroOrNegativeZumo);
          setShow(true);
          setZumo(undefined);
        } else if (naoh) {
          if (naoh <= 0) {
            setModalText(zeroOrNegativeNaoh);
            setShow(true);
            setNaoh(undefined);
          } else if (madurez) {
            if (madurez <= 0) {
              setModalText(zeroOrNegativeMadurez);
              setShow(true);
              setMadurez(undefined);
            } else if (acidez) {
              if (acidez <= 0) {
                setModalText(zeroOrNegativeAcidez);
                setShow(true);
                setAcidez(undefined);
              } else {
                getSeasonByName(checkSeasonShort(), token)
                  .then((season: any) => {
                    let plantationId = varieties.find(
                      (plt) =>
                        plantation?.toString() ===
                          plt.plantation.name?.toString() &&
                        variety?.toString() === plt.variety.name.toString()
                    ).id;
                    let report: IQualityReport = {
                      record: new Date(),
                      juicePercentage: zumo,
                      brix: brix,
                      acidity: acidez,
                      madurity: madurez,
                      sodiumHidroxide: naoh,
                      owner: fullname!,
                      plantationVarietyId: plantationId,
                      seasonId: season.id,
                    };
                    postQualityReport(report, token)
                      .then((qp: any) => {
                        Promise.all(
                          filesToSend.map((file: any, i: any) => {
                            let container = `q${qp.id}`;

                            setTimeout(
                              () => compress(file.file[0], container),
                              500 + i * 500
                            );
                          })
                        )
                          .then((response) => {
                            // aqui debo notificar
                            getSourcingAndQualityUsers(token)
                              .then((re) => {
                                re.forEach((element: any) => {
                                  let not = {
                                    type: 1,
                                    docId: qp.id,
                                    personId: element.id,
                                  };
                                  postUserNotification(not, token)
                                    .then((resp: any) => {})
                                    .catch((error) => {
                                      setModalText(
                                        "No se han podido asociar las notificaciones"
                                      );
                                      setShow(true);
                                    });
                                });
                              })
                              .catch((error) => {
                                setModalText(
                                  "No se han podido asociar las notificaciones"
                                );
                                setShow(true);
                              });
                            setTimeout(() => openModalThanks(qp), 500);
                          })
                          .catch((error) => {
                            setModalText("No se han podido subir las imágenes");
                            setShow(true);
                          });
                      })
                      .catch((error) => {
                        setModalText("No se ha podido crear correctamente.");
                        setShow(true);
                      });
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              }
            }
          }
        }
      }
    }
  };

  const getFileMetadata = (file: any) => {
    return {
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    };
  };

  const handleChange = (e: any) => {
    let file = e.target.files;

    let newFiles: any = [...filesToSend];
    if (e.target.files.length) {
      newFiles = [
        {
          file,
        },
        ...filesToSend,
      ];
    }

    setFilesToSend(newFiles);

    let newstate: any = [...files];

    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      let metadata = getFileMetadata(file);
      let url = URL.createObjectURL(file);
      newstate = [...files, { url, metadata }];
    }

    setFiles(newstate);
  };

  const compress = (e: any, container: any) => {
    // const width = 500;
    // const height = 300;

    const width = 500;

    const fileName = e.name;
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;
      (img.onload = () => {
        const elem = document.createElement("canvas");
        // elem.width = width;
        const scaleFactor = width / img.width;
        elem.width = width;
        elem.height = img.height * scaleFactor;
        // elem.height = height;
        const ctx: any = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        // ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob: any) => {
            const file1 = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            uploadImage(file1, container);
          },
          "image/jpeg",
          1
        );
      }),
        (reader.onerror = (error) => console.log(error));
    };
  };

  const uploadImage = async (file: any, container: any) => {
    const formData = new FormData();
    formData.append("file", file);

    postFiles(formData, container, token);
  };

  const deleteImage = (index: any) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    const newFilesToSend = [...filesToSend];
    newFilesToSend.splice(index, 1);
    setFilesToSend(newFilesToSend);
  };

  const openModalThanks = (pls: any) => {
    Swal.fire({
      icon: "success",
      text: "Informe de calidad creado correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setTimeout(
          () => history.push("/informes-calidad/" + pls.plantationVarietyId),
          1200
        );
      }
    });
  };
  const openModalCreate = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres generar un informe de calidad?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        createQualityReport();
      }
    });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Crear Informe de Calidad Interno</div>

        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alerta</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setShow(false)}
            className="send-button"
            type="button"
            style={{
              color: "white",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="form-panel">
        <div className="campos-panel">
          <div className="subcampos-panel">
            <div className="title-form">*Finca</div>
            <Form.Control
              style={{ width: 300 }}
              as="select"
              value={plantation}
              onChange={plantationHandler}
              className="form-select"
            >
              <option>Seleccione finca</option>
              {plantations.length > 0 ? (
                plantations.map((va: any) => (
                  <option key={va.name} value={va.name}>
                    {va.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
          </div>
          <div className="subcampos-panel">
            <div className="title-form">*Variedad</div>
            <Form.Control
              style={{ width: 301 }}
              as="select"
              value={variety}
              onChange={varietyHandler}
              className="form-select"
            >
              <option>Seleccione variedad</option>
              {varieties.length > 0 ? (
                varieties.map((va: any) => (
                  <option
                    placeholder="no existen variedades..."
                    key={va.variety.name}
                    value={va.variety.name}
                  >
                    {va.variety.name}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Control>
          </div>
        </div>
        <div className="campos-panel">
          <div className="subcampos-panel">
            <div className="title-form">*% Zumo</div>
            <Form.Control
              value={zumo}
              onChange={zumoHandler}
              type="number"
              className="form-input"
              style={{ height: 35, width: 186 }}
            />
          </div>
          <div className="subcampos-panel">
            <div className="title-form">*º Bx</div>
            <Form.Control
              value={brix}
              onChange={brixHandler}
              type="number"
              className="form-input"
              style={{ height: 35, width: 186 }}
            />
          </div>
          <div className="subcampos-panel">
            <div className="title-form-masa">NaOH (ml)</div>
            <Form.Control
              value={naoh}
              onChange={naohHandler}
              type="number"
              className="form-input"
              style={{ height: 35, width: 186 }}
            />
          </div>
        </div>
        <div className="campos-panel">
          <div className="subcampos-panel">
            <div className="title-form">*Acidez</div>
            <Form.Control
              value={acidez}
              onChange={acidezHandler}
              type="number"
              className="form-input"
              style={{ height: 35, width: 186 }}
            />
          </div>
          <div className="subcampos-panel">
            <div className="title-form">I. Madurez</div>
            <Form.Control
              value={madurez}
              disabled
              // onChange={madurezHandler}
              type="number"
              className="form-input"
              style={{ height: 35, width: 186 }}
            />
          </div>
        </div>
        <div className="campos-panel">
          <div className="subcampos-panel">
            <div className="title-form">*Imágenes</div>

            {
              <input
                type="file"
                ref={fileInput}
                id="file-upload"
                name="fileUpload"
                onChange={handleChange}
              />
            }

            <div className="  mt-1" style={{ display: "flex" }}>
              {/* Show Preview Image */}
              {files.map((f: any, index: any) => {
                return (
                  <div key={index}>
                    <img
                      src={f.url}
                      height="50"
                      width="75"
                      style={{ paddingLeft: "10%" }}
                    />
                    <div
                      style={{ justifyContent: "center", marginLeft: "40%" }}
                    >
                      <img
                        className="iconsSize"
                        src="/icon/close.png"
                        alt=""
                        onClick={() => deleteImage(index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="campos-panel">
          <div>Todos los campos son obligatorios</div>
          <div style={{ color: "red" }}> *</div>
        </div> */}
        <div>
          {plantation !== "Seleccione finca" &&
          variety !== "Seleccione variedad" &&
          zumo &&
          brix &&
          acidez &&
          madurez &&
          files.length ? (
            <button onClick={openModalCreate} className="enabled-button">
              Finalizar
            </button>
          ) : (
            <button disabled className="disabled-button">
              Finalizar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateQualityView;
