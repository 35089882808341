import React from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationListItemProps {
  calibre: any;
}

const CalibreTablePopupRow: React.FC<PlantationListItemProps> = ({
  calibre,
}) => {
  return (
    <tr key={calibre.calibres.code}>
      <td>{calibre.calibres.code}</td>
      <td>{treatNumber(calibre.percentage / 1000)}</td>
    </tr>
  );
};

export default CalibreTablePopupRow;
