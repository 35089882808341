import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import "../theme/purchases.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button } from "semantic-ui-react";
import {
  getPurchasesByOffice,
  postPurchaseToSent,
} from "../utils/Requests/PurchaseRequest";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import { IStore } from "../interfaces/IStore";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

// import { searchCutOrder } from "../utils/Auxiliar/CutOrderAuxiliar";

interface IChecked {
  id: any;
  check: boolean;
}

const PurchasesListView: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showFin, setShowFin] = useState<boolean>(false);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [purchasesShow, setPurchasesShow] = useState<any[]>([]);
  const [purchases, setPurchases] = useState<any[]>([]);
  const [numberShow, setNumberShow] = useState<number>(10);
  const [checked, setChecked] = useState<IChecked[]>([]);

  const { officeId, token } = useSelector(({ user }: IStore) => user as IUser);
  useEffect(() => {
    if (token) {
      const season = checkSeasonShort();
      getSeasonByName(season, token)
        .then((season: any) => {
          getPurchasesByOffice(season.id, officeId, token)
            .then((response2) => {
              let aux2: any[] = [];
              response2.forEach((p: any) => {
                let volume = 0;
                p.realPurchaseVarCals.forEach((r: any) => {
                  volume = volume + r.volumen;
                });
                aux2.push({ volumen: volume, purchase: p });
              });
              setPurchases(aux2);
              setPurchasesShow(aux2.slice(0, 10));
            })

            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [token]);

  const nextPage = () => {
    setNumberShow(numberShow + 5);
    setPurchasesShow(purchases.slice(0, numberShow + 5));
  };

  const setCutOrderVisible = async () => {
    setShowFin(false);

    checked.map((c) => {
      if (c.check == true) {
        postPurchaseToSent(c.check, c.id, token).then(() => {
          setShowRedirect(true);
        });
      }
    });
  };
  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Compras reales</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>

      <Modal
        show={showFin}
        onHide={() => {
          setShowFin(false);
        }}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Alerta</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>¿Quieres enviar las compras?</Modal.Body>

        <Modal.Footer className="footer-modal">
          <Button
            onClick={setCutOrderVisible}
            className="send-button"
            type="button"
            style={{
              color: "white",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
            }}
          >
            Aceptar
          </Button>
          <Button
            onClick={() => setShowFin(false)}
            className="send-button"
            type="button"
            style={{
              color: "white",
              width: "70px",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
              backgroundColor: " #707070 ",
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Alerta</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setShow(false)}
            className="send-button"
            type="button"
            style={{
              color: "white",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRedirect}
        onHide={() => {
          setShowRedirect(false);
        }}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Alerta</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Datos enviados correctamente</Modal.Body>

        <Modal.Footer className="footer-modal">
          <Button
            onClick={() =>
              setTimeout(
                () => (window.location.href = "/compras-definitivas"),
                250
              )
            }
            className="send-button"
            type="button"
            style={{
              color: "white",
              paddingTop: 5,
              paddingRight: 10,
              paddingLeft: 10,
              paddingBottom: 5,
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="create-row"
        style={{ marginTop: "10px", marginLeft: "75%" }}
      >
        <Link to="/crear-compra-definitiva">
          <button
            type="button"
            className="btn bt-small"
            style={{
              width: "60px !importance",
              backgroundColor: "#cad401",
              color: "white",
              borderRadius: "20px",
            }}
          >
            <svg
              width="20"
              height="20"
              style={{ marginRight: 10 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="plus-circle"
              className="svg-inline--fa fa-plus-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
              ></path>
            </svg>
            Añadir compra
          </button>
        </Link>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "15px",
            width: "85%",
            marginLeft: "5%",
            textAlign: "center",
          }}
        >
          <table className="table" style={{ textAlign: "center" }}>
            <thead className="thead-dark">
              <tr>
                <th scope="col">SEMANA</th>
                <th scope="col">PRODUCTOR</th>
                <th scope="col">VOLUMEN (Tn)</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {purchasesShow.map((purchase: any) => (
                <tr key={purchase.purchase.id}>
                  <td>{purchase.purchase.week}</td>
                  <td>{purchase.purchase.company.name}</td>
                  <td>{treatNumber(purchase.volumen / 1000)}</td>
                  <td>
                    <Link
                      to={`/resumen-compra/${purchase.purchase.id}`}
                      className="link"
                    >
                      <b style={{ fontSize: 15 }}>Ver/Editar</b>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "15px",
            width: "85%",
            marginLeft: "5%",
            textAlign: "center",
          }}
        ></div>
        <div className="more-button-align" style={{ marginBottom: "20px" }}>
          {numberShow < purchases.length ? (
            <button className="more-button" onClick={() => nextPage()}>
              Ver más
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchasesListView;
