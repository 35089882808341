import React, { useState } from "react";
import "../theme/loginpage.css";
import { myPrivateKey } from "../utils/constants";
import { login, getUserInfo } from "../utils/Requests/LoginRequest";

//middleware
import { sign } from "jsonwebtoken";
import Swal from "sweetalert2";
import history from "../utils/Auxiliar/HistoryAuxiliar";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsByUserId } from "../utils/Requests/NotificationRequest";
import INotification from "../interfaces/INotification";
import { SetUserAction, SetNotificationsAction } from "../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faCheck } from "@fortawesome/free-solid-svg-icons";
import { changePassword } from "../utils/Requests/PasswordRequest";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

interface IProps {
  setToken(token: string): void;
}
const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const { token } = useSelector(({ user }: IStore) => user as IUser);

  const handlePassword = () => {
    setLoading(true);

    if (newPassword !== repeatPassword) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        text: "Las contraseñas no coinciden",
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "finishButton",
        },
      }).then(({ value }) => {
        if (value) {
          setNewPassword("");
          setRepeatPassword("");
        }
      });
    } else {
      changePassword(newPassword, token).then((response) => {
        if (!response.success) {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            text: "Se ha producido un error, vuelve a intentarlo más tarde",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "finishButton",
            },
          }).then(({ value }) => {
            if (value) {
              setNewPassword("");
              setRepeatPassword("");
            }
          });
        } else if (response.success) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: "Contraseña cambiada correctamente",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "finishButton",
            },
          }).then(({ value }) => {
            if (value) {
              setNewPassword("");
              setRepeatPassword("");
              logout();
            }
          });
        }
      });
    }
  };

  const logout = () => {
    setLoading(true);

    sessionStorage.removeItem("token2");
    sessionStorage.removeItem("notifications");
    window.location.href = "/";
  };

  return (
    <div className="div-principal">
      <div className="login-panel">
        <div
          className="logo"
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        >
          <img className="logo" src="icon/logo-agribio.png"></img>
        </div>
        <div className="row">
          <div className="col-sm-4 col-12"></div>
          <div
            className="col-sm-4 col-12"
            style={{ maxWidth: "350px", marginLeft: "55px" }}
          >
            <div>
              <div className="login-form">
                <FontAwesomeIcon icon={faKey} />
                <h3 style={{ marginLeft: "3%" }}>Cambia tu Contraseña</h3>
              </div>
              <div className="form-group mt-4">
                <input
                  className="form-control mr-sm-1"
                  type="password"
                  placeholder="Nueva Contraseña"
                  value={newPassword}
                  onChange={(e) => {
                    setError("");
                    setNewPassword(e.target.value);
                  }}
                  required
                />
              </div>

              <div
                style={{
                  marginTop: "4%",
                  fontSize: "14px",
                  marginLeft: "2%",
                  marginBottom: "5%",
                }}
              >
                {newPassword.length < 4 ? (
                  <span style={{ color: "red" }}>
                    {"Al menos 4 caracteres"}
                  </span>
                ) : (
                  <span style={{ color: "#97b337" }}>
                    {"Al menos 4 caracteres"}
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                )}
              </div>

              <div className="form-group">
                <input
                  className="form-control mr-sm-1"
                  type="password"
                  placeholder="Repetir Contraseña"
                  value={repeatPassword}
                  onChange={(e) => {
                    setError("");
                    setRepeatPassword(e.target.value);
                  }}
                  onKeyDown={({ key }) => key === "Enter" && handlePassword()}
                  required
                />
              </div>
              <span className="text-danger">{error}</span>
              <button
                className="btn passwordButton btn-block mt-3"
                disabled={
                  newPassword.length === 0 || repeatPassword.length === 0
                }
                onClick={handlePassword}
              >
                {!loading ? (
                  "Cambiar Contraseña"
                ) : (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
          <div className="col-sm-4 col-12"></div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
