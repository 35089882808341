import { RadialChart } from "react-vis";
import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import "../theme/purchases.css";
import "leaflet/dist/leaflet.css";
import LegendComponent2 from "./LegendComponent2";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
import { colorGama } from "../utils/constants";

const RadialChartWarehouse = ({ report }) => {
  const [calibres, setCalibres] = useState([]);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    let aux = [];
    if (report) {
      report.warehouseCalibres.forEach((wc) => {
        aux.push({ angle: wc.volumen, label: wc.calibre.code });
      });
      let i = 1;
      let arr2 = [];
      aux.forEach((d) => {
        let laux = {
          color: colorGama[report.warehouseCalibres.length - i],
          value: d.label,
          pct: d.angle,
        };

        arr2.push(laux);
        i++;
      });
      setLegend(arr2);
    }

    setCalibres(aux);
  }, [report]);

  return (
    <>
      {report ? (
        <>
          <div className="graf-panel-purchase" style={{ width: "40%" }}>
            <div style={{ fontSize: 20, color: "#515258", fontWeight: 500 }}>
              Volumen disponible
            </div>
            <div className="graf2-panel-purchase">
              <RadialChart
                colorRange={colorGama}
                colorDomain={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                innerRadius={40}
                radius={70}
                data={calibres}
                width={150}
                height={150}
              />
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {legend.length > 0 ? (
                  legend.map((va) => (
                    <LegendComponent2 key={va.value} comp={va} />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div style={{ textAlign: "left", marginTop: 10 }}>
              {report.harvestVolume ? (
                <div className="button-flex-warehouse">
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "500",
                      color: "#242424",
                      marginLeft: 4,
                    }}
                  >
                    Volumen real recogido:{" "}
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "500",
                      color: "grey",
                      marginLeft: 4,
                    }}
                  >
                    {treatNumber(report?.harvestVolume / 1000)} Tn
                  </div>
                </div>
              ) : (
                <></>
              )}
              {report.discard ? (
                <div className="button-flex-warehouse">
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "500",
                      color: "#242424",
                      marginLeft: 4,
                    }}
                  >
                    Destrío:{" "}
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "500",
                      color: "grey",
                      marginLeft: 4,
                    }}
                  >
                    {treatNumber(report?.discard / 1000)} Tn
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="graf2-panel-purchase" style={{ background: "white" }}>
          <div
            style={{
              fontSize: 18,
              color: "#515258",
              fontWeight: 400,
              background: "white",
              padding: 10,
            }}
          >
            No hay calibres asociados
          </div>
        </div>
      )}
    </>
  );
};

export default RadialChartWarehouse;
