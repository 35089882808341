import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { RouteComponentProps } from "react-router-dom";
import ICutOrder from "../interfaces/ICutOrder";
import {
  getSingleCutOrderById,
  getSingleCutOrderCalibreById,
  deleteCutCalibre,
  deleteCutOrder,
} from "../utils/Requests/CutOrderRequest";
import { checkVarietyByName } from "../utils/Auxiliar/VarietyAuxiliar";
import ICutCalibre from "../interfaces/ICutCalibre";
import { IRadialChartData } from "../interfaces/IRadialCharData";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import { constructReportDataRadialChar } from "../utils/Auxiliar/CutOrderAuxiliar";
import RadialCharts from "../components/RadialCharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";

interface IEditedCutOrder {
  pickedCalibre: string;
  tons: number;
}

interface CutOrderViewProps extends RouteComponentProps<{ id: string }> {}

const CutOrderView: React.FC<CutOrderViewProps> = ({ match }) => {
  const [cutOrder, setCutOrder] = useState<ICutOrder>();
  const [ruta, setRuta] = useState<string>("");
  const [cutCalibres, setCutCalibres] = useState<ICutCalibre[]>([]);
  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);
  const [arrive, setArrive] = useState<any>();
  const [visible, setVisisble] = useState<boolean>(false);
  const [mostrar, setMostrar] = useState<boolean>(true);
  const { token, companyId } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      getSingleCutOrderById(parseInt(match.params.id), token)
        .then((cutOrder) => {
          if (cutOrder.companyId === companyId) {
            setCutOrder(cutOrder);
            editData(cutOrder);
            setArrive(cutOrder?.arrive);
            setVisisble(cutOrder.visible!);
            setRuta(
              checkVarietyByName(cutOrder?.plantationVarieties.variety.name)
            );
            getSingleCutOrderCalibreById(parseInt(match.params.id), token)
              .then((cutCalibres) => {
                setCutCalibres(cutCalibres);
              })
              .catch((error: string) => {
                console.log(error);
              });
          } else {
            setMostrar(false);
          }
        })
        .catch((error: string) => {
          console.log(error);
        });
    }
  }, [token]);

  const editData = (cutOrder: any) => {
    let editedCutOrder: IEditedCutOrder[] = [];
    cutOrder?.cutCalibres?.map((c: any) => {
      editedCutOrder.push({
        pickedCalibre: c.calibre.code,
        tons: c.volume / 1000,
      });
    });

    let radialData = constructReportDataRadialChar(editedCutOrder);
    setRadialData(radialData);
  };

  const deleteSingleCutOrder = async () => {
    deleteCutCalibre(parseInt(match.params.id), token).then(() => {
      deleteCutOrder(parseInt(match.params.id), token).then(() =>
        openModalThanks()
      );
    });
  };

  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Quieres borrar esta previsión de compra?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        deleteSingleCutOrder();
      }
    });
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Orden de corte eliminada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setTimeout(() => history.push("/ordenes-corte"), 250);
      }
    });
  };

  let cutOrderId = parseInt(match.params.id);
  let options = { weekday: "short", month: "long", day: "numeric" };
  let volume = (
    cutCalibres.reduce((a: any, c: any) => a + c.volume, 0) / 1000
  ).toFixed(2);

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Órdenes de Corte</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {mostrar ? (
        <>
          {" "}
          <div className="constainer">
            <div className="row" style={{ marginTop: "1%" }}>
              <div className="col-9"></div>
              {visible === false && (
                <div className="col-3" style={{ display: "flex" }}>
                  <button
                    type="button"
                    className="btn btn-md text-light finishButton"
                    style={{ height: 35 }}
                    onClick={() =>
                      history.push(`/gestionar-orden-corte/${cutOrderId}`)
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    {"   "}
                    Editar
                  </button>
                  <button
                    style={{ marginTop: 2, marginLeft: 10 }}
                    className="delete-report-button"
                    onClick={openModalDelete}
                  >
                    <div className="button-flex-warehouse">
                      <div>
                        <svg
                          width="12"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="trash-alt"
                          className="svg-inline--fa fa-trash-alt fa-w-14"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            className="container"
            style={{ display: "flex" }}
          >
            <div className="row main-containersCO ml-5">
              <div className="col-5" style={{ paddingTop: "10%" }}>
                <img className="list-iconC" src={ruta}></img>
              </div>
              <div className="col-7" style={{ paddingTop: "15%" }}>
                <div className="list-detail">
                  {cutOrder?.plantationVarieties?.variety?.name}
                </div>
                <span
                  className="list-title"
                  style={{ color: "#cad401", fontWeight: "bold" }}
                >
                  {cutOrder?.plantationVarieties?.plantation?.name}
                </span>
                <div className="li">
                  {cutOrder?.plantationVarieties?.plantation?.location?.name} ,{" "}
                  {
                    cutOrder?.plantationVarieties?.plantation?.location?.province?.name
                  }
                </div>
              </div>
            </div>

            <div className=" main-containersCO">
              <h4 style={{ marginLeft: "50px", marginTop: "2%" }}>
                Tn/calibre
              </h4>
              <div
                className="row"
                style={{ display: "flex", paddingTop: "10px" }}
              >
                <div className="col-5">
                  <div style={{ paddingLeft: "8%" }}>
                    Volumen: {treatNumber(parseFloat(volume))}
                    {""} Tn
                    <RadialCharts data={radialData} />
                  </div>
                </div>
                <div className="col-7">
                  {radialData.map(({ angle, label, color }, index) => (
                    <div
                      key={index}
                      className="d-flex"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div
                        className="circle"
                        style={{ backgroundColor: `${color}` }}
                      />
                      {label !== "Cualquiera" && <p> Calibre {label} : </p>}
                      {label === "Cualquiera" && <p> Sin calibrar : </p>}
                      <span>{treatNumber(angle)} Tn</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div  className="container">
            <div className="row">
              <div className="col-2"></div>
            <div className="col-7"
            style={{
              height: "70px",
              width: "600px",
              backgroundColor: "white",
              marginTop: "30px",
              paddingTop: "20px",
              paddingLeft: "2%",
            }}
          >
            Fecha requerida de llegada al almacén:
            <img
              className="iconsSize"
              style={{ marginLeft: "10px" }}
              src="/icon/reloj.png"
              alt=""
            />
            <span style={{ fontWeight: "bold" }}>
              {new Date(arrive).toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              horas
            </span>
            <img
              className="iconsSize"
              style={{ marginLeft: "10px" }}
              src="/icon/calendar.png"
              alt=""
            />
            <span style={{ paddingLeft: "1%", fontWeight: "bold" }}>
              {new Date(arrive).toLocaleDateString("es-ES", options)}
            </span>
          </div>
            </div>
          </div>
          
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Orden de corte privada
        </div>
      )}
    </div>
  );
};

export default CutOrderView;
