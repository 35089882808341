import { RadialChart } from "react-vis";
import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import LegendComponent2 from "./LegendComponent2";
import { colorGama } from "../utils/constants";

const RadialChartPurchase = ({ selectedVariety, varieties }) => {
  const [calibres, setCalibres] = useState([]);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    if (varieties.length > 0) {
      let aux = varieties.find((v) => v.id === selectedVariety);
      setCalibres(aux.data);
      let i = 1;
      let arr2 = [];
      aux.data.forEach((d) => {
        let laux = {
          color: colorGama[aux.data.length - i],
          value: d.label,
          pct: d.angle,
        };

        arr2.push(laux);
        i++;
      });
      setLegend(arr2);
    }
  }, [selectedVariety, varieties]);

  return (
    <>
      {selectedVariety ? (
        <>
          <div
            className="graf-panel-purchase"
            style={{ minHeight: "180px", width: "42.3%" }}
          >
            <div style={{ fontSize: 20, color: "#515258", fontWeight: 500 }}>
              Calibres
            </div>
            <div className="graf2-panel-purchase">
              <RadialChart
                colorRange={colorGama}
                colorDomain={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                innerRadius={30}
                radius={50}
                data={calibres}
                width={120}
                height={120}
              />
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {legend.length > 0 ? (
                  legend.map((va) => (
                    <LegendComponent2 key={va.code} comp={va} />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="graf2-panel-purchase" style={{ background: "white" }}>
          <div
            style={{
              fontSize: 20,
              color: "#515258",
              fontWeight: 500,
              background: "white",
              padding: 10,
            }}
          >
            Seleccione una variedad
          </div>
        </div>
      )}
    </>
  );
};

export default RadialChartPurchase;
