import { conditionRoute } from "./EquivalentAuxiliar";

export const createPriorAnalysis = (
  analyses: any[],
  reports: any[],
  reportsNow: any[],
  quality: any
) => {
  // recibo unos analisis y tengo que sacar los valores de los más recientes.
  // todo respecto a una finca-variedad
  let aforo: number | undefined = undefined;
  let colouring: number | undefined = undefined;
  let condition: number | undefined = undefined;
  let conRoute: string = "";
  let brix: number | undefined = undefined;
  let inicioActual: any = undefined;
  let inicioAnterior: string | undefined = undefined;
  let tnAnterior: number | undefined = undefined;
  let aforoEncontrado: boolean = false;
  let colouringEncontrado: boolean = false;
  let conditionEncontrado: boolean = false;
  let brixEncontrado: boolean = false;
  let madurity: number | undefined = undefined;
  let restarKilos: number = 0;

  analyses = analyses.sort(function (a, b) {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  if (quality.length > 0) {
    madurity = quality[0].madurity;
  }
  if (analyses.length > 0) {
    if (reports.length > 0) {
      tnAnterior = 0;
      reports.forEach((ant) => {
        tnAnterior = tnAnterior + ant.volumeEstimated;
      });

      let dia = new Date(reports[0].arrive).getDate();
      let diaT: string = "";
      if (dia < 10) {
        diaT = "0" + dia.toString();
      } else {
        diaT = dia.toString();
      }
      let mes = new Date(reports[0].arrive).getMonth();
      mes = mes + 1;

      let mesT: string = "";
      if (mes < 10) {
        mesT = "0" + mes.toString();
      } else {
        mesT = mes.toString();
      }
      inicioAnterior = diaT + "/" + mesT;
    }

    if (reportsNow.length > 0) {
      let dia = new Date(reportsNow[0].arrive).getDate();
      let diaT: string = "";
      if (dia < 10) {
        diaT = "0" + dia.toString();
      } else {
        diaT = dia.toString();
      }
      let mes = new Date(reportsNow[0].arrive).getMonth();
      mes = mes + 1;

      let mesT: string = "";
      if (mes < 10) {
        mesT = "0" + mes.toString();
      } else {
        mesT = mes.toString();
      }
      inicioActual = diaT + "/" + mesT;

      reportsNow.forEach((r) => {
        restarKilos = restarKilos + r.volumeEstimated;
      });
    }
  }

  analyses.forEach((v: any) => {
    if (!colouringEncontrado) {
      // COLORACIÓN
      if (v.colouring) {
        if (v.colouring !== null) {
          colouringEncontrado = true;
          colouring = v.colouring;
        }
      }
    }

    if (!aforoEncontrado) {
      // AFORO
      if (v.capacity) {
        if (v.capacity !== null) {
          aforoEncontrado = true;
          aforo = parseFloat(v.capacity);
        }
      }
    }

    if (!conditionEncontrado) {
      // CONDICION
      if (v.condition) {
        if (v.condition !== null) {
          conditionEncontrado = true;
          condition = v.condition;
          conRoute = conditionRoute(v.condition);
        }
      }
    }

    if (!brixEncontrado) {
      // GRADO BRIX
      if (v.brix) {
        if (v.brix !== null) {
          brixEncontrado = true;
          brix = v.brix;
        }
      }
    }
  });

  let devolver = {
    madurity: madurity,
    conRoute: conRoute,
    brix: brix,
    colouring: colouring,
    condition: condition,
    restarKilos: restarKilos,
    aforo: aforo,
    tnAnterior: tnAnterior,
    inicioActual: inicioActual,
    inicioAnterior: inicioAnterior,
  };

  return devolver;
};


export const createAnalysisToExport = (rawData: any) => {
  let newAnalysisToExport: any[] = [];

  if(rawData){
  rawData.forEach((v: any) => {
    newAnalysisToExport.push({
      analysisDate: new Date(v.record),
      plantation: v.plantationVariety.plantation.name,
      town: v.plantationVariety.plantation.location.name,
      province: v.plantationVariety.plantation.location.province.name,
      owner: v?.owner,
      variety: v.plantationVariety.variety.name,
      juicePercentage: v.juicePercentage,
      brix: v.brix,
      sodiumHidroxide: v.sodiumHidroxide,
      acidity: v.acidity,
      madurity: v.madurity

      });
  });
}

  return replacingQualityDataDotByComma(newAnalysisToExport);
};

export const replacingQualityDataDotByComma = (data: any[]) => {
  let finalData: any[] = [];;

  data.map(
    ({
      analysisDate,
      plantation,
      town,
      province,
      owner,
      variety,
      juicePercentage,
      brix,
      sodiumHidroxide,
      acidity,
      madurity

    }) => { 
      finalData.push({
        analysisDate,
        plantation,
        town,
        province,
        owner,
        variety,
        juicePercentage: juicePercentage.toLocaleString(),
        brix: brix.toLocaleString(),
        sodiumHidroxide: sodiumHidroxide.toLocaleString(),
        acidity: acidity.toLocaleString(),
        madurity:madurity.toLocaleString()
      });
    })
 return finalData
}

