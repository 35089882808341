import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import "../theme/sourcing.css";
import history from "../utils/Auxiliar/HistoryAuxiliar";
//aux
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { getPreviousSeason } from "../utils/Auxiliar/SourcingSalesAux";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";

//reqs

import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getAllProducts } from "../utils/Requests/ProductsReq";
import {
  getCurrentSales,
  getCurrentSalesByCompany,
  getCurrentSalesByCompanyId,
} from "../utils/Requests/SalesReq";

//middleware
import LineChart from "../components/LineChart";

//interfaces
import { ISales } from "../interfaces/ISales";
import { ILineChart } from "../interfaces/ILineChart";
import {
  filterDataByVariety,
  addingProducerSeasonReference,
  convertToProducerLineChartData,
  sortCompanyRawData,
  sortCompanyDataToEdit,
  sortTableData,
} from "../utils/Auxiliar/ProducerSalesAux";
import LineChart2 from "../components/LineChartNew";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import { getVarietiesByCompanyId } from "../utils/Requests/VarietyRequests";

export interface IExcelProducerSalesData {
  id?: number;
  reference?: string;
  seasonId?: number;
  week: number;
  weekSort: number;
  volumen: any;
  saleDate: any;
  calibre: any;
  variety?: string;
}

const ProducerSales: React.FC = () => {
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  const [thisYearRawData, setThisYearRawData] = useState<ISales[]>([]);
  const [lastYearRawData, setLastYearRawData] = useState<ISales[]>([]);

  const [tableData, setTableData] = useState<any[]>([]);

  const [weeklyPreviousYear, setWeeklyPreviousYear] = useState<ILineChart[]>(
    []
  );
  const [weeklyCurrentYear, setWeeklyCurrentYear] = useState<ILineChart[]>([]);

  const [height, setHeight] = useState(50);
  const [tableDataIndex, setTableDataIndex] = useState(3);

  const [varieties, setVarieties] = useState<any[]>([]);
  const [variety, setVariety] = useState<string>();

  const [excelTotalData, setExcelTotalData] = useState<
    IExcelProducerSalesData[]
  >([]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const fetchData = async () => {
    let data = await getCurrentSalesByCompany(token);

    //filter by season
    const season = checkSeasonShort();
    const previousSeason = getPreviousSeason(season);

    const { id: currentSeasonId } = await getSeasonByName(season, token);
    const { id: lastSeasonId } = await getSeasonByName(previousSeason, token);

    //This season Data
    const thisYearRawData = sortCompanyRawData(
      data,
      currentSeasonId,
      companyId!
    );
    setThisYearRawData(thisYearRawData);
    let weeklyThisYear = sortCompanyDataToEdit(thisYearRawData);

    setWeeklyCurrentYear(weeklyThisYear);

    //Previous season Data
    let lastYearData = sortCompanyRawData(data, lastSeasonId, companyId!);

    setLastYearRawData(lastYearData);

    let weeklyPreviousYear = sortCompanyDataToEdit(lastYearData);

    setWeeklyPreviousYear(weeklyPreviousYear);

    concatYears(weeklyPreviousYear, weeklyThisYear);

    //Excel data

    setDataToExport(
      thisYearRawData,
      lastYearData,
      currentSeasonId,
      lastSeasonId
    );

    //Table data
    let sortedTableData = sortTableData(thisYearRawData);

    setTableData(sortedTableData);
    let varieties = await getVarietiesByCompanyId(companyId!, token);
    setVarieties(varieties);
  };

  const concatYears = (weeklyPreviousYear: any, weeklyThisYear: any) => {
    let combinedArrays = weeklyThisYear.concat(
      weeklyPreviousYear,
      weeklyThisYear
    );
    setHighestNumber(combinedArrays);
  };

  const setHighestNumber = (combinedArrays: ILineChart[]) => {
    console.log("entro");
    let highestNumber = 0;

    for (var i = 0; i < combinedArrays.length; i++) {
      if (highestNumber < combinedArrays[i].y) {
        highestNumber = combinedArrays[i].y;
      }
    }
    setHeight(highestNumber * 1.1);
  };

  const setDataToExport = (
    thisYearRawData: any[],
    lastYearRawData: any[],
    currentSeasonId: number,
    lastSeasonId: number
  ) => {
    let concataEsxelData = thisYearRawData.concat(
      thisYearRawData,
      lastYearRawData
    );
    let excelSortedSalesData = addingProducerSeasonReference(
      concataEsxelData,
      currentSeasonId,
      lastSeasonId
    );
    setExcelTotalData(excelSortedSalesData);
  };

  const filteringVariety = (variety: string) => {
    let filteredLastData: any = filterDataByVariety(lastYearRawData, variety);
    setWeeklyPreviousYear(filteredLastData);

    let filteredThisData: any = filterDataByVariety(thisYearRawData, variety);
    setWeeklyCurrentYear(filteredThisData);

    concatYears(filteredLastData, filteredThisData);
  };

  //Excel headers
  const excelHeaders = [
    { label: "Semana", key: "week" },
    { label: "Referencia", key: "reference" },
    { label: "Variedad", key: "variety" },
    { label: "Calibre", key: "calibre" },
    { label: "Toneladas", key: "volumen" },
  ];

  let options = { month: "2-digit", weekday: "short", day: "2-digit" };

  return (
    <div className="div-principal2">
      <div className="top-panel">Compras Reales</div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>

      <div
        style={{
          width: "3%",
          marginLeft: "77%",
          marginTop: "1%",
        }}
      >
        <div style={{ marginBottom: "2%" }}>
          <CSVLink
            data={excelTotalData}
            headers={excelHeaders}
            filename={`compras-reales.csv`}
          >
            <button className="btn btn-md text-light export-button">
              Exportar a excel
            </button>
          </CSVLink>
        </div>
      </div>
      <div className="container whiteContainer pt-1">
        <div className="container pl-4">
          <div className="row mb-3 mt-3">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <div
              className="circle ml-5 mr-1"
              style={{ backgroundColor: `darkGrey` }}
            />
            <span className="pt-1">Año Anterior</span>
            <div
              className="circle ml-5 mr-1"
              style={{ backgroundColor: `#cad401` }}
            />
            <span className="pt-1">Año Actual</span>
            <select
              className="form-control form-control-sm "
              style={{ width: "7.2rem", marginLeft: "42.2%" }}
              data-spy="scroll"
              value={variety}
              onChange={(e) => filteringVariety(e.target.value)}
            >
              <option value="">Variedad</option>
              {varieties.map(({ name, id }: any) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </select>
            {!weeklyPreviousYear.length && !weeklyCurrentYear.length ? (<div className=" ml-5">
              <h4 className=" mb-5 ml-5">No hay datos para esta búsqueda</h4></div>
            ):(
            <LineChart
              data={weeklyCurrentYear}
              data2={weeklyPreviousYear}
              data3={false}
              height={[0, height]}
              width={1000}
            />
            )}
          </div>
          <div
            style={{ marginTop: "-2%" }}
            className="row justify-content-center pb-3"
          >
            {!lastYearRawData.length && !thisYearRawData.length ? (
              <span
                className="spinner-border spinner-border-lg mb-5"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span style={{ fontWeight: "bold" }}>Semanas</span>
            )}{" "}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "2%" }}>
        <div
          className="container"
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "20px",
            width: "85%",
            marginLeft: "5%",
            marginTop: "3%",
          }}
        >
          <table className="table" style={{ textAlign: "center" }}>
            <thead className="thead-dark">
              <tr>
                <th scope="col">SEMANA</th>
                <th scope="col">FECHA</th>
                <th scope="col">VOLUMEN TOTAL(Tn)</th>
                <th scope="col">VENTA REAL</th>
              </tr>
            </thead>

            <tbody>
              {tableData.slice(0, tableDataIndex)?.map((tb: any) => (
                <tr key={tb.w}>
                  <td>{tb.w}</td>
                  <td>{new Date(tb.d).toLocaleDateString("es-ES", options)}</td>
                  <td>{tb.t}</td>
                  <td
                    style={{
                      color: "#cad401",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push(`/compras-reales-detalle/${tb.w}`)
                    }
                  >
                    {"Ver"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {tableData.length && tableData.length > tableDataIndex ? (
            <div className="d-flex justify-content-center mb-3 ">
              <span
                className="boldFontLink"
                onClick={() => setTableDataIndex(tableDataIndex + 49)}
              >
                Ver más
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProducerSales;
