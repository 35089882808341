import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

export const getPriorAnalysesBySeasonAndId = (
  id: number,
  season: string,
  token: string
) => {
  let filter = {
    where: {
      plantationVarietyId: id,
      seasonId: season,
    },
    include: {
      relation: "analysisCalibres",
      scope: {
        include: {
          relation: "calibres",
        },
      },
    },
    order: "createdAt DESC",
  };
  return Axios.get(`${baseURL}PriorAnalyses?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
