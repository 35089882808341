import { IUser } from "../interfaces/IUser";
import { TAction } from "./types";
import INotification from "../interfaces/INotification";

export const SetUserAction = (user: IUser): TAction => ({
  type: "SET_USER",
  payload: user,
});

export const SetNotificationsAction = (
  notifications: INotification[]
): TAction => ({
  type: "SET_NOTIFICATIONS",
  payload: notifications,
});

export const RemoveNotificationAction = (id: number): TAction => ({
  type: "REMOVE_NOTIFICATION",
  payload: id,
});
