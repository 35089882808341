import React, { useEffect } from "react";
import { Route, Switch, Router } from "react-router-dom";
import "./App.css";

// Navbar
import ProductorNavbar from "./components/Navbar/ProductorNavbar";
import ComercialNavbar from "./components/Navbar/ComercialNavbar";
import QualityNavbar from "./components/Navbar/QualityNavbar";
import SourcingNavbar from "./components/Navbar/SourcingNavbar";
import AdminNavbar from "./components/Navbar/AdminNavbar";
import AgribioNavbar from "./components/Navbar/AgribioNavbar";
import MarketingNavbar from "./components/Navbar/MarketingNavbar";

import ProducerMultimedia from "./pages/ProducerMultimedia";
import SourcingMultimedia from "./pages/SourcingMultimedia";
import history from "./utils/Auxiliar/HistoryAuxiliar";
import Login from "./pages/Login";
import PurchasesForecast from "./pages/PurchasesForecast";
import PlantationsListView from "./pages/PlantationsListView";
import PlantationView from "./pages/PlantationView";
import CutOrderListView from "./pages/CutOrderListView";
import HomeProductorView from "./pages/Home/HomeProductorView";
import HomeComercialView from "./pages/Home/HomeComercialView";
import HomeQualityView from "./pages/Home/HomeQualityView";
import QualityReportsListView from "./pages/QualityReportsListView";
import QualitiesView from "./pages/QualitiesView";
import CreatePurchaseView from "./pages/CreatePurchaseView";
import CreateQualityView from "./pages/CreateQualityView";
import CutOrderView from "./pages/CutOrderView";
import ManageCutOrder from "./pages/ManageCutOrder";
import HarvestReportListView from "./pages/HarvestReportListView";
import ManagePurchasesForecast from "./pages/ManagePurchasesForecast";
import ManageForecast from "./pages/ManageForecast";
import AgribioForecast from "./pages/AgribioForecast";
import AgribioPurchaseForecast from "./pages/AgribioPurchaseForecast";
import HarvestReportView from "./pages/HarvestReportView";
import SourcingAvailabilityAgribio from "./pages/SourcingAvailabilityAgribio";
import SourcingGraphView from "./pages/SourcingGraphView";

import SourcingSales from "./pages/SourcingSales";
import SourcingWeeklySales from "./pages/SourcingWeeklySales";
import PurchasesListView from "./pages/PurchasesListView";
import ManagePurchaseView from "./pages/ManagePurchaseView";
import PurchaseResumeView from "./pages/PurchaseResumeView";

import ProducerSales from "./pages/ProducerSales";
import CreateWarehouseReportView from "./pages/CreateWarehouseReportView";
import WarehouseReportListView from "./pages/WarehouseReportListView";
import WarehouseReportView from "./pages/WarehouseReportView";
import EditWarehouseReportView from "./pages/EditWarehouseReportView";
import AgribioPurchaseDetail from "./pages/AgribioPurchaseDetail";

//middleware
import { verify } from "jsonwebtoken";

//redux
import { useSelector, useDispatch } from "react-redux";
import { SetUserAction, SetNotificationsAction } from "./redux/actions";

//interfaces
import { IStore } from "./interfaces/IStore";
import { IUser } from "./interfaces/IUser";
import { myPrivateKey } from "./utils/constants";
import AdminPlantations from "./pages/Admin/AdminPlantations";
import AdminCompanies from "./pages/Admin/AdminCompanies";
import AdminProducts from "./pages/Admin/AdminProducts";

import AdminUsers from "./pages/Admin/AdminUsers";
import EditProductAdmin from "./pages/Admin/EditProductAdmin";
import INotification from "./interfaces/INotification";
import { getNotificationsByUserId } from "./utils/Requests/NotificationRequest";

import EditPlantationAdmin from "./pages/Admin/EditPlantationAdmin";
import EditUserAdmin from "./pages/Admin/EditUserAdmin";
import ChangePassword from "./pages/ChangePassword";

function App() {
  const dispatch = useDispatch();
  const { role, userId, token, type } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  useEffect(() => {
    const token2 = sessionStorage.getItem("token2");
    token2 && resetUser(token2);
    if (token && userId && role) {
      getNotificationsByUserId(userId!, token!)
        .then((notifications: INotification[]) => {
          dispatch(SetNotificationsAction(notifications));
        })
        .catch(() => {
          console.log("No se han podido cargar las notificaciones");
        });
    }
  }, [userId, role, token, type]);

  const resetUser = (token2: string) => {
    try {
      //if token exists and is valid
      const {
        userId,
        username,
        role,
        companyId,
        officeId,
        fullname,
        token,
        type,
      }: any = verify(token2, myPrivateKey);

      dispatch(
        SetUserAction({
          userId,
          username,
          role,
          companyId,
          officeId,
          fullname,
          token,
          type,
        })
      );
    } catch {
      //if token was fiddled with

      sessionStorage.removeItem("token2");
      history.push("/");
    }
  };
  const sourcing = token && role === "sourcing";
  const producer = token && role === "producer";
  const comercial = token && role === "sales";
  const marketing = token && role === "marketing";
  const quality = token && role === "quality";
  const qualityAssistant = token && role === "qualityAssistant";
  const agribio = token && role === "agribio";
  const admin = token && role === "admin";

  return (
    <Router history={history}>
      {producer && (
        <>
          <ProductorNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route
            exact
            path="/agribio-prevision-compras"
            component={AgribioPurchaseForecast}
          />
          <Route path="/fincas" exact={true} component={PlantationsListView} />
          <Route
            exact
            path="/compras-reales-detalle/:id"
            component={AgribioPurchaseDetail}
          />
          <Route
            exact
            path="/compras-reales-productor"
            component={ProducerSales}
          />
          <Route exact path="/ordenes-corte" component={CutOrderListView} />
          <Route exact path="/ordencorte/:id" component={CutOrderView} />
          <Route
            exact
            path="/gestionar-orden-corte/:id"
            component={ManageCutOrder}
          />
          <Route
            exact
            path="/parte-recogida/:id"
            component={HarvestReportView}
          />
          <Route
            exact
            path="/partes-recogida"
            component={HarvestReportListView}
          />
          {type === 1 ? (
            <>
              <Route
                exact
                path="/partes-almacen"
                component={WarehouseReportListView}
              />
              <Route
                exact
                path="/parte-almacen/:id"
                component={WarehouseReportView}
              />
              <Route
                exact
                path="/editar-parte-almacen/:id"
                component={EditWarehouseReportView}
              />
              <Route
                exact
                path="/crear-parte-almacen"
                component={CreateWarehouseReportView}
              />
            </>
          ) : (
            <></>
          )}

          <Route exact path="/disponibilidad" component={AgribioForecast} />
          <Route
            exact
            path="/gestionar-disponibilidad/:id"
            component={ManageForecast}
          />
          <Route
            exact
            path="/mostrar-multimedia"
            component={ProducerMultimedia}
          />
          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeProductorView} />
        </>
      )}

      {comercial && (
        <>
          <ComercialNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route exact path="/multimedia" component={SourcingMultimedia} />
          <Route path="/fincas" exact={true} component={PlantationsListView} />
          <Route
            exact
            path="/compras-definitivas"
            component={PurchasesListView}
          />
          <Route
            path="/crear-compra-definitiva"
            exact={true}
            component={CreatePurchaseView}
          />
          <Route
            path="/resumen-compra/:id"
            exact={true}
            component={PurchaseResumeView}
          />
          <Route
            path="/editar-compras-reales/:id"
            exact={true}
            component={ManagePurchaseView}
          />
          <Route
            exact
            path="/compras-semanales/:company"
            component={SourcingWeeklySales}
          />
          <Route exact path="/compras-reales" component={SourcingSales} />
          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeComercialView} />
        </>
      )}

      {agribio && (
        <>
          <AgribioNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route exact path="/multimedia" component={SourcingMultimedia} />
          <Route path="/fincas" exact={true} component={PlantationsListView} />

          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeComercialView} />
        </>
      )}

      {quality && (
        <>
          <QualityNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route exact path="/multimedia" component={SourcingMultimedia} />
          <Route
            path="/informes-calidad"
            exact={true}
            component={QualityReportsListView}
          />
          <Route
            path="/informes-calidad/:id"
            exact={true}
            component={QualitiesView}
          />
          <Route path="/fincas" exact={true} component={PlantationsListView} />

          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeComercialView} />
        </>
      )}

      {qualityAssistant && (
        <>
          <QualityNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route
            path="/informes-calidad"
            exact={true}
            component={QualityReportsListView}
          />
          <Route
            path="/informes-calidad/:id"
            exact={true}
            component={QualitiesView}
          />
          <Route
            path="/crear-informe-calidad"
            exact={true}
            component={CreateQualityView}
          />
          <Route path="/fincas" exact={true} component={PlantationsListView} />

          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeQualityView} />
        </>
      )}
      {sourcing && (
        <>
          <SourcingNavbar />
          <Route exact path="/multimedia" component={SourcingMultimedia} />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route
            path="/informes-calidad"
            exact={true}
            component={QualityReportsListView}
          />
          <Route
            path="/informes-calidad/:id"
            exact={true}
            component={QualitiesView}
          />
          <Route
            exact
            path="/previsiones-compras"
            component={PurchasesForecast}
          />
          <Route
            exact
            path="/gestionar-previsiones-compras/:id"
            component={ManagePurchasesForecast}
          />
          <Route
            path={[
              "/resumen-previsiones-compras/:id",
              "/resumen-disponibilidad-agribio/:id",
            ]}
            component={SourcingGraphView}
          />
          <Route exact path="/compras-reales" component={SourcingSales} />
          <Route
            exact
            path="/disponibilidad-agribio"
            component={SourcingAvailabilityAgribio}
          />
          <Route path="/fincas" exact={true} component={PlantationsListView} />
          <Route
            exact
            path="/compras-semanales/:company"
            component={SourcingWeeklySales}
          />

          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeQualityView} />
        </>
      )}

      {marketing && (
        <>
          <MarketingNavbar />
          <Route exact path="/multimedia" component={SourcingMultimedia} />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route
            path="/informes-calidad"
            exact={true}
            component={QualityReportsListView}
          />
          <Route
            path="/informes-calidad/:id"
            exact={true}
            component={QualitiesView}
          />
          <Route
            exact
            path="/previsiones-compras"
            component={PurchasesForecast}
          />

          <Route
            path={[
              "/resumen-previsiones-compras/:id",
              "/resumen-disponibilidad-agribio/:id",
            ]}
            component={SourcingGraphView}
          />
          <Route exact path="/compras-reales" component={SourcingSales} />
          <Route
            exact
            path="/disponibilidad-agribio"
            component={SourcingAvailabilityAgribio}
          />
          <Route path="/fincas" exact={true} component={PlantationsListView} />
          <Route
            exact
            path="/compras-semanales/:company"
            component={SourcingWeeklySales}
          />

          <Route path="/fincas/:id" exact component={PlantationView} />
          <Route path="/" exact={true} component={HomeQualityView} />
        </>
      )}

      {admin && (
        <>
          <AdminNavbar />
          <Route
            path="/reset-password"
            exact={true}
            component={ChangePassword}
          />
          <Route exact path="/admin-companias" component={AdminCompanies} />
          <Route
            exact
            path="/admin-fincas/:id"
            component={EditPlantationAdmin}
          />
          {/*  Below route done for potential creation and edition of products
            <Route exact path="/admin-productos" component={AdminProducts} />
            <Route
              exact
              path="/admin-productos/:id"
              component={EditProductAdmin}
            /> */}

          <Route exact path="/admin-usuarios" component={AdminUsers} />
          <Route exact path="/admin-usuarios/:id" component={EditUserAdmin} />
          <Route exact path="/admin-companias" component={AdminCompanies} />
          <Route exact path="/admin-productos" component={AdminProducts} />
          <Route
            exact
            path="/admin-productos/:id"
            component={EditProductAdmin}
          />
          <Route exact path="/admin-usuarios" component={AdminUsers} />
          <Route exact path="/" component={AdminPlantations} />
        </>
      )}

      <Switch>{!token && <Login />}</Switch>
    </Router>
  );
}

export default App;
