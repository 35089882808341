import { baseURL } from "../constants";
import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";

export const getCurrentSales = (token: string) => {
  let filter = {
    include: [
      {
        relation: "realPurchaseVarCals",
        scope: {
          include: {
            relation: "varietyCalibre",
            scope: {
              include: [
                {
                  relation: "variety",
                  scope: {
                    fields: ["name", "id", "productId"],
                    include: {
                      relation: "product",
                      scope: {
                        fields: ["name", "id"],
                      },
                    },
                  },
                },
                // {
                //   relation: "calibre",
                //   scope: {
                //     fields: ["code", "id"],
                //   },
                // },
              ],
            },
          },
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["id", "country"],
        },
      },
      {
        relation: "company",
        scope: {
          fields: ["id", "name"],
        },
      },
      {
        relation: "season",
        scope: {
          fields: ["id", "name"],
        },
      },
    ],
    order: "weekSort ASC",

    where: {
      isSent: 1,
    },
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCurrentSalesByCompanyOfficeWeek = (
  companyId: number,
  officeId: number | string,
  week: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "realPurchaseVarCals",
        scope: {
          include: {
            relation: "varietyCalibre",
            scope: {
              include: [
                {
                  relation: "variety",
                  scope: {
                    fields: ["name", "id", "productId"],
                    include: {
                      relation: "product",
                      scope: {
                        fields: ["name", "id"],
                      },
                    },
                  },
                },
                {
                  relation: "calibre",
                  scope: {
                    fields: ["code", "id"],
                  },
                },
              ],
            },
          },
        },
      },
      {
        relation: "office",
        scope: {
          fields: ["id", "country"],
        },
      },
      {
        relation: "company",
        scope: {
          fields: ["id", "name"],
        },
      },
      {
        relation: "season",
        scope: {
          fields: ["id", "name"],
        },
      },
    ],

    where: {
      isSent: 1,
      officeId: officeId,
      companyId: companyId,
      week: week,
    },
  };

  let filterNoOffice = {
    include: {
      relation: "realPurchaseVarCals",
      scope: {
        include: {
          relation: "varietyCalibre",
          scope: {
            include: [
              {
                relation: "variety",
                scope: {
                  fields: ["name", "id", "productId"],
                  include: {
                    relation: "product",
                    scope: {
                      fields: ["name", "id"],
                    },
                  },
                },
              },
              {
                relation: "calibre",
                scope: {
                  fields: ["code", "id"],
                },
              },
            ],
          },
        },
      },
    },

    where: {
      isSent: 1,
      companyId: companyId,
      week: week,
    },
  };

  let finalFilter = officeId !== "n/a" ? filter : filterNoOffice;

  return Axios.get(
    `${baseURL}Purchases?filter=` + JSON.stringify(finalFilter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        //TODO - handle error when no data comes (e.g. a certan office has no data)

        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCurrentSalesByCompanyId = (
  token: string,
  companyId: number,
  seasonId:number
) => {
  let filter = {
    include: {
      relation: "realPurchaseVarCals",
      scope: {
        include: {
          relation: "varietyCalibre",
          scope: {
            include: [
              {
                relation: "variety",
                scope: {
                  fields: ["name", "id", "productId"],
                },
              },
              {
                relation: "calibre",
                scope: {
                  fields: ["code", "id"],
                },
              },
            ],
          },
        },
      },
    },
    order: "week ASC",
    where: {
      // and: [{ companyId: 1}, {seasonId: 3}, {send: 1 } ],
      and: [{ companyId: 1}, {seasonId: seasonId} ],
    },
  };

  return Axios.get(`${baseURL}Purchases?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getCurrentSalesByCompany = (token: string) => {
  let filter = {
    include: [
      {
        relation: "purchase",
        scope: {
          where: { send: 1 },
          order: "weekSort ASC",
        },
      },
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },

      
    ],

    // order: "week ASC",
  };

  return Axios.get(
    `${baseURL}RealPurchaseVarCals?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ data, status }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getSalestInfoByCompany = (
  companyId: any,
  week: number,
  seasonId: number,
  token: string
) => {
  let filter = {
    include: [
      {
        relation: "varietyCalibre",
        scope: {
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                // include: {
                //   relation: "product",
                //   scope:{
                //     fields: ["name", "id"]
                //   }
                // },
              },
            },
            {
              relation: "calibre",
              scope: {
                fields: ["code", "id"],
              },
            },
          ],
        },
      },
      {
        relation: "purchase",
        scope: {
          where: { send: 1 },
          fields: ["id", "week", "send", "companyId", "seasonId"],
        },
      },
    ],
    where: {
      and: [
        { companyId: companyId },
        { week: week },
        { seasonId: seasonId },
        { send: 1 },
      ],
    },
    order: "id DESC",
  };
  return Axios.get(
    `${baseURL}RealPurchaseVarCals?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
