import React from "react";
import "../theme/qualityinfo.css";
import "leaflet/dist/leaflet.css";

interface PlantationListItemProps {
  cities: any[];
}

const KpisSourcing: React.FC<PlantationListItemProps> = ({ cities }) => {
  function diaSemana(dia: any, mes: any, anio: any) {
    var dias = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    var dt = new Date(mes + " " + dia + ", " + anio + " 12:00:00");
    return dias[dt.getUTCDay()];
  }

  return (
    <div>
      {cities.length > 0 ? (
        <>
          <div className="bot-div" style={{ marginTop: 30 }}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[0].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[0].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginLeft: 16 }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[1].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[1].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginLeft: 16 }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[2].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[2].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bot-div" style={{ marginTop: 10 }}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[3].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[3].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginLeft: 16 }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[4].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[4].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center", marginLeft: 16 }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[5].name}
              </div>
              <div className="meteo-kpi">
                <div className="dia-container2">
                  {cities[5].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bot-div" style={{ marginTop: 10 }}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {cities[6].name}
              </div>
              <div className="meteo-kpi" style={{ marginBottom: 10 }}>
                <div className="dia-container2">
                  {cities[6].meteos
                    .slice(0, 7)
                    .map((met: any, index: number) => (
                      <div
                        key={index}
                        style={{ marginLeft: 5, textAlign: "center" }}
                      >
                        <div>{diaSemana(met.day, met.month, met.year)}</div>
                        <div>
                          <img
                            alt="clima-hoy"
                            width="35"
                            height="35"
                            src={met.icon}
                          ></img>
                        </div>
                        <div>
                          {Math.round(met.min)}º/
                          {Math.round(met.max)}º
                        </div>
                        <div style={{ fontWeight: "lighter" }}>{met.mm} mm</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default KpisSourcing;
