import { RadialChart } from "react-vis";
import React, { useState, useEffect } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import LegendComponent from "./LegendComponent";
import { colorGama } from "../utils/constants";

const RadialChartPlantation = ({ selectedVariety, varieties }) => {
  const [mostrar, setMostrar] = useState(false);
  const [calibres, setCalibres] = useState([]);
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    setMostrar(false);
    let fecha = undefined;
    if (varieties) {
      let aux = varieties.find((v) => v.varietyId === selectedVariety);
      if (aux.analyses && aux.analyses.length > 0) {
        aux.analyses.forEach((a) => {
          if (a.analysisCalibres.length > 0) {
            if (a.createdAt > fecha || fecha === undefined) {
              fecha = a.createdAt;
              setMostrar(true);
              let arr = [];
              let total = 0;
              a.analysisCalibres.forEach((c) => {
                total = total + c.percentage;
              });

              a.analysisCalibres.forEach((c) => {
                arr.push({
                  angle: parseFloat((c.percentage * 100) / total).toFixed(2),
                  label: c.calibres.code,
                });
              });

              setCalibres(arr);
              let i = 1;
              let arr2 = [];
              arr.forEach((d) => {
                let laux = {
                  color: colorGama[arr.length - i],
                  value: d.label,
                  pct: d.angle,
                };

                arr2.push(laux);
                i++;
              });
              setLegend(arr2);
            }
          }
        });

        setMostrar(true);
      }
    }
  }, [selectedVariety]);

  return (
    <>
      {selectedVariety ? (
        <>
          {mostrar ? (
            <div className="graf-panel">
              <div style={{ fontSize: 20, color: "#515258", fontWeight: 500 }}>
                Calibres
              </div>
              <div className="graf2-panel">
                <RadialChart
                  colorRange={colorGama}
                  colorDomain={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                  innerRadius={30}
                  radius={50}
                  data={calibres}
                  width={120}
                  height={120}
                />
                <div style={{ marginLeft: 15 }}>
                  {legend.length > 0 ? (
                    legend.map((va) => (
                      <LegendComponent key={va.value} comp={va} />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="graf-panel"
              style={{
                fontSize: 20,
                color: "#515258",
                fontWeight: 500,
              }}
            >
              No hay calibres asociados a los informes
            </div>
          )}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default RadialChartPlantation;
