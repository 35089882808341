export const getNavbarIndex = (url: string, setSelectedIndex: any) => {
  const base: string = "http://localhost:3001";

  /*            GUÍA DE ÍNDICES
          /home / -> 0
          /informes-calidad -> 1
          /crear-informe-calidad -> 2
          /fincas -> 3
          /previsión compras -> 4
          /compras-reales -> 5
          /órdenes de corte -> 6
          /crear orden de corte -> 7
          /partes de recogida -> 8
          /disponibilidad -> 9
          /multimedia -> 10
          /añadir disponibilidad -> 11
          /compras-definitivas -> 12
          /crear-compras-definitivas -> 13
          /prevision-compras-productor -> 14
          /crear-prevision-productor -> 15
               /partes-almacen -> 16
          /crear-parte-almacen -> 17
          /
*/

  if (url.toLowerCase().includes("/home")) {
    setSelectedIndex(0);
  } else if (url.toLowerCase().includes("/informes-calidad")) {
    setSelectedIndex(1);
  } else if (url.toLowerCase().includes("/crear-informe-calidad")) {
    setSelectedIndex(2);
  } else if (url.toLowerCase().includes("/fincas")) {
    setSelectedIndex(3);
  } else if (url.toLowerCase().includes("/agribio-prevision-compras")) {
    setSelectedIndex(4);
  } else if (url.toLowerCase().includes("/gestionar-orden-corte/0")) {
    setSelectedIndex(7);
  } else if (
    url.toLowerCase().includes("/ordenes-corte") ||
    url.toLowerCase().includes("/ordencorte/") ||
    url.toLowerCase().includes("/gestionar-orden-corte/")
  ) {
    setSelectedIndex(6);
  } else if (
    url.toLowerCase().includes("/partes-recogida") ||
    url.toLowerCase().includes("/parte-recogida")
  ) {
    setSelectedIndex(8);
  } else if (url.toLowerCase().includes("/disponibilidad")) {
    setSelectedIndex(9);
  } else if (url.toLowerCase().includes("/multimedia")) {
    setSelectedIndex(10);
  } else if (url.toLowerCase().includes("/gestionar-disponibilidad/")) {
    setSelectedIndex(9);
  } else if (url.toLowerCase().includes("/compras-definitivas")) {
    setSelectedIndex(12);
  } else if (url.toLowerCase().includes("/crear-compra-definitiva")) {
    setSelectedIndex(13);
  } else if (url.toLowerCase().includes("/crear-prevision-productor")) {
    setSelectedIndex(15);
  } else if (url.toLowerCase().includes("/previsiones-compras")) {
    setSelectedIndex(14);
  } else if (
    url.toLowerCase().includes("/partes-almacen") ||
    url.toLowerCase().includes("/parte-almacen") ||
    url.toLowerCase().includes("/editar-parte-almacen")
  ) {
    setSelectedIndex(16);
  } else if (url.toLowerCase().includes("/crear-parte-almacen")) {
    setSelectedIndex(17);
  } else if (url.toLowerCase().includes("/compras-reales")) {
    setSelectedIndex(18);
  }
};

export const getSourcingNavIndex = (url: string) => {
  if (url.toLowerCase().includes("/home")) {
    return 0;
  } else if (url.toLowerCase().includes("previsiones")) {
    return 1;
  } else if (url.toLowerCase().includes("compras")) {
    return 2;
  } else if (url.toLowerCase().includes("disponibilidad-agribio")) {
    return 3;
  } else if (url.toLowerCase().includes("informes-calidad")) {
    return 4;
  } else if (url.toLowerCase().includes("fincas")) {
    return 5;
  } else if (url.toLowerCase().includes("multimedia")) {
    return 6;
  } else {
    return 0;
  }
};

export const getAdminNavIndex = (url: string) => {
  if (url.toLowerCase().includes("companias")) {
    return 1;
  } else if (url.toLowerCase().includes("usuarios")) {
    return 2;
  } else {
    return 0;
  }
};
