import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";

import "../theme/sourcing.css";
import {
  getVarietyIdByName,
  getCalibresByVarietyId,
  getVarietyCalibreId,
  getAllVarietiesByCompanyId,
} from "../utils/Requests/VarietyRequests";
import { getCalibreCodes } from "../utils/Auxiliar/CalibreAuxiliar";
import produce from "immer";

import more from "../icons/more.png";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getCalibreIdByCode } from "../utils/Requests/CalibreReq";
import {
  saveToForecastVarietyCalibre,
  saveAgribioForecast,
  getAgribioForecastVarieties,
  editAgribioForecast,
  resetForecastVarietyCalibre,
  getLastWeekAgribioForecastId,
  getAvailabilityAgribioById,
} from "../utils/Requests/AgribioForecastRequest";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import { IStore } from "../interfaces/IStore";
import ModalPopup from "../components/ModalPopup";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
import { SettingsSystemDaydreamOutlined } from "@material-ui/icons";

interface IAddedForecast {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
}

const ManageForecast: React.FC = () => {
  //forecastId = 0. Edit; forecastId !=0. Create
  let { pathname } = useLocation();
  let forecastId = +pathname.split("/")[2];

  const [show, setShow] = useState<boolean>(false);
  const [showFin, setShowFin] = useState<boolean>(false);
  const [showRedirect, setShowRedirect] = useState<boolean>(false);
  const [mostrar, setMostrar] = useState<boolean>(true);
  const [modalText, setModalText] = useState<string>("Error");
  const [isAddingOpen, setIsAddingOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [varieties, setVarieties] = useState<string[]>([]);
  const [pickedVariety, setPickedVariety] = useState("");
  const [isAddied, setIsAdded] = useState(false);

  const [calibres, setCalibres] = useState<string[]>([]);
  const [pickedCalibre, setPickedCalibre] = useState("");

  const [tons, setTons] = useState<number>(0);

  const [totalTons, setTotalTons] = useState(0);

  const [addedForecast, setAddedForecast] = useState<IAddedForecast[]>([]);
  const { companyId, userId, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  useEffect(() => {
    setAddedForecast([]);
    getAllVarietiesByCompanyId(companyId!, token)
      .then((varieties) => {
        setVarieties(varieties);

        getLastWeekAgribioForecastId(companyId, token)
          .then(async (week) => {
            if (week?.week === +setWeekNumber(new Date())) {
              setIsAdded(true);
            }
          })
          .catch((error: string) => {
            setModalText(error.toString());
            setShow(true);
          });
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });
    if (Boolean(forecastId)) {
      // esto es si se edita
      getForecastDataToEdit();
    }
  }, [pathname, companyId, token]);

  const getForecastDataToEdit = async () => {
    getAvailabilityAgribioById(forecastId, token).then((f) => {
      if (Boolean(forecastId) && f.companyId === companyId) {
        getAgribioForecastVarieties(forecastId, token).then((forecast) => {
          let editedForecasts: IAddedForecast[] = [];
          forecast.map((f: any) => {
            editedForecasts.push({
              pickedVariety: f.varietyCalibre.variety.name,
              pickedCalibre: f.varietyCalibre.calibre.code,
              tons: f.volume / 1000,
            });
          });

          let totalTons = editedForecasts.reduce(
            (a: any, c: any) => a + c.tons,
            0
          );
          setTotalTons(totalTons);
          setAddedForecast(editedForecasts);
        });
      } else {
        setMostrar(false);
      }
    });
  };

  const pickVariety = async (event: any) => {
    setPickedVariety(event.target.value);
    if (event.target.value !== "Selecciona Variedad") {
      getVarietyIdByName(event.target.value, token)
        .then(({ id }) => {
          getCalibresByVarietyId(id, token)
            .then((response) => {
              let calibres = getCalibreCodes(response);
              setCalibres(calibres);
            })
            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    } else {
      setCalibres([]);
      setPickedCalibre("");
      setTons(0);
    }
  };

  const pickCalibre = (event: any) => {
    setPickedCalibre(event.target.value);
    if (event.target.value === "Añadir Calibre") {
      setTons(0);
    }
  };

  const addForecast = () => {
    if (tons) {
      if (tons.toString() === "0" || parseFloat(tons.toString()) === 0) {
        setAlertText("No se pueden poner valores de 0 para las toneladas");
        setShowAlert(true);
      } else {
        setAddedForecast([
          ...addedForecast,
          {
            pickedVariety,
            pickedCalibre,
            tons,
          },
        ]);
        let total =
          parseFloat(totalTons.toString()) + parseFloat(tons.toString());
        setTotalTons(total);
        setPickedCalibre("");
        setTons(0);
      }
    }
  };

  const deleteForecast = (index: number, pickedCalibre: string) => {
    let total =
      parseFloat(totalTons.toString()) -
      parseFloat(addedForecast[index].tons.toString());
    setTotalTons(total);
    setAddedForecast((state) => {
      const newAddedForecast = produce(state, (drafState) => {
        drafState.splice(index, 1);
      });
      return newAddedForecast;
    });
  };

  const openModalRepetido = (variedad: string) => {
    Swal.fire({
      icon: "warning",
      text:
        "La variedad " +
        variedad +
        " tiene calibres repetidos, por favor corríjalo.",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(() => {});
  };

  const sendAgribioForecast = async () => {
    //save forecast report

    let repetido: string = "";
    addedForecast.forEach((af: any) => {
      let aux = addedForecast.filter(
        (a: any) =>
          a.pickedVariety === af.pickedVariety &&
          a.pickedCalibre === af.pickedCalibre
      );
      if (aux.length > 1) {
        repetido = af.pickedVariety;
      }
    });

    if (repetido === "") {
      const seasonName = checkSeasonShort();
      let { id: seasonId } = await getSeasonByName(seasonName, token);

      let agribioForecastReport = {
        createdAt: new Date(),
        updatedAt: new Date(),
        week: +setWeekNumber(new Date()),
        seasonId,
        companyId,
        ownerId: userId,
      };

      //edit forecast report
      if (Boolean(forecastId)) {
        let editedAgribioForecastReport = {
          updatedAt: new Date(),
          seasonId,
          companyId,
          ownerId: userId,
        };
        editAgribioForecast(editedAgribioForecastReport, forecastId, token);
        resetForecastVarietyCalibre(forecastId, token);
        Promise.all(
          addedForecast.map(async ({ pickedVariety, pickedCalibre, tons }) => {
            let { id: varietyId } = await getVarietyIdByName(
              pickedVariety,
              token
            );

            let { id: calibreId } = await getCalibreIdByCode(
              pickedCalibre,
              token
            );

            let { id: varietyCalibreId } = await getVarietyCalibreId(
              varietyId,
              calibreId,
              token
            );

            let forecastVarietyCalibreReport = {
              varietyCalibreId,
              forecastId,
              volume: (tons * 1000).toFixed(2),
            };
            let response = await saveToForecastVarietyCalibre(
              forecastVarietyCalibreReport,
              token
            );
            return response;
          })
        ).then(() => openModalThanks());
      } else {
        getLastWeekAgribioForecastId(companyId, token).then(async (week) => {
          if (week?.week === +setWeekNumber(new Date())) {
            setShowFin(false);
            Swal.fire({
              icon: "warning",
              text: "Ya existe disponibilidad esta semana",
              // showCancelButton: true,
              confirmButtonText: "Aceptar",
              // cancelButtonText: "No",
              customClass: {
                // cancelButton: "finishButton",
                confirmButton: "finishButton",
              },
            }).then(({ value }) => {
              if (value) {
                setTimeout(() => history.push("/disponibilidad"), 250);
              }
            });
          } else {
            let { id } = await saveAgribioForecast(
              agribioForecastReport,
              token
            );
            forecastId = id;
            Promise.all(
              addedForecast.map(
                async ({ pickedVariety, pickedCalibre, tons }) => {
                  let { id: varietyId } = await getVarietyIdByName(
                    pickedVariety,
                    token
                  );

                  let { id: calibreId } = await getCalibreIdByCode(
                    pickedCalibre,
                    token
                  );

                  let { id: varietyCalibreId } = await getVarietyCalibreId(
                    varietyId,
                    calibreId,
                    token
                  );

                  let forecastVarietyCalibreReport = {
                    varietyCalibreId,
                    forecastId,
                    volume: (tons * 1000).toFixed(2),
                  };
                  let response = await saveToForecastVarietyCalibre(
                    forecastVarietyCalibreReport,
                    token
                  );
                  return response;
                }
              )
            ).then(() => openModalThanks());
          }
        });
      }
    } else {
      openModalRepetido(repetido);
    }
  };

  const openModalThanks = () => {
    Swal.fire({
      icon: "success",
      text: "Disponibilidad finalizada correctamente. ¡Gracias!",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      // cancelButtonText: "Cancelar",
      customClass: {
        // cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        setTimeout(() => history.push("/disponibilidad"), 250);
      }
    });
  };
  const openModalQuestion = () => {
    Swal.fire({
      icon: "question",
      text: "¿Quieres finalizar la disponibilidad?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        sendAgribioForecast();
      }
    });
  };

  const tonHandler = (event: any) => {
    if (event.target.value < 0) {
      setAlertText(
        "No se pueden poner valores 0 o negativos para el las toneladas"
      );
      setShowAlert(true);
      setTons(0);
    } else {
      setTons(event.target.value);
    }
  };

  return (
    <div className="div-principal2">
      <ModalPopup
        show={showAlert}
        setShow={setShowAlert}
        modalText={alertText}
      />
      <div className="top-panel">
        <span className="top-panel-text">
          Añadir disponibilidad de variedades
        </span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div className="cointainer"></div>
      {mostrar ? (
        <>
          {" "}
          <div
            style={{
              width: "60vw",
              backgroundColor: "white",
              marginLeft: "5%",
              marginTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <div className="container p-3">
              <div className="row mb-3">
                <div className="totalInput">
                  <input
                    className="form-control form-control-sm ml-3"
                    style={{ width: "8rem" }}
                    type="text"
                    disabled
                    value={
                      totalTons
                        ? treatNumber(totalTons) + " Tn"
                        : "Volumen Total Tn"
                    }
                  />
                </div>
              </div>
              <div className="row" style={{ paddingTop: "2vh" }}>
                <div className="col-12">
                  <table className="table text-center">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">VARIEDAD</th>
                        <th scope="col">CALIBRE</th>
                        <th scope="col">VOLUMEN (Tn)</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedForecast.map(
                        ({ pickedVariety, pickedCalibre, tons }, index) => (
                          <tr key={index}>
                            <th scope="row">{pickedVariety}</th>
                            <td>{pickedCalibre}</td>
                            <td>{treatNumber(parseFloat(tons.toString()))}</td>
                            <td>
                              <img
                                className="iconsSize"
                                onClick={() =>
                                  deleteForecast(index, pickedCalibre)
                                }
                                src="/icon/close.png"
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="container addVariety">
              <div
                className="row mt-2"
                onClick={() => setIsAddingOpen(!isAddingOpen)}
              >
                <img
                  className="ml-2 mt-1"
                  src={more}
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <span className="ml-2">Añadir variedad</span>
              </div>
              {isAddingOpen && (
                <div className="row mt-3 pb-2">
                  <select
                    className="form-control form-control-sm ml-2"
                    style={{ width: "12rem" }}
                    data-spy="scroll"
                    disabled={!varieties.length}
                    value={pickedVariety}
                    onChange={pickVariety}
                  >
                    <option>Selecciona Variedad</option>
                    {varieties.map((variety: any) => (
                      <option key={variety.id} value={variety.name}>
                        {variety.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-control form-control-sm ml-2"
                    style={{ width: "10rem" }}
                    value={pickedCalibre}
                    disabled={!calibres.length}
                    onChange={pickCalibre}
                  >
                    <option>Añadir Calibre</option>
                    {calibres.map((calibreCode: any, index: number) => (
                      <option key={index} value={calibreCode}>
                        {calibreCode}
                      </option>
                    ))}
                  </select>
                  {pickedCalibre !== "Añadir Calibre" ? (
                    <input
                      className="form-control form-control-sm ml-3"
                      style={{ width: "8rem" }}
                      type="number"
                      placeholder="Toneladas"
                      disabled={!pickedCalibre}
                      value={tons === 0 ? "" : tons}
                      onChange={tonHandler}
                      // onChange={handleKg}
                    />
                  ) : (
                    <input
                      className="form-control form-control-sm ml-3"
                      style={{ width: "8rem" }}
                      type="number"
                      placeholder="Toneladas"
                      disabled
                      value={tons === 0 ? "" : tons}
                      onChange={tonHandler}
                      // onChange={handleKg}
                    />
                  )}

                  <button
                    className="btn btn-sm text-light ml-3 addButton"
                    disabled={!pickedCalibre || !tons}
                    onClick={addForecast}
                  >
                    Añadir
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="cointainer">
            <div className="row ">
              {!forecastId ?(<div className="col-12 d-flex flex-row-reverse">
                { !isAddied ?(<div><button
                  className="btn btn-md text-light mr-5 mt-3 mb-4 finishButton"
                  disabled={!addedForecast.length}
                  onClick={() => openModalQuestion()}
                >
                  Finalizar
                </button></div>):(<div >
                  
                  <button
                  className="btn btn-md text-light mr-5 mt-3 mb-4 finishButton"
                  disabled
                  onClick={() => openModalQuestion()}
                >
                  Finalizar
                </button>
                <p style={{fontSize:"12px"}} className=" pr-5">Disponibilidad ya enviada para la semana</p>
                </div>
                )}
                 
              </div>):(<div className="col-12 d-flex flex-row-reverse">
              
              <button
               className="btn btn-md text-light mr-5 mt-3 mb-4 finishButton"
               disabled={!addedForecast.length}
               onClick={() => openModalQuestion()}
             >
                Guardar
             </button>
           </div>)}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Previsión privada
        </div>
      )}
    </div>
  );
};

export default ManageForecast;
