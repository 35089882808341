import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { baseURL } from "../constants";

// const API_Key = "7e337d733677e0bbecbd2259442f6277"; // Key de pruebas

const API_Key = "f8ff6dd42cf36198e12cd8ac3f75ebfb";

// const API_Key = "adios";

export const getMeteoByPlantationCoords = (lat: number, long: number) => {
  console.log("Calling OpenWeather API");
  return Axios.get(
    "https://api.openweathermap.org/data/2.5/onecall?lat=" +
      lat +
      "&lon=" +
      long +
      "&units=metric&exclude=hourly,current,minutely&appid=" +
      API_Key
  )
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

// le paso una lista de fincas
export const checkMeteos = (pls: any, token: string) => {
  // le estoy pasando todas las fincas
  let now = new Date();
  let arr: any = []; // Todos los días sueltos en el mismo array
  let proxFechas = [
    new Date(now.setDate(now.getDate() + 0)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
  ];

  pls.forEach((plt: any) => {
    let falta = false;

    // para cada finca

    proxFechas.forEach((date: any) => {
      let aux = plt.meteos.find((p: any) => date === p.dayDate);
      if (aux === undefined) {
        // Si aux no es undefined esa fecha existe
        falta = true;
      }
    });
    if (!falta) {
      // si aún no ha encontrado alguno que falte comprobamos si han pasado 8 horas
      // desde la anterior actualización
      let anterior = new Date();
      anterior.setHours(anterior.getHours() - 12);

      let aux2 = plt.meteos.find((p: any) => anterior > new Date(p.updateDate));
      if (aux2 !== undefined) {
        falta = true;
      }
    } else {
      getMeteoByPlantationCoords(plt.latitude, plt.longitude)
        .then((meteo: any) => {
          let x = new Date();
          let x2 = new Date(x.setDate(x.getDate() - 1));

          let indice = 0;

          meteo.daily.forEach((day: any) => {
            let fec = new Date(x.setDate(x.getDate() + 1));

            let post = {
              plantationId: plt.id,
              dt: day.dt,
              updateDate: new Date(),
              min: day.temp.min,
              max: day.temp.max,
              mm: day.rain ? day.rain : 0,
              icon: "/icon/climate/openweather/" + day.weather[0].icon + ".png",
              group: day.weather[0].description,
              dayDate: fec.toLocaleDateString(),
              day: fec.getDate(),
              month: fec.getMonth() + 1,
              year: fec.getFullYear(),
              cityId: null,
            };

            updateMeteo(post, plt.meteos[indice].id, token)
              .then((r: any) => {
                arr.push(r);
              })
              .catch((error) => {
                console.log("fallo meteo");
              });
            indice++;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  return arr;
};

export const checkMeteosForCities = (pls: any, token: string) => {
  // le estoy pasando todas las fincas
  let now = new Date();
  let arr: any = []; // Todos los días sueltos en el mismo array
  let proxFechas = [
    new Date(now.setDate(now.getDate() + 0)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
  ];

  pls.forEach((plt: any) => {
    let falta = false;

    // para cada finca

    proxFechas.forEach((date: any) => {
      let aux = plt.meteos.find((p: any) => date === p.dayDate);
      if (aux === undefined) {
        // Si aux no es undefined esa fecha existe
        falta = true;
      }
    });
    if (!falta) {
      // si aún no ha encontrado alguno que falte comprobamos si han pasado 8 horas
      // desde la anterior actualización
      let anterior = new Date();
      anterior.setHours(anterior.getHours() - 8);
      let aux2 = plt.meteos.find((p: any) => anterior > new Date(p.updateDate));
      if (aux2 !== undefined) {
        falta = true;
      }
    }

    if (falta) {
      getMeteoByPlantationCoords(plt.latitude, plt.longitude)
        .then((meteo: any) => {
          let x = new Date();
          let x2 = new Date(x.setDate(x.getDate() - 1));

          let indice = 0;

          meteo.daily.forEach((day: any) => {
            let fec = new Date(x.setDate(x.getDate() + 1));

            let post = {
              plantationId: null,
              dt: day.dt,
              updateDate: new Date(),
              min: day.temp.min,
              max: day.temp.max,
              mm: day.rain ? day.rain : 0,
              icon: "/icon/climate/openweather/" + day.weather[0].icon + ".png",
              group: day.weather[0].description,
              dayDate: fec.toLocaleDateString(),
              day: fec.getDate(),
              month: fec.getMonth() + 1,
              year: fec.getFullYear(),
              cityId: plt.id,
            };

            updateMeteo(post, plt.meteos[indice].id, token)
              .then((r: any) => {
                arr.push(r);
              })
              .catch((error) => {
                console.log("fallo meteo");
              });
            indice++;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  return arr;
};

export const checkMeteoByPlantation = (plt: any, smet: any, token: string) => {
  // le estoy pasando todas las fincas
  let now = new Date();
  let arr: any = []; // Todos los días sueltos en el mismo array
  let proxFechas = [
    new Date(now.setDate(now.getDate() + 0)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
    new Date(now.setDate(now.getDate() + 1)).toLocaleDateString(),
  ];

  let falta = false;

  // para cada finca

  proxFechas.forEach((date: any) => {
    let aux = plt.meteos.find((p: any) => date === p.dayDate);
    if (aux === undefined) {
      // Si aux no es undefined esa fecha existe
      falta = true;
    }
  });
  if (!falta) {
    // si aún no ha encontrado alguno que falte comprobamos si han pasado 12 horas
    // desde la anterior actualización
    let anterior = new Date();
    anterior.setHours(anterior.getHours() - 8);
    let aux2 = plt.meteos.find((p: any) => anterior > new Date(p.updateDate));
    if (aux2 !== undefined) {
      falta = true;
    }
  }

  if (falta) {
    getMeteoByPlantationCoords(plt.latitude, plt.longitude)
      .then((meteo: any) => {
        let x = new Date();
        let x2 = new Date(x.setDate(x.getDate() - 1));

        let indice = 0;

        meteo.daily.forEach((day: any) => {
          let fec = new Date(x.setDate(x.getDate() + 1));
          let post = {
            plantationId: plt.id,
            dt: day.dt,
            updateDate: new Date(),
            min: day.temp.min,
            max: day.temp.max,
            mm: day.rain ? day.rain : 0,
            icon: "/icon/climate/openweather/" + day.weather[0].icon + ".png",
            group: day.weather[0].description,
            dayDate: fec.toLocaleDateString(),
            day: fec.getDate(),
            month: fec.getMonth() + 1,
            year: fec.getFullYear(),
            cityId: null,
          };

          updateMeteo(post, plt.meteos[indice].id, token)
            .then((r: any) => {
              arr.push(r);
            })
            .catch((error) => {
              console.log("fallo meteo");
            });
          indice++;
        });
        smet(arr);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  } else {
    smet(plt.meteos);
  }
};

export const updateMeteo = (Meteo: any, id: number, token: string) => {
  return Axios.put(`${baseURL}Meteos/${id}`, Meteo, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};
