import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import TitlePlantationInfo from "../components/TitlePlantationInfo";
import { getPlantationVarietyQualityById } from "../utils/Requests/PlantationVarietyRequests";
import QualityRow from "../components/QualityRow";
import ModalPopup from "../components/ModalPopup";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { baseURL } from "../utils/constants";
import { createAnalysisToExport } from "../utils/Auxiliar/AnalysisAuxiliar";
import { getQualityReportByPlantationVarietyId } from "../utils/Requests/QualityReportRequests";
import { CSVLink } from "react-csv";

//middleware
import { useSelector, useDispatch } from "react-redux";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import INotification from "../interfaces/INotification";

import { RemoveNotificationAction } from "../redux/actions";

export interface IExcelForecastData {
  analysisDate: any;
  plantation: string;
  town: string;
  province: string;
  owner?: string;
  variety: string;
  juicePercentage: any;
  brix: number;
  sodiumHidroxide: number;
  acidity: number;
  madurity: number;
}

interface PlantationViewProps extends RouteComponentProps<{ id: string }> {}
// recojo el id de la finca-variedad
const QualitiesView: React.FC<PlantationViewProps> = ({ match }) => {
  const { role, token, companyId } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [plantation, setPlantation] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");
  const [mostrar, setMostrar] = useState<boolean>(true);

  const [excelAnalysisData, setExcelAnalysisData] = useState<
    IExcelForecastData[]
  >([]);

  const [showImages, setShowImages] = useState<boolean>(false);
  const [images, setImages] = useState<any[]>([]);

  const sourcing = token && role === "sourcing";
  const quality = token && role === "quality";

  useEffect(() => {
    if (token) {
      getSeasonByName(checkSeasonShort(), token)
        .then((season) => {
          getPlantationVarietyQualityById(
            parseInt(match.params.id),
            season.id,
            token
          )
            .then((plt) => {
              if (role === "qualityAssistant") {
                if (plt.plantation.companyId === companyId) {
                  setPlantation(plt);
                } else {
                  setMostrar(false);
                }
              } else {
                setPlantation(plt);
                exportToExcel(+match.params.id, season.id);
              }
            })
            .catch((error: string) => {
              setModalText(error.toString());
              setShow(true);
            });
        })
        .catch((error: string) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [match.params.id, token, role]);

  const exportToExcel = async (id: number, seasonId: string) => {
    let excelData: any = await getQualityReportByPlantationVarietyId(
      id,
      seasonId,
      token
    );

    setExcelAnalysisData(excelData);
  };

  //Excel headers
  const analysisHeaders = [
    { label: "Fecha", key: "analysisDate" },
    { label: "Finca", key: "plantation" },
    { label: "Municipio", key: "town" },
    { label: "Creador", key: "owner" },
    { label: "Variedad", key: "variety" },
    { label: "% Zumo", key: "juicePercentage" },
    { label: "º Brix", key: "brix" },
    { label: "NaOH", key: "sodiumHidroxide" },
    { label: "Acidez", key: "acidity" },
    { label: "Madurez", key: "madurity" },
  ];

  return (
    <div className="div-principal2">
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />

      <Modal
        show={showImages}
        onHide={() => {
          setShowImages(false);
        }}
        closeButton
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Carousel>
            {images.map(({ id, url }: any) => (
              <Carousel.Item key={id}>
                <div
                  className="image-carousel"
                  style={{
                    backgroundImage: `url(${baseURL}${url})`,
                  }}
                ></div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      <div className="top-panel">
        <div className="top-panel-text">Informes de Calidad Interna</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {mostrar ? (
        <>
          {" "}
          {(sourcing || quality) && excelAnalysisData && (
            <div style={{ marginTop: "1%", width:"4%", marginLeft:"77%" }}>
              <CSVLink
                data={excelAnalysisData}
                headers={analysisHeaders}
                filename={`analisisFinca${plantation?.plantation
                  ?.name!}Variedad${plantation?.variety?.name!}.csv`}
              >
                <button className="btn btn-md text-light export-button2">
                  Exportar a excel
                </button>
              </CSVLink>
            </div>
          )}
          <TitlePlantationInfo plantation={plantation} />
          <div className="table-style">
            <table
              className="table"
              style={{
                textAlign: "center",
                background: "white",
              }}
            >
              <thead className="thead-dark" style={{ background: "#242424" }}>
                <tr>
                  <th scope="col">FECHA</th>
                  {role !== "qualityAssistant" ? (
                    <th scope="col">CREADOR/A</th>
                  ) : (
                    <></>
                  )}

                  <th scope="col">% ZUMO</th>
                  <th scope="col">ºBx</th>
                  <th scope="col">NaOH (ml)</th>
                  <th scope="col">ACIDEZ</th>
                  <th scope="col">I.MADUREZ</th>
                  <th scope="col">IMÁGENES</th>
                  {role === "qualityAssistant" ? <th></th> : <></>}
                </tr>
              </thead>
              <tbody>
                {plantation?.qualityReports.map((report: any) => (
                  <QualityRow
                    report={report}
                    setShowImages={setShowImages}
                    setImages={setImages}
                    key={report.id}
                    rol={role}
                    token={token}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            marginTop: 100,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Informe de calidad privado
        </div>
      )}
    </div>
  );
};

export default QualitiesView;
