import React, { useState } from "react";
import "../theme/loginpage.css";
import { myPrivateKey } from "../utils/constants";
import { login, getUserInfo } from "../utils/Requests/LoginRequest";

//middleware
import { sign } from "jsonwebtoken";

//redux
import { useDispatch } from "react-redux";
import { SetUserAction } from "../redux/actions";

const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleLogin = async () => {
    setLoading(true);
    login(username, password)
      .then(async ({ id: token, userId }) => {
        let {
          username,
          role,
          companyId,
          officeId,
          name,
          surname1,
          surname2,
          company,
        }: any = await getUserInfo(userId, token);
        if (role !== "fieldManager") {
          setLoading(false);
          let fullname = name;
          if (surname1) {
            fullname = fullname + " " + surname1;
          }
          if (surname2) {
            fullname = fullname + " " + surname2;
          }
          let type;
          if (company) {
            type = company.type;
          }

          const token2 = sign(
            {
              userId,
              username,
              role,
              companyId,
              officeId,
              fullname,
              token,
              type,
            },
            myPrivateKey
          );

          sessionStorage.setItem("token2", token2);
          dispatch(
            SetUserAction({
              userId,
              username,
              role,
              companyId,
              officeId,
              fullname,
              token,
              type,
            })
          );
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Nombre de usuario o contraseña incorrecta.");
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  return (
    <div className="login-panel">
      <div className="row">
        <div className="col-sm-4 col-12"></div>
        <div
          className="col-sm-4 col-12"
          style={{ maxWidth: "350px", marginLeft: "55px" }}
        >
          <div>
            <div className="login-form">
              <h3>AGRIBIO</h3>
            </div>
            <div className="form-group mt-4">
              <input
                className="form-control mr-sm-1"
                type="text"
                placeholder="Usuario"
                value={username}
                onChange={(e) => {
                  setError("");
                  setUsername(e.target.value);
                }}
                required
              />
            </div>

            <div className="form-group">
              <input
                className="form-control mr-sm-1"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
                onKeyDown={({ key }) => key === "Enter" && handleLogin()}
                required
              />
            </div>
            <span className="text-danger">{error}</span>
            {/* <button className="btn text-primary btn-link ml-5">
              ¿Olvidaste la contraseña?
            </button> */}
            <button
              className="btn loginButton btn-block mt-3"
              disabled={password.length === 0 || username.length === 0}
              onClick={handleLogin}
            >
              {!loading ? (
                "Iniciar sesión"
              ) : (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
        <div className="col-sm-4 col-12"></div>
      </div>
    </div>
  );
};

export default Login;
