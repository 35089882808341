import Axios from "axios";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import { clearCalibres } from "../Auxiliar/CalibreAuxiliar";
import IVariety from "../../interfaces/IVariety";
import {
  sortVarietiesByCompanyWithId,
  sortVarietiesByCompany,
} from "../Auxiliar/VarietyAuxiliar";
import { baseURL } from "../constants";

export const getCalibresByVarietyId = (id: any, token: string) => {
  let filter = {
    include: {
      relation: "calibre",
    },
    where: { varietyId: id },
  };

  return Axios.get(
    `${baseURL}VarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = clearCalibres(response.data).sort(function (a, b) {
          if (a.code > b.code) {
            return 1;
          }
          if (a.code < b.code) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietyIdByName = (name: string, token: string) => {
  let filter = {
    where: {
      name: name,
    },
  };

  return Axios.get(`${baseURL}Varieties?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        // console.log(response);
        return Promise.resolve(response.data[0] as IVariety);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietiesByCompanyWithId = (
  companyName: string,
  token: string
) => {
  let filter = {
    fields: ["id", "name"],
    include: {
      relation: "plantations",
      scope: {
        fields: ["name", "id"],
        include: {
          relation: "plantationVarieties",
          scope: {
            include: {
              relation: "variety",
            },
          },
        },
      },
    },
    where: {
      name: companyName,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        if (data.length) {
          let varieties = sortVarietiesByCompanyWithId(data[0].plantations);
          return varieties;
        } else {
          return [];
        }
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietiesByCompanyId = (companyId: number, token: string) => {
  let filter = {
    fields: ["id", "name"],
    include: {
      relation: "plantations",
      scope: {
        fields: ["name", "id"],
        include: {
          relation: "plantationVarieties",
          scope: {
            include: {
              relation: "variety",
            },
          },
        },
      },
    },
    where: {
      id: companyId,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        if (data.length) {
          let varieties = sortVarietiesByCompanyWithId(data[0].plantations);
          return varieties;
        } else {
          return [];
        }
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietiesByCompany = (companyName: string, token: string) => {
  let filter = {
    fields: ["id", "name"],
    include: {
      relation: "plantations",
      scope: {
        fields: ["name", "id"],
        include: {
          relation: "plantationVarieties",
          scope: {
            include: {
              relation: "variety",
            },
          },
        },
      },
    },
    where: {
      name: companyName,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        if (data.length) {
          let varieties = sortVarietiesByCompany(data[0].plantations);
          return varieties.sort(function (a: any, b: any) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        } else {
          return [];
        }
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietyCalibreId = (
  varietyId: number,
  calibreId: number,
  token: string
) => {
  let filter = { where: { calibreId: calibreId, varietyId: varietyId } };

  return Axios.get(
    `${baseURL}VarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarCalIds = (id: number, token: string) => {
  let filter = { where: { id: id } };

  return Axios.get(
    `${baseURL}VarietyCalibres?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getVarietyNameFromVarietyId = (id: number, token: string) => {
  let filter = { where: { id: id } };

  return Axios.get(`${baseURL}Varieties?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data[0]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllVarietiesByCompanyId = (id: number, token: string) => {
  let filter = {
    fields: ["id", "name"],
    include: {
      relation: "plantations",
      scope: {
        fields: ["name", "id"],
        include: {
          relation: "plantationVarieties",
          scope: {
            include: {
              relation: "variety",
            },
          },
        },
      },
    },
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Companies?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        if (data.length) {
          let varieties = sortVarietiesByCompany(data[0].plantations);

          return varieties.sort(function (a: any, b: any) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        } else {
          return [];
        }
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllVarieties = (token: string) => {
  return Axios.get(`${baseURL}Varieties`, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};
