import React, { useState } from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import {
  getQualityReportImagesById,
  getImagesIdByQualityReport,
  deleteImageById,
} from "../utils/Requests/AttachmentsRequest";
import { deleteQualityReportById } from "../utils/Requests/QualityReportRequests";
import history from "../utils/Auxiliar/HistoryAuxiliar";
import Swal from "sweetalert2";
import INotification from "../interfaces/INotification";
import {
  getNotificationsByTypeAndDocId,
  deleteNotificationById,
} from "../utils/Requests/NotificationRequest";
import {
  treatNumber,
  treatNumber2,
} from "../utils/Auxiliar/EquivalentAuxiliar";

interface PlantationListItemProps {
  report: any;
  setShowImages: any;
  setImages: any;
  rol: any;
  token: string;
}

const QualityRow: React.FC<PlantationListItemProps> = ({
  report,
  setShowImages,
  setImages,
  rol,
  token,
}) => {
  const [show, setShow] = useState<boolean>(false);

  const [modalText, setModalText] = useState<string>("Error");

  const showReportImages = async (id: number) => {
    getQualityReportImagesById(report.id, token)
      .then((response) => {
        setImages(response);
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });

    setShowImages(true);
  };

  const deleteReport = async () => {
    getNotificationsByTypeAndDocId(1, report.id, token)
      .then((nots: INotification[]) => {
        nots.forEach((n: INotification) => {
          // eliminarlo de la base de datos
          deleteNotificationById(n?.id!, token)
            .then((not: any) => {})
            .catch((error: string) => {
              console.log(error.toString());
            });
        });
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });
    getImagesIdByQualityReport(report.id, token)
      .then((response) => {
        response.forEach((img: any) => {
          deleteImageById(img.id, token).then((res) => {});
        });
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });

    deleteQualityReportById(report.id, token)
      .then((r) => {
        // eliminar notificaciones asociadas
      })
      .catch((error: string) => {
        setModalText(error.toString());
        setShow(true);
      });
    setTimeout(() => openModalConfirm(), 600);
  };

  const openModalConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Informe de calidad eliminado correctamente.",
      showCancelButton: false,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        history.push("/informes-calidad");
      }
    });
  };
  const openModalDelete = () => {
    Swal.fire({
      icon: "warning",
      text: "¿Quieres eliminar un informe de calidad?",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "finishButton",
      },
    }).then(({ value }) => {
      if (value) {
        deleteReport();
      }
    });
  };
  return (
    <tr key={report.id}>
      <td>
        {new Date(report.record).toLocaleDateString()}{" "}
        {new Date(report.record).toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </td>
      {rol !== "qualityAssistant" ? <td>{report.owner}</td> : <></>}
      <td>{treatNumber2(parseFloat(report.juicePercentage))}</td>
      <td>{treatNumber(parseFloat(report.brix))}</td>
      <td>{treatNumber(parseFloat(report.sodiumHidroxide))}</td>
      <td>{treatNumber(parseFloat(report.acidity))}</td>
      <td>{treatNumber(parseFloat(report.madurity))}</td>
      <td
        style={{ color: "#cad401", cursor: "pointer", fontWeight: "bold" }}
        onClick={() => {
          showReportImages(report.id);
        }}
      >
        Ver
      </td>
      {rol === "qualityAssistant" ? (
        <td
          style={{ color: "#cad401", cursor: "pointer", fontWeight: "bold" }}
          onClick={openModalDelete}
        >
          Eliminar
        </td>
      ) : (
        <></>
      )}
    </tr>
  );
};

export default QualityRow;
