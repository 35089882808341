import React, { useEffect, useState } from "react";
import "../theme/homepage.css";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import history from "../utils/Auxiliar/HistoryAuxiliar";

import {
  getVarCalIds,
  getVarietyNameFromVarietyId,
} from "../utils/Requests/VarietyRequests";

import { getCompanyNameById } from "../utils/Requests/CompanyRequest";
import { getOfficeNameById } from "../utils/Requests/OfficeReqs";

import {
  getPurchasesForecastDetailsById,
  getPurchasesForecastById,
  getPurchaseForecastWeeksByCompanyId,
  getPurchasesForecastByWeekAndCompanyId,
  getLastPurchasesForecastByWeek,
  getPurchaseForecastInfo,
} from "../utils/Requests/PurchaseForecastReq";
import { getCalCodeFromCalId } from "../utils/Requests/CalibreReq";
import { useLocation, RouteComponentProps } from "react-router-dom";
import {
  constructDataBarChar,
  constructDataRadialChar,
} from "../utils/Auxiliar/PurchasesForecastAux";
import BarChart from "../components/BarChart";
import { IBarChartData } from "../interfaces/IBarCharData";
import RadialCharts from "../components/RadialCharts";
import { IRadialChartData } from "../interfaces/IRadialCharData";
import { colorGama } from "../utils/constants";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { constructAgribioPurchaseForecastData } from "../utils/Auxiliar/ForecastAuxiliar";
import { getSalestInfoByCompany } from "../utils/Requests/SalesReq";
import { sortCompanySalesData } from "../utils/Auxiliar/ProducerSalesAux";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

interface AgribioPurchaseDetailProps
  extends RouteComponentProps<{ id: string }> {}
export interface IAgribioSales {
  pickedVariety: string;
  pickedCalibre: string;
  tons: number;
}

const AgribioPurchaseDetail: React.FC<AgribioPurchaseDetailProps> = ({
  match,
}) => {
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);

  const [rawSalesData, setRawSalesData] = useState<any[]>([]);
  const [editedSalesData, setEditedSalesData] = useState<IAgribioSales[]>([]);

  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [barData, setBarData] = useState<IBarChartData[]>([]);
  const [radialData, setRadialData] = useState<IRadialChartData[]>([]);

  const [pickedVariety, setPickedVariety] = useState<string>("");

  const [pickedWeek, setPickedWeek] = useState<number>();

  const [height, setHeight] = useState(50);

  useEffect(() => {
    if (token) {
      getData();
    }
  }, [match.params.id, token]);

  const getData = async () => {
    const seasonName = checkSeasonShort();
    let { id: seasonId } = await getSeasonByName(seasonName, token);

    let pickedWeek = +match.params.id;

    setPickedWeek(pickedWeek);
    let rawSalesData = await getSalestInfoByCompany(
      companyId!,
      pickedWeek,
      seasonId,
      token
    );

    setRawSalesData(rawSalesData);

    let editedSalesData = sortCompanySalesData(
      rawSalesData,
      pickedWeek,
      seasonId,
      companyId!
    );

    setEditedSalesData(editedSalesData);

    let filteredData = constructAgribioPurchaseForecastData(editedSalesData);
    setFilteredData(filteredData);
   

    let barData = constructDataBarChar(editedSalesData);
    setBarData(barData);
    setHighestNumber(barData);

    let radialData = constructDataRadialChar(editedSalesData);
    setRadialData(radialData);
    setPickedVariety(editedSalesData[0]?.pickedVariety);
  };

  const handlePickedVariety = (variety: string) => {
    setPickedVariety(variety);

    let newRadialData = constructDataRadialChar(editedSalesData, variety);
    setRadialData(newRadialData);
  };

  const splitCode = (code: string) => {
    let num = code.match(/\d+/g);

    let letr = code.match(/[a-zA-Z]+/g);

    if (letr !== null && num !== null) {
      let newLetr = ",Sin Calibrar";
      let result = num!.toString();
      let char = result.concat(newLetr);
      return char;
    } else if (letr === null && num !== null) {
      let result = num[0].split("");
      let char = result.toString();
      return char;
    } else {
      let char = "Sin calibrar";
      return char;
    }
  };

  const pickCalibre = (calibre: any) => {
    const uniqueSet: any = new Set(calibre);
    const uniqueCalibre = [...uniqueSet];
    let char = uniqueCalibre.toString();
    return char;
  };

  const colorCondition = (variety: string) => {
    if (variety === pickedVariety) return true;
    else {
      return false;
    }
  };

  const setHighestNumber = (array: IBarChartData[]) => {
    let highestNumber = 0;

    for (var i = 0; i < array.length; i++) {
      if (highestNumber < array[i].y) {
        highestNumber = array[i].y;
      }
    }

    setHeight(highestNumber * 1.1);
    setHeight(highestNumber * 1.1);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <span>Previsión de compras</span>
      </div>
      <div className="semana-div3">
        Semana actual{" "}
        <div className="dia-div3">{setWeekNumber(new Date())}</div>
      </div>
      <div className="cointainer">
        <div className="row">
          <div className="col-4" style={{ marginLeft: "5%" }}>
            <input
              className="form-control form-control-sm mt-2  filterWeekInput"
              style={{ width: "12rem", fontWeight: "bold" }}
              data-spy="scroll"
              value={"Semana  " + pickedWeek}
              readOnly
            />
          </div>
          <div className="col-6"></div>
        </div>
      </div>
      <div
        style={{
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container pl-5">
          <div className="row mb-3 mt-2">
            <span
              style={{
                marginBottom: "2.5%",
                marginTop: "2.5%",
                fontWeight: "bold",
              }}
            >
              Volúmen (Tn)
            </span>
            <BarChart
              data={barData}
              data2={false}
              height={[0, height]}
              width={930}
              type={"Tn"}
            />
          </div>
          <div className="row justify-content-center">
            <span style={{ fontWeight: "bold" }}>Variedades</span>
          </div>
          <div className="row" style={{ paddingTop: "2vh" }}></div>
        </div>
      </div>

      <div
        style={{
          minHeight: "180px",
          width: "89%",
          backgroundColor: "white",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        <div className="container pl-3 pt-2">
          <div className="row mb-3 mt-3">
            <div className="col-sm-8 col-12">
              <table className="table text-center smallTable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">SEMANA</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">CALIBRE</th>
                    <th scope="col">VOLUMEN (Tn)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(
                    ({ t: tons, v: variety, c: calibre }, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: colorCondition(variety)
                            ? "#cad401"
                            : "",
                        }}
                        onClick={() => handlePickedVariety(variety)}
                      >
                        <th scope="row">{pickedWeek}</th>
                        <td>{variety}</td>
                        <td>{splitCode(calibre)}</td>
                        <td>{tons.toFixed(2)}</td>
                        <td
                          className="boldFontLink"
                          style={{
                            color: pickedVariety === variety ? "white" : "",
                          }}
                        >
                          {">"}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div className="col-sm-2 col-md-2 col-12">
              <h4>Tn/Calibre</h4>
              <RadialCharts data={radialData} />
            </div>
            <div className="col-sm-2 col-md-2 col-12 text-right">
              <h6 className="mb-4 mr-4">
                {pickedVariety ? pickedVariety : barData[0]?.x}
              </h6>

              {radialData.map(({ angle, label, color }, index) => (
                <div
                  key={index}
                  className="d-flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div
                    className="circle"
                    style={{ backgroundColor: `${color}` }}
                  />
                  {label !== "Cualquiera" && <p> Calibre : {angle} </p>}
                  {label === "Cualquiera" && <p> Sin calibrar: {angle} </p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgribioPurchaseDetail;
