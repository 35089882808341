import React, { useState, useEffect } from "react";
import "../theme/qualitylist.css";
import { searchPlantationsByGGPlantationAndOwner2 } from "../utils/Auxiliar/PlantationAuxiliar";
import {
  getActivesPlantationVarieties,
  getActivesPlantationVarietiesByCompany,
} from "../utils/Requests/PlantationVarietyRequests";
import QualityTableRow from "../components/QualityTableRow";
import { collectVarieties } from "../utils/Auxiliar/VarietyAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { getAllCertifications } from "../utils/Requests/CertificationRequest";
import { getAllCompanies } from "../utils/Requests/CompanyRequest";
import { filterHandlerNoRainNoRecolect } from "../utils/Auxiliar/SearchAuxiliar";
import Form from "react-bootstrap/Form";
import ModalPopup from "../components/ModalPopup";

//redux
import { useSelector } from "react-redux";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import INotification from "../interfaces/INotification";
import { normalizer } from "../utils/Auxiliar/EquivalentAuxiliar";

const QualityReportsList: React.FC = () => {
  const { role, companyId, token } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [searchText, setSearchText] = useState<string>("");
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [plantations, setPlantations] = useState<any[]>([]);
  const [plantationsTable, setPlantationsTable] = useState<any[]>([]);
  const [varieties, setVarieties] = useState([]);
  const [plantationsSearch, setPlantationsSearch] = useState<any[]>([]);
  const [numberShow, setNumberShow] = useState<number>(10);
  const [show, setShow] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("Error");

  const [company, setCompany] = useState<string>("Cualquier productor");
  const [companies, setcompanies] = useState([]);

  const [certificacion, setCertificacion] = useState<string>(
    "Cualquier certificación"
  );
  const [certifications, setCertificacions] = useState([]);
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );

  useEffect(() => {
    if (token && role) {
      getSeasonByName(checkSeasonShort(), token)
        .then((season: any) => {
          if (role === "qualityAssistant") {
            getActivesPlantationVarietiesByCompany(season, companyId, token)
              .then((pls: any) => {
                let plsAux: any[] = [];
                pls.forEach((p: any) => {
                  plsAux.push({ new: false, pls: p });
                });
                setPlantations(plsAux);
                setPlantationsSearch(plsAux);
                setPlantationsTable(plsAux.slice(0, 10));
                let aux = collectVarieties(pls).filter(
                  (rv: any) => !rv.varietyName.includes("Hoja")
                );

                aux.sort(function (a: any, b: any) {
                  if (a.varietyName > b.varietyName) {
                    return 1;
                  }
                  if (a.varietyName < b.varietyName) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                aux.unshift({
                  id: -1,
                  varietyName: "Cualquier variedad",
                  productName: "none",
                });

                setVarieties(aux);

                getAllCertifications(token)
                  .then((ctf: any) => {
                    setCertificacions(ctf);
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          } else if (role === "quality" || "sourcing") {
            let notsQ: INotification[] | undefined = notifications?.filter(
              (n: INotification) => n.type === 1
            );
            getActivesPlantationVarieties(season, token)
              .then((pls: any) => {
                // debo comprobar si alguno de los qualityReports es de los nots
                let plsAux: any[] = [];
                pls.forEach((p: any) => {
                  let encontrado: boolean = false;
                  notsQ?.forEach((n: INotification) => {
                    if (!encontrado) {
                      let aux = p.qualityReports.find(
                        (q: any) => q.id === n.docId
                      );
                      if (aux) {
                        encontrado = true;
                      }
                    }
                  });
                  plsAux.push({ new: encontrado, pls: p });
                });

                setPlantations(plsAux);
                setPlantationsSearch(plsAux);
                let aux = collectVarieties(pls).filter(
                  (rv: any) => !rv.varietyName.includes("Hoja")
                );

                aux.sort(function (a: any, b: any) {
                  if (a.varietyName > b.varietyName) {
                    return 1;
                  }
                  if (a.varietyName < b.varietyName) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                aux.unshift({
                  id: -1,
                  varietyName: "Cualquier variedad",
                  productName: "none",
                });

                setVarieties(aux);

                setPlantationsTable(plsAux.slice(0, 10));
                getAllCompanies(token)
                  .then((companies: any) => {
                    setcompanies(companies);
                    getAllCertifications(token)
                      .then((ctf: any) => {
                        setCertificacions(ctf);
                      })
                      .catch((error) => {
                        setModalText(error.toString());
                        setShow(true);
                      });
                  })
                  .catch((error) => {
                    setModalText(error.toString());
                    setShow(true);
                  });
              })
              .catch((error) => {
                setModalText(error.toString());
                setShow(true);
              });
          }
        })
        .catch((error) => {
          setModalText(error.toString());
          setShow(true);
        });
    }
  }, [role, notifications, token]);

  const searchHanlder = (event: any) => {
    setVariety("Cualquier variedad");
    setCompany("Cualquier productor");
    setCertificacion("Cualquier certificación");
    setSearchText(event.target.value);
    setNumberShow(10);
    let ax: any = searchPlantationsByGGPlantationAndOwner2(
      plantations,
      normalizer(event.target.value)
    );
    setPlantationsSearch(ax);
    setPlantationsTable(ax.slice(0, 10));
  };

  const nextPage = () => {
    setPlantationsTable(plantationsSearch.slice(0, numberShow + 5));
    setNumberShow(numberShow + 5);
  };

  const companyHandler = (event: any) => {
    setSearchText("");
    setNumberShow(10);
    setCompany(event.target.value);
    filterHandlerNoRainNoRecolect(
      event.target.value,
      variety,
      certificacion,
      plantations,
      varieties,
      setPlantationsSearch,
      setPlantationsTable
    );
  };
  const varietyHandler = (event: any) => {
    setSearchText("");
    setNumberShow(10);
    setVariety(event.target.value);
    filterHandlerNoRainNoRecolect(
      company,
      event.target.value,
      certificacion,
      plantations,
      varieties,
      setPlantationsSearch,
      setPlantationsTable
    );
  };

  const certificationHandler = (event: any) => {
    setSearchText("");
    setNumberShow(10);
    setCertificacion(event.target.value);
    filterHandlerNoRainNoRecolect(
      company,
      variety,
      event.target.value,
      plantations,
      varieties,
      setPlantationsSearch,
      setPlantationsTable
    );
  };

  const clearSearch = () => {
    setVariety("Cualquier variedad");
    setCertificacion("Cualquier certificación");
    setCompany("Cualquier productor");
    setPlantationsSearch(plantations);
    setPlantationsTable(plantations.slice(0, 10));
    setNumberShow(10);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Informes de Calidad Interna</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      <ModalPopup show={show} setShow={setShow} modalText={modalText} />
      <div className="select-divider-search">
        <Form.Control
          className="search-select"
          size="sm"
          type="text"
          placeholder="Buscar finca, agricultor, GGN ..."
          value={searchText}
          onChange={searchHanlder}
          style={{ width: 250, height: 31 }}
        />
        {role !== "qualityAssistant" ? (
          <Form.Control
            onChange={companyHandler}
            value={company}
            as="select"
            size="sm"
            style={{ width: 170 }}
            className="search-select"
          >
            <option>Cualquier productor</option>
            {companies.length > 0 ? (
              companies.map((va: any) => (
                <option key={va.name} value={va.name}>
                  {va.name}
                </option>
              ))
            ) : (
              <></>
            )}
          </Form.Control>
        ) : (
          <></>
        )}

        <Form.Control
          as="select"
          size="sm"
          style={{ width: 160 }}
          value={variety}
          onChange={varietyHandler}
          className="search-select"
        >
          {varieties.length > 0 ? (
            varieties.map((va: any) => (
              <option key={va.varietyName} value={va.varietyName}>
                {va.varietyName}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>
        <Form.Control
          as="select"
          size="sm"
          style={{ width: 180 }}
          onChange={certificationHandler}
          value={certificacion}
          className="search-select"
        >
          <option>Cualquier certificación</option>
          {certifications.length > 0 ? (
            certifications.map((va: any) => (
              <option key={va.name} value={va.name}>
                {va.name}
              </option>
            ))
          ) : (
            <></>
          )}
        </Form.Control>

        <button className="button-search" onClick={() => clearSearch()}>
          Quitar filtros
        </button>
      </div>

      <div className="table-style">
        <table
          className="table"
          style={{
            textAlign: "center",
            background: "white",
          }}
        >
          <thead className="thead-dark" style={{ fontSize: 14 }}>
            <tr>
              <th scope="col"></th>
              <th scope="col">FECHA</th>
              <th scope="col">CÓDIGO GG</th>
              <th scope="col">FINCA</th>
              <th scope="col">AGRICULTOR</th>
              {role !== "qualityAssistant" ? <th>PRODUCTOR</th> : <></>}
              <th scope="col">VARIEDAD</th>
              <th scope="col">INF. DE CALIDAD</th>
            </tr>
          </thead>
          <tbody>
            {plantationsTable.map((plt: any) => (
              <QualityTableRow key={plt.pls.id} plt={plt} token={token} />
            ))}
          </tbody>
        </table>

        {plantationsSearch.length > numberShow ? (
          <div className="more-button-align">
            <button className="more-button" onClick={() => nextPage()}>
              Ver más
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default QualityReportsList;
