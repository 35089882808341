import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import "../theme/multimedia.css";

import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getPlantations3 } from "../utils/Requests/PlantationRequests";
import IPlantation from "../interfaces/IPlantation";
import { getAllPlantationsVarieties } from "../utils/Requests/PlantationVarietyRequests";

import {
  getPriorAnalysesImages,
  getHarvestReportImages,
  getQualityReportImages,
  getImageById,
} from "../utils/Requests/AttachmentsRequest";
import {
  createHarvestReportImagesArray,
  searchSourcingImages,
  createPriorAnalysisImagesArray,
  createQualityReportImagesArray,
} from "../utils/Auxiliar/MultimediaAuxiliar";
import Card from "react-bootstrap/Card";
import { baseURL } from "../utils/constants";
import { getAllCompanies } from "../utils/Requests/CompanyRequest";
import { IStore } from "../interfaces/IStore";
import { useSelector } from "react-redux";
import { IUser } from "../interfaces/IUser";
import ReactPlayer from "react-player";

const SourcingMultimedia: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [plantations, setPlantations] = useState<IPlantation[]>([]);
  const [pltVarieties, setPltVarieties] = useState<[]>([]);
  const [varieties, setVarieties] = useState<[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [plantation, setPlantation] = useState<string>("Todas las fincas");
  const [company, setCompany] = useState<string>("Todos los productores");
  const [seasonId, setSeasonId] = useState<any>();
  const [priorImages, setPriorImages] = useState<any[]>([]);
  const [harvestImages, setHarvestImages] = useState<any[]>([]);
  const [qualityImages, setQualityImages] = useState<any[]>([]);
  const [totalImages, setTotalImages] = useState<any[]>([]);
  const [imagesToShow, setImagesToShow] = useState<any[]>([]);
  const [imagesIndex, setImagesIndex] = useState(12);

  const { token } = useSelector(({ user }: IStore) => user as IUser);

  useEffect(() => {
    if (token) {
      const season = checkSeasonShort();

      getSeasonByName(season, token)
        .then((response) => {
          setSeasonId(response.id);
          getAllCompanies(token)
            .then((companies) => {
              setCompanies(companies);

              getPlantations3(token)
                .then((plantations) => {
                  setPlantations(plantations);
                  getAllPlantationsVarieties(
                    plantations,
                    setPltVarieties,
                    token
                  )
                    .then(async (varieties: any) => {
                      varieties.sort(function (a: any, b: any) {
                        if (a.varietyName > b.varietyName) {
                          return 1;
                        }
                        if (a.varietyName < b.varietyName) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      });

                      varieties.unshift({
                        id: -1,
                        varietyName: "Cualquier variedad",
                        productName: "none",
                      });

                      setVarieties(varieties);

                      let rawPriorImages = await getPriorAnalysesImages(token);
                      console.log(rawPriorImages);
                      let priorImages = createPriorAnalysisImagesArray(
                        rawPriorImages
                      );
                      setPriorImages(priorImages);

                      let rawHarvest = await getHarvestReportImages(token);
                      let harvestImages = createHarvestReportImagesArray(
                        rawHarvest
                      );

                      setHarvestImages(harvestImages);

                      let rawQuality = await getQualityReportImages(token);

                      //below line has issues
                      let qualityImages = createQualityReportImagesArray(
                        rawQuality
                      );

                      setQualityImages(qualityImages);

                      concatImages(
                        priorImages,
                        harvestImages,
                        qualityImages,
                        response.id
                      );
                    })
                    .catch((error) => {
                      setAlertText(error.toString());
                      setShowAlert(true);
                    });
                })
                .catch((error) => {
                  setAlertText(error.toString());
                  setShowAlert(true);
                });
            })
            .catch((error) => {
              setAlertText(error.toString());
              setShowAlert(true);
            });
        })
        .catch((error) => {
          setAlertText(error.toString());
          setShowAlert(true);
        });
    }
  }, [token]);

  const concatImages = (
    priorImages: any,
    harvestImages: any,
    qualityImages: any,
    seasonId: number
  ) => {
    let images2 = priorImages.concat(harvestImages, qualityImages);

    images2.sort(function (a: any, b: any) {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    });

    setTotalImages(images2);
    setImagesToShow(images2);
    console.log(images2);
  };

  const searchCompanyHandler = (event: any) => {
    let company = event.target.value;
    setCompany(company);
    searchSourcingImages(
      company,
      plantation,
      variety,
      setImagesToShow,
      totalImages
    );
  };

  const searchPlantationHandler = (event: any) => {
    let plantation = event.target.value;
    setPlantation(plantation);
    searchSourcingImages(
      company,
      plantation,
      variety,
      setImagesToShow,
      totalImages
    );
  };

  const searchVarietyHandler = (event: any) => {
    let variety = event.target.value;
    setVariety(variety);

    searchSourcingImages(
      company,
      plantation,
      variety,
      setImagesToShow,
      totalImages
    );
  };

  const clearFilters = () => {
    setPlantation("Todas las fincas");
    setVariety("Cualquier variedad");
    setCompany("Todos los productores");
    setImagesToShow(totalImages);
  };

  const downloadImage = async (url: any, variety: string, type: string) => {
    getImageById(url, token).then((response) => {
      console.log(response);
      const blob = new Blob([response], { type: type });
      saveAs(blob, variety);
    });
  };

  const downloadVideo = async (url: any, variety: string, type: string) => {
    console.log("entrooo");

    getImageById(url, token).then((response) => {
      console.log(response);
      const blob = new Blob([response], { type: type });
      saveAs(blob, variety);
    });
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Multimedia</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>

      <div className="select-groupCO">
        <select
          className="form-control form-control-sm ml-3"
          style={{ width: "12rem" }}
          data-spy="scroll"
          value={company}
          onChange={searchCompanyHandler}
        >
          <option>Todos los productores</option>
          {companies.map((co: any) => (
            <option key={co.id} value={co.name}>
              {co.name}
            </option>
          ))}
        </select>
        <select
          className="form-control form-control-sm ml-3"
          style={{ width: "12rem" }}
          data-spy="scroll"
          value={plantation}
          onChange={searchPlantationHandler}
        >
          <option>Todas las fincas</option>
          {plantations.map((pla: any) => (
            <option key={pla.name} value={pla.name}>
              {pla.name}
            </option>
          ))}
        </select>
        <select
          className="form-control form-control-sm ml-3"
          style={{ width: "12rem" }}
          data-spy="scroll"
          value={variety}
          onChange={searchVarietyHandler}
        >
          {varieties.map((va: any) => (
            <option key={va.varietyName} value={va.varietyName}>
              {va.varietyName}
            </option>
          ))}
        </select>

        <button
          className="btn btn-md button-search"
          style={{ height: 30 }}
          onClick={() => clearFilters()}
        >
          Quitar filtros
        </button>
      </div>

      <div className="container">
        <div className="row mt-5 ml-3">
          {imagesToShow
            .slice(0, imagesIndex)
            ?.map(({ id, url, type, variety }: any) => (
              <div key={id} className="col-4 ">
                <Card
                  style={{
                    width: "19rem",
                    height: "14.3rem",
                    borderRadius: "5%",
                  }}
                  className="cardStyle"
                >
                  <Card.Body>
                    {type.includes("image") ? (
                      <img
                        alt={""}
                        className="multimedia2"
                        src={`${baseURL}${url}`}
                        onClick={() => downloadImage(url, variety, type)}
                      />
                    ) : (
                      <>
                        {console.log(url)}
                        <ReactPlayer
                          className="multimedia2"
                          url={`${baseURL}${url}`}
                          width="100%"
                          height="100%"
                          controls={true}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                                disablePictureInPicture: true,
                              },
                            },
                          }}
                          onContextMenu={(e: any) => e.preventDefault()}
                          onClick={() => downloadVideo(url, variety, type)}
                        />
                      </>
                    )}
                  </Card.Body>
                </Card>

                <br />
              </div>
            ))}
        </div>
        {imagesToShow.length && imagesToShow.length > imagesIndex ? (
          <div className="d-flex justify-content-center mb-3 ">
            <span
              className="boldFontLink"
              onClick={() => setImagesIndex(imagesIndex + 5)}
            >
              Ver más
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SourcingMultimedia;
