import React from "react";
import "../theme/plantation.css";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import INotification from "../interfaces/INotification";
import { useSelector, useDispatch } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { deleteNotificationById } from "../utils/Requests/NotificationRequest";
import { RemoveNotificationAction } from "../redux/actions";

interface PlantationListItemProps {
  plt: any;
  token: any;
}

const QualityTableRow: React.FC<PlantationListItemProps> = ({ plt, token }) => {
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );
  const dispatch = useDispatch();

  const removeNotifications = () => {
    if (plt.new) {
      let notsQ: INotification[] | undefined = notifications?.filter(
        (n: INotification) => n.type === 1
      );
      notsQ.forEach((n: INotification) => {
        let aux = plt.pls.qualityReports.find((q: any) => q.id === n.docId);
        if (aux) {
          dispatch(RemoveNotificationAction(n?.id!));
          // eliminarlo de la base de datos
          deleteNotificationById(n?.id!, token)
            .then((not: any) => {})
            .catch((error: string) => {
              console.log(error.toString());
            });
        }
      });
    }
  };

  return (
    <tr key={plt.pls.id}>
      <td>
        {plt.new ? (
          <svg
            color="#15d60f"
            width="15"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="circle"
            className="svg-inline--fa fa-circle fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
            ></path>
          </svg>
        ) : (
          <></>
        )}
      </td>
      {plt.pls.qualityReports[0] ? (
        <td>
          {new Date(plt.pls.qualityReports[0].record).toLocaleDateString()}
        </td>
      ) : (
        <td>No hay informe</td>
      )}
      <td>{plt.pls.plantation.ggNumber}</td>
      <td>{plt.pls.plantation.name}</td>
      <td>{plt.pls.plantation.owner}</td>
      {plt.pls.plantation.company ? (
        <td>{plt.pls.plantation.company.name}</td>
      ) : (
        <></>
      )}

      <td>{plt.pls.variety.name}</td>
      {plt.pls.qualityReports.length > 0 ? (
        <td>
          <Link
            to={`/informes-calidad/${plt.pls.id}`}
            onClick={removeNotifications}
          >
            <b style={{ color: "#cad401" }}>Ver</b>
          </Link>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

export default QualityTableRow;
