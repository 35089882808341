import Axios from "axios";
import IPlantation from "../../interfaces/IPlantation";
import { locateError } from "../Auxiliar/ErrorAuxiliar";
import {
  cleanEmptyProvinces,
  collectProvinces,
} from "../Auxiliar/ProvinceAuxiliar";

import { baseURL } from "../constants";

export const getPlantationNameById = (id: number, token: string) => {
  let filter = {
    // Filtro para recoger el nombre de una finca con su id
    fields: ["name"],
    where: {
      id: id,
    },
  };
  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationIdByPlantationVarietyId = (
  id: number,
  token: string
) => {
  let filter = {
    // Filtro para recoger el nombre de una finca con su id
    fields: ["id", "plantationId"],
    where: {
      id: id,
    },
  };
  return Axios.get(
    `${baseURL}PlantationVarieties?filter=` + JSON.stringify(filter),
    {
      headers: {
        Authorization: token,
      },
    }
  )

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0].plantationId as number);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationsById = (id: number, token: string) => {
  /* LLAMADA PARA RECOGER FINCAS CON PRODUCTOS, VARIEDADES,
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: [
      "name",
      "sigpac",
      "gg",
      "ggNumber",
      "grasp",
      "calendar",
      "demeter",
      "biosuisse",
      "otros",
      "area",
      "latitude",
      "longitude",
      "locationId",
      "plantationId",
      "owner",
      "id",
    ],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                where: { capacity: { neq: null } },
                order: "createdAt DESC",
                limit: 1,
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationQualityById = (
  id: number,
  season: any,
  token: string
) => {
  /* LLAMADA PARA RECOGER FINCAS CON PRODUCTOS, VARIEDADES,
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: [
      "name",
      "sigpac",
      "gg",
      "ggNumber",
      "grasp",
      "calendar",
      "demeter",
      "biosuisse",
      "otros",
      "area",
      "latitude",
      "longitude",
      "locationId",
      "plantationId",
      "owner",
      "id",
    ],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                where: { capacity: { neq: null } },
                order: "createdAt DESC",
                limit: 1,
              },
            },
            {
              relation: "qualityReports",
              scope: {
                limit: 1,
                where: { seasonId: season.id },
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationByIdForProductor = (id: number, token: string) => {
  /* LLAMADA PARA RECOGER FINCAS CON PRODUCTOS, VARIEDADES,
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: [
      "name",
      "sigpac",
      "gg",
      "ggNumber",
      "grasp",
      "calendar",
      "demeter",
      "biosuisse",
      "otros",
      "area",
      "latitude",
      "longitude",
      "locationId",
      "plantationId",
      "owner",
      "id",
      "companyId",
      "staticAforo",
    ],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],

                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                where: { capacity: { neq: null } },
                order: "createdAt DESC",
                limit: 1,
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
      {
        relation: "meteos",
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationByOwnerName = (
  name: string,
  cid: number,
  token: string
) => {
  /* LLAMADA PARA RECOGER FINCAS CON PRODUCTOS, VARIEDADES,
    LOCALIZACIONES Y EL ULTIMO ANALISIS PREVIO DE CADA PRODUCTO-VARIEDAD ACTIVA */

  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: [
      "owner",
      "name",
      "locationId",
      "plantationId",
      "owner",
      "id",
      "companyId",
    ],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
    ],
    where: {
      owner: name,
      companyId: cid,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAnalysesByPlantationId = (id: number, token: string) => {
  /* LLAMADA PARA RECOGER NOMBRE FINCA, 
    VARIEDADES ACTIVAS, Y LA FECHA DE SU ULTIMO INFORME SI EXISTIERA */
  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: ["name", "plantationId", "id"],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId"],
          include: [
            {
              relation: "variety",
              scope: {
                fields: ["name", "id", "productId"],
                include: {
                  relation: "product",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            {
              relation: "priorAnalyses",
              scope: {
                order: "createdAt DESC",
                limit: 1,
              },
            },
          ],
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationById = (id: number, token: string) => {
  let filter = {
    // Filtro para recoger las fincas con sus productos y variedades

    fields: [
      "name",
      "owner",
      "area",
      "latitude",
      "longitude",
      "locationId",
      "plantationId",
      "id",
      "description",
      "sigpac",
      "gg",
      "ggNumber",
      "grasp",
      "demeter",
      "biosuisse",
      "otros",
      "calendar",
      "companyId",
      "staticAforo",
    ],
    include: [
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "varietyId", "active"],
          include: {
            relation: "variety",
            scope: {
              fields: ["name", "id", "productId"],
              include: {
                relation: "product",
                scope: {
                  fields: ["name"],
                },
              },
            },
          },
          where: { active: 1 }, // 1 activo 0 inactivo -> solo traigo los activos
        },
      },
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name"],
            },
          },
        },
      },
    ],
    where: {
      id: id,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationsByCompanyId = (id: number, token: string) => {
  let filter = {
    // Filtro para recoger las fincas asociadas a una empresa
    fields: ["name", "locationId", "id"],
    include: {
      relation: "location",
      scope: {
        fields: ["name", "provinceId"],
        include: {
          relation: "province",
          scope: {
            fields: ["name"],
          },
        },
      },
    },
    where: {
      companyId: id,
    },
    order: "name ASC",
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        // console.log(response);
        return Promise.resolve(response.data as IPlantation[]);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationsByCompany = (
  companyId: number,
  sp: any,
  token: string,
  seasonId: string
) => {
  let filter = {
    order: "name ASC",
    fields: [
      "name",
      "locationId",
      "id",
      "latitude",
      "longitude",
      "owner",
      "gg",
      "ggNumber",
      "grasp",
      "biosuisse",
      "demeter",
    ],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name", "id"],
            },
          },
        },
      },
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "active"],
          where: { active: 1 },
          include: [
            {
              relation: "cutOrders",
              scope: {
                include: { relation: "harvestReports" },
                scope: { where: { seasonId: seasonId } },
              },
            },
            {
              relation: "priorAnalyses",
              scope: { where: { seasonId: seasonId } },
            },
          ],
        },
      },
      {
        relation: "meteos",
      },
    ],
    where: {
      companyId: companyId,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        sp(collectProvinces(aux));
        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOnlyPlantationsByCompany = (
  companyId: number,
  token: string,
  seasonId: any
) => {
  let filter = {
    order: "name ASC",
    fields: [
      "name",
      "locationId",
      "id",
      "latitude",
      "longitude",
      "owner",
      "gg",
      "ggNumber",
      "grasp",
      "biosuisse",
      "demeter",
    ],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name", "id"],
            },
          },
        },
      },
      {
        relation: "meteos",
      },
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "active"],
          where: { active: 1 },
          include: [
            {
              relation: "cutOrders",
              scope: {
                include: { relation: "harvestReports" },
                scope: { where: { seasonId: seasonId } },
              },
            },
            {
              relation: "priorAnalyses",
              scope: { where: { seasonId: seasonId } },
            },
          ],
        },
      },
    ],
    where: {
      companyId: companyId,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getOnlyPlantationsByCompany2 = (
  companyId: number | null,
  token: string
) => {
  let filter = {
    order: "name ASC",
    fields: ["name", "locationId", "id"],
    include: {
      relation: "location",
      scope: {
        fields: ["name", "provinceId"],
        include: {
          relation: "province",
          scope: {
            fields: ["name", "id"],
          },
        },
      },
    },
    where: {
      companyId: companyId,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAllPlantations = (token: string, seasonId: string) => {
  let filter = {
    order: "name ASC",
    fields: [
      "name",
      "locationId",
      "id",
      "latitude",
      "longitude",
      "owner",
      "ggNumber",
      "gg",
      "demeter",
      "grasp",
      "biosuisse",
      "companyId",
    ],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name", "id"],
            },
          },
        },
      },
      { relation: "meteos" },
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "active"],
          where: { active: 1 },
          include: [
            {
              relation: "cutOrders",
              scope: {
                include: { relation: "harvestReports" },
                scope: { where: { seasonId: seasonId } },
              },
            },
            {
              relation: "priorAnalyses",
              scope: { where: { seasonId: seasonId } },
            },
          ],
        },
      },
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
    ],
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantationIdByName = (name: string, token: string) => {
  let filter = {
    fields: ["name", "id"],

    where: {
      name: name,
    },
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        // console.log(response);
        return Promise.resolve(response.data[0] as IPlantation);
      }
    })
    .catch((err) => Promise.reject(err));
};
export const getAllPlantations2 = (seasonId: number, token: string) => {
  let filter = {
    order: "name ASC",
    fields: [
      "name",
      "locationId",
      "id",
      "latitude",
      "longitude",
      "owner",
      "gg",
      "grasp",
      "biosuisse",
      "demeter",
      "ggNumber",
      "companyId",
    ],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name", "id"],
            },
          },
        },
      },
      {
        relation: "plantationVarieties",
        scope: {
          fields: ["id", "active"],
          where: { active: 1 },
          include: [
            {
              relation: "cutOrders",
              scope: {
                include: { relation: "harvestReports" },
                scope: { where: { seasonId: seasonId } },
              },
            },
            {
              relation: "priorAnalyses",
              scope: { where: { seasonId: seasonId } },
            },
          ],
        },
      },
      {
        relation: "meteos",
      },
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
    ],
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const editPlantation = (info: any, id: number, token: any) => {
  return Axios.patch(`${baseURL}Plantations/${id}`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
    });
};

export const saveNewPlantation = (info: any, token: any) => {
  return Axios.post(`${baseURL}Plantations`, info, {
    headers: {
      Authorization: token,
    },
  })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(locateError(status));
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });
};

export const getAllPlantationsForAdmin = (token: string) => {
  let filter = {
    order: "name ASC",
    fields: ["name", "locationId", "id", "owner", "companyId"],
    include: [
      {
        relation: "location",
        scope: {
          fields: ["name", "provinceId"],
          include: {
            relation: "province",
            scope: {
              fields: ["name", "id"],
            },
          },
        },
      },
      {
        relation: "company",
        scope: {
          fields: ["name", "id"],
        },
      },
    ],
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        let aux = cleanEmptyProvinces(response.data).sort(function (a, b) {
          if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
            return 1;
          }
          if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        return Promise.resolve(aux);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPlantations3 = (token: string) => {
  let filter = {
    // Filtro para recoger las fincas asociadas a una empresa
    fields: ["name", "locationId", "id"],
    include: {
      relation: "location",
      scope: {
        fields: ["name", "provinceId"],
        include: {
          relation: "province",
          scope: {
            fields: ["name"],
          },
        },
      },
    },
    order: "name ASC",
  };

  return Axios.get(`${baseURL}Plantations?filter=` + JSON.stringify(filter), {
    headers: {
      Authorization: token,
    },
  })

    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(locateError(response.status));
      } else {
        return Promise.resolve(response.data as IPlantation[]);
      }
    })
    .catch((err) => Promise.reject(err));
};
