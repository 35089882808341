import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import "../../theme/dashboard.css";
import { List, ListItem, withStyles } from "@material-ui/core";
import { checkSeason } from "../../utils/Auxiliar/SeasonAuxiliar";
import BotSide from "./BotSide";
import { getNavbarIndex } from "../../utils/Auxiliar/NavbarAuxiliar";

//logo
import logo from "../../icons/logo-agribio.png";

/*            GUÍA DE ÍNDICES
          /home / -> 0
          /informes-calidad -> 1
          /crear-informe-calidad -> 2
          /fincas -> 3
          /previsión compras -> 4
          /compras-reales -> 5
          /órdenes de corte -> 6
          /crear orden de corte -> 7
          /partes de recogida -> 8
          /disponibilidad -> 9
          /multimedia -> 10
          /añadir disponibilidad -> 11
          /compras-definitivas -> 12
          /crear-compras-definitivas -> 13
          /prevision-compras-productor -> 14
          /crear-prevision-productor -> 15
*/

export default function AgribioNavbar() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    getNavbarIndex(window.location.href, setSelectedIndex);
  }, []);

  const StyledListItem = withStyles({
    root: {
      backgroundColor: "#242424",
      "&$selected": {
        backgroundColor: "#000000",
        color: "#cad401",
      },
      "&$selected, &$selected:hover": {
        backgroundColor: "#000000",
      },

      "&:hover": {
        textDecoration: "none",
        color: "#cad401",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    selected: {},
  })(ListItem);

  const season = checkSeason();
  return (
    <div>
      <div className="top-bar">
        <img className="logo" src={logo} alt=""/>
      </div>
      <div className="mid-bar">CAMPAÑA {season}</div>
      <List className="sidebar" component="nav" disablePadding dense>
        <StyledListItem
          selected={selectedIndex === 0}
          style={{ paddingLeft: 18 }}
          button
          component={Link}
          to="/"
          onClick={() => setSelectedIndex(0)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="home"
              className="svg-inline--fa fa-home fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
              ></path>
            </svg>
            Inicio
          </ListItemText>
        </StyledListItem>

        <StyledListItem
          selected={selectedIndex === 3}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/fincas"
          onClick={() => setSelectedIndex(3)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="warehouse"
              className="svg-inline--fa fa-warehouse fa-w-20"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
              ></path>
            </svg>
            Fincas
          </ListItemText>
        </StyledListItem>
        <StyledListItem
          selected={selectedIndex === 10}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/multimedia"
          onClick={() => setSelectedIndex(10)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="images"
              className="svg-inline--fa fa-images fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"
              ></path>
            </svg>
            Multimedia
          </ListItemText>
        </StyledListItem>
        <StyledListItem
          selected={selectedIndex === 18}
          className="title-sidebar"
          style={{ paddingLeft: 18, textSize: 10 }}
          button
          component={Link}
          to="/reset-password"
          onClick={() => setSelectedIndex(6)}
        >
          <ListItemText>
            <svg
              heigth="15"
              width="15"
              style={{ marginRight: 12, marginBottom: 3 }}
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-cog"
              className="svg-inline--fa fa-user-cog fa-w-20"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M610.5 373.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 400.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm201.2 226.5c-2.3-1.2-4.6-2.6-6.8-3.9l-7.9 4.6c-6 3.4-12.8 5.3-19.6 5.3-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-5.5-17.7 1.9-36.4 17.9-45.7l7.9-4.6c-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-16-9.2-23.4-28-17.9-45.7.9-2.9 2.2-5.8 3.2-8.7-3.8-.3-7.5-1.2-11.4-1.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c10.1 0 19.5-3.2 27.2-8.5-1.2-3.8-2-7.7-2-11.8v-9.2z"
              ></path>
            </svg>
            Cambiar Contraseña
          </ListItemText>
        </StyledListItem>
      </List>
      <BotSide />
    </div>
  );
}
