import React, { useState, useEffect } from "react";
import "../theme/homepage.css";
import "../theme/cutOrder.css";
import ICutOrder from "../interfaces/ICutOrder";
import { setWeekNumber } from "../utils/Auxiliar/WeekAuxiliar";
import { checkSeasonShort } from "../utils/Auxiliar/SeasonAuxiliar";
import { getSeasonByName } from "../utils/Requests/SeasonRequests";
import { getPlantationsByCompanyId } from "../utils/Requests/PlantationRequests";
import IPlantation from "../interfaces/IPlantation";
import { getAllPlantationsVarieties } from "../utils/Requests/PlantationVarietyRequests";
import { Link } from "react-router-dom";
// import { searchCutOrder } from "../utils/Auxiliar/CutOrderAuxiliar";

import { getHarvestReportsByCompanyId } from "../utils/Requests/HarvestReportRequest";
import { searchHarvestReport2 } from "../utils/Auxiliar/HarvestReportAuxiliar";
import { useSelector } from "react-redux";
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";
import INotification from "../interfaces/INotification";
import { treatNumber } from "../utils/Auxiliar/EquivalentAuxiliar";
// import { createHarvestReportList } from "../utils/Auxiliar/HarvestReportAuxiliar";

const HarvestReportListView: React.FC = () => {
  interface IHarvestReportList {
    id: number;
    plantation: string;
    variety: string;
    volume: number;
    week: Date;
    state: number;
  }

  const weeks = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
  ];

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("Error");
  const [cutOrders, setCutOrders] = useState<ICutOrder[]>([]);
  const [plantations, setPlantations] = useState<IPlantation[]>([]);
  const [pltVarieties, setPltVarieties] = useState<[]>([]);
  const [varieties, setVarieties] = useState<[]>([]);
  const [harvestReporSearch, setHarvestReportSearch] = useState<
    IHarvestReportList[]
  >([]);
  const [variety, setVariety] = useState<string>("Cualquier variedad");
  const [week, setWeek] = useState<string>("Todas las semanas");
  const [plantation, setPlantation] = useState<string>("Todas las fincas");

  const [harvestReports, setHarvestReports] = useState<IHarvestReportList[]>(
    []
  );

  const [harvestReportIndex, setHarvestReportIndex] = useState(12);
  const { companyId, token } = useSelector(({ user }: IStore) => user as IUser);
  const notifications = useSelector(
    ({ notifications }: IStore) => notifications as INotification[]
  );
  useEffect(() => {
    let notsHr: INotification[] | undefined = notifications?.filter(
      (n: INotification) => n.type === 3
    );
    // obtengo las notificaciones de hr
    const season = checkSeasonShort();
    getSeasonByName(season, token)
      .then((response) => {
        getHarvestReportsByCompanyId(companyId!, response.id, token)
          .then((harvestReports) => {
            let aux2: any[] = [];
            harvestReports.forEach((h: any) => {
              let aux = notsHr?.find((n: INotification) => n.docId === h.id);

              if (aux) {
                aux2.push({ new: true, report: h });
              } else {
                aux2.push({ new: false, report: h });
              }
            });
            setHarvestReports(aux2);
            getPlantationsByCompanyId(companyId!, token)
              .then((plantations) => {
                setPlantations(plantations);
                getAllPlantationsVarieties(plantations, setPltVarieties, token)
                  .then((varieties: any) => {
                    let aux = varieties.filter(
                      (rv: any) => !rv.varietyName.includes("Hoja")
                    );

                    aux.sort(function (a: any, b: any) {
                      if (a.varietyName > b.varietyName) {
                        return 1;
                      }
                      if (a.varietyName < b.varietyName) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    });

                    aux.unshift({
                      varietyId: -1,
                      varietyName: "Cualquier variedad",
                      productName: "none",
                    });

                    setVarieties(aux);

                    setHarvestReportSearch(aux2);
                    // setPlantationsTable(pls.slice(0, numberShow));
                  })
                  .catch((error) => {
                    setAlertText(error.toString());
                    setShowAlert(true);
                  });
              })
              .catch((error) => {
                setAlertText(error.toString());
                setShowAlert(true);
              });
          })
          .catch((error) => {
            setAlertText(error.toString());
            setShowAlert(true);
          });
      })
      .catch((error) => {
        setAlertText(error.toString());
        setShowAlert(true);
      });
  }, [notifications, token, companyId]);

  const searchPlantationHandler = (event: any) => {
    let plantation = event.target.value;
    setPlantation(plantation);
    searchHarvestReport2(
      plantation,
      variety,
      week,
      setHarvestReportSearch,
      harvestReports
    );
  };

  const searchVarietyHandler = (event: any) => {
    let variety = event.target.value;
    setVariety(variety);

    searchHarvestReport2(
      plantation,
      variety,
      week,
      setHarvestReportSearch,
      harvestReports
    );
  };

  const searchWeekHandler = (event: any) => {
    let week = event.target.value;
    setWeek(week);
    searchHarvestReport2(
      plantation,
      variety,
      week,
      setHarvestReportSearch,
      harvestReports
    );
  };

  const clearFilters = () => {
    setPlantation("Todas las fincas");
    setVariety("Cualquier variedad");
    setWeek("Todas las semanas");
    setHarvestReportSearch(harvestReports);
  };

  return (
    <div className="div-principal2">
      <div className="top-panel">
        <div className="top-panel-text">Partes de Recogida</div>
        <div className="semana-div3">
          Semana actual{" "}
          <div className="dia-div3">{setWeekNumber(new Date())}</div>
        </div>
      </div>
      {harvestReports.length > 0 ? (
        <>
          <div className="select-groupCO">
            <select
              className="form-control form-control-sm ml-3"
              style={{ width: "12rem" }}
              data-spy="scroll"
              value={plantation}
              onChange={searchPlantationHandler}
            >
              <option>Todas las fincas</option>
              {plantations.map((pla: any) => (
                <option key={pla.name} value={pla.name}>
                  {pla.name}
                </option>
              ))}
            </select>
            <select
              className="form-control form-control-sm ml-3"
              style={{ width: "12rem" }}
              data-spy="scroll"
              value={variety}
              onChange={searchVarietyHandler}
            >
              {varieties.map((va: any) => (
                <option key={va.varietyName} value={va.varietyName}>
                  {va.varietyName}
                </option>
              ))}
            </select>

            <select
              className="form-control form-control-sm ml-3"
              style={{ width: "12rem" }}
              data-spy="scroll"
              value={week}
              onChange={searchWeekHandler}
            >
              <option>Todas las semanas</option>
              {weeks.map((wk: any) => (
                <option key={wk} value={wk}>
                  {wk}
                </option>
              ))}
            </select>

            <button
              className="button-search"
              style={{ height: 30 }}
              onClick={() => clearFilters()}
            >
              Quitar filtros
            </button>
          </div>

          <div style={{ marginTop: "2%" }}>
            <div
              className="container"
              style={{
                backgroundColor: "#ffffff",
                paddingTop: "20px",
                width: "85%",
                marginLeft: "5%",
                marginTop: "3%",
              }}
            >
              <table className="table" style={{ textAlign: "center" }}>
                <thead className="thead-dark">
                  <tr>
                    <th></th>
                    <th scope="col">CÓDIGO</th>
                    <th scope="col">SEMANA</th>
                    <th scope="col">VARIEDAD</th>
                    <th scope="col">FINCA</th>
                    <th scope="col">VOLUMEN TOTAL(Tn)</th>
                    <th scope="col">PARTE DE RECOGIDA</th>
                  </tr>
                </thead>

                <tbody>
                  {harvestReporSearch
                    .slice(0, harvestReportIndex)
                    ?.map((hvt: any) => (
                      <tr key={hvt.report.id}>
                        <td>
                          {hvt.new ? (
                            <svg
                              color="#15d60f"
                              width="15"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="circle"
                              className="svg-inline--fa fa-circle fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                              ></path>
                            </svg>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>AGR-{hvt?.report?.id}</td>
                        <td>{setWeekNumber(hvt?.report?.week)}</td>

                        <td>{hvt?.report?.variety}</td>
                        <td>{hvt?.report?.plantation}</td>
                        <td>{treatNumber(hvt?.report?.volume / 1000)}</td>
                        <td>
                          {hvt?.report?.state === 4 && (
                            <Link
                              to={`/parte-recogida/${hvt?.report?.id}`}
                              className="link"
                              style={{
                                color: "#cad401",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              {"Ver"}
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {harvestReporSearch.length &&
              harvestReporSearch.length > harvestReportIndex ? (
                <div className="d-flex justify-content-center mb-3 ">
                  <span
                    className="boldFontLink"
                    onClick={() =>
                      setHarvestReportIndex(harvestReportIndex + 5)
                    }
                  >
                    Ver más
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              fontSize: 25,
              fontWeight: 500,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            No hay partes de recogida
          </div>
        </>
      )}
    </div>
  );
};

export default HarvestReportListView;
